import { combineReducers } from 'redux';

import loadProfilePhotosError from './loadProfilePhotosError';
import createProfilePhotoError from './createProfilePhotoError';
import deleteProfilePhotoError from './deleteProfilePhotoError';
import updateProfilePhotoError from './updateProfilePhotoError';
import activateProfilePhotoError from './activateProfilePhotoError';


export default combineReducers({
  loadProfilePhotosError,
  createProfilePhotoError,
  deleteProfilePhotoError,
  updateProfilePhotoError,
  activateProfilePhotoError,
});
