import _get from 'lodash/get';


export const getItemInfo = state => _get(state, 'mediaViewer.itemInfo');

const baseInfoSelector = state => _get(getItemInfo(state), 'baseInfo');
export const getItemId = state => _get(baseInfoSelector(state), 'id');
export const getItemName = state => _get(baseInfoSelector(state), 'name');
export const getItemDescription = state => _get(baseInfoSelector(state), 'description');
export const getItemCategoryId = state => _get(baseInfoSelector(state), 'categoryId');
export const getItemCategoryLabel = state => _get(baseInfoSelector(state), 'categoryLabel');
export const getItemType = state => _get(baseInfoSelector(state), 'type');
export const getItemCost = state => _get(baseInfoSelector(state), 'cost');
export const getItemSales = state => _get(baseInfoSelector(state), 'sales');
export const getPostId = state => _get(baseInfoSelector(state), 'postId');
export const getPostText = state => _get(baseInfoSelector(state), 'text');

const ownerSelector = state => _get(getItemInfo(state), 'owner');
export const getItemOwnerId = state => _get(ownerSelector(state), 'userId');
export const getItemOwnerNick = state => _get(ownerSelector(state), 'nick');
export const getItemOwnerUsername = state => _get(ownerSelector(state), 'username');
export const getItemOwnerIsVisible = state => _get(ownerSelector(state), 'isVisible');
export const getItemOwnerIsBlueChecked = state => _get(ownerSelector(state), 'isBlueChecked');
export const getItemOwnerIam = state => _get(ownerSelector(state), 'iam');
export const getItemOwnerAge = state => _get(ownerSelector(state), 'age');
export const getItemOwnerProfilePhoto = state => _get(ownerSelector(state), 'profilePhoto');

export const getPrivatePhotoPreviewUrl = state => _get(getItemInfo(state), 'photo.previewUrl');

const videoSelector = state => _get(getItemInfo(state), 'video');
export const getVideoThumbUrl = state => _get(videoSelector(state), 'thumbUrl');
export const getVideoUrl = state => _get(videoSelector(state), 'videoUrl');
export const getVideoWidth = state => _get(videoSelector(state), 'width');
export const getVideoHeight = state => _get(videoSelector(state), 'height');
export const getVideoDuration = state => _get(videoSelector(state), 'duration');

const commentsSelector = state => _get(getItemInfo(state), 'comments');
export const getSendCommentError = state => _get(commentsSelector(state), 'sendError');
export const getItemComments = state => _get(commentsSelector(state), 'items');

export const getIsCommentBeingDeleted = (state, commentId) => (
  !!_get(state, `mediaViewer.isCommentBeingDeleted.${commentId}`)
);
