export const MODAL_DIALOG__ADVENT_PRIZES = 'MODAL_DIALOG__ADVENT_PRIZES';
export const MODAL_DIALOG__GIFT_EVENT = 'MODAL_DIALOG__GIFT_EVENT';
export const MODAL_DIALOG__FILL_NAME = 'MODAL_DIALOG__FILL_NAME';
export const MODAL_DIALOG__LOGIN = 'MODAL_DIALOG__LOGIN';
export const MODAL_DIALOG__MISSED_BONUSES = 'MODAL_DIALOG__MISSED_BONUSES';
export const MODAL_DIALOG__PAID_ROULETTE = 'MODAL_DIALOG__PAID_ROULETTE';
export const MODAL_DIALOG__PRIZES = 'MODAL_DIALOG__PRIZES';
export const MODAL_DIALOG__REGISTRATION = 'MODAL_DIALOG__REGISTRATION';
export const MODAL_DIALOG__ROULETTE = 'MODAL_DIALOG__ROULETTE';
export const MODAL_DIALOG__SOCIAL_LOGIN_ERROR = 'MODAL_DIALOG__SOCIAL_LOGIN_ERROR';
export const MODAL_DIALOG__TERMS = 'MODAL_DIALOG__TERMS';
