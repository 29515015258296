const SOCIAL_AUTH_FAILED = 'SOCIAL_NETWORK_AUTH_FAILED';
const SOCIAL_ALREADY_CONNECTED = 'SOCIAL_NETWORK_ALREADY_CONNECTED';
const SOCIAL_NO_EMAIL = 'SOCIAL_NETWORK_NO_EMAIL';
export const SOCIAL_USER_WITH_EMAIL_EXISTS = 'SOCIAL_NETWORK_USER_WITH_EMAIL_EXISTS';

export const socialErrors = [
  SOCIAL_AUTH_FAILED,
  SOCIAL_ALREADY_CONNECTED,
  SOCIAL_NO_EMAIL,
  SOCIAL_USER_WITH_EMAIL_EXISTS,
];

export const socialErrorLangKeys = {
  [SOCIAL_AUTH_FAILED]: 'msg.social-error.auth-failed',
  [SOCIAL_ALREADY_CONNECTED]: 'msg.social-error.already-connected',
  [SOCIAL_NO_EMAIL]: 'msg.social-error.no-email',
  // error is shown in separate dialog
  [SOCIAL_USER_WITH_EMAIL_EXISTS]: '',
};
