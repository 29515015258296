import { combineReducers } from 'redux';

import loadErostoriesError from './loadErostoriesError';
import createErostoryError from './createErostoryError';
import deleteErostoryError from './deleteErostoryError';
import updateErostoryError from './updateErostoryError';


export default combineReducers({
  loadErostoriesError,
  createErostoryError,
  deleteErostoryError,
  updateErostoryError,
});
