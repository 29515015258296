import _omit from 'lodash/omit';

import {
  SALE_TYPE__PRIVATE_PHOTO,
  SALE_TYPE__PRIVATE_VIDEO,
} from '../constants';


export const filterSaleTypeLangKeys = (saleTypeLangKeys, isPrivateContentForbidden) => (
  isPrivateContentForbidden
    ? _omit(saleTypeLangKeys, [SALE_TYPE__PRIVATE_PHOTO, SALE_TYPE__PRIVATE_VIDEO])
    : saleTypeLangKeys
);
