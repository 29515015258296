import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'revenue.errors');
export const getCancelBankAccountError = state => (
  _get(errorsSelector(state), 'cancelBankAccountError')
);
export const getCancelPayoutRequestError = state => (
  _get(errorsSelector(state), 'cancelPayoutRequestError')
);
export const getConnectRevolutError = state => (
  _get(errorsSelector(state), 'connectRevolutError')
);
export const getDeletePayoutRequestNoticeError = state => (
  _get(errorsSelector(state), 'deletePayoutRequestNoticeError')
);
export const getDownloadPayoutInvoiceError = state => (
  _get(errorsSelector(state), 'downloadPayoutInvoiceError')
);
export const getLoadBankAccountFileError = state => (
  _get(errorsSelector(state), 'loadBankAccountFileError')
);
export const getLoadLatestSalesError = state => (
  _get(errorsSelector(state), 'loadLatestSalesError')
);
export const getLoadPayoutComplaintSettingsError = state => (
  _get(errorsSelector(state), 'loadPayoutComplaintSettingsError')
);
export const getLoadRevenueSettingsError = state => (
  _get(errorsSelector(state), 'loadRevenueSettingsError')
);
export const getOrderXCardError = state => (
  _get(errorsSelector(state), 'orderXCardError')
);
export const getRequestPayoutError = state => (
  _get(errorsSelector(state), 'requestPayoutError')
);
export const getSendPayoutComplaintError = state => (
  _get(errorsSelector(state), 'sendPayoutComplaintError')
);
export const getSubmitFiscalNumberError = state => (
  _get(errorsSelector(state), 'submitFiscalNumberError')
);
export const getUpdateBankAccountDetailsError = state => (
  _get(errorsSelector(state), 'updateBankAccountDetailsError')
);
