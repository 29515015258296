import {
  ACTION_LOAD_LATEST_SALES__REQUEST,
  ACTION_LOAD_LATEST_SALES__SUCCESS,
  ACTION_LOAD_LATEST_SALES__FAIL,
} from '../actionTypes';

import { LATEST_SALES_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from '../../ownSaleItem/actions';


const actionLoadLatestSalesSuccess = latestSales => ({
  type: ACTION_LOAD_LATEST_SALES__SUCCESS,
  payload: { latestSales },
});

const actionLoadLatestSalesFail = error => ({
  type: ACTION_LOAD_LATEST_SALES__FAIL,
  payload: { error },
});

export const actionLoadLatestSalesRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LATEST_SALES__REQUEST,
    });

    return apiClientHelper.get(
      'sale/latest',
      {},
      LATEST_SALES_SCHEMA
    ).then(
      ({ entities }) => {
        dispatch(actionLoadLatestSalesSuccess(entities.latestSales || {}));
        dispatch(actionAddOwnSaleItems(entities.items));
      },
      error => {
        dispatch(actionLoadLatestSalesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
