import {
  ACTION_SET_LOGIN_INFO,
  ACTION_LOAD_LOGIN_INFO__REQUEST,
  ACTION_LOAD_LOGIN_INFO__SUCCESS,
  ACTION_LOAD_LOGIN_INFO__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const actionSetLoginInfo = loginInfo => ({
  type: ACTION_SET_LOGIN_INFO,
  payload: { loginInfo },
});

const actionLoadLoginInfoSuccess = () => ({
  type: ACTION_LOAD_LOGIN_INFO__SUCCESS,
});

const actionLoadLoginInfoFail = error => ({
  type: ACTION_LOAD_LOGIN_INFO__FAIL,
  payload: { error },
});

export const actionLoadLoginInfoRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LOGIN_INFO__REQUEST,
    });

    return apiClientHelper.get(
      'login/info'
    ).then(
      loginInfo => {
        dispatch(actionLoadLoginInfoSuccess());
        dispatch(actionSetLoginInfo(loginInfo));
      },
      error => {
        dispatch(actionLoadLoginInfoFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
