import React from 'react';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';

import { getIndexUrl } from '../../utils/url';

import { getLangToLogo } from '../../../initSettings/selectors';

import LangLink from '../../../core/i18n/components/LangLink';

import styles from './styles/index.css.json';


export default () => {
  const lang = useCurrentLang();
  const { url, width, height } = useShortSelector(getLangToLogo)[lang];

  const style = {};
  if (width && height) {
    style.width = `${width}px`;
    style.height = `${height}px`;
  }

  return (
    <LangLink to={getIndexUrl()}>
      <img
        src={url}
        alt="Logo"
        className={styles.logo}
        style={style}
      />
    </LangLink>
  );
};
