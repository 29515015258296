import { MODAL_DIALOG__WEEKEND_BONUS } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowWeekendBonusDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__WEEKEND_BONUS))
);

export const actionHideWeekendBonusDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__WEEKEND_BONUS))
);
