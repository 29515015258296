export const ACTION_LOAD_GIFTS__REQUEST = 'ACTIONO_LOAD_GIFTS__REQUEST';
export const ACTION_LOAD_GIFTS__SUCCESS = 'ACTIONO_LOAD_GIFTS__SUCCESS';
export const ACTION_LOAD_GIFTS__FAIL = 'ACTIONO_LOAD_GIFTS__FAIL';

export const ACTION_SEND_GIFT__REQUEST = 'ACTION_SEND_GIFT__REQUEST';
export const ACTION_SEND_GIFT__SUCCESS = 'ACTION_SEND_GIFT__SUCCESS';
export const ACTION_SEND_GIFT__FAIL = 'ACTION_SEND_GIFT__FAIL';

export const ACTION_SELECT_GIFT = 'ACTION_SELECT_GIFT';

export const ACTION_SET_IS_GIFT_SENDING_CONFIRMED = 'ACTION_SET_IS_GIFT_SENDING_CONFIRMED';
