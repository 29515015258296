import { defaultFilter } from '../constants';

import { getFilterByName } from 'react-redux-app/lib/modules/queryFilter/utils';


export const getGridFilterByName = (location, filterName, defaultFilterValue = defaultFilter) => {
  const filter = getFilterByName(location, filterName);

  return { ...defaultFilterValue, ...filter };
};
