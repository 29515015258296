import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';

import HighlightedNumber from '../HighlightedNumber';
import MenuLink from '../MenuLink';

import styles from './styles/index.css.json';


const propTypes = {
  to: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  titleLangKey: PropTypes.string.isRequired,
  number: PropTypes.number,
  className: PropTypes.string.isRequired,
};

const MaterialIconMenuLink = ({
  to,
  type,
  titleLangKey,
  number = null,
  className,
}) => {
  const { formatMessage: fm } = useIntl();

  return (
    <MenuLink to={to} className={classnames(styles.link, className)}>
      <i
        title={fm({ id: titleLangKey })}
        className={classnames('material-icons-outlined', styles.icon)}
      >
        {type}
      </i>

      {number > 0 && <HighlightedNumber number={number} className={styles.number} />}
    </MenuLink>
  );
};

MaterialIconMenuLink.propTypes = propTypes;

export default MaterialIconMenuLink;
