import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_BALANCE_ADJUSTMENTS,
  ACTION_ADD_EXPIRED_CREDITS,
  ACTION_ADD_FEES,
  ACTION_ADD_LOSSES_COMPENSATIONS,
  ACTION_ADD_PURCHASED_SERVICES,
  ACTION_ADD_PURCHASES,
  ACTION_ADD_REVENUE_CONVERSIONS,
  ACTION_ADD_SALES,
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST,
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS,
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL,
} from '../actionTypes';


const adjustmentsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_BALANCE_ADJUSTMENTS:
      return { ...state, ...action.payload.balanceAdjustments };

    default:
      return state;
  }
};

const lossesCompensationsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_LOSSES_COMPENSATIONS:
      return { ...state, ...action.payload.lossesCompensations };

    default:
      return state;
  }
};

const expiredCreditsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_EXPIRED_CREDITS:
      return { ...state, ...action.payload.expiredCredits };

    default:
      return state;
  }
};

const purchasesById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PURCHASES:
      return { ...state, ...action.payload.purchases };

    default:
      return state;
  }
};

const salesById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_SALES:
      return { ...state, ...action.payload.sales };

    default:
      return state;
  }
};

const feesById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_FEES:
      return { ...state, ...action.payload.fees };

    default:
      return state;
  }
};

const purchasedServicesById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PURCHASED_SERVICES:
      return { ...state, ...action.payload.purchasedServices };

    default:
      return state;
  }
};

const revenueConversionsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_REVENUE_CONVERSIONS:
      return { ...state, ...action.payload.revenueConversions };

    default:
      return state;
  }
};

const isRevenueConversionRequestBeingCanceled = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST:
      return { ...state, [action.payload.id]: true };

    case ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS:
    case ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL:
      return _omit(state, action.payload.id);

    default:
      return state;
  }
};

export default combineReducers({
  adjustmentsById,
  lossesCompensationsById,
  expiredCreditsById,
  purchasesById,
  salesById,
  feesById,
  purchasedServicesById,
  revenueConversionsById,
  isRevenueConversionRequestBeingCanceled,
  errors,
});
