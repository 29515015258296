import { getIndexUrl, getProfileUrl } from '../../app/utils/url';
import { getMediaViewerCloseUrl } from '../../mediaViewer/utils';

import { PROFILE_HASH } from '../constants';


export const hasProfileHash = hash => hash.startsWith(PROFILE_HASH);

export const getProfileId = hash => parseInt(hash.replace(PROFILE_HASH, ''), 10);

export const getProfileOverlayLocation = (location, id) => {
  const modifiedLocation = getMediaViewerCloseUrl(location);
  modifiedLocation.pathname = modifiedLocation.pathname.replace(
    new RegExp(getProfileUrl('\\d+')), getIndexUrl()
  );
  modifiedLocation.hash = `${PROFILE_HASH}${id}`;
  return modifiedLocation;
};
