import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_SET_EMOTICONS,
  ACTION_SET_EMOTICON_IDS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_EMOTICONS:
      return action.payload.emoticons;

    default:
      return state;
  }
};

const emoticons = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_EMOTICON_IDS:
      return action.payload.emoticonIds;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  emoticons,
  errors,
});
