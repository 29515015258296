import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_SET_PRESENTATIONS,

  ACTION_LOAD_PRESENTATIONS__REQUEST,
  ACTION_LOAD_PRESENTATIONS__SUCCESS,
  ACTION_LOAD_PRESENTATIONS__FAIL,
} from '../actionTypes/presentation';

import { OWN_ITEMS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionSetPresentations = presentations => ({
  type: ACTION_SET_PRESENTATIONS,
  payload: { presentations },
});

const actionLoadPresentationsSuccess = () => ({
  type: ACTION_LOAD_PRESENTATIONS__SUCCESS,
});

const actionLoadPresentationsFail = error => ({
  type: ACTION_LOAD_PRESENTATIONS__FAIL,
  payload: { error },
});

export const actionLoadPresentationsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PRESENTATIONS__REQUEST,
    });

    return apiClientHelper.get(
      'account/presentation/list',
      {},
      OWN_ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetPresentations(result));

          return dispatch(actionLoadPresentationsSuccess());
        }

        // Preload video thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          dispatch(actionSetPresentations(result));
          dispatch(actionLoadPresentationsSuccess());
        });
      },
      error => {
        dispatch(actionLoadPresentationsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
