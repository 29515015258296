import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import publicPhoto from './publicPhoto';
import video from './video';
import privatePhoto from './privatePhoto';
import erostory from './erostory';
import underwear from './underwear';
import personalContact from './personalContact';
import presentation from './presentation';
import profilePhoto from './profilePhoto';
import coverPhoto from './coverPhoto';
import errors from './errors';

import {
  ACTION_ADD_OWN_SALE_ITEMS,
  ACTION_ADD_OWN_SALE_ITEM,
  ACTION_DELETE_OWN_SALE_ITEM,
} from '../actionTypes';
import {
  ACTION_ADD_RECORDED_PRIVATES,
  ACTION_UPDATE_RECORDED_PRIVATE,
  ACTION_POST_RECORDED_PRIVATE__SUCCESS,
  ACTION_DELETE_RECORDED_PRIVATE__REQUEST,
  ACTION_DELETE_RECORDED_PRIVATE__SUCCESS,
  ACTION_DELETE_RECORDED_PRIVATE__FAIL,
} from '../actionTypes/recordedPrivate';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_SALE_ITEMS:
      return { ...state, ...action.payload.items };

    case ACTION_ADD_OWN_SALE_ITEM:
      return {
        ...state,
        [action.payload.item.id]: action.payload.item,
      };

    case ACTION_DELETE_OWN_SALE_ITEM:
      return _omit(state, action.payload.itemId);

    default:
      return state;
  }
};

const recordedPrivatesById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_RECORDED_PRIVATES:
      return { ...state, ...action.payload.recordedPrivates };

    case ACTION_UPDATE_RECORDED_PRIVATE:
      return {
        ...state,
        [action.payload.recordedPrivate.id]: {
          ...state[action.payload.recordedPrivate.id],
          ...action.payload.recordedPrivate,
        },
      };

    case ACTION_DELETE_RECORDED_PRIVATE__SUCCESS:
    case ACTION_POST_RECORDED_PRIVATE__SUCCESS:
      return _omit(state, action.payload.recordedPrivateId);

    default:
      return state;
  }
};

const isRecordedPrivateBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_RECORDED_PRIVATE__REQUEST:
      return { ...state, [action.payload.recordedPrivateId]: true };

    case ACTION_DELETE_RECORDED_PRIVATE__SUCCESS:
    case ACTION_DELETE_RECORDED_PRIVATE__FAIL:
      return _omit(state, action.payload.recordedPrivateId);

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  recordedPrivatesById,
  publicPhoto,
  video,
  privatePhoto,
  erostory,
  underwear,
  personalContact,
  presentation,
  profilePhoto,
  coverPhoto,
  isRecordedPrivateBeingDeleted,
  errors,
});
