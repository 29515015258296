import {
  ACTION_ACCEPT_PRIZE__REQUEST,
  ACTION_ACCEPT_PRIZE__SUCCESS,
  ACTION_ACCEPT_PRIZE__FAIL,
} from '../actionTypes';

import { ADVENT_PRIZE_RECEIVED_COOKIE } from '../constants';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionAcceptPrizeSuccess = () => ({
  type: ACTION_ACCEPT_PRIZE__SUCCESS,
});

const actionAcceptPrizeFail = error => ({
  type: ACTION_ACCEPT_PRIZE__FAIL,
  payload: { error },
});

export const actionAcceptPrizeRequest = id => (
  (dispatch, getState, { apiClientHelper, setCookie }) => {
    dispatch({
      type: ACTION_ACCEPT_PRIZE__REQUEST,
    });

    return apiClientHelper.put(
      `site_event/prize/${id}`
    ).then(
      result => {
        const ttl = result?.secondsUntilNextAdventPrize;

        if (ttl) {
          setCookie(
            ADVENT_PRIZE_RECEIVED_COOKIE,
            1,
            {
              expires: ttl * 1000,
              path: '/',
            }
          );
        }

        dispatch(actionAcceptPrizeSuccess());
      },
      error => {
        dispatch(actionAcceptPrizeFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
