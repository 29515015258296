import { getPathnamePrefix, getPathname, getFilter } from '../../utils';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsForBoys } from '../../../initSettings/selectors';


export default ({ getState, dispatch }) => (
  function checkAliasHandler(nextState, successCallback, errorCallback) {
    const lang = getCurrentLang(getState());
    const aliasPathname = getPathname(
      getCurrentLangForUrl(getState()),
      getPathnamePrefix(getIsForBoys(getState()), lang),
      getFilter(nextState.params.filter),
      lang
    );

    if (nextState.location.pathname !== aliasPathname) {
      errorCallback({ pathname: aliasPathname, state: { status: 301 } });
    } else {
      successCallback();
    }
  }
);
