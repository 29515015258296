export const ID_PAGE__1 = 'idPage1';
export const ID_PAGE__2 = 'idPage2';
export const AGREEMENT_PAGE__1 = 'agreement2257Page1';
export const AGREEMENT_PAGE__2 = 'agreement2257Page2';

export const PARTNERS_GRID_NAME = 'PartnersGrid';

export const PARTNER_STATUS__PENDING = 'pending';
export const PARTNER_STATUS__APPROVED = 'approved';
export const PARTNER_STATUS__DECLINED = 'declined';
export const PARTNER_STATUS__CANCELED = 'canceled';

export const partnerStatuses = [
  PARTNER_STATUS__PENDING,
  PARTNER_STATUS__APPROVED,
  PARTNER_STATUS__DECLINED,
  PARTNER_STATUS__CANCELED,
];

export const partnerStatusLangKeys = {
  [PARTNER_STATUS__PENDING]: 'msg.status.pending',
  [PARTNER_STATUS__APPROVED]: 'msg.status.approved',
  [PARTNER_STATUS__DECLINED]: 'msg.status.declined',
  [PARTNER_STATUS__CANCELED]: 'msg.status.canceled',
};
