import { takeLatest, put, select } from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalErrorWithHistory } from 'react-redux-app/lib/modules/api/utils';

import {
  ACTION_ADD_BLOCKED_USER__REQUEST,
  ACTION_ADD_BLOCKED_USER__SUCCESS,
  ACTION_ADD_BLOCKED_USER__FAIL,

  ACTION_DELETE_BLOCKED_USER__REQUEST,
  ACTION_DELETE_BLOCKED_USER__SUCCESS,
  ACTION_DELETE_BLOCKED_USER__FAIL,
} from '../../blackList/actionTypes';
import { ACTION_SEND_MESSAGE__SUCCESS } from '../../dialogMessage/actionTypes';
import {
  ACTION_LOAD_DIALOGS__SUCCESS,
  ACTION_LOAD_DIALOGS__FAIL,
  ACTION_LOAD_DIALOGS__REQUEST,

  ACTION_DELETE_DIALOG__REQUEST,
  ACTION_DELETE_DIALOG__SUCCESS,
  ACTION_DELETE_DIALOG__FAIL,
} from '../actionTypes';

import { getAreDialogsMounted } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddGlobalError } from 'react-redux-app/lib/modules/globalError/actions';
import { actionEnableDialogs, actionDisableDialogs } from '../actions';
import { actionLoadDialogsRequest } from '../actions/loadDialogs';


function* disableDialogs(action) {
  try {
    const isMounted = yield select(getAreDialogsMounted);
    if (!isMounted) {
      return;
    }
    console.consoleLog('disable Dialogs by action:', action);

    yield put(actionDisableDialogs());
  } catch (e) {
    yield put(actionAddException(e));
  }
}

function* enableDialogs(action) {
  try {
    const isMounted = yield select(getAreDialogsMounted);
    if (!isMounted) {
      return;
    }

    console.consoleLog('enable Dialogs by action:', action);

    yield put(actionEnableDialogs());
  } catch (e) {
    yield put(actionAddException(e));
  }
}

function* handleLoadDialogsError(history, action) {
  try {
    const { error } = action.payload;
    if (!maybeHandleCriticalErrorWithHistory(history, error.globalError)) {
      yield put(actionAddGlobalError({
        text: { id: 'error.grid-data.load' },
        header: { id: 'header.grid-data.load' },
      }));

      yield* enableDialogs(action);
    }
  } catch (e) {
    yield put(actionAddException(e));
  }
}

function* reloadDialogs(action) {
  try {
    const isMounted = yield select(getAreDialogsMounted);
    if (!isMounted) {
      return;
    }

    console.consoleLog('reload Dialogs by action:', action);

    yield put(actionLoadDialogsRequest());
  } catch (e) {
    yield put(actionAddException(e));
  }
}

export default function* saga(history) {
  yield takeLatest([
    ACTION_LOAD_DIALOGS__REQUEST,
    ACTION_DELETE_DIALOG__REQUEST,
    ACTION_ADD_BLOCKED_USER__REQUEST,
    ACTION_DELETE_BLOCKED_USER__REQUEST,
  ], disableDialogs);

  yield takeLatest([
    ACTION_LOAD_DIALOGS__SUCCESS,
    ACTION_DELETE_DIALOG__FAIL,
    ACTION_ADD_BLOCKED_USER__FAIL,
    ACTION_DELETE_BLOCKED_USER__FAIL,
  ], enableDialogs);

  yield takeLatest([
    ACTION_SEND_MESSAGE__SUCCESS,
    ACTION_DELETE_DIALOG__SUCCESS,
    ACTION_ADD_BLOCKED_USER__SUCCESS,
    ACTION_DELETE_BLOCKED_USER__SUCCESS,
  ], reloadDialogs);

  yield takeLatest([ACTION_LOAD_DIALOGS__FAIL], handleLoadDialogsError, history);
}
