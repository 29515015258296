import { getGridFilter } from '../utils/interviewForm';

import { ACTION_ADD_INTERVIEW_FORMS } from '../actionTypes';

import { INTERVIEW_FORMS_GRID_NAME } from '../constants/interviewForm';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


export const actionAddInterviewForms = interviewForms => ({
  type: ACTION_ADD_INTERVIEW_FORMS,
  payload: { interviewForms },
});

export const actionLoadInterviewFormsRequest = getActionLoadGridDataRequest(
  INTERVIEW_FORMS_GRID_NAME,
  getGridFilter,
  'account/interview/created/list',
  actionAddInterviewForms
);
