import { combineReducers } from 'redux';

import loadPostsError from './loadPostsError';
import createPostError from './createPostError';
import deletePostError from './deletePostError';
import updatePostError from './updatePostError';
import deleteMediaError from './deleteMediaError';


export default combineReducers({
  loadPostsError,
  createPostError,
  deletePostError,
  updatePostError,
  deleteMediaError,
});
