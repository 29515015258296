import _get from 'lodash/get';

import { getTotalPagesCount } from '../../core/utils/selectors';

import {
  getIsBlockedUserBeingDeleted,
  getIsUserBeingBlocked,
} from '../../blackList/selectors';


export const getDialog = (state, id) => _get(state, `dialog.byId.${id}`);

const dialogsSelector = state => _get(state, 'dialog.dialogs');
export const getDialogIds = state => _get(dialogsSelector(state), 'items');
const getDialogsTotalCount = state => _get(dialogsSelector(state), 'count');
const getDialogsLimit = state => _get(dialogsSelector(state), 'limit');
export const getDialogTotalPagesCount = state => getTotalPagesCount(
  state, getDialogsTotalCount, getDialogsLimit
);

export const getIsDialogBeingDeleted = (state, peerId) => (
  !!_get(state, `dialog.isDialogBeingDeleted.${peerId}`)
);
export const getAreDialogsMounted = state => _get(state, 'dialog.areDialogsMounted');
export const getAreDialogsDisabled = state => _get(state, 'dialog.areDialogsDisabled');
export const getAreActionsChanged = (state, peerId) => {
  const isBlockedUserBeingDeleted = getIsBlockedUserBeingDeleted(state, peerId);
  const isUserBeingBlocked = getIsUserBeingBlocked(state, peerId);
  const isDialogBeingDeleted = getIsDialogBeingDeleted(state, peerId);

  return isBlockedUserBeingDeleted || isUserBeingBlocked || isDialogBeingDeleted;
};
