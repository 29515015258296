import { normalize } from 'normalizr';

import { cookie, dom } from 'react-redux-app/lib/modules/core/utils';
import { httpStatusCodes } from 'react-redux-app/lib/modules/core/constants';

import formatApiUrl from './utils/formatApiUrl';

import * as apiErrors from './constants/errors';

import {
  SID_HEADER,
  SID_COOKIE,

  AF_USER_TOKEN_ID_HEADER,
  AF_USER_TOKEN_ID_COOKIE,
  COOKIE_AF_USER_TOKEN_ID_EXPIRES,

  RCID_COOKIE,

  USER_AGENT_UIDS_HEADER,
  USER_AGENT_UIDS_COOKIE,
  COOKIE_USER_AGENT_UIDS_EXPIRES,
} from './constants';

import { LANGUAGE_HEADER } from '../i18n/constants';


const superagent = require('superagent');

const rejectWithErrorsByResponse = async (err, res, reject) => {
  const getDefaultErrorCodeByStatusCode = httpStatusCode => {
    switch (httpStatusCode) {
      case httpStatusCodes.UNAUTHORIZED:
        return apiErrors.API_UNAUTHORIZED_ERROR;

      case httpStatusCodes.FORBIDDEN:
        return apiErrors.API_FORBIDDEN_ERROR;

      case httpStatusCodes.BAD_REQUEST:
        return apiErrors.API_BAD_REQUEST_ERROR;

        // case httpStatusCodes.NOT_FOUND:
        //  return apiErrors.API_NOT_FOUND_ERROR;

      default:
        return apiErrors.API_UNKNOWN_ERROR;
    }
  };

  const httpStatusCode = err.status || 500;
  let result = null;
  if (httpStatusCode === httpStatusCodes.BAD_REQUEST && res?.body) {
    const body = dom.isBrowser() && res.body instanceof Blob
      ? JSON.parse(await res.body.text())
      : res.body;
    const { globalError, fieldErrors } = body;

    if ((fieldErrors?.length > 0) || globalError) {
      result = {
        globalError: null,
        fieldErrors: [],
        ...body,
      };
    }
  }

  if (!result) {
    result = {
      globalError: { code: getDefaultErrorCodeByStatusCode(httpStatusCode) },
      fieldErrors: [],
    };
  }

  reject(result);
};

export default class ApiClient {
  constructor(nodeRequest = null, nodeResponse = null) {
    this.uiLang = null;

    this.nodeRequest = nodeRequest;
    this.nodeResponse = nodeResponse;

    ApiClient.getMethods().forEach(method => {
      this[method] = (
        path,
        {
          params,
          data,
          file = false,
          onProgress = null,
          blobResponse = false,
        } = {},
        schema,
        schemaOptions
      ) => new Promise((resolve, reject) => {
        const request = superagent[method](
          formatApiUrl(this.getDomain(), this.getCookie(RCID_COOKIE), path)
        );
        if (!dom.isBrowser()) {
          request.disableTLSCerts();
        }

        const headers = { Accept: 'application/json' };
        if (!file) {
          headers['Content-Type'] = 'application/json';
        }

        if (this.getCookie(SID_COOKIE)) {
          headers[SID_HEADER] = this.getCookie(SID_COOKIE);
        }

        if (this.getCookie(AF_USER_TOKEN_ID_COOKIE)) {
          headers[AF_USER_TOKEN_ID_HEADER] = this.getCookie(AF_USER_TOKEN_ID_COOKIE);
        }

        if (this.getCookie(USER_AGENT_UIDS_COOKIE)) {
          headers[USER_AGENT_UIDS_HEADER] = this.getCookie(USER_AGENT_UIDS_COOKIE);
        }

        if (this.uiLang) {
          headers[LANGUAGE_HEADER] = this.uiLang;
        }

        if (nodeRequest?.get('authorization')) {
          headers.Authorization = nodeRequest.get('authorization');
        }

        if (nodeRequest?.get('x-forwarded-for')) {
          headers['X-Forwarded-For'] = nodeRequest.get('x-forwarded-for');
        }

        if (nodeRequest?.get('user-agent')) {
          headers['User-Agent'] = nodeRequest.get('user-agent');
        }

        // Note: disables IE caching
        if (method === 'get') {
          headers['If-Modified-Since'] = 0;
        }

        request.set(headers);

        if (onProgress) {
          request.on('progress', onProgress);
        }

        if (params) {
          request.query(params);
        }

        if (data) {
          request.send(data);
        }

        if (blobResponse) {
          request.responseType('blob');
        }

        request.end(
          (err, res) => {
            if (err) {
              rejectWithErrorsByResponse(err, res, reject);
            } else {
              let result = res.body;

              if (res.headers[SID_HEADER.toLowerCase()]) {
                this.setCookie(
                  SID_COOKIE,
                  res.headers[SID_HEADER.toLowerCase()],
                  {
                    path: '/',
                  }
                );
              }

              if (res.headers[AF_USER_TOKEN_ID_HEADER.toLowerCase()]) {
                this.setCookie(
                  AF_USER_TOKEN_ID_COOKIE,
                  res.headers[AF_USER_TOKEN_ID_HEADER.toLowerCase()],
                  {
                    expires: COOKIE_AF_USER_TOKEN_ID_EXPIRES,
                    path: '/',
                  }
                );
              }

              if (res.headers[USER_AGENT_UIDS_HEADER.toLowerCase()]) {
                this.setCookie(
                  USER_AGENT_UIDS_COOKIE,
                  res.headers[USER_AGENT_UIDS_HEADER.toLowerCase()],
                  {
                    expires: COOKIE_USER_AGENT_UIDS_EXPIRES,
                    path: '/',
                  }
                );
              }

              if (schema && result) {
                result = normalize(result, schema, schemaOptions);
              }
              resolve(result);
            }
          }
        );
      });
    });
  }

  setUiLang(uiLang) {
    this.uiLang = uiLang;
  }

  static getMethods() {
    return ['get', 'post', 'put', 'del'];
  }

  getDomain() {
    return this.nodeRequest ? this.nodeRequest.hostname : window.location.host;
  }

  getCookie(name) {
    return this.nodeRequest ? this.nodeRequest.cookies[name] : cookie.getCookie(name);
  }

  setCookie(name, value, { expires, ...otherOptions }) {
    const options = otherOptions;

    if (this.nodeResponse) {
      if (typeof expires === 'number') {
        const date = new Date();
        date.setTime(date.getTime() + expires);
        options.expires = date;
      }

      this.nodeResponse.cookie(name, value, options);
    } else {
      cookie.setCookie(name, value, options);
    }
  }

  uploadFile(path, name, file) {
    return new Promise((resolve, reject) => {
      const request = superagent.put(
        formatApiUrl(this.getDomain(), this.getCookie(RCID_COOKIE), path),
        null,
        null
      );

      request
        .attach(name, file, file.name)
        .end(
          (err, response) => {
            if (err) {
              reject(response.body || err);
            } else {
              resolve(response);
            }
          }
        );
    });
  }
}

export const apiClient = new ApiClient();
