import { SORT_DIRECTION__ASC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const MODELS_GRID_NAME = 'ModelsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'nick',
  sortDirection: SORT_DIRECTION__ASC,
};
