import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _pick from 'lodash/pick';

import { getTabs } from '../../core/tabs/utils';

import { langLangKeys } from '../../core/language/constants';
import { sexPreferencesLangKeys } from '../constants';
import {
  ACCOUNT_TAB__ACCOUNT,
  ACCOUNT_TAB__BLUE_CHECK,
  ACCOUNT_TAB__PREFERENCES,
  ACCOUNT_TAB__PRESENTATION,
  ACCOUNT_TAB__PRIVACY,
  ACCOUNT_TAB__PROFILE,
  ACCOUNT_TAB__PROFILE_PHOTO,
} from '../constants/tabs';

import { getIsCommon, getIsModel } from '../../auth/selectors';
import { getSpokenLanguages, getSexPreferences } from '../../initSettings/selectors';


const accountDataSelector = state => _get(state, 'account.data');
export const getIsUserNickPending = state => _get(accountDataSelector(state), 'isNickPending');
export const getUserNick = state => _get(accountDataSelector(state), 'nick');
export const getUserEmail = state => _get(accountDataSelector(state), 'email');
export const getUserNewsletter = state => _get(accountDataSelector(state), 'newsletter');
export const getUserNotifications = state => _get(accountDataSelector(state), 'notifications');
export const getEmailResetHashExpirationDate = state => (
  _get(accountDataSelector(state), 'emailResetHashExpirationDate')
);
export const getHasPassword = state => _get(accountDataSelector(state), 'hasPassword');

const preferencesSelector = state => _get(state, 'account.preferences.userPreferences');
export const getUserLanguages = state => _get(preferencesSelector(state), 'languages');
export const getUserIam = state => _get(preferencesSelector(state), 'iam');
export const getUserHairPreferences = state => _get(preferencesSelector(state), 'hairPreferences');
export const getUserBreastsPreferences = state => (
  _get(preferencesSelector(state), 'breastsPreferences')
);
export const getUserPubesPreferences = state => (
  _get(preferencesSelector(state), 'pubesPreferences')
);
export const getUserCockPreferences = state => _get(preferencesSelector(state), 'cockPreferences');
export const getUserDescription = state => _get(preferencesSelector(state), 'description');
export const getUserMyfantasies = state => _get(preferencesSelector(state), 'myfantasies');
export const getUserDislike = state => _get(preferencesSelector(state), 'dislike');
export const getUserHobbies = state => _get(preferencesSelector(state), 'hobbies');

const fieldToIsPendingMapSelector = state => (
  _get(state, 'account.preferences.fieldToIsPendingMap')
);
export const getUserMyfantasiesPending = state => (
  _get(fieldToIsPendingMapSelector(state), 'myfantasies')
);
export const getUserDescriptionPending = state => (
  _get(fieldToIsPendingMapSelector(state), 'description')
);
export const getUserDislikePending = state => _get(fieldToIsPendingMapSelector(state), 'dislike');
export const getUserHobbiesPending = state => _get(fieldToIsPendingMapSelector(state), 'hobbies');

const webcamSettingsSelector = state => _get(state, 'account.webcamSettings');
export const getWebcamIsEnabled = state => (
  _get(webcamSettingsSelector(state), 'webcamSettings.isEnabled')
);
export const getWebcamType = state => _get(webcamSettingsSelector(state), 'webcamSettings.type');
export const getWebcamCost = state => _get(webcamSettingsSelector(state), 'webcamSettings.cost');
export const getWebcamSpyingIsEnabled = state => (
  _get(webcamSettingsSelector(state), 'scopoSettings.isEnabled')
);
export const getWebcamSpyingCost = state => (
  _get(webcamSettingsSelector(state), 'scopoSettings.cost')
);

const blueCheckSettingsSelector = state => _get(state, 'account.blueCheckSettings');
export const getBlueCheckStatus = state => _get(blueCheckSettingsSelector(state), 'blueCheck');
export const getBlueCheckAutoConvert = state => (
  _get(blueCheckSettingsSelector(state), 'autoRevenueConvert')
);
export const getBlueCheckPaidUntil = state => _get(blueCheckSettingsSelector(state), 'paidUntil');
export const getBlueCheckCost = state => _get(blueCheckSettingsSelector(state), 'cost');

const videochatExternalApplicationSettingsSelector = state => (
  _get(state, 'account.videochatExternalApplicationSettings')
);
export const getStreamingServerUrl = state => (
  _get(videochatExternalApplicationSettingsSelector(state), 'streamingServerUrl')
);
export const getStreamKey = state => (
  _get(videochatExternalApplicationSettingsSelector(state), 'streamKey')
);
export const getServerUrlForLarix = state => (
  _get(videochatExternalApplicationSettingsSelector(state), 'serverUrlForLarix')
);
export const getAreVideochatExternalApplicationSettingsBeingGenerated = state => (
  _get(state, 'account.areVideochatExternalApplicationSettingsBeingGenerated')
);

export const getVisibility = state => _get(state, 'account.privacy.visibility');

const pivacyAddressInformationSelector = state => (
  _get(state, 'account.privacyAddressInformation')
);
export const getPrivacyAddressInformationCountry = state => (
  _get(pivacyAddressInformationSelector(state), 'values.country')
);
export const getPrivacyAddressInformationProvince = state => (
  _get(pivacyAddressInformationSelector(state), 'values.province')
);
export const getPrivacyAddressInformationCity = state => (
  _get(pivacyAddressInformationSelector(state), 'values.city')
);
export const getPrivacyAddressInformationCountryIsVisible = state => (
  _get(pivacyAddressInformationSelector(state), 'isVisible.country')
);
export const getPrivacyAddressInformationProvinceIsVisible = state => (
  _get(pivacyAddressInformationSelector(state), 'isVisible.province')
);
export const getPrivacyAddressInformationCityIsVisible = state => (
  _get(pivacyAddressInformationSelector(state), 'isVisible.city')
);

export const getCountries = state => _get(state, 'account.countries');
export const getGeoFilters = state => _get(state, 'account.geoFiltersById');
export const getIsGeoFilterBeingDeleted = (state, geoFilterId) => (
  !!_get(state, `account.isGeoFilterBeingDeleted.${geoFilterId}`)
);

export const getWhiteList = state => _get(state, 'account.whiteListById');
export const getIsWhiteListedUserBeingDeleted = (state, userId) => (
  !!_get(state, `account.isWhiteListedUserBeingDeleted.${userId}`)
);

const modelInfoSelector = state => _get(state, 'account.modelProfile.modelInfo');
export const getModelZip = state => _get(modelInfoSelector(state), 'zip');
export const getModelAddress = state => _get(modelInfoSelector(state), 'address');
export const getModelCity = state => _get(modelInfoSelector(state), 'city');
export const getModelState = state => _get(modelInfoSelector(state), 'province');
export const getModelCountry = state => _get(modelInfoSelector(state), 'country');
export const getIsModelCityVisible = state => _get(modelInfoSelector(state), 'isCityVisible');
export const getIsModelProvinceVisible = state => (
  _get(modelInfoSelector(state), 'isProvinceVisible')
);
export const getIsModelCountryVisible = state => (
  _get(modelInfoSelector(state), 'isCountryVisible')
);
export const getModelStatusText = state => _get(modelInfoSelector(state), 'statusmessage');
const modelLanguagesSelector = state => _get(modelInfoSelector(state), 'languages');
export const getModelLanguages = createSelector(
  modelLanguagesSelector,
  getSpokenLanguages,
  (modelLanguages, spokenLanguages) => _intersection(modelLanguages, spokenLanguages)
);
export const getModelAdvertisedAge = state => _get(modelInfoSelector(state), 'advertisedAge');
export const getModelAgeStatus = state => _get(modelInfoSelector(state), 'ageStatus');
export const getModelIsBirthdayGreetingForbidden = state => (
  _get(modelInfoSelector(state), 'isBirthdayGreetingForbidden')
);
export const getModelHeight = state => _get(modelInfoSelector(state), 'height');
export const getModelBodyType = state => _get(modelInfoSelector(state), 'body');
export const getModelEthnicType = state => _get(modelInfoSelector(state), 'ethnic');
export const getModelCockType = state => _get(modelInfoSelector(state), 'cock');
const modelSexPreferencesSelector = state => _get(modelInfoSelector(state), 'sexPreferences');
export const getModelSexPreferences = createSelector(
  modelSexPreferencesSelector,
  getSexPreferences,
  (modelSexPreferences, sexPreferences) => _intersection(modelSexPreferences, sexPreferences)
);
export const getModelFantasies = state => _get(modelInfoSelector(state), 'myfantasies');
export const getModelWhysee = state => _get(modelInfoSelector(state), 'whysee');
export const getModelHobbies = state => _get(modelInfoSelector(state), 'hobbies');
export const getModelDislike = state => _get(modelInfoSelector(state), 'dislike');
export const getModelIam = state => _get(modelInfoSelector(state), 'iam');
export const getModelBreasts = state => _get(modelInfoSelector(state), 'breast');
export const getModelHair = state => _get(modelInfoSelector(state), 'hair');
export const getModelPubes = state => _get(modelInfoSelector(state), 'pubes');
export const getModelDescription = state => _get(modelInfoSelector(state), 'description');
export const getModelCouple = state => _get(modelInfoSelector(state), 'couple');
export const getModelSchedule = state => _get(modelInfoSelector(state), 'schedule');
export const getModelNick = state => _get(modelInfoSelector(state), 'nick');
export const getModelUsername = state => _get(modelInfoSelector(state), 'username');
export const getModelBlogDescription = state => _get(modelInfoSelector(state), 'blogDescription');
export const getModelSubscriptionPrice = state => _get(modelInfoSelector(state), 'subscriptionPrice');

const modelFieldToIsPendingMapSelector = state => (
  _get(state, 'account.modelProfile.fieldToIsPendingMap')
);
export const getModelZipPending = state => _get(modelFieldToIsPendingMapSelector(state), 'zip');
export const getModelAddressPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'address')
);
export const getModelCityPending = state => _get(modelFieldToIsPendingMapSelector(state), 'city');
export const getModelProvincePending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'province')
);
export const getModelDescriptionPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'description')
);
export const getModelDislikePending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'dislike')
);
export const getModelHobbiesPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'hobbies')
);
export const getModelMyfantasiesPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'myfantasies')
);
export const getModelStatusMessagePending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'statusmessage')
);
export const getModelWhyseePending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'whysee')
);
export const getModelNickPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'nick')
);
export const getModelUsernamePending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'username')
);
export const getModelBlogDescriptionPending = state => (
  _get(modelFieldToIsPendingMapSelector(state), 'blogDescription')
);

export const getModelHasApprovedPartners = state => (
  _get(state, 'account.modelProfile.hasApprovedPartners')
);

const telegramSettingsSelector = state => _get(state, 'account.telegramSettings');
export const getIsTelegramConnected = state => (
  _get(telegramSettingsSelector(state), 'isConnected')
);
const accountTelegramNotificationSettingsSelector = state => (
  _get(telegramSettingsSelector(state), 'notificationSettings')
);
export const getOnPrivateMessage = state => (
  _get(accountTelegramNotificationSettingsSelector(state), 'onPrivateMessage')
);
export const getOnModelInPersonalRoom = state => (
  _get(accountTelegramNotificationSettingsSelector(state), 'onModelInPersonalRoom')
);
export const getIsUserBeingDisconnectedFromTelegram = state => (
  _get(state, 'account.isUserBeingDisconnectedFromTelegram')
);

const pushSettingsSelector = state => _get(state, 'account.pushSettings');
export const getPushOnPrivateMessage = state => (
  _get(pushSettingsSelector(state), 'onPrivateMessage')
);
export const getPushOnModelInPersonalRoom = state => (
  _get(pushSettingsSelector(state), 'onModelInPersonalRoom')
);
export const getPushOnGiftReceived = state => _get(pushSettingsSelector(state), 'onGiftReceived');

export const getConnectedSocialTypes = state => _get(state, 'account.socialSettings.connected');

export const getAllowedSexPreferencesLangKeys = createSelector(
  getSexPreferences,
  sexPreferences => _pick(sexPreferencesLangKeys, sexPreferences)
);
export const getAllowedSpokenLanguageLangKeys = createSelector(
  getSpokenLanguages,
  spokenLanguages => _pick(langLangKeys, spokenLanguages)
);

export const getFlowerDeliverySettings = state => _get(state, 'account.flowerDeliverySettings');

export const getAccountTabs = createSelector(
  getIsCommon,
  getIsModel,
  (isCommon, isModel) => getTabs({
    [ACCOUNT_TAB__ACCOUNT]: true,
    [ACCOUNT_TAB__PROFILE_PHOTO]: true,
    [ACCOUNT_TAB__PRESENTATION]: isModel,
    [ACCOUNT_TAB__PRIVACY]: isCommon || isModel,
    [ACCOUNT_TAB__PREFERENCES]: isCommon,
    [ACCOUNT_TAB__PROFILE]: isModel,
    [ACCOUNT_TAB__BLUE_CHECK]: isModel,
  })
);
