import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const videoSelector = state => _get(state, 'saleItem.video');
const categoriesSelector = state => _get(videoSelector(state), 'categories');
export const getVideoCategories = state => _get(categoriesSelector(state), 'categories');

const videosSelector = state => _get(videoSelector(state), 'videos');
export const getVideoIds = state => _get(videosSelector(state), 'items');
export const getVideosTotalCount = state => _get(videosSelector(state), 'count');
const getVideosLimit = state => _get(videosSelector(state), 'limit');
export const getIsLastVideoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getVideoIds, getVideosTotalCount, getVideosLimit
);
export const getVideoTotalPagesCount = state => getTotalPagesCount(
  state, getVideosTotalCount, getVideosLimit
);

export const getVideoPaginationMode = state => _get(videoSelector(state), 'paginationMode');

const videoPreviewSelector = state => _get(videoSelector(state), 'preview');
export const getIsVideoPreviewBeingLoaded = (state, id) => (
  !!_get(videoPreviewSelector(state), `isBeingLoaded.${id}`)
);
export const getVideoPreviewFrames = (state, id) => (
  _get(videoPreviewSelector(state), `byId.${id}`)
);

export const getLoadVideoCategoriesError = state => (
  _get(categoriesSelector(state), 'errors.loadVideoCategoriesError')
);
export const getLoadVideosError = state => (
  _get(videoSelector(state), 'errors.loadVideosError')
);
export const getLoadVideoPreviewError = state => (
  _get(videoPreviewSelector(state), 'errors.loadVideoPreviewError')
);
