import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_SET_COVER_PHOTOS,
  ACTION_DELETE_COVER_PHOTO__REQUEST,
  ACTION_DELETE_COVER_PHOTO__SUCCESS,
  ACTION_DELETE_COVER_PHOTO__FAIL,
} from '../../actionTypes/coverPhoto';


const coverPhotos = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_COVER_PHOTOS:
      return action.payload.coverPhotos;

    case ACTION_DELETE_COVER_PHOTO__SUCCESS:
      return _without(state, action.payload.coverPhotoId);

    default:
      return state;
  }
};

const isCoverPhotoBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_COVER_PHOTO__REQUEST:
      return { ...state, [action.payload.coverPhotoId]: true };

    case ACTION_DELETE_COVER_PHOTO__SUCCESS:
    case ACTION_DELETE_COVER_PHOTO__FAIL:
      return _omit(state, action.payload.coverPhotoId);

    default:
      return state;
  }
};

export default combineReducers({
  coverPhotos,
  isCoverPhotoBeingDeleted,
  errors,
});
