import {
  ACTION_UPDATE_PERSONAL_CONTACT__REQUEST,
  ACTION_UPDATE_PERSONAL_CONTACT__FAIL,
  ACTION_UPDATE_PERSONAL_CONTACT__SUCCESS,
} from '../../../actionTypes/personalContact';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_UPDATE_PERSONAL_CONTACT__REQUEST:
    case ACTION_UPDATE_PERSONAL_CONTACT__SUCCESS:
      return null;

    case ACTION_UPDATE_PERSONAL_CONTACT__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
