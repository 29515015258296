import { dom } from 'react-redux-app/lib/modules/core/utils';
import { modifyHelpCamonLiveUrl } from '../../initSettings/utils';

import {
  ACTION_LOAD_ZENDESK_LOGIN_LINK__REQUEST,
  ACTION_LOAD_ZENDESK_LOGIN_LINK__SUCCESS,
  ACTION_LOAD_ZENDESK_LOGIN_LINK__FAIL,
} from '../actionTypes';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadZendeskLoginLinkSuccess = () => ({
  type: ACTION_LOAD_ZENDESK_LOGIN_LINK__SUCCESS,
});

const actionLoadZendeskLoginLinkFail = error => ({
  type: ACTION_LOAD_ZENDESK_LOGIN_LINK__FAIL,
  payload: { error },
});

export const actionLoadZendeskLoginLinkRequest = shouldRedirect => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_ZENDESK_LOGIN_LINK__REQUEST,
    });

    return apiClientHelper.get(
      'support/url'
    ).then(
      ({ url }) => {
        dispatch(actionLoadZendeskLoginLinkSuccess());

        if (shouldRedirect) {
          dom.redirect(modifyHelpCamonLiveUrl(url, getCurrentLang(getState())));
        }
      },
      error => {
        dispatch(actionLoadZendeskLoginLinkFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
