import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_ADD_INTERVIEWS,
  ACTION_ADD_INTERVIEW_IDS,
  ACTION_SET_INTERVIEW_IDS,
  ACTION_LOAD_INTERVIEW__SUCCESS,
  ACTION_ADD_INTERVIEW_FORMS,
  ACTION_LOAD_INTERVIEW_FORM__SUCCESS,
  ACTION_ADD_ANSWERED_INTERVIEWS,
  ACTION_ADD_STAFF_INTERVIEWS,
  ACTION_ADD_INTERVIEW_REQUESTS,
  ACTION_LOAD_INTERVIEWER__SUCCESS,
  ACTION_LOAD_INTERVIEWS__REQUEST,
  ACTION_LOAD_INTERVIEWS__SUCCESS,
  ACTION_LOAD_INTERVIEWS__FAIL,
  ACTION_SET_INTERVIEWS_PRESERVE,
  ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__SUCCESS,
  ACTION_CANCEL_INTERVIEW_FORM__REQUEST,
  ACTION_CANCEL_INTERVIEW_FORM__SUCCESS,
  ACTION_CANCEL_INTERVIEW_FORM__FAIL,
  ACTION_REJECT_INTERVIEW__REQUEST,
  ACTION_REJECT_INTERVIEW__SUCCESS,
  ACTION_REJECT_INTERVIEW__FAIL,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_INTERVIEWS:
      return { ...action.payload.interviews, ...state };

    default:
      return state;
  }
};

const interviews = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_INTERVIEW_IDS: {
      const items = _union(
        state.items,
        action.payload.interviews.items
      );

      return {
        items,
        count: action.payload.interviews.count,
        limit: action.payload.interviews.limit,
      };
    }

    case ACTION_SET_INTERVIEW_IDS:
      return action.payload.interviews;

    default:
      return state;
  }
};

const interview = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_INTERVIEW__SUCCESS:
      return action.payload.interview;

    default:
      return state;
  }
};

const interviewFormsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_INTERVIEW_FORMS:
      return { ...state, ...action.payload.interviewForms };

    default:
      return state;
  }
};

const interviewForm = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_INTERVIEW_FORM__SUCCESS:
      return action.payload.interviewForm;

    default:
      return state;
  }
};

const answeredInterviewsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_ANSWERED_INTERVIEWS:
      return { ...state, ...action.payload.answeredInterviews };

    default:
      return state;
  }
};

const staffInterviewRequestsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_STAFF_INTERVIEWS:
      return { ...state, ...action.payload.staffInterviewRequests };

    default:
      return state;
  }
};

const interviewRequestsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_INTERVIEW_REQUESTS:
      return { ...state, ...action.payload.interviewRequests };

    default:
      return state;
  }
};

const interviewer = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_INTERVIEWER__SUCCESS:
      return action.payload.interviewer;

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_INTERVIEWS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_INTERVIEWS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_INTERVIEWS__SUCCESS:
    case ACTION_LOAD_INTERVIEWS__FAIL:
      return null;

    default:
      return state;
  }
};

const createInterviewFormSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__SUCCESS:
      return action.payload.settings;

    default:
      return state;
  }
};

const isInterviewFormBeingCancelled = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CANCEL_INTERVIEW_FORM__REQUEST:
      return { ...state, [action.payload.interviewFormId]: true };

    case ACTION_CANCEL_INTERVIEW_FORM__SUCCESS:
    case ACTION_CANCEL_INTERVIEW_FORM__FAIL:
      return _omit(state, action.payload.interviewFormId);

    default:
      return state;
  }
};

const isInterviewBeingRejected = (state = {}, action) => {
  switch (action.type) {
    case ACTION_REJECT_INTERVIEW__REQUEST:
      return { ...state, [action.payload.inviteId]: true };

    case ACTION_REJECT_INTERVIEW__SUCCESS:
    case ACTION_REJECT_INTERVIEW__FAIL:
      return _omit(state, action.payload.inviteId);

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  interviews,
  interview,
  interviewFormsById,
  interviewForm,
  answeredInterviewsById,
  staffInterviewRequestsById,
  interviewRequestsById,
  interviewer,
  paginationMode,
  createInterviewFormSettings,
  isInterviewFormBeingCancelled,
  isInterviewBeingRejected,
  errors,
});
