import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_ADD_VISITORS,
  ACTION_ADD_VISITOR_IDS,
  ACTION_SET_VISITOR_IDS,
  ACTION_SET_VISITORS_PRESERVE,
  ACTION_LOAD_VISITORS__REQUEST,
  ACTION_LOAD_VISITORS__SUCCESS,
  ACTION_LOAD_VISITORS__FAIL,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getVisitorPaginationMode } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionAddVisitors = visitors => ({
  type: ACTION_ADD_VISITORS,
  payload: { visitors },
});

const actionAddVisitorIds = visitors => ({
  type: ACTION_ADD_VISITOR_IDS,
  payload: { visitors },
});

const actionSetVisitorIds = visitors => ({
  type: ACTION_SET_VISITOR_IDS,
  payload: { visitors },
});

export const actionSetVisitorsPreserve = () => ({
  type: ACTION_SET_VISITORS_PRESERVE,
});

const actionLoadVisitorsSuccess = () => ({
  type: ACTION_LOAD_VISITORS__SUCCESS,
});

const actionLoadVisitorsFail = error => ({
  type: ACTION_LOAD_VISITORS__FAIL,
  payload: { error },
});

export const actionLoadVisitorsRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getVisitorPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_VISITORS__REQUEST,
    });

    const { page = 1, limit = 12 } = getFilter(location);

    return apiClientHelper.get(
      `visitor/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: visitorIds, entities }) => {
        const visitors = entities.items || {};

        dispatch(actionAddVisitors(visitors));

        if (!dom.isBrowser()) {
          dispatch(actionSetVisitorIds(visitorIds));

          return dispatch(actionLoadVisitorsSuccess());
        }

        const promises = Object.keys(visitors).map(id => preloadImage(visitors[id].photoUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddVisitorIds(visitorIds));
          } else {
            dispatch(actionSetVisitorIds(visitorIds));
          }

          dispatch(actionLoadVisitorsSuccess());
        });
      },
      error => {
        dispatch(actionLoadVisitorsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
