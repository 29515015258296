import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_SET_USER,
  ACTION_LOAD_USER_PUBLIC_PHOTOS__SUCCESS,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_USER:
      return action.payload.user;

    case ACTION_LOAD_USER_PUBLIC_PHOTOS__SUCCESS: {
      const items = _union(
        state.publicPhotos.items,
        action.payload.publicPhotos.items
      );

      return {
        ...state,
        publicPhotos: {
          items,
          count: action.payload.publicPhotos.count,
          limit: action.payload.publicPhotos.limit,
        },
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
