import { schema } from 'normalizr';

import { postMediaAccessTypes } from '../constants';


const MEDIA_SCHEMA = new schema.Entity(
  'media'
);

const mediaStructure = {};
postMediaAccessTypes.forEach(accessType => { mediaStructure[accessType] = [MEDIA_SCHEMA]; });

export const POST_SCHEMA = new schema.Entity(
  'posts',
  { items: mediaStructure }
);

export const POSTS_SCHEMA = {
  items: [POST_SCHEMA],
};
