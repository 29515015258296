import { combineReducers } from 'redux';

import models from './models';
import preview from './preview';


export default combineReducers({
  models,
  preview,
});
