export const POST_VISIBILITY__FOR_ALL = 'public';
export const POST_VISIBILITY__ONLY_FOR_SUBSCRIBERS = 'subscribers-only';

export const postVisibilityLangKeys = {
  [POST_VISIBILITY__FOR_ALL]: 'msg.post-visibility.for-all',
  [POST_VISIBILITY__ONLY_FOR_SUBSCRIBERS]: 'msg.post-visibility.only-for-subscribers',
};

export const POST_MEDIA_ACCESS_TYPE__FREE = 'free';
export const POST_MEDIA_ACCESS_TYPE__SUBSCRIBERS = 'subscribers-only';
export const POST_MEDIA_ACCESS_TYPE__EXCLUSIVE = 'paid';

export const postMediaAccessTypes = [
  POST_MEDIA_ACCESS_TYPE__FREE,
  POST_MEDIA_ACCESS_TYPE__SUBSCRIBERS,
  POST_MEDIA_ACCESS_TYPE__EXCLUSIVE,
];

export const postMediaAccessTypeLangKeys = {
  [POST_MEDIA_ACCESS_TYPE__FREE]: 'msg.post-media-access-type.free',
  [POST_MEDIA_ACCESS_TYPE__SUBSCRIBERS]: 'msg.post-media-access-type.subscribers',
  [POST_MEDIA_ACCESS_TYPE__EXCLUSIVE]: 'msg.post-media-access-type.exclusive',
};

export const POST_MEDIA_TYPE__PHOTO = 'photo';
export const POST_MEDIA_TYPE__VIDEO = 'video';

export const postMediaTypes = [
  POST_MEDIA_TYPE__PHOTO,
  POST_MEDIA_TYPE__VIDEO,
];

export const POST_SENT_MEDIA_TYPE__POST_PHOTO = 'blog-post-photo';
export const POST_SENT_MEDIA_TYPE__POST_VIDEO = 'blog-post-video';
export const POST_SENT_MEDIA_TYPE__SALE_ITEM_PHOTO = 'sale-item-photo';
export const POST_SENT_MEDIA_TYPE__SALE_ITEM_VIDEO = 'sale-item-video';
export const POST_SENT_MEDIA_TYPE__UPLOADED_PHOTO = 'uploaded-photo';
export const POST_SENT_MEDIA_TYPE__UPLOADED_VIDEO = 'uploaded-video';

export const selectableSentMediaTypes = [
  POST_SENT_MEDIA_TYPE__SALE_ITEM_PHOTO,
  POST_SENT_MEDIA_TYPE__SALE_ITEM_VIDEO,
  POST_SENT_MEDIA_TYPE__POST_PHOTO,
  POST_SENT_MEDIA_TYPE__POST_VIDEO,
];

export const SELECT_MEDIA_DIALOG_ID = 'select-media-dialog';
