import {
  ACTION_LOAD_VIDEOS__REQUEST,
  ACTION_LOAD_VIDEOS__SUCCESS,
  ACTION_LOAD_VIDEOS__FAIL,
} from '../../../actionTypes/video';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEOS__REQUEST:
    case ACTION_LOAD_VIDEOS__SUCCESS:
      return null;

    case ACTION_LOAD_VIDEOS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
