export const ACTION_ADD_INIT_SETTINGS = 'ACTION_ADD_INIT_SETTINGS';
export const ACTION_ADD_INIT_SETTINGS_SEO = 'ACTION_ADD_INIT_SETTINGS_SEO';

export const ACTION_LOAD_INIT_SETTINGS__REQUEST = 'ACTION_LOAD_INIT_SETTINGS__REQUEST';
export const ACTION_LOAD_INIT_SETTINGS__SUCCESS = 'ACTION_LOAD_INIT_SETTINGS__SUCCESS';
export const ACTION_LOAD_INIT_SETTINGS__FAIL = 'ACTION_LOAD_INIT_SETTINGS__FAIL';

export const ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST = 'ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST';
export const ACTION_LOAD_PARTIAL_INIT_SETTINGS__SUCCESS = 'ACTION_LOAD_PARTIAL_INIT_SETTINGS__SUCCESS';
export const ACTION_LOAD_PARTIAL_INIT_SETTINGS__FAIL = 'ACTION_LOAD_PARTIAL_INIT_SETTINGS__FAIL';
