import { console } from 'react-redux-app/lib/modules/core/utils';
import { handleOnEnterException, url } from 'react-redux-app/lib/modules/routing/utils';
import { getNotFoundUrl, getSystemMessageUrl, getSystemErrorUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getConfirmEmailError, getOptoutEmailError } from '../../selectors/errors';

import { actionConfirmEmailRequest } from '../../actions/confirmEmail';
import { actionOptoutEmailRequest } from '../../actions/optoutEmail';


const EMAIL_TYPE__NEWSLETTER = 1;
const EMAIL_TYPE__NOTIFICATION = 2;

const emailTypeToLangKeyMap = {
  [EMAIL_TYPE__NEWSLETTER]: 'msg.optout-by-token.newsletter.success',
  [EMAIL_TYPE__NOTIFICATION]: 'msg.optout-by-token.notifications.success',
};

const proxyMap = {
  'confirm-email-change': {
    action: actionConfirmEmailRequest,
    getArgs: queryParams => [queryParams.userId, queryParams.hash],
    error: getConfirmEmailError,
    getSuccessLangKey: () => 'msg.confirm-email.success',
  },
  'optout-by-token': {
    action: actionOptoutEmailRequest,
    getArgs: queryParams => [queryParams.token, queryParams.type],
    error: getOptoutEmailError,
    getSuccessLangKey: queryParams => emailTypeToLangKeyMap[queryParams.type],
  },
};

export default ({ getState, dispatch }) => (
  function proxyPhpActionHandler(nextState, successCallback, errorCallback) {
    const queryParams = url.parseQuery(nextState.location.search);

    const proxy = proxyMap[queryParams.do];

    if (!proxy) {
      console.consoleError('proxyPhpActionHandler failed: missing proxy');
      errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
      return;
    }

    dispatch(
      proxy.action(...proxy.getArgs(queryParams))
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = proxy.error(getState());
        const langForUrl = getCurrentLangForUrl(getState());
        errorCallback(error
          ? getSystemErrorUrl(error.globalError.message, langForUrl)
          : getSystemMessageUrl(proxy.getSuccessLangKey(queryParams), langForUrl));
      }
    ).catch(error => {
      console.consoleError('proxyPhpActionHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
