export const ACTION_ADD_OWN_PUBLIC_PHOTOS = 'ACTION_ADD_OWN_PUBLIC_PHOTOS';
export const ACTION_SET_OWN_PUBLIC_PHOTOS = 'ACTION_SET_OWN_PUBLIC_PHOTOS';

export const ACTION_SET_OWN_PUBLIC_PHOTOS_PRESERVE = 'ACTION_SET_OWN_PUBLIC_PHOTOS_PRESERVE';
export const ACTION_SET_OWN_PUBLIC_PHOTO_ACTIVE_PAGE = 'ACTION_SET_OWN_PUBLIC_PHOTO_ACTIVE_PAGE';

export const ACTION_LOAD_OWN_PUBLIC_PHOTOS__REQUEST = 'ACTION_LOAD_OWN_PUBLIC_PHOTOS__REQUEST';
export const ACTION_LOAD_OWN_PUBLIC_PHOTOS__SUCCESS = 'ACTION_LOAD_OWN_PUBLIC_PHOTOS__SUCCESS';
export const ACTION_LOAD_OWN_PUBLIC_PHOTOS__FAIL = 'ACTION_LOAD_OWN_PUBLIC_PHOTOS__FAIL';

export const ACTION_CREATE_PUBLIC_PHOTO__REQUEST = 'ACTION_CREATE_PUBLIC_PHOTO__REQUEST';
export const ACTION_CREATE_PUBLIC_PHOTO__SUCCESS = 'ACTION_CREATE_PUBLIC_PHOTO__SUCCESS';
export const ACTION_CREATE_PUBLIC_PHOTO__FAIL = 'ACTION_CREATE_PUBLIC_PHOTO__FAIL';

export const ACTION_DELETE_PUBLIC_PHOTO__REQUEST = 'ACTION_DELETE_PUBLIC_PHOTO__REQUEST';
export const ACTION_DELETE_PUBLIC_PHOTO__SUCCESS = 'ACTION_DELETE_PUBLIC_PHOTO__SUCCESS';
export const ACTION_DELETE_PUBLIC_PHOTO__FAIL = 'ACTION_DELETE_PUBLIC_PHOTO__FAIL';

export const ACTION_UPDATE_PUBLIC_PHOTO__REQUEST = 'ACTION_UPDATE_PUBLIC_PHOTO__REQUEST';
export const ACTION_UPDATE_PUBLIC_PHOTO__SUCCESS = 'ACTION_UPDATE_PUBLIC_PHOTO__SUCCESS';
export const ACTION_UPDATE_PUBLIC_PHOTO__FAIL = 'ACTION_UPDATE_PUBLIC_PHOTO__FAIL';
