import { getIndexUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsPrivateContentForbidden } from '../../../initSettings/selectors';


export default ({ getState, dispatch }) => (
  function privateContentForbiddenHandler(nextState, successCallback, errorCallback) {
    if (getIsPrivateContentForbidden(getState())) {
      errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
