import {
  ACTION_UPDATE_PRESENTATION__REQUEST,
  ACTION_UPDATE_PRESENTATION__SUCCESS,
  ACTION_UPDATE_PRESENTATION__FAIL,
} from '../../../actionTypes/presentation';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_UPDATE_PRESENTATION__REQUEST:
    case ACTION_UPDATE_PRESENTATION__SUCCESS:
      return null;

    case ACTION_UPDATE_PRESENTATION__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
