import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


export const getInterview = (state, id) => _get(state, `interview.byId.${id}`);

const interviewsSelector = state => _get(state, 'interview.interviews');
export const getInterviewIds = state => _get(interviewsSelector(state), 'items');
export const getInterviewsTotalCount = state => _get(interviewsSelector(state), 'count');
const getInterviewsLimit = state => _get(interviewsSelector(state), 'limit');
export const getIsLastInterviewLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getInterviewIds, getInterviewsTotalCount, getInterviewsLimit
);
export const getInterviewTotalPagesCount = state => getTotalPagesCount(
  state, getInterviewsTotalCount, getInterviewsLimit
);

export const getInterviewPaginationMode = state => _get(state, 'interview.paginationMode');

const currentInterviewSelector = state => _get(state, 'interview.interview');
export const getInterviewId = state => _get(currentInterviewSelector(state), 'id');
export const getInterviewTitle = state => _get(currentInterviewSelector(state), 'title');
export const getInterviewDescription = state => (
  _get(currentInterviewSelector(state), 'description')
);
export const getInterviewDate = state => _get(currentInterviewSelector(state), 'created');
const currentInterviewModelSelector = state => _get(currentInterviewSelector(state), 'respondent');
export const getModelId = state => _get(currentInterviewModelSelector(state), 'id');
export const getModelNick = state => _get(currentInterviewModelSelector(state), 'nick');
export const getModelIsBlueChecked = state => (
  _get(currentInterviewModelSelector(state), 'isBlueChecked')
);
export const getModelCity = state => _get(currentInterviewModelSelector(state), 'city');
export const getModelAge = state => _get(currentInterviewModelSelector(state), 'age');
export const getModelThumbUrl = state => (
  _get(currentInterviewModelSelector(state), 'profilePhotoThumbUrl')
);
export const getInterviewAnswers = state => _get(currentInterviewSelector(state), 'answers');

const errorsSelector = state => _get(state, 'interview.errors');
export const getLoadInterviewsError = state => (
  _get(errorsSelector(state), 'loadInterviewsError')
);
export const getLoadInterviewError = state => (
  _get(errorsSelector(state), 'loadInterviewError')
);
