import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getIndexUrl, getUnderwearUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { FORBIDDEN } from '../../../core/api/constants/errors';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadUnderwearError } from '../../selectors/underwear';

import { actionLoadUnderwearRequest } from '../../actions/underwear';


export default ({ getState, dispatch }) => (
  function loadUnderwearHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadUnderwearRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadUnderwearError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            if (error.globalError?.code === FORBIDDEN) {
              errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
            } else if (error.fieldErrors) {
              errorCallback(getUnderwearUrl(getCurrentLangForUrl(getState())));
            } else {
              console.consoleError('loadUnderwearHandler error', error);
              errorCallback(INTERNAL_ERROR_URI);
            }
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadUnderwearHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
