import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { getUri, handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getLangFromRequestUri, getLangUrlPart, stripLangFromUri } from '../../utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getUILangs, getDefaultLang } from '../../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../../initSettings/selectors/errors';

import {
  actionLoadDefaultLangAndUiLangsRequest,
} from '../../../../initSettings/actions/loadDefaultLangAndUiLangs';
import { actionChangeLang } from '../../actions';


export default ({ getState, dispatch }) => (
  function checkLangHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadDefaultLangAndUiLangsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadPartialInitSettingsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            console.consoleError('checkLangHandler error', error);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        const currentUri = getUri(nextState.location);
        const urlLang = getLangFromRequestUri(currentUri);
        const lang = getCurrentLang(getState());
        const defaultLang = getDefaultLang(getState());
        const uiLangs = getUILangs(getState());

        const nextLang = (
          (uiLangs.includes(urlLang) && urlLang)
          || (uiLangs.includes(lang) && lang)
          || defaultLang
        );

        if (nextLang !== lang) {
          dispatch(actionChangeLang(nextLang));
        }

        const { pathname, search, hash } = nextState.location;
        const nextUrlLang = getLangUrlPart(nextLang, defaultLang);
        const nextUri = `${nextUrlLang}${stripLangFromUri(pathname)}${search}${hash}`;

        if (nextUri === currentUri) {
          successCallback();
          return;
        }

        errorCallback(nextUri);
      }
    ).catch(error => {
      console.consoleError('checkLangHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
