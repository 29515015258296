import { MODAL_DIALOG__ADVENT_PRIZES } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowAdventPrizesDialog = prizes => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__ADVENT_PRIZES, { prizes }))
);

export const actionHideAdventPrizesDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__ADVENT_PRIZES))
);
