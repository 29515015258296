import { useDispatch } from 'react-redux';


export default () => {
  const dispatch = useDispatch();

  return (action = null) => e => {
    if (action) {
      e.preventDefault();
      dispatch(action);
    }
  };
};
