import {
  ACTION_CANCEL_REWARD_REQUEST__REQUEST,
  ACTION_CANCEL_REWARD_REQUEST__SUCCESS,
  ACTION_CANCEL_REWARD_REQUEST__FAIL,
  ACTION_SEND_REWARD_REQUEST__REQUEST,
  ACTION_SEND_REWARD_REQUEST__SUCCESS,
  ACTION_SEND_REWARD_REQUEST__FAIL,
} from '../../actionTypes';

import { REWARD_REQUESTS_GRID_NAME } from '../../constants/rewardRequest';

import { actionLoadRewardRequestsRequest } from '../../actions/rewardRequestsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  REWARD_REQUESTS_GRID_NAME,
  actionLoadRewardRequestsRequest,
  [ACTION_CANCEL_REWARD_REQUEST__REQUEST, ACTION_SEND_REWARD_REQUEST__REQUEST],
  [ACTION_CANCEL_REWARD_REQUEST__FAIL, ACTION_SEND_REWARD_REQUEST__FAIL],
  [ACTION_CANCEL_REWARD_REQUEST__SUCCESS, ACTION_SEND_REWARD_REQUEST__SUCCESS]
);
