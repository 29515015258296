import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { loadableReady } from '@loadable/component';
import TagManager from 'react-gtm-module';

import { apiClient } from '../core/api/ApiClient';
import { apiClientHelper } from '../core/api/ApiClientHelper';
import { criticalErrorHandlersStorage } from 'react-redux-app/lib/modules/api/CriticalErrorHandlersStorage';
import configureStore from './configureStore';

import { cookie } from 'react-redux-app/lib/modules/core/utils';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getGoogleTagManagerId } from '../initSettings/selectors';

import { Routes } from 'react-redux-app/lib/modules/routing/components';
import Root from './components/Root';


class App {
  constructor(rootReducer, routes, sagas, getApiCriticalErrorHandlers) {
    apiClient.setUiLang(getCurrentLang(window.reduxInitialState));

    this.routes = routes;
    this.history = createBrowserHistory();

    this.reduxStore = configureStore(
      window.reduxInitialState,
      [],
      rootReducer,
      sagas,
      cookie.setCookie,
      cookie.getCookie,
      cookie.deleteCookie,
      apiClient,
      apiClientHelper,
      this.history
    );

    criticalErrorHandlersStorage.setErrorHandlers(getApiCriticalErrorHandlers(this.reduxStore));
  }

  start() {
    const gtmId = getGoogleTagManagerId(this.reduxStore.getState());
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }

    loadableReady(() => {
      hydrateRoot(
        document.getElementById('appRoot'),
        <Provider store={this.reduxStore} key="provider">
          <HistoryRouter history={this.history}>
            <Root>
              <Routes routes={this.routes} />
            </Root>
          </HistoryRouter>
        </Provider>
      );
    });
  }
}

export default App;
