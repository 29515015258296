import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, DropdownItem } from 'reactstrap';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { menuItemsPropType } from '../../propTypes';

import LangLink from '../../../core/i18n/components/LangLink';

import styles from './styles/index.css.json';


const propTypes = {
  itemLangKey: PropTypes.string.isRequired,
  items: menuItemsPropType,
  children: PropTypes.node,
};

const DropdownItemSubmenu = ({ itemLangKey, items = null, children = null }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DropdownItem
        tag="span"
        toggle={false}
        onClick={() => { setIsOpen(!isOpen); }}
        className={styles.toggle}
      >
        {l(itemLangKey)}
      </DropdownItem>

      <Collapse isOpen={isOpen} className={styles.collapse}>
        {items?.map(({ name, langKey, path }) => (
          <DropdownItem tag={LangLink} to={path} key={path}>
            {name || l(langKey)}
          </DropdownItem>
        ))}

        {children}
      </Collapse>
    </>
  );
};

DropdownItemSubmenu.propTypes = propTypes;

export default DropdownItemSubmenu;
