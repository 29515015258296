import {
  ACTION_CONTACT_US__REQUEST,
  ACTION_CONTACT_US__SUCCESS,
  ACTION_CONTACT_US__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_CONTACT_US__REQUEST:
    case ACTION_CONTACT_US__SUCCESS:
      return null;

    case ACTION_CONTACT_US__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
