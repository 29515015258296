import { MODAL_DIALOG__PRIZES } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowPrizesDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__PRIZES))
);

export const actionHidePrizesDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__PRIZES))
);
