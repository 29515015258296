import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


export const getFavorite = (state, id) => _get(state, `favorite.byId.${id}`);

const favoritesSelector = state => _get(state, 'favorite.favorites');
export const getFavoriteIds = state => _get(favoritesSelector(state), 'items');
export const getFavoritesTotalCount = state => _get(favoritesSelector(state), 'count');
const getFavoritesLimit = state => _get(favoritesSelector(state), 'limit');
export const getIsLastFavoriteLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getFavoriteIds, getFavoritesTotalCount, getFavoritesLimit
);
export const getFavoriteTotalPagesCount = state => getTotalPagesCount(
  state, getFavoritesTotalCount, getFavoritesLimit
);

export const getFavoritePaginationMode = state => _get(state, 'favorite.paginationMode');
