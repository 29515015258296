import { combineReducers } from 'redux';

import buyPostError from './buyPostError';
import likeMediaError from './likeMediaError';
import likePostError from './likePostError';
import loadBlogError from './loadBlogError';
import loadPostCommentsError from './loadPostCommentsError';
import loadPostsError from './loadPostsError';
import subscribe from './subscribe';
import unlikeMediaError from './unlikeMediaError';
import unlikePostError from './unlikePostError';
import unsubscribe from './unsubscribe';


export default combineReducers({
  buyPostError,
  likeMediaError,
  likePostError,
  loadBlogError,
  loadPostCommentsError,
  loadPostsError,
  subscribe,
  unlikeMediaError,
  unlikePostError,
  unsubscribe,
});
