import { combineReducers } from 'redux';

import errors from './errors';

import { ACTION_LOAD_EVENT_AWARDS__SUCCESS } from '../actionTypes';


const data = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_EVENT_AWARDS__SUCCESS:
      return action.payload.eventAwards;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
