import { schema } from 'normalizr';

import { ITEM_SCHEMA } from '../../core/schemas/items';


const PAYOUT_SCHEMA = new schema.Entity(
  'items',
  {},
  { idAttribute: value => value.request.id }
);

export const PAYOUTS_SCHEMA = {
  items: [PAYOUT_SCHEMA],
};

const LATEST_SALE_SCHEMA = new schema.Entity(
  'latestSales',
  { saleItem: { video: ITEM_SCHEMA } }
);

export const LATEST_SALES_SCHEMA = {
  items: [LATEST_SALE_SCHEMA],
};
