import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _omit from 'lodash/omit';

import { useUserProfilePhoto } from '../../../auth/hooks';

import { refPropType } from '../../../core/propTypes/ref';

import PhotoIcon from '../../../core/components/icons/PhotoIcon';

import styles from './styles/index.css.json';


const propTypes = {
  isMenu: PropTypes.bool,
  innerRef: refPropType,
  className: PropTypes.string,
};

const ProfilePhotoPreview = ({
  isMenu = false,
  innerRef = null,
  className = null,
  ...rest
}) => {
  const profilePhoto = useUserProfilePhoto();

  return !profilePhoto ? (
    <PhotoIcon
      {...rest}
      isSmall={!isMenu}
      innerRef={innerRef}
      className={className}
    />
  ) : (
    <img
      {..._omit(rest, ['children'])}
      src={profilePhoto}
      ref={innerRef}
      className={classnames(
        isMenu ? styles.photoPreview_menu : styles.photoPreview,
        className
      )}
    />
  );
};

ProfilePhotoPreview.propTypes = propTypes;

export default ProfilePhotoPreview;
