export const ACTION_LOAD_LATEST_PUBLIC_PHOTOS__REQUEST = 'ACTION_LOAD_LATEST_PUBLIC_PHOTOS__REQUEST';
export const ACTION_LOAD_LATEST_PUBLIC_PHOTOS__SUCCESS = 'ACTION_LOAD_LATEST_PUBLIC_PHOTOS__SUCCESS';
export const ACTION_LOAD_LATEST_PUBLIC_PHOTOS__FAIL = 'ACTION_LOAD_LATEST_PUBLIC_PHOTOS__FAIL';

export const ACTION_LOAD_LATEST_PRIVATE_PHOTOS__REQUEST = 'ACTION_LOAD_LATEST_PRIVATE_PHOTOS__REQUEST';
export const ACTION_LOAD_LATEST_PRIVATE_PHOTOS__SUCCESS = 'ACTION_LOAD_LATEST_PRIVATE_PHOTOS__SUCCESS';
export const ACTION_LOAD_LATEST_PRIVATE_PHOTOS__FAIL = 'ACTION_LOAD_LATEST_PRIVATE_PHOTOS__FAIL';

export const ACTION_LOAD_LATEST_VIDEOS__REQUEST = 'ACTION_LOAD_LATEST_VIDEOS__REQUEST';
export const ACTION_LOAD_LATEST_VIDEOS__SUCCESS = 'ACTION_LOAD_LATEST_VIDEOS__SUCCESS';
export const ACTION_LOAD_LATEST_VIDEOS__FAIL = 'ACTION_LOAD_LATEST_VIDEOS__FAIL';

export const ACTION_LOAD_LATEST_EROSTORIES__REQUEST = 'ACTION_LOAD_LATEST_EROSTORIES__REQUEST';
export const ACTION_LOAD_LATEST_EROSTORIES__SUCCESS = 'ACTION_LOAD_LATEST_EROSTORIES__SUCCESS';
export const ACTION_LOAD_LATEST_EROSTORIES__FAIL = 'ACTION_LOAD_LATEST_EROSTORIES__FAIL';

export const ACTION_LOAD_LATEST_UNDERWEAR__REQUEST = 'ACTION_LOAD_LATEST_UNDERWEAR__REQUEST';
export const ACTION_LOAD_LATEST_UNDERWEAR__SUCCESS = 'ACTION_LOAD_LATEST_UNDERWEAR__SUCCESS';
export const ACTION_LOAD_LATEST_UNDERWEAR__FAIL = 'ACTION_LOAD_LATEST_UNDERWEAR__FAIL';
