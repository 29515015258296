export default url => (
  new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      resolve();
    };
    image.onerror = () => {
      resolve(); // Ignore image load error
    };
    image.src = url;
  })
);
