const productionConfig = require('./config.production.json');

let config = {};

try {
  // eslint-disable-next-line import/no-unresolved,global-require
  const overrideConfig = require('./config.override.json');
  config = Object.assign({}, productionConfig, overrideConfig);
} catch (ex) {
  config = productionConfig;
}

const finalConfig = config;

export default finalConfig;
