import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_PURCHASED_UNDERWEAR,
  ACTION_SET_PURCHASED_UNDERWEAR,

  ACTION_SET_PURCHASED_UNDERWEAR_PRESERVE,
  ACTION_SET_PURCHASED_UNDERWEAR_ACTIVE_PAGE,

  ACTION_LOAD_PURCHASED_UNDERWEAR__REQUEST,
  ACTION_LOAD_PURCHASED_UNDERWEAR__SUCCESS,
  ACTION_LOAD_PURCHASED_UNDERWEAR__FAIL,
} from '../actionTypes/underwear';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getUnderwearPaginationMode } from '../selectors/underwear';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';


const actionAddPurchasedUnderwear = underwear => ({
  type: ACTION_ADD_PURCHASED_UNDERWEAR,
  payload: { underwear },
});

const actionSetPurchasedUnderwear = underwear => ({
  type: ACTION_SET_PURCHASED_UNDERWEAR,
  payload: { underwear },
});

export const actionSetPurchasedUnderwearPreserve = () => ({
  type: ACTION_SET_PURCHASED_UNDERWEAR_PRESERVE,
});

const actionSetPurchasedUnderwearActivePage = activePage => ({
  type: ACTION_SET_PURCHASED_UNDERWEAR_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadPurchasedUnderwearSuccess = () => ({
  type: ACTION_LOAD_PURCHASED_UNDERWEAR__SUCCESS,
});

const actionLoadPurchasedUnderwearFail = error => ({
  type: ACTION_LOAD_PURCHASED_UNDERWEAR__FAIL,
  payload: { error },
});

export const actionLoadPurchasedUnderwearRequest = ({ page = 1, limit = 36 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getUnderwearPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_PURCHASED_UNDERWEAR__REQUEST,
    });

    return apiClientHelper.get(
      `underwear/purchased/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: underwear, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetPurchasedUnderwear(underwear));
          dispatch(actionSetPurchasedUnderwearActivePage(page));

          return dispatch(actionLoadPurchasedUnderwearSuccess());
        }

        // Preload video thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddPurchasedUnderwear(underwear));
          } else {
            dispatch(actionSetPurchasedUnderwear(underwear));
          }

          dispatch(actionSetPurchasedUnderwearActivePage(page));
          dispatch(actionLoadPurchasedUnderwearSuccess());
        });
      },
      error => {
        dispatch(actionLoadPurchasedUnderwearFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
