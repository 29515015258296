import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_INIT_SETTINGS,
  ACTION_ADD_INIT_SETTINGS_SEO,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_ADD_INIT_SETTINGS:
      return { ...state, ...action.payload.settings };

    case ACTION_ADD_INIT_SETTINGS_SEO:
      return {
        ...state,
        seo: { ...state.seo, ...action.payload.seo },
      };

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
