export const ACTION_ADD_POSTS = 'ACTION_ADD_POSTS';
export const ACTION_ADD_MEDIA = 'ACTION_ADD_MEDIA';
export const ACTION_SET_BLOG = 'ACTION_SET_BLOG';
export const ACTION_SET_ACTIVE_VIDEO_UUID = 'ACTION_SET_ACTIVE_VIDEO_UUID';

export const ACTION_LOAD_BLOG__REQUEST = 'ACTION_LOAD_BLOG__REQUEST';
export const ACTION_LOAD_BLOG__SUCCESS = 'ACTION_LOAD_BLOG__SUCCESS';
export const ACTION_LOAD_BLOG__FAIL = 'ACTION_LOAD_BLOG__FAIL';

export const ACTION_LOAD_POSTS__REQUEST = 'ACTION_LOAD_POSTS__REQUEST';
export const ACTION_LOAD_POSTS__SUCCESS = 'ACTION_LOAD_POSTS__SUCCESS';
export const ACTION_LOAD_POSTS__FAIL = 'ACTION_LOAD_POSTS__FAIL';

export const ACTION_SUBSCRIBE__REQUEST = 'ACTION_SUBSCRIBE__REQUEST';
export const ACTION_SUBSCRIBE__SUCCESS = 'ACTION_SUBSCRIBE__SUCCESS';
export const ACTION_SUBSCRIBE__FAIL = 'ACTION_SUBSCRIBE__FAIL';

export const ACTION_UNSUBSCRIBE__REQUEST = 'ACTION_UNSUBSCRIBE__REQUEST';
export const ACTION_UNSUBSCRIBE__SUCCESS = 'ACTION_UNSUBSCRIBE__SUCCESS';
export const ACTION_UNSUBSCRIBE__FAIL = 'ACTION_UNSUBSCRIBE__FAIL';

export const ACTION_BUY_POST__REQUEST = 'ACTION_BUY_POST__REQUEST';
export const ACTION_BUY_POST__SUCCESS = 'ACTION_BUY_POST__SUCCESS';
export const ACTION_BUY_POST__FAIL = 'ACTION_BUY_POST__FAIL';

export const ACTION_LIKE_POST__REQUEST = 'ACTION_LIKE_POST__REQUEST';
export const ACTION_LIKE_POST__SUCCESS = 'ACTION_LIKE_POST__SUCCESS';
export const ACTION_LIKE_POST__FAIL = 'ACTION_LIKE_POST__FAIL';

export const ACTION_UNLIKE_POST__REQUEST = 'ACTION_UNLIKE_POST__REQUEST';
export const ACTION_UNLIKE_POST__SUCCESS = 'ACTION_UNLIKE_POST__SUCCESS';
export const ACTION_UNLIKE_POST__FAIL = 'ACTION_UNLIKE_POST__FAIL';

export const ACTION_LIKE_MEDIA__REQUEST = 'ACTION_LIKE_MEDIA__REQUEST';
export const ACTION_LIKE_MEDIA__SUCCESS = 'ACTION_LIKE_MEDIA__SUCCESS';
export const ACTION_LIKE_MEDIA__FAIL = 'ACTION_LIKE_MEDIA__FAIL';

export const ACTION_UNLIKE_MEDIA__REQUEST = 'ACTION_UNLIKE_MEDIA__REQUEST';
export const ACTION_UNLIKE_MEDIA__SUCCESS = 'ACTION_UNLIKE_MEDIA__SUCCESS';
export const ACTION_UNLIKE_MEDIA__FAIL = 'ACTION_UNLIKE_MEDIA__FAIL';

export const ACTION_LOAD_POST_COMMENTS__REQUEST = 'ACTION_LOAD_POST_COMMENTS__REQUEST';
export const ACTION_LOAD_POST_COMMENTS__SUCCESS = 'ACTION_LOAD_POST_COMMENTS__SUCCESS';
export const ACTION_LOAD_POST_COMMENTS__FAIL = 'ACTION_LOAD_POST_COMMENTS__FAIL';
