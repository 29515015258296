import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getNotFoundUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getLoadInterviewerError } from '../../selectors/ownInterview';

import { actionLoadInterviewerRequest } from '../../actions/loadInterviewer';


export default ({ getState, dispatch }) => (
  function loadInterviewerHandler(nextState, successCallback, errorCallback) {
    const { formId } = nextState.params;

    dispatch(
      actionLoadInterviewerRequest(formId)
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadInterviewerError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadInterviewerHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
