export const getIsLastLoaded = (
  state, activePageNumber, idsSelector, totalCountSelector, limitSelector
) => {
  const totalCount = totalCountSelector(state);
  if (totalCount === 0) {
    return true;
  }

  const loadedCount = idsSelector(state).length;
  const limit = limitSelector(state);
  const loadedPagesCount = Math.ceil(loadedCount / limit);
  const skippedPagesCount = activePageNumber - loadedPagesCount;

  return totalCount - limit * skippedPagesCount === loadedCount;
};

export const getTotalPagesCount = (state, totalCountSelector, limitSelector) => (
  Math.ceil(totalCountSelector(state) / limitSelector(state))
);
