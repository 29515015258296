import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_MEDIA_VIEWER_ITEMS,
  ACTION_SET_MEDIA_VIEWER_ITEMS,
  ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST,
  ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS,
  ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS,
  ACTION_DELETE_COMMENT__REQUEST,
  ACTION_DELETE_COMMENT__SUCCESS,
  ACTION_DELETE_COMMENT__FAIL,
  ACTION_SET_REPLY_RECEIVER,
  ACTION_REMOVE_REPLY_RECEIVER,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_MEDIA_VIEWER_ITEMS:
      return { ...state, ...action.payload.items };

    case ACTION_SET_MEDIA_VIEWER_ITEMS:
      return action.payload.items;

    default:
      return state;
  }
};

const items = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST:
      return null;

    case ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS:
      return action.payload.items;

    default:
      return state;
  }
};

const itemInfo = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST:
      return null;

    case ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS:
      return action.payload.itemInfo;

    case ACTION_DELETE_COMMENT__SUCCESS: {
      if (!state?.comments.items) {
        return state;
      }

      return {
        ...state,
        comments: {
          ...state.comments,
          items: state.comments.items.filter(
            comment => comment.commentId !== action.payload.commentId
          ),
        },
      };
    }

    default:
      return state;
  }
};

const isCommentBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_COMMENT__REQUEST:
      return { ...state, [action.payload.commentId]: true };

    case ACTION_DELETE_COMMENT__SUCCESS:
    case ACTION_DELETE_COMMENT__FAIL:
      return _omit(state, action.payload.commentId);

    default:
      return state;
  }
};

const receiver = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_REPLY_RECEIVER:
      return {
        id: action.payload.receiverId,
        nick: action.payload.receiverNick,
      };

    case ACTION_REMOVE_REPLY_RECEIVER:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  items,
  itemInfo,
  isCommentBeingDeleted,
  receiver,
  errors,
});
