import { MODAL_DIALOG__PAID_ROULETTE } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowPaidRouletteDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__PAID_ROULETTE))
);

export const actionHidePaidRouletteDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__PAID_ROULETTE))
);
