import {
  ACTION_BUY_UNDERWEAR__REQUEST,
  ACTION_BUY_UNDERWEAR__SUCCESS,
  ACTION_BUY_UNDERWEAR__FAIL,
} from '../../actionTypes/underwear';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_BUY_UNDERWEAR__REQUEST:
    case ACTION_BUY_UNDERWEAR__SUCCESS:
      return null;

    case ACTION_BUY_UNDERWEAR__FAIL:
      return action.payload.error;


    default:
      return state;
  }
};
