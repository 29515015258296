export const ACTION_COMPLAIN_COPYRIGHT__REQUEST = 'ACTION_COMPLAIN_COPYRIGHT__REQUEST';
export const ACTION_COMPLAIN_COPYRIGHT__SUCCESS = 'ACTION_COMPLAIN_COPYRIGHT__SUCCESS';
export const ACTION_COMPLAIN_COPYRIGHT__FAIL = 'ACTION_COMPLAIN_COPYRIGHT__FAIL';

export const ACTION_PROVIDE_INFORMATION__REQUEST = 'ACTION_PROVIDE_INFORMATION__REQUEST';
export const ACTION_PROVIDE_INFORMATION__SUCCESS = 'ACTION_PROVIDE_INFORMATION__SUCCESS';
export const ACTION_PROVIDE_INFORMATION__FAIL = 'ACTION_PROVIDE_INFORMATION__FAIL';

export const ACTION_REMOVE_CONTENT__REQUEST = 'ACTION_REMOVE_CONTENT__REQUEST';
export const ACTION_REMOVE_CONTENT__SUCCESS = 'ACTION_REMOVE_CONTENT__SUCCESS';
export const ACTION_REMOVE_CONTENT__FAIL = 'ACTION_REMOVE_CONTENT__FAIL';

export const ACTION_REPORT_CONTENT__REQUEST = 'ACTION_REPORT_CONTENT__REQUEST';
export const ACTION_REPORT_CONTENT__SUCCESS = 'ACTION_REPORT_CONTENT__SUCCESS';
export const ACTION_REPORT_CONTENT__FAIL = 'ACTION_REPORT_CONTENT__FAIL';

export const ACTION_REPORT_ILLEGAL_CONTENT__REQUEST = 'ACTION_REPORT_ILLEGAL_CONTENT__REQUEST';
export const ACTION_REPORT_ILLEGAL_CONTENT__SUCCESS = 'ACTION_REPORT_ILLEGAL_CONTENT__SUCCESS';
export const ACTION_REPORT_ILLEGAL_CONTENT__FAIL = 'ACTION_REPORT_ILLEGAL_CONTENT__FAIL';

export const ACTION_REPORT_ILLEGAL_CONTENT_TF__REQUEST = 'ACTION_REPORT_ILLEGAL_CONTENT_TF__REQUEST';
export const ACTION_REPORT_ILLEGAL_CONTENT_TF__SUCCESS = 'ACTION_REPORT_ILLEGAL_CONTENT_TF__SUCCESS';
export const ACTION_REPORT_ILLEGAL_CONTENT_TF__FAIL = 'ACTION_REPORT_ILLEGAL_CONTENT_TF__FAIL';
