import {
  ACTION_DELETE_BANNED_USER__REQUEST,
  ACTION_DELETE_BANNED_USER__SUCCESS,
  ACTION_DELETE_BANNED_USER__FAIL,
} from '../../actionTypes';

import { BANNED_USERS_GRID_NAME } from '../../constants';

import { actionLoadBannedUsersRequest } from '../../actions/bannedUsersGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  BANNED_USERS_GRID_NAME,
  actionLoadBannedUsersRequest,
  [ACTION_DELETE_BANNED_USER__REQUEST],
  [ACTION_DELETE_BANNED_USER__FAIL],
  [ACTION_DELETE_BANNED_USER__SUCCESS]
);
