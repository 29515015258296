import { ACTION_SET_EMOTICONS, ACTION_SET_EMOTICON_IDS } from '../actionTypes';


export const actionSetEmoticons = emoticons => ({
  type: ACTION_SET_EMOTICONS,
  payload: { emoticons },
});

export const actionSetEmoticonIds = emoticonIds => ({
  type: ACTION_SET_EMOTICON_IDS,
  payload: { emoticonIds },
});
