import { getUri } from 'react-redux-app/lib/modules/routing/utils';
import { getNotLoggedRedirectUrl } from '../../../auth/utils';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function userRequiredHandler(nextState, successCallback, errorCallback) {
    if (getIsAuthenticated(getState())) {
      successCallback();
    } else {
      errorCallback(getNotLoggedRedirectUrl(
        getCurrentLangForUrl(getState()), getUri(nextState.location)
      ));
    }
  }
);
