import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_BANNED_USERS,
  ACTION_DELETE_BANNED_USER__REQUEST,
  ACTION_DELETE_BANNED_USER__SUCCESS,
  ACTION_DELETE_BANNED_USER__FAIL,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_BANNED_USERS:
      return { ...state, ...action.payload.bannedUsers };

    case ACTION_DELETE_BANNED_USER__SUCCESS:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

const isBannedUserBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_BANNED_USER__REQUEST:
      return { ...state, [action.payload.userId]: true };

    case ACTION_DELETE_BANNED_USER__SUCCESS:
    case ACTION_DELETE_BANNED_USER__FAIL:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isBannedUserBeingDeleted,
  errors,
});
