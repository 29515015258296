import { combineReducers } from 'redux';

import loadVideosError from './loadVideosError';
import createVideoError from './createVideoError';
import deleteVideoError from './deleteVideoError';
import updateVideoError from './updateVideoError';


export default combineReducers({
  loadVideosError,
  createVideoError,
  deleteVideoError,
  updateVideoError,
});
