import { combineReducers } from 'redux';

import addBlockedUserError from './addBlockedUserError';
import deleteBlockedUserError from './deleteBlockedUserError';


export default combineReducers({
  addBlockedUserError,
  deleteBlockedUserError,
});
