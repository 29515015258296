import {
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST,
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS,
  ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL,
} from '../../actionTypes';

import { REVENUE_CONVERSIONS_GRID_NAME } from '../../constants/revenueConversion';

import { actionLoadRevenueConversionsRequest } from '../../actions/revenueConversionsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  REVENUE_CONVERSIONS_GRID_NAME,
  actionLoadRevenueConversionsRequest,
  [ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__REQUEST],
  [ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__FAIL],
  [ACTION_CANCEL_REVENUE_CONVERSION_REQUEST__SUCCESS]
);
