import ApiClient, { apiClient as apiClientInstance } from './ApiClient';

import { dom, format } from 'react-redux-app/lib/modules/core/utils';


const errorParamsFormatterMap = {
  maxFileSize: format.humanReadableSize,
  allowedMediaTypes: format.humanReadableFileTypes,
};

class ApiClientHelper {
  constructor(apiClient) {
    // Note: a hack [Issue #23847]
    this.isWindowUnloading = false;

    if (dom.isBrowser()) {
      window.addEventListener(
        'beforeunload',
        () => { this.isWindowUnloading = true; }
      );
    }

    ApiClient.getMethods().forEach(method => {
      this[method] = (...apiCallParams) => {
        if (this.isWindowUnloading) {
          return new Promise(() => {});
        }

        return new Promise((resolve, reject) => {
          apiClient[method](...apiCallParams).then(
            result => {
              if (this.isWindowUnloading) {
                return;
              }

              resolve(result);
            },
            error => {
              if (this.isWindowUnloading) {
                return;
              }

              reject(ApiClientHelper.formatRequestErrorParams(error));
            }
          );
        });
      };
    });
  }

  static formatRequestErrorParams({ globalError, fieldErrors }) {
    const result = {};

    if (globalError) {
      result.globalError = globalError;
    }
    if (fieldErrors) {
      result.fieldErrors = ApiClientHelper.formatErrorParams(fieldErrors);
    }

    return result;
  }

  static formatErrorParams(errors) {
    return errors.map(error => {
      const { params } = error;
      if (!params) {
        return error;
      }

      const formattedParams = {};
      Object.keys(params).forEach(paramName => {
        formattedParams[paramName] = (
          typeof errorParamsFormatterMap[paramName] === 'function'
            ? errorParamsFormatterMap[paramName](params[paramName])
            : params[paramName]
        );
      });

      return { ...error, params: formattedParams };
    });
  }
}

export default ApiClientHelper;

export const apiClientHelper = new ApiClientHelper(apiClientInstance);
