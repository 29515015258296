import { getFilterByName } from 'react-redux-app/lib/modules/queryFilter/utils';

import {
  ACTION_LOAD_DIALOGS__REQUEST,
  ACTION_LOAD_DIALOGS__SUCCESS,
  ACTION_LOAD_DIALOGS__FAIL,
  ACTION_ADD_DIALOGS,
  ACTION_SET_DIALOG_IDS,
} from '../actionTypes';

import { DIALOGS_GRID_NAME } from '../constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionAddDialogs = dialogs => ({
  type: ACTION_ADD_DIALOGS,
  payload: { dialogs },
});

const actionSetDialogIds = dialogIds => ({
  type: ACTION_SET_DIALOG_IDS,
  payload: { dialogIds },
});

const actionLoadDialogsSuccess = () => ({
  type: ACTION_LOAD_DIALOGS__SUCCESS,
});

const actionLoadDialogsFail = (location, error) => ({
  type: ACTION_LOAD_DIALOGS__FAIL,
  payload: { location, error },
});

export const actionLoadDialogsRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_LOAD_DIALOGS__REQUEST,
    });

    const { page = 1, nick, limit = 10 } = getFilterByName(location, DIALOGS_GRID_NAME) || {};

    const params = { excludeStaff: true, limit };
    if (nick) {
      params.nick = nick;
    }

    return apiClientHelper.get(
      `message/dialogs/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddDialogs(entities.items));

        dispatch(actionSetDialogIds(result));
        dispatch(actionLoadDialogsSuccess());
      },
      error => {
        dispatch(actionLoadDialogsFail(location, error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
