import {
  ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST,
  ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS,
  ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL,
} from '../../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST:
    case ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS:
      return null;

    case ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
