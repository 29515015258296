import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

import { getTabs } from '../../core/tabs/utils';

import {
  OWN_SALE_ITEMS_TAB__EROSTORIES,
  OWN_SALE_ITEMS_TAB__PERSONAL_CONTACTS,
  OWN_SALE_ITEMS_TAB__PHOTOS,
  OWN_SALE_ITEMS_TAB__RECORDED_PRIVATES,
  OWN_SALE_ITEMS_TAB__UNDERWEAR,
  OWN_SALE_ITEMS_TAB__VIDEOS,
} from '../constants/tabs';

import { getIsPrivateContentForbidden } from '../../initSettings/selectors';


export const getOwnSaleItems = state => (
  _get(state, 'ownSaleItem.byId')
);
export const getOwnSaleItem = (state, id) => (
  _get(getOwnSaleItems(state), id)
);
export const getOwnSaleItemName = (state, id) => (
  _get(getOwnSaleItem(state, id), 'name')
);
export const getOwnSaleItemPendingName = (state, id) => (
  _get(getOwnSaleItem(state, id), 'pendingName')
);
export const getOwnSaleItemDescription = (state, id) => (
  _get(getOwnSaleItem(state, id), 'description')
);
export const getOwnSaleItemPendingDescription = (state, id) => (
  _get(getOwnSaleItem(state, id), 'pendingDescription')
);
export const getOwnSaleItemCost = (state, id) => (
  _get(getOwnSaleItem(state, id), 'cost')
);
export const getOwnSaleItemCategoryId = (state, id) => (
  _get(getOwnSaleItem(state, id), 'categoryId')
);
export const getOwnSaleItemTags = (state, id) => (
  _get(getOwnSaleItem(state, id), 'tags')
);
export const getOwnSaleItemPendingTags = (state, id) => (
  _get(getOwnSaleItem(state, id), 'pendingTags')
);
export const getOwnSaleItemThumbUrl = (state, id) => (
  _get(getOwnSaleItem(state, id), 'thumbUrl')
);
export const getOwnSaleItemViews = (state, id) => (
  _get(getOwnSaleItem(state, id), 'views')
);
export const getOwnSaleItemWidth = (state, id) => (
  _get(getOwnSaleItem(state, id), 'width')
);
export const getOwnSaleItemHeight = (state, id) => (
  _get(getOwnSaleItem(state, id), 'height')
);
export const getOwnPhotoCropParams = createSelector(
  getOwnSaleItem,
  ownSaleItem => _pick(ownSaleItem, ['cropX', 'cropY', 'cropWidth', 'cropHeight'])
);
export const getOwnPhotoUrl = (state, id) => (
  _get(getOwnSaleItem(state, id), 'photoUrl')
);
export const getOwnStoryUrl = (state, id) => (
  _get(getOwnSaleItem(state, id), 'downloadUrl')
);
export const getOwnVideoUrl = (state, id) => (
  _get(getOwnSaleItem(state, id), 'videoUrl')
);
export const getOwnVideoPreviewFrames = (state, id) => (
  _get(getOwnSaleItem(state, id), 'slideshow')
);
export const getOwnVideoConverted = (state, id) => (
  _get(getOwnSaleItem(state, id), 'isConverted')
);
export const getOwnVideoEditable = (state, id) => (
  _get(getOwnSaleItem(state, id), 'isEditable')
);
export const getOwnVideoDuration = (state, id) => (
  _get(getOwnSaleItem(state, id), 'duration')
);

export const getIsRecordedPrivateBeingDeleted = (state, recordedPrivateId) => (
  !!_get(state, `ownSaleItem.isRecordedPrivateBeingDeleted.${recordedPrivateId}`)
);

export const getOwnSaleItemsTabs = createSelector(
  getIsPrivateContentForbidden,
  isPrivateContentForbidden => getTabs({
    [OWN_SALE_ITEMS_TAB__VIDEOS]: !isPrivateContentForbidden,
    [OWN_SALE_ITEMS_TAB__RECORDED_PRIVATES]: !isPrivateContentForbidden,
    [OWN_SALE_ITEMS_TAB__PHOTOS]: !isPrivateContentForbidden,
    [OWN_SALE_ITEMS_TAB__EROSTORIES]: true,
    [OWN_SALE_ITEMS_TAB__UNDERWEAR]: true,
    [OWN_SALE_ITEMS_TAB__PERSONAL_CONTACTS]: true,
  })
);
