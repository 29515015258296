import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_LOAD_VIDEO_CATEGORIES__REQUEST,
  ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS,
  ACTION_LOAD_VIDEO_CATEGORIES__FAIL,

  ACTION_LOAD_VIDEO_PREVIEW__REQUEST,
  ACTION_LOAD_VIDEO_PREVIEW__SUCCESS,
  ACTION_LOAD_VIDEO_PREVIEW__FAIL,

  ACTION_ADD_VIDEOS,
  ACTION_SET_VIDEOS,

  ACTION_SET_VIDEOS_PRESERVE,

  ACTION_LOAD_VIDEOS__REQUEST,
  ACTION_LOAD_VIDEOS__SUCCESS,
  ACTION_LOAD_VIDEOS__FAIL,
} from '../actionTypes/video';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getVideoPaginationMode } from '../selectors/video';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from './index';


const actionLoadVideoPreviewSuccess = (videoId, frames) => ({
  type: ACTION_LOAD_VIDEO_PREVIEW__SUCCESS,
  payload: { videoId, frames },
});

const actionLoadVideoPreviewFail = (error, videoId) => ({
  type: ACTION_LOAD_VIDEO_PREVIEW__FAIL,
  payload: { error, videoId },
});

export const actionLoadVideoPreviewRequest = videoId => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_VIDEO_PREVIEW__REQUEST,
      payload: { videoId },
    });

    return apiClientHelper.get(
      `video/${videoId}/slideshow/size_180x134`
    ).then(
      ({ thumbUrls }) => {
        const promises = thumbUrls.map(frame => preloadImage(frame));

        return Promise.all(promises).then(() => {
          dispatch(actionLoadVideoPreviewSuccess(videoId, thumbUrls));
        });
      },
      error => {
        dispatch(actionLoadVideoPreviewFail(error, videoId));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

const actionLoadVideoCategoriesSuccess = categories => ({
  type: ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS,
  payload: { categories },
});

const actionLoadVideoCategoriesFail = error => ({
  type: ACTION_LOAD_VIDEO_CATEGORIES__FAIL,
  payload: { error },
});

export const actionLoadVideoCategoriesRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_VIDEO_CATEGORIES__REQUEST,
    });

    return apiClientHelper.get(
      'video/categories'
    ).then(
      ({ categories }) => {
        dispatch(actionLoadVideoCategoriesSuccess(categories));
      },
      error => {
        dispatch(actionLoadVideoCategoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

const actionAddVideos = videos => ({
  type: ACTION_ADD_VIDEOS,
  payload: { videos },
});

export const actionSetVideos = videos => ({
  type: ACTION_SET_VIDEOS,
  payload: { videos },
});

export const actionSetVideosPreserve = () => ({
  type: ACTION_SET_VIDEOS_PRESERVE,
});

const actionLoadVideosSuccess = () => ({
  type: ACTION_LOAD_VIDEOS__SUCCESS,
});

const actionLoadVideosFail = error => ({
  type: ACTION_LOAD_VIDEOS__FAIL,
  payload: { error },
});

export const actionLoadVideosRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getVideoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_VIDEOS__REQUEST,
    });

    const {
      nick,
      page = 1,
      category,
      orderBy,
      limit = 36,
    } = getFilter(location);

    const params = { limit };
    if (nick) {
      params.nick = nick;
    }
    if (category) {
      params.category = category;
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }

    return apiClientHelper.get(
      `video/list/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result: videos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetVideos(videos));

          return dispatch(actionLoadVideosSuccess());
        }

        // Preload video thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].src));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddVideos(videos));
          } else {
            dispatch(actionSetVideos(videos));
          }

          dispatch(actionLoadVideosSuccess());
        });
      },
      error => {
        dispatch(actionLoadVideosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
