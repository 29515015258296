import _isEmpty from 'lodash/isEmpty';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getSearchUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getChatPreviewSettings } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import { getLoadModelsError } from '../../selectors/models';

import {
  actionLoadAllowedSexPreferencesAndPreviewSettingsRequest,
} from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import { actionLoadModelsForSearchRequest } from '../../actions/loadModels';


export default ({ getState, dispatch }) => (
  function loadModelsHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadLandingPageRequest()),
      dispatch(actionLoadModelsForSearchRequest()),
      dispatch(actionLoadAllowedSexPreferencesAndPreviewSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        const loadModelsError = getLoadModelsError(getState());
        if (loadModelsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadModelsError.globalError, errorCallback
          )) {
            errorCallback(getSearchUrl(getCurrentLangForUrl(getState())));
          }
          return;
        }

        const loadPartialInitSettingsError = getLoadPartialInitSettingsError(getState());
        if (loadPartialInitSettingsError) {
          if (!maybeHandleCriticalError(
            nextState.location, loadPartialInitSettingsError.globalError, errorCallback
          )) {
            console.consoleError('loadModelsHandler error', loadPartialInitSettingsError);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        if (_isEmpty(getChatPreviewSettings(getState()))) {
          console.consoleError('loadModelsHandler - unable to initialize application');
          errorCallback(INTERNAL_ERROR_URI);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadModelsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
