import { actionChangeLang as baseActionChangeLang } from 'react-redux-app/lib/modules/i18n/actions';


export const actionChangeLang = lang => (
  (dispatch, getState, { apiClient }) => {
    apiClient.setUiLang(lang);

    dispatch(baseActionChangeLang(lang));
  }
);
