import {
  ACTION_SEND_SPEED_TEST_RESULT__REQUEST,
  ACTION_SEND_SPEED_TEST_RESULT__SUCCESS,
  ACTION_SEND_SPEED_TEST_RESULT__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_SEND_SPEED_TEST_RESULT__REQUEST:
    case ACTION_SEND_SPEED_TEST_RESULT__SUCCESS:
      return null;

    case ACTION_SEND_SPEED_TEST_RESULT__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
