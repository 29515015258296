export const ACTION_ADD_FAVORITES = 'ACTION_ADD_FAVORITES';

export const ACTION_ADD_FAVORITE_IDS = 'ACTION_ADD_FAVORITE_IDS';
export const ACTION_SET_FAVORITE_IDS = 'ACTION_SET_FAVORITE_IDS';

export const ACTION_LOAD_FAVORITES__REQUEST = 'ACTION_LOAD_FAVORITES__REQUEST';
export const ACTION_LOAD_FAVORITES__SUCCESS = 'ACTION_LOAD_FAVORITES__SUCCESS';
export const ACTION_LOAD_FAVORITES__FAIL = 'ACTION_LOAD_FAVORITES__FAIL';

export const ACTION_SET_FAVORITES_PRESERVE = 'ACTION_SET_FAVORITES_PRESERVE';
