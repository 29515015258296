import _pick from 'lodash/pick';

import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_AWARDS,
  ACTION_ADD_AWARD_SETTINGS,
  ACTION_LOAD_AWARDS__REQUEST,
  ACTION_LOAD_AWARDS__SUCCESS,
  ACTION_LOAD_AWARDS__FAIL,
} from '../actionTypes';

import { AWARDS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionAddAwards = awards => ({
  type: ACTION_ADD_AWARDS,
  payload: { awards },
});

const actionAddAwardSettings = settings => ({
  type: ACTION_ADD_AWARD_SETTINGS,
  payload: { settings },
});

const actionLoadAwardsSuccess = () => ({
  type: ACTION_LOAD_AWARDS__SUCCESS,
});

const actionLoadAwardsFail = error => ({
  type: ACTION_LOAD_AWARDS__FAIL,
  payload: { error },
});

export const actionLoadAwardsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_AWARDS__REQUEST,
    });

    return apiClientHelper.get(
      'awards/current_month',
      {},
      AWARDS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const awards = entities.items || {};
        const settings = _pick(result, ['forModel', 'noProfilePhotoUrl']);

        if (!dom.isBrowser()) {
          dispatch(actionAddAwards(awards));
          dispatch(actionAddAwardSettings(settings));

          return dispatch(actionLoadAwardsSuccess());
        }

        const promises = Object.keys(awards).map(id => preloadImage(awards[id].photoUrl));

        return Promise.all(promises).then(() => {
          dispatch(actionAddAwards(awards));
          dispatch(actionAddAwardSettings(settings));
          dispatch(actionLoadAwardsSuccess());
        });
      },
      error => {
        dispatch(actionLoadAwardsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
