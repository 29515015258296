import _omit from 'lodash/omit';

import { getGridFilter } from '../utils/deposit';

import { ACTION_ADD_DEPOSITS } from '../actionTypes';

import { DEPOSITS_GRID_NAME } from '../constants/deposit';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddDeposits = deposits => ({
  type: ACTION_ADD_DEPOSITS,
  payload: { deposits },
});

export const actionLoadDepositsRequest = getActionLoadGridDataRequest(
  DEPOSITS_GRID_NAME,
  getGridFilter,
  'deposit/list',
  actionAddDeposits,
  filter => ({ ..._omit(filter, ['statuses']), 'statuses[]': filter.statuses })
);
