import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getFilter } from '../utils';


export default () => {
  const { filter } = useParams();

  return useMemo(() => getFilter(filter), [filter]);
};
