import { getUnbanUrl } from '../../utils/url';

import { BAN_LOCATION__CHAT } from '../../../auth/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated, getIsBanned, getBanLocation } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function notChatBannedRequiredHandler(nextState, successCallback, errorCallback) {
    if (
      getIsAuthenticated(getState())
      && getIsBanned(getState())
      && getBanLocation(getState()) === BAN_LOCATION__CHAT
    ) {
      errorCallback(getUnbanUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
