import {
  ACTION_POST_RECORDED_PRIVATE__REQUEST,
  ACTION_POST_RECORDED_PRIVATE__SUCCESS,
  ACTION_POST_RECORDED_PRIVATE__FAIL,
  ACTION_DELETE_RECORDED_PRIVATE__REQUEST,
  ACTION_DELETE_RECORDED_PRIVATE__SUCCESS,
  ACTION_DELETE_RECORDED_PRIVATE__FAIL,
} from '../../actionTypes/recordedPrivate';

import { RECORDED_PRIVATES_GRID_NAME } from '../../constants';

import { actionLoadRecordedPrivatesRequest } from '../../actions/recordedPrivatesGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  RECORDED_PRIVATES_GRID_NAME,
  actionLoadRecordedPrivatesRequest,
  [ACTION_POST_RECORDED_PRIVATE__REQUEST, ACTION_DELETE_RECORDED_PRIVATE__REQUEST],
  [ACTION_POST_RECORDED_PRIVATE__FAIL, ACTION_DELETE_RECORDED_PRIVATE__FAIL],
  [ACTION_POST_RECORDED_PRIVATE__SUCCESS, ACTION_DELETE_RECORDED_PRIVATE__SUCCESS]
);
