import _get from 'lodash/get';


const coverPhotoSelector = state => _get(state, 'ownSaleItem.coverPhoto');

export const getCoverPhotoIds = state => _get(coverPhotoSelector(state), 'coverPhotos');

export const getIsCoverPhotoBeingDeleted = (state, coverPhotoId) => (
  !!_get(coverPhotoSelector(state), `isCoverPhotoBeingDeleted.${coverPhotoId}`)
);

const errorsSelector = state => _get(coverPhotoSelector(state), 'errors');
export const getLoadCoverPhotosError = state => (
  _get(errorsSelector(state), 'loadCoverPhotosError')
);
export const getActivateCoverPhotoError = state => (
  _get(errorsSelector(state), 'activateCoverPhotoError')
);
export const getCreateCoverPhotoError = state => (
  _get(errorsSelector(state), 'createCoverPhotoError')
);
export const getDeleteCoverPhotoError = state => (
  _get(errorsSelector(state), 'deleteCoverPhotoError')
);
