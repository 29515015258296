import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_LOAD_VIDEO_PREVIEW__REQUEST,
  ACTION_LOAD_VIDEO_PREVIEW__SUCCESS,
  ACTION_LOAD_VIDEO_PREVIEW__FAIL,
} from '../../../actionTypes/video';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEO_PREVIEW__SUCCESS: {
      const { videoId, frames } = action.payload;

      return { ...state, [videoId]: frames };
    }

    default:
      return state;
  }
};

const isBeingLoaded = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEO_PREVIEW__REQUEST:
      return { ...state, [action.payload.videoId]: true };

    case ACTION_LOAD_VIDEO_PREVIEW__SUCCESS:
    case ACTION_LOAD_VIDEO_PREVIEW__FAIL:
      return _omit(state, action.payload.videoId);

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isBeingLoaded,
  errors,
});
