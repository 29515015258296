import { getUrl } from '../../../app/utils/url';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getStaticPage, getStaticPageIdWithLang } from '../../selectors';


export default ({ getState, dispatch }) => (
  function checkStaticPageHandler(nextState, successCallback, errorCallback) {
    const idWithLang = getStaticPageIdWithLang(getState(), nextState.location);
    const lang = getCurrentLang(getState());
    if (!idWithLang || idWithLang.lang === lang) {
      successCallback();
      return;
    }

    const staticPage = getStaticPage(getState(), idWithLang.id, lang);
    if (staticPage) {
      errorCallback(getUrl(getCurrentLangForUrl(getState()), staticPage.url));
      return;
    }

    successCallback();
  }
);
