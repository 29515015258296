import {
  ACTION_CREATE_PROFILE_PHOTO__REQUEST,
  ACTION_CREATE_PROFILE_PHOTO__SUCCESS,
  ACTION_CREATE_PROFILE_PHOTO__FAIL,
} from '../../../actionTypes/profilePhoto';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_CREATE_PROFILE_PHOTO__REQUEST:
    case ACTION_CREATE_PROFILE_PHOTO__SUCCESS:
      return null;

    case ACTION_CREATE_PROFILE_PHOTO__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
