import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const privatePhotoSelector = state => _get(state, 'saleItem.privatePhoto');
const categoriesSelector = state => _get(privatePhotoSelector(state), 'categories');
export const getPrivatePhotoCategories = state => _get(categoriesSelector(state), 'categories');

const privatePhotosSelector = state => _get(privatePhotoSelector(state), 'privatePhotos');
export const getPrivatePhotoIds = state => _get(privatePhotosSelector(state), 'items');
export const getPrivatePhotosTotalCount = state => _get(privatePhotosSelector(state), 'count');
const getPrivatePhotosLimit = state => _get(privatePhotosSelector(state), 'limit');
export const getIsLastPrivatePhotoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getPrivatePhotoIds, getPrivatePhotosTotalCount, getPrivatePhotosLimit
);
export const getPrivatePhotoTotalPagesCount = state => getTotalPagesCount(
  state, getPrivatePhotosTotalCount, getPrivatePhotosLimit
);

export const getPrivatePhotoPaginationMode = state => (
  _get(privatePhotoSelector(state), 'paginationMode')
);

export const getLoadPrivatePhotoCategoriesError = state => (
  _get(categoriesSelector(state), 'errors.loadPrivatePhotoCategoriesError')
);
export const getLoadPrivatePhotosError = state => (
  _get(privatePhotoSelector(state), 'errors.loadPrivatePhotosError')
);
