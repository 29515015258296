import {
  ACTION_LOAD_SPIN_PRICE__REQUEST,
  ACTION_LOAD_SPIN_PRICE__SUCCESS,
  ACTION_LOAD_SPIN_PRICE__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadSpinPriceSuccess = spinPrice => ({
  type: ACTION_LOAD_SPIN_PRICE__SUCCESS,
  payload: { spinPrice },
});

const actionLoadSpinPriceFail = error => ({
  type: ACTION_LOAD_SPIN_PRICE__FAIL,
  payload: { error },
});

export const actionLoadSpinPriceRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_SPIN_PRICE__REQUEST,
    });

    return apiClientHelper.get(
      'site_event/spin/price'
    ).then(
      spinPrice => {
        dispatch(actionLoadSpinPriceSuccess(spinPrice));
      },
      error => {
        dispatch(actionLoadSpinPriceFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
