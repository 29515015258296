import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_AFFILIATED_USERS,
  ACTION_LOAD_REFERER_CODE__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_AFFILIATED_USERS:
      return { ...state, ...action.payload.affiliates };

    default:
      return state;
  }
};

const refererCode = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_REFERER_CODE__SUCCESS:
      return action.payload.refererCode;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  refererCode,
  errors,
});
