import { combineReducers } from 'redux';

import cancelUnderwearRequestError from './cancelUnderwearRequestError';
import markUnderwearSentError from './markUnderwearSentError';


export default combineReducers({
  markUnderwearSentError,
  cancelUnderwearRequestError,
});
