import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_LOAD_STEP__SUCCESS,
  ACTION_SET_IS_AGREEMENT_ACCEPTED,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_STEP__SUCCESS:
      return action.payload.step;

    default:
      return state;
  }
};

const isAgreementAccepted = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_IS_AGREEMENT_ACCEPTED:
      return action.payload.isAccepted;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  isAgreementAccepted,
  errors,
});
