import { getUnbanUrl } from '../../utils/url';

import { BAN_LOCATION__SITE } from '../../../auth/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import {
  getIsAuthenticated,
  getIsBanned,
  getIsBanPermanent,
  getBanLocation,
} from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function notBannedRequiredHandler(nextState, successCallback, errorCallback) {
    if (
      getIsAuthenticated(getState())
      && getIsBanned(getState())
      && (getIsBanPermanent(getState()) || getBanLocation(getState()) === BAN_LOCATION__SITE)
    ) {
      errorCallback(getUnbanUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
