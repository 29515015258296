import _get from 'lodash/get';


export const getBlackList = state => _get(state, 'blackList.byId');

export const getIsBlockedUserBeingDeleted = (state, userId) => (
  !!_get(state, `blackList.isBlockedUserBeingDeleted.${userId}`)
);
export const getIsUserBeingBlocked = (state, userId) => (
  !!_get(state, `blackList.isUserBeingBlocked.${userId}`)
);
export const getIsUserBlocked = state => (
  _get(state, 'blackList.isUserBlocked')
);
