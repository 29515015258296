import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import { getUserRevenue } from '../selectors';


export default (round = true) => {
  const revenue = useShortSelector(getUserRevenue);

  return round ? Math.floor(revenue) : revenue;
};
