import { getUri, url } from 'react-redux-app/lib/modules/routing/utils';
import { getLoadingUrl } from '../utils/url';

import {
  ERROR_CODE_QUERY_PARAM,
  AUTH_TOKEN_QUERY_PARAM,
  SOCIAL_TOKEN_QUERY_PARAM,
  REDIRECT_URL_QUERY_PARAM,
} from '../../core/social/constants';

import { getCurrentLangForUrl } from '../../core/i18n/selectors';


export const actionCallOnEnterHooks = () => (
  (dispatch, getState, { history }) => {
    const currentUri = url.removeQueryParams(getUri(history.location), [
      ERROR_CODE_QUERY_PARAM,
      AUTH_TOKEN_QUERY_PARAM,
      SOCIAL_TOKEN_QUERY_PARAM,
      REDIRECT_URL_QUERY_PARAM,
    ]);

    // Force react-router to call onEnter hooks by changing route
    history.push(getLoadingUrl(getCurrentLangForUrl(getState())));

    setTimeout(() => { history.push(currentUri); }, 300);
  }
);
