import { useCallCancellablePromise } from 'react-redux-app/lib/modules/core/hooks';
import useHandleErrorOrExecute from './useHandleErrorOrExecute';


export default errorSelector => {
  const callCancellablePromise = useCallCancellablePromise(errorSelector);
  const handleErrorOrExecute = useHandleErrorOrExecute();

  return (
    promise,
    errorDialogHeaderLangKey,
    successCallback = null,
    errorCallback = null,
    reloadPageOnGlobalError = true,
    callback = null,
    globalErrorCodes = null
  ) => {
    callCancellablePromise(
      promise,
      error => {
        handleErrorOrExecute(
          error,
          errorDialogHeaderLangKey,
          successCallback,
          errorCallback,
          reloadPageOnGlobalError,
          callback,
          globalErrorCodes
        );
      }
    );
  };
};
