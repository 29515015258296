import _omit from 'lodash/omit';

import { getGridFilter } from '../utils';

import { ACTION_ADD_UNDERWEAR_REQUESTS } from '../actionTypes';

import { UNDERWEAR_REQUESTS_GRID_NAME } from '../constants';

import { UNDERWEAR_REQUESTS_SCHEMA } from '../schemas';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddUnderwearRequests = underwearRequests => ({
  type: ACTION_ADD_UNDERWEAR_REQUESTS,
  payload: { underwearRequests },
});

export const actionLoadUnderwearRequestsRequest = getActionLoadGridDataRequest(
  UNDERWEAR_REQUESTS_GRID_NAME,
  getGridFilter,
  'account/underwear/request/list',
  actionAddUnderwearRequests,
  filter => ({ ..._omit(filter, ['statuses']), 'statuses[]': filter.statuses }),
  UNDERWEAR_REQUESTS_SCHEMA
);
