import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../core/api/hooks';

import { cookie } from 'react-redux-app/lib/modules/core/utils';

import { SID_COOKIE } from '../../core/api/constants';

import { getLoadPartialInitSettingsError } from '../selectors/errors';

import { actionLoadIsMobileDeviceRequest } from '../actions/loadPartialInitSettings';


export default () => {
  const dispatch = useDispatch();

  const callActionLoadIsMobileDeviceRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadPartialInitSettingsError)
  );

  useEffect(() => {
    if (!cookie.getCookie(SID_COOKIE)) {
      callActionLoadIsMobileDeviceRequest(
        dispatch(actionLoadIsMobileDeviceRequest()),
        'header.mobile-device-flag.load'
      );
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
};
