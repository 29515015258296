export const ACTION_ADD_PRIVATE_PHOTOS = 'ACTION_ADD_PRIVATE_PHOTOS';
export const ACTION_SET_PRIVATE_PHOTOS = 'ACTION_SET_PRIVATE_PHOTOS';

export const ACTION_LOAD_PRIVATE_PHOTOS__REQUEST = 'ACTION_LOAD_PRIVATE_PHOTOS__REQUEST';
export const ACTION_LOAD_PRIVATE_PHOTOS__SUCCESS = 'ACTION_LOAD_PRIVATE_PHOTOS__SUCCESS';
export const ACTION_LOAD_PRIVATE_PHOTOS__FAIL = 'ACTION_LOAD_PRIVATE_PHOTOS__FAIL';

export const ACTION_SET_PRIVATE_PHOTOS_PRESERVE = 'ACTION_SET_PRIVATE_PHOTOS_PRESERVE';

export const ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__REQUEST = 'ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__REQUEST';
export const ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__SUCCESS = 'ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__SUCCESS';
export const ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__FAIL = 'ACTION_LOAD_PRIVATE_PHOTO_CATEGORIES__FAIL';
