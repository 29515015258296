import { combineReducers } from 'redux';

import deleteRecordedPrivateError from './deleteRecordedPrivateError';
import postRecordedPrivateError from './postRecordedPrivateError';


export default combineReducers({
  deleteRecordedPrivateError,
  postRecordedPrivateError,
});
