import {
  ACTION_LOAD_SITEMAP__REQUEST,
  ACTION_LOAD_SITEMAP__SUCCESS,
  ACTION_LOAD_SITEMAP__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadSitemapSuccess = sitemap => ({
  type: ACTION_LOAD_SITEMAP__SUCCESS,
  payload: { sitemap },
});

const actionLoadSitemapFail = error => ({
  type: ACTION_LOAD_SITEMAP__FAIL,
  payload: { error },
});

export const actionLoadSitemapRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_SITEMAP__REQUEST,
    });

    return apiClientHelper.get(
      'sitemap'
    ).then(
      sitemap => {
        dispatch(actionLoadSitemapSuccess(sitemap));
      },
      error => {
        dispatch(actionLoadSitemapFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
