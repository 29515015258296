import { getGridFilter } from '../utils';

import { ACTION_ADD_BLACKLIST } from '../actionTypes';

import { BLACKLIST_GRID_NAME } from '../constants';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddBlacklist = blackList => ({
  type: ACTION_ADD_BLACKLIST,
  payload: { blackList },
});

export const actionLoadBlackListRequest = getActionLoadGridDataRequest(
  BLACKLIST_GRID_NAME, getGridFilter, 'ignore/list', actionAddBlacklist
);
