export const ORDER_BY_QUERY_PARAM = 'orderBy';

export const ORDER__DATE = 'date';
export const ORDER__VIEWS = 'views';
export const ORDER__COMMENTS = 'comments';
export const ORDER__CHEAP = 'cheap';
export const ORDER__EXPENSIVE = 'expensive';
export const ORDER__LAST_SEEN = 'lastSeen';
export const ORDER__NICK = 'nick';
export const ORDER__SIGNUP_DATE = 'signupDate';
export const ORDER__WEBCAM_COST = 'webcamCost';

export const orderLangKeys = {
  [ORDER__DATE]: 'msg.newest',
  [ORDER__VIEWS]: 'msg.most-viewed',
  [ORDER__COMMENTS]: 'msg.most-commented',
  [ORDER__CHEAP]: 'msg.cheapest',
  [ORDER__EXPENSIVE]: 'msg.most-expensive',
  [ORDER__LAST_SEEN]: 'msg.last-seen',
  [ORDER__NICK]: 'msg.by-nick',
  [ORDER__SIGNUP_DATE]: 'msg.signup-date',
  [ORDER__WEBCAM_COST]: 'msg.webcam-price',
};
