import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';
import { useUri } from 'react-redux-app/lib/modules/routing/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';

import { cookie } from 'react-redux-app/lib/modules/core/utils';
import { changeLangForRequestUri } from '../../../core/i18n/utils';

import { uiLangMap, LANGUAGE_COOKIE, COOKIE_LANGUAGE_EXPIRES } from '../../../core/i18n/constants';

import { getUILangs, getDefaultLang } from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';

import { actionChangeLang } from '../../../core/i18n/actions';
import { actionLoadTranslationsAndSeoRequest } from '../../../initSettings/actions/loadTranslationsAndSeo';

import { ActionLink } from 'react-redux-app/lib/modules/actionLink/components';
import Language from '../../../core/language/components/Language';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string,
};

const LanguageSelector = ({ className = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultLang = useShortSelector(getDefaultLang);
  const uiLangs = useShortSelector(getUILangs);
  const lang = useCurrentLang();

  const currentUri = useUri();

  const areTranslationsLoaded = useRef({ [lang]: true });

  const pushUri = uiLang => {
    navigate(changeLangForRequestUri(currentUri, uiLang, defaultLang));

    dispatch(actionChangeLang(uiLang));

    cookie.setCookie(
      LANGUAGE_COOKIE,
      uiLang,
      {
        expires: COOKIE_LANGUAGE_EXPIRES,
        path: '/',
      }
    );
  };

  const callActionLoadTranslationsAndSeoRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadPartialInitSettingsError)
  );
  const changeLang = uiLang => {
    if (areTranslationsLoaded.current[uiLang]) {
      pushUri(uiLang);
      return;
    }

    callActionLoadTranslationsAndSeoRequest(
      dispatch(actionLoadTranslationsAndSeoRequest(uiLang)),
      'header.translations-and-seo.load',
      () => {
        areTranslationsLoaded.current[uiLang] = true;
        pushUri(uiLang);
      }
    );
  };

  return (
    <UncontrolledDropdown className={className}>
      <DropdownToggle tag={Language} lang={lang} className={styles.languageSelector__toggle} />

      <DropdownMenu end>
        {uiLangs.map(uiLang => (
          <DropdownItem
            tag={ActionLink}
            key={uiLang}
            onClick={() => {
              if (uiLang !== lang) {
                changeLang(uiLang);
              }
            }}
          >
            {uiLangMap[uiLang]}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

LanguageSelector.propTypes = propTypes;

export default LanguageSelector;
