import {
  LANG__EN,
  LANG__IT,
  LANG__ES,
} from '../../language/constants';


export const LANGUAGE_ENGLISH = 'English';
export const LANGUAGE_ITALIAN = 'Italiano';
export const LANGUAGE_SPANISH = 'Español';

export const LANGUAGE_HEADER = 'RIV-API-Lang';
export const LANGUAGE_COOKIE = '_Lang';
export const COOKIE_LANGUAGE_EXPIRES = 1000 * 60 * 60 * 24 * 365;

export const uiLangs = [
  LANG__EN,
  LANG__IT,
  LANG__ES,
];

export const uiLangMap = {
  [LANG__EN]: LANGUAGE_ENGLISH,
  [LANG__IT]: LANGUAGE_ITALIAN,
  [LANG__ES]: LANGUAGE_SPANISH,
};
