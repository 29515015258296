import { MODAL_DIALOG__GIFT } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowGiftDialog = (
  giftSource,
  senderId,
  senderNick,
  senderProfileHidden,
  comment
) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__GIFT, {
    giftSource,
    senderId,
    senderNick,
    senderProfileHidden,
    comment,
  }))
);

export const actionHideGiftDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__GIFT))
);
