import { combineReducers } from 'redux';

import photo from './photo';
import video from './video';

import {
  ACTION_ADD_OWN_MEDIA,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_MEDIA:
      return { ...state, ...action.payload.media };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  photo,
  video,
});
