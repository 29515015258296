export const ACTION_ADD_OWN_POSTS = 'ACTION_ADD_OWN_POSTS';
export const ACTION_ADD_OWN_MEDIA = 'ACTION_ADD_OWN_MEDIA';

export const ACTION_ADD_OWN_POST_IDS = 'ACTION_ADD_OWN_POST_IDS';
export const ACTION_SET_OWN_POST_IDS = 'ACTION_SET_OWN_POST_IDS';

export const ACTION_SET_OWN_POSTS_PRESERVE = 'ACTION_SET_OWN_POSTS_PRESERVE';
export const ACTION_SET_OWN_POST_ACTIVE_PAGE = 'ACTION_SET_OWN_POST_ACTIVE_PAGE';

export const ACTION_LOAD_OWN_POSTS__REQUEST = 'ACTION_LOAD_OWN_POSTS__REQUEST';
export const ACTION_LOAD_OWN_POSTS__SUCCESS = 'ACTION_LOAD_OWN_POSTS__SUCCESS';
export const ACTION_LOAD_OWN_POSTS__FAIL = 'ACTION_LOAD_OWN_POSTS__FAIL';

export const ACTION_CREATE_POST__REQUEST = 'ACTION_CREATE_POST__REQUEST';
export const ACTION_CREATE_POST__SUCCESS = 'ACTION_CREATE_POST__SUCCESS';
export const ACTION_CREATE_POST__FAIL = 'ACTION_CREATE_POST__FAIL';

export const ACTION_DELETE_POST__REQUEST = 'ACTION_DELETE_POST__REQUEST';
export const ACTION_DELETE_POST__SUCCESS = 'ACTION_DELETE_POST__SUCCESS';
export const ACTION_DELETE_POST__FAIL = 'ACTION_DELETE_POST__FAIL';

export const ACTION_UPDATE_POST__REQUEST = 'ACTION_UPDATE_POST__REQUEST';
export const ACTION_UPDATE_POST__SUCCESS = 'ACTION_UPDATE_POST__SUCCESS';
export const ACTION_UPDATE_POST__FAIL = 'ACTION_UPDATE_POST__FAIL';

export const ACTION_SET_ACCESS_TYPE = 'ACTION_SET_ACCESS_TYPE';
export const ACTION_ADD_SENT_MEDIA = 'ACTION_ADD_SENT_MEDIA';
export const ACTION_SET_SENT_MEDIA = 'ACTION_SET_SENT_MEDIA';
export const ACTION_REMOVE_MEDIA = 'ACTION_REMOVE_MEDIA';
export const ACTION_SET_MEDIA_SHOW_THUMB = 'ACTION_SET_MEDIA_SHOW_THUMB';

export const ACTION_DELETE_MEDIA__REQUEST = 'ACTION_DELETE_MEDIA__REQUEST';
export const ACTION_DELETE_MEDIA__SUCCESS = 'ACTION_DELETE_MEDIA__SUCCESS';
export const ACTION_DELETE_MEDIA__FAIL = 'ACTION_DELETE_MEDIA__FAIL';
