import {
  ACTION_LOAD_PRIVACY__REQUEST,
  ACTION_LOAD_PRIVACY__SUCCESS,
  ACTION_LOAD_PRIVACY__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadPrivacySuccess = privacy => ({
  type: ACTION_LOAD_PRIVACY__SUCCESS,
  payload: { privacy },
});

const actionLoadPrivacyFail = error => ({
  type: ACTION_LOAD_PRIVACY__FAIL,
  payload: { error },
});

export const actionLoadPrivacyRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PRIVACY__REQUEST,
    });

    return apiClientHelper.get(
      'account/privacy'
    ).then(
      privacy => {
        dispatch(actionLoadPrivacySuccess(privacy));
      },
      error => {
        dispatch(actionLoadPrivacyFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
