import {
  ACTION_OPTOUT_EMAIL__REQUEST,
  ACTION_OPTOUT_EMAIL__SUCCESS,
  ACTION_OPTOUT_EMAIL__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionOptoutEmailSuccess = () => ({
  type: ACTION_OPTOUT_EMAIL__SUCCESS,
});

const actionOptoutEmailFail = error => ({
  type: ACTION_OPTOUT_EMAIL__FAIL,
  payload: { error },
});

export const actionOptoutEmailRequest = (token, type) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_OPTOUT_EMAIL__REQUEST,
    });

    return apiClientHelper.post(
      'email/opt_out',
      { data: { token, type } }
    ).then(
      () => {
        dispatch(actionOptoutEmailSuccess());
      },
      error => {
        dispatch(actionOptoutEmailFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
