import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_LOAD_OWN_VIDEOS__REQUEST,
  ACTION_LOAD_OWN_VIDEOS__SUCCESS,
  ACTION_LOAD_OWN_VIDEOS__FAIL,
  ACTION_SET_OWN_VIDEOS,
  ACTION_ADD_OWN_VIDEOS,
  ACTION_SET_OWN_VIDEOS_PRESERVE,
  ACTION_SET_OWN_VIDEO_ACTIVE_PAGE,
  ACTION_CREATE_VIDEO__REQUEST,
  ACTION_CREATE_VIDEO__SUCCESS,
  ACTION_DELETE_VIDEO__REQUEST,
  ACTION_DELETE_VIDEO__SUCCESS,
  ACTION_DELETE_VIDEO__FAIL,
} from '../../actionTypes/video';
import {
  ACTION_POST_RECORDED_PRIVATE__REQUEST,
  ACTION_POST_RECORDED_PRIVATE__SUCCESS,
} from '../../actionTypes/recordedPrivate';

import { defaultItemsState } from '../../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../../core/loadableItems/constants';


const videos = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_VIDEOS: {
      const items = _union(
        state.items,
        action.payload.videos.items
      );

      return {
        items,
        count: action.payload.videos.count,
        limit: action.payload.videos.limit,
      };
    }

    case ACTION_SET_OWN_VIDEOS:
      return action.payload.videos;

    case ACTION_DELETE_VIDEO__SUCCESS:
      return {
        items: _without(state.items, action.payload.videoId),
        count: state.count - 1,
        limit: state.limit,
      };

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_VIDEOS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_OWN_VIDEOS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_OWN_VIDEOS__SUCCESS:
    case ACTION_LOAD_OWN_VIDEOS__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_VIDEO_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

const shouldLoadFirstPage = (state = false, action) => {
  switch (action.type) {
    case ACTION_CREATE_VIDEO__REQUEST:
    case ACTION_POST_RECORDED_PRIVATE__REQUEST:
      return false;

    case ACTION_CREATE_VIDEO__SUCCESS:
    case ACTION_POST_RECORDED_PRIVATE__SUCCESS:
      return true;

    default:
      return state;
  }
};

const shouldReloadActivePage = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_VIDEO__REQUEST:
      return false;

    case ACTION_DELETE_VIDEO__SUCCESS:
      return true;

    default:
      return state;
  }
};

const isVideoBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_VIDEO__REQUEST:
      return { ...state, [action.payload.videoId]: true };

    case ACTION_DELETE_VIDEO__SUCCESS:
    case ACTION_DELETE_VIDEO__FAIL:
      return _omit(state, action.payload.videoId);

    default:
      return state;
  }
};

export default combineReducers({
  videos,
  paginationMode,
  activePage,
  shouldLoadFirstPage,
  shouldReloadActivePage,
  isVideoBeingDeleted,
  errors,
});
