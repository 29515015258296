import useDomains from './useDomains';
import useIsModelHub from './useIsModelHub';
import useCurrentLangForUrl from '../../core/i18n/hooks/useCurrentLangForUrl';

import { getRegisterModelUrl } from '../../app/utils/url';

import { REGISTRATION_TYPE__MODEL } from '../../auth/constants';


export default () => {
  const langForUrl = useCurrentLangForUrl();
  const domains = useDomains();
  const isModelHub = useIsModelHub();

  return domains && !isModelHub
    ? `https://${domains[REGISTRATION_TYPE__MODEL]}${getRegisterModelUrl(langForUrl)}`
    : null;
};
