import { schema } from 'normalizr';

import { ITEM_SCHEMA, ITEMS_SCHEMA } from '../../core/schemas/items';
import { NEWS_FEEDS_SCHEMA } from '../../newsFeed/schemas';


const INTERVIEW_SCHEMA = new schema.Entity(
  'interviews'
);

export const PROFILE_SCHEMA = new schema.Entity(
  'profiles',
  {
    model: {
      publicPhotos: ITEMS_SCHEMA,
      privatePhotos: ITEMS_SCHEMA,
      videos: ITEMS_SCHEMA,
      stories: ITEMS_SCHEMA,
      underwear: [ITEM_SCHEMA],
      interviews: [INTERVIEW_SCHEMA],
      newsFeed: NEWS_FEEDS_SCHEMA,
    },
    common: {
      publicPhotos: ITEMS_SCHEMA,
    },
  }
);
