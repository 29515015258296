import { combineReducers } from 'redux';

import loadPresentationsError from './loadPresentationsError';
import activatePresentationError from './activatePresentationError';
import createPresentationError from './createPresentationError';
import deletePresentationError from './deletePresentationError';
import updatePresentationError from './updatePresentationError';


export default combineReducers({
  loadPresentationsError,
  activatePresentationError,
  createPresentationError,
  deletePresentationError,
  updatePresentationError,
});
