import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'landingPage.errors');
export const getLoadLandingPageError = state => (
  _get(errorsSelector(state), 'loadLandingPageError')
);
export const getVisitLinkError = state => (
  _get(errorsSelector(state), 'visitLinkError')
);
