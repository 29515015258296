import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_OWN_PUBLIC_PHOTOS,
  ACTION_SET_OWN_PUBLIC_PHOTOS,

  ACTION_SET_OWN_PUBLIC_PHOTOS_PRESERVE,
  ACTION_SET_OWN_PUBLIC_PHOTO_ACTIVE_PAGE,

  ACTION_LOAD_OWN_PUBLIC_PHOTOS__REQUEST,
  ACTION_LOAD_OWN_PUBLIC_PHOTOS__SUCCESS,
  ACTION_LOAD_OWN_PUBLIC_PHOTOS__FAIL,
} from '../actionTypes/publicPhoto';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getPublicPhotoPaginationMode } from '../selectors/publicPhoto';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionAddOwnPublicPhotos = publicPhotos => ({
  type: ACTION_ADD_OWN_PUBLIC_PHOTOS,
  payload: { publicPhotos },
});

const actionSetOwnPublicPhotos = publicPhotos => ({
  type: ACTION_SET_OWN_PUBLIC_PHOTOS,
  payload: { publicPhotos },
});

export const actionSetOwnPublicPhotosPreserve = () => ({
  type: ACTION_SET_OWN_PUBLIC_PHOTOS_PRESERVE,
});

const actionSetOwnPublicPhotoActivePage = activePage => ({
  type: ACTION_SET_OWN_PUBLIC_PHOTO_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnPublicPhotosSuccess = () => ({
  type: ACTION_LOAD_OWN_PUBLIC_PHOTOS__SUCCESS,
});

const actionLoadOwnPublicPhotosFail = error => ({
  type: ACTION_LOAD_OWN_PUBLIC_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadOwnPublicPhotosRequest = ({
  isOnlyApproved = false, page = 1, limit = 27,
} = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getPublicPhotoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_PUBLIC_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      `account/public_photo/list/${page}`,
      { params: { isOnlyApproved, limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: publicPhotos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetOwnPublicPhotos(publicPhotos));
          dispatch(actionSetOwnPublicPhotoActivePage(page));

          return dispatch(actionLoadOwnPublicPhotosSuccess());
        }

        // Preload publicPhoto thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddOwnPublicPhotos(publicPhotos));
          } else {
            dispatch(actionSetOwnPublicPhotos(publicPhotos));
          }

          dispatch(actionSetOwnPublicPhotoActivePage(page));
          dispatch(actionLoadOwnPublicPhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadOwnPublicPhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadApprovedOwnPublicPhotosRequest = (filter = {}) => (
  actionLoadOwnPublicPhotosRequest({ ...filter, isOnlyApproved: true, limit: 28 })
);
