import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';

import styles from './styles/index.css.json';


const propTypes = {
  isBlueChecked: PropTypes.bool,
  showRed: PropTypes.bool,
  className: PropTypes.string,
};

const BlueCheck = ({ isBlueChecked = false, showRed = false, className = null }) => {
  const { formatMessage: fm } = useIntl();

  const isRedChecked = !isBlueChecked && showRed;

  return (isBlueChecked || showRed) && (
    <span
      title={fm({ id: isRedChecked ? 'msg.non-verified-profile' : 'msg.verified-profile' })}
      className={classnames(
        {
          [styles.blueCheck]: isBlueChecked,
          [styles.redCheck]: isRedChecked,
        },
        className
      )}
    />
  );
};

BlueCheck.propTypes = propTypes;

export default BlueCheck;
