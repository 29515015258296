export const MODEL_TAB__AWARDS = 'awards';
export const MODEL_TAB__EROSTORIES = 'erostories';
export const MODEL_TAB__FEEDBACKS = 'feedbacks';
export const MODEL_TAB__GIFTS = 'gifts';
export const MODEL_TAB__INTERVIEWS = 'interviews';
export const MODEL_TAB__NEWS_FEED = 'newsFeed';
export const MODEL_TAB__PERSONAL_CONTACTS = 'personalContacts';
export const MODEL_TAB__PRIVATE_PHOTOS = 'privatePhotos';
export const MODEL_TAB__PROFILE = 'profile';
export const MODEL_TAB__PUBLIC_PHOTOS = 'publicPhotos';
export const MODEL_TAB__UNDERWEAR = 'underwear';
export const MODEL_TAB__VIDEOS = 'videos';
