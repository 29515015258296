import { combineReducers } from 'redux';

import {
  ACTION_ADD_PRIZES,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PRIZES:
      return { ...state, ...action.payload.prizes };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
});
