import {
  ACTION_DELETE_NOTIFICATION__REQUEST,
  ACTION_DELETE_NOTIFICATION__SUCCESS,
  ACTION_DELETE_NOTIFICATION__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionDeleteNotificationSuccess = () => ({
  type: ACTION_DELETE_NOTIFICATION__SUCCESS,
});

const actionDeleteNotificationFail = error => ({
  type: ACTION_DELETE_NOTIFICATION__FAIL,
  payload: { error },
});

export const actionDeleteNotificationRequest = notificationId => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_DELETE_NOTIFICATION__REQUEST,
    });

    return apiClientHelper.del(
      `page_notification/${notificationId}`
    ).then(
      () => {
        dispatch(actionDeleteNotificationSuccess());
      },
      error => {
        dispatch(actionDeleteNotificationFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
