import { combineReducers } from 'redux';

import loadInitSettingsError from './loadInitSettingsError';
import loadPartialInitSettingsError from './loadPartialInitSettingsError';


export default combineReducers({
  loadInitSettingsError,
  loadPartialInitSettingsError,
});
