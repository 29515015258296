import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


export const getVisitor = (state, id) => _get(state, `visitor.byId.${id}`);

const visitorsSelector = state => _get(state, 'visitor.visitors');
export const getVisitorIds = state => _get(visitorsSelector(state), 'items');
export const getVisitorsTotalCount = state => _get(visitorsSelector(state), 'count');
const getVisitorsLimit = state => _get(visitorsSelector(state), 'limit');
export const getIsLastVisitorLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getVisitorIds, getVisitorsTotalCount, getVisitorsLimit
);
export const getVisitorTotalPagesCount = state => getTotalPagesCount(
  state, getVisitorsTotalCount, getVisitorsLimit
);

export const getVisitorPaginationMode = state => _get(state, 'visitor.paginationMode');
