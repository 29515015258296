import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadTelegramSettingsError, getLoadPushSettingsError } from '../../selectors/errors';

import { actionLoadPushSettingsRequest } from '../../actions/loadPushSettings';
import { actionLoadTelegramSettingsRequest } from '../../actions/loadTelegramSettings';


export default ({ getState, dispatch }) => (
  function loadNotificationSettingsHandler(nextState, successCallback, errorCallback) {
    Promise.all([
      dispatch(actionLoadTelegramSettingsRequest()),
      dispatch(actionLoadPushSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadTelegramSettingsError(getState()),
          getLoadPushSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            console.consoleError('loadNotificationSettingsHandler error', globalError);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadNotificationSettingsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
