import {
  ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__REQUEST,
  ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS,
  ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadPrivacyAddressInformationSuccess = privacyAddressInformation => ({
  type: ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS,
  payload: { privacyAddressInformation },
});

const actionLoadPrivacyAddressInformationFail = error => ({
  type: ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__FAIL,
  payload: { error },
});

export const actionLoadPrivacyAddressInformationRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__REQUEST,
    });

    return apiClientHelper.get(
      'account/privacy/address_information'
    ).then(
      privacy => {
        dispatch(actionLoadPrivacyAddressInformationSuccess(privacy));
      },
      error => {
        dispatch(actionLoadPrivacyAddressInformationFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
