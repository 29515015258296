import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_AWARDS,
  ACTION_ADD_AWARD_SETTINGS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_AWARDS:
      return action.payload.awards;

    default:
      return state;
  }
};

const settings = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_AWARD_SETTINGS:
      return action.payload.settings;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  settings,
  errors,
});
