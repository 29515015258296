import {
  ACTION_ADD_WHITELISTED_USER__REQUEST,
  ACTION_ADD_WHITELISTED_USER__SUCCESS,
  ACTION_ADD_WHITELISTED_USER__FAIL,
  ACTION_DELETE_WHITELISTED_USER__REQUEST,
  ACTION_DELETE_WHITELISTED_USER__SUCCESS,
  ACTION_DELETE_WHITELISTED_USER__FAIL,
} from '../../actionTypes';

import { WHITELIST_GRID_NAME } from '../../constants/whiteList';

import { actionLoadWhiteListRequest } from '../../actions/whiteListGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  WHITELIST_GRID_NAME,
  actionLoadWhiteListRequest,
  [ACTION_ADD_WHITELISTED_USER__REQUEST, ACTION_DELETE_WHITELISTED_USER__REQUEST],
  [ACTION_ADD_WHITELISTED_USER__FAIL, ACTION_DELETE_WHITELISTED_USER__FAIL],
  [ACTION_ADD_WHITELISTED_USER__SUCCESS, ACTION_DELETE_WHITELISTED_USER__SUCCESS]
);
