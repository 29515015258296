import { console } from 'react-redux-app/lib/modules/core/utils';
import { handleOnEnterException, url } from 'react-redux-app/lib/modules/routing/utils';
import { getSystemMessageUrl, getSystemErrorUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getResetEmailError } from '../../selectors/errors';

import { actionResetEmailRequest } from '../../actions/resetEmail';


export default ({ getState, dispatch }) => (
  function proxyPhpResetEmailHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionResetEmailRequest(url.parseQuery(nextState.location.search).id)
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getResetEmailError(getState());
        const langForUrl = getCurrentLangForUrl(getState());
        errorCallback(error
          ? getSystemErrorUrl(error.globalError.message, langForUrl)
          : getSystemMessageUrl('msg.reset-email.success', langForUrl));
      }
    ).catch(error => {
      console.consoleError('proxyPhpResetEmailHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
