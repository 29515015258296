import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_SITE_NEWS,
  ACTION_SET_SITE_NEWS_IDS,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_SITE_NEWS:
      return { ...state, ...action.payload.siteNews };

    default:
      return state;
  }
};

const siteNews = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_SET_SITE_NEWS_IDS:
      return action.payload.siteNewsIds;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  siteNews,
  errors,
});
