import _pick from 'lodash/pick';
import _pickBy from 'lodash/pickBy';

import { url } from 'react-redux-app/lib/modules/routing/utils';

import { PAGE_QUERY_PARAM, loadableItemsQueryParams } from '../constants';


export const getFilterFromQuery = query => _pickBy(
  _pick(query, loadableItemsQueryParams),
  value => !!value
);

export const getFilter = location => getFilterFromQuery(url.parseQuery(location.search));

export const getQuery = (key, oldValue, value) => {
  const query = { [key]: value };

  // Reset page parameter on value change
  if (
    (!oldValue && value) // Set value
    || (oldValue && value === null) // Reset value
    || (oldValue && value && value !== oldValue) // Change value
  ) {
    query[PAGE_QUERY_PARAM] = null;
  }

  return query;
};
