import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getFilter } from '../../../core/loadableItems/utils';
import { getVideosUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { CATEGORY_QUERY_PARAM } from '../../constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsVideoSite } from '../../../initSettings/selectors';
import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import {
  getVideoCategories,
  getLoadVideosError,
  getLoadVideoCategoriesError,
} from '../../selectors/video';

import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import {
  actionLoadVideosRequest,
  actionLoadVideoCategoriesRequest,
} from '../../actions/video';


export default checkIsVideoSite => ({ getState, dispatch }) => (
  function loadVideosHandler(nextState, successCallback, errorCallback) {
    if (checkIsVideoSite && !getIsVideoSite(getState())) {
      successCallback();
      return;
    }

    const selectedCategory = getFilter(nextState.location)[CATEGORY_QUERY_PARAM];
    const videosUrl = getVideosUrl(getCurrentLangForUrl(getState()));

    Promise.all([
      dispatch(actionLoadLandingPageRequest(checkIsVideoSite, nextState.location.pathname)),
      dispatch(actionLoadVideosRequest()),
      dispatch(actionLoadVideoCategoriesRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError, fieldErrors } of [
          getLoadVideosError(getState()),
          getLoadVideoCategoriesError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            if (fieldErrors) {
              errorCallback(videosUrl);
            } else {
              console.consoleError('loadVideosHandler error', globalError);
              errorCallback(INTERNAL_ERROR_URI);
            }
          }
          return;
        }

        if (selectedCategory && getVideoCategories(getState()).filter(
          category => category.id === parseInt(selectedCategory, 10)
        ).length === 0) {
          errorCallback(videosUrl);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadVideosHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
