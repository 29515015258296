import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'ownPost.errors');
export const getLoadPostsError = state => (
  _get(errorsSelector(state), 'loadPostsError')
);
export const getCreatePostError = state => (
  _get(errorsSelector(state), 'createPostError')
);
export const getDeletePostError = state => (
  _get(errorsSelector(state), 'deletePostError')
);
export const getUpdatePostError = state => (
  _get(errorsSelector(state), 'updatePostError')
);
export const getDeleteMediaError = state => (
  _get(errorsSelector(state), 'deleteMediaError')
);
