import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { getAccountSettingsUrl } from '../../utils/url';

import LangLink from '../../../core/i18n/components/LangLink';
import ProfilePhotoPreview from '../ProfilePhotoPreview';
import UserMenuHeader from '../UserMenuHeader';
import UserMenuItems from '../UserMenuItems';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string,
};

const UserDropdownMenu = ({ className = null }) => (
  <UncontrolledDropdown className={className}>
    <DropdownToggle tag={ProfilePhotoPreview} className={styles.userDropdownMenu__toggle} />

    <DropdownMenu end className={styles.userDropdownMenu__dropdown}>
      <DropdownItem
        tag={LangLink}
        to={getAccountSettingsUrl()}
        className={styles.userDropdownMenu__dropdown__photo}
      >
        <ProfilePhotoPreview isMenu />
      </DropdownItem>

      <UserMenuHeader />

      <DropdownItem divider />

      <UserMenuItems />
    </DropdownMenu>
  </UncontrolledDropdown>
);

UserDropdownMenu.propTypes = propTypes;

export default UserDropdownMenu;
