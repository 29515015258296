import {
  ACTION_LOAD_COUNTRIES__REQUEST,
  ACTION_LOAD_COUNTRIES__SUCCESS,
  ACTION_LOAD_COUNTRIES__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadCountriesSuccess = countries => ({
  type: ACTION_LOAD_COUNTRIES__SUCCESS,
  payload: { countries },
});

const actionLoadCountriesFail = error => ({
  type: ACTION_LOAD_COUNTRIES__FAIL,
  payload: { error },
});

export const actionLoadCountriesRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_COUNTRIES__REQUEST,
    });

    return apiClientHelper.get(
      'geo_names/countries'
    ).then(
      countries => {
        dispatch(actionLoadCountriesSuccess(countries));
      },
      error => {
        dispatch(actionLoadCountriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
