/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import { getGoogleAnalyticsPurchaseEventCodeTemplate } from '../../../initSettings/selectors';


const propTypes = {
  depositId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
};

const GoogleAnalyticsPurchaseEventTracker = ({ depositId, amount }) => {
  const trackerCodeTemplate = useShortSelector(getGoogleAnalyticsPurchaseEventCodeTemplate);

  if (!trackerCodeTemplate) {
    return null;
  }

  const trackerCode = trackerCodeTemplate
    .replaceAll('#depositId#', depositId)
    .replaceAll('#amount#', amount);

  return <div dangerouslySetInnerHTML={{ __html: trackerCode }} />;
};

GoogleAnalyticsPurchaseEventTracker.propTypes = propTypes;

export default GoogleAnalyticsPurchaseEventTracker;
