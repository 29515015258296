import { combineReducers } from 'redux';

import loadDialogMessagesError from './loadDialogMessagesError';
import deleteMessagesError from './deleteMessagesError';
import loadPeerError from './loadPeerError';
import deleteAttachmentError from './deleteAttachmentError';
import sendDialogMessageError from './sendDialogMessageError';


export default combineReducers({
  loadDialogMessagesError,
  deleteMessagesError,
  loadPeerError,
  deleteAttachmentError,
  sendDialogMessageError,
});
