export const ACTION_START_UPLOAD_SESSION = 'ACTION_START_UPLOAD_SESSION';
export const ACTION_INTERRUPT_UPLOAD_SESSION = 'ACTION_INTERRUPT_UPLOAD_SESSION';
export const ACTION_END_UPLOAD_SESSION = 'ACTION_END_UPLOAD_SESSION';

export const ACTION_CHANGE_UPLOADING_MEDIA_THUMB = 'ACTION_CHANGE_UPLOADING_MEDIA_THUMB';
export const ACTION_CHANGE_MEDIA_UPLOAD_PROGRESS = 'ACTION_CHANGE_MEDIA_UPLOAD_PROGRESS';
export const ACTION_START_MEDIA_PROCESSING = 'ACTION_START_MEDIA_PROCESSING';

export const ACTION_CLEAR_UPLOADING_ERRORS = 'ACTION_CLEAR_UPLOADING_ERRORS';

export const ACTION_UPLOAD_MEDIA__REQUEST = 'ACTION_UPLOAD_MEDIA__REQUEST';
export const ACTION_UPLOAD_MEDIA__SUCCESS = 'ACTION_UPLOAD_MEDIA__SUCCESS';
export const ACTION_UPLOAD_MEDIA__FAIL = 'ACTION_UPLOAD_MEDIA__FAIL';
