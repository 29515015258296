import _range from 'lodash/range';

import { multiSelect } from 'react-redux-app/lib/modules/form/utils';

import { heights } from '../../account/constants';
import { ages, prices } from '../../chatGallery/constants';

import ageToString from './ageToString';
import heightToString from './heightToString';
import priceToString from './priceToString';


const getSpecialOptions = (data, transform, isEmptyRequired = false) => (
  multiSelect.addEmptyOption(
    data.map(str => multiSelect.createOption(transform(str), str)),
    isEmptyRequired
  )
);

export const getAgeOptions = (isEmptyRequired = false) => (
  getSpecialOptions(ages, ageToString, isEmptyRequired)
);

export const getPriceOptions = (isEmptyRequired = false) => (
  getSpecialOptions(prices, priceToString, isEmptyRequired)
);

export const getHeightOptions = (isEmptyRequired = false) => (
  getSpecialOptions(heights, heightToString, isEmptyRequired)
);

export const getCategoryOptions = categories => (
  categories.map(({ id, name }) => multiSelect.createOption(name, id))
);

export const getCostOptions = (minCost, maxCost) => (
  _range(minCost, maxCost + 1).map(cost => multiSelect.createOption(`${cost} CRD`, cost))
);
