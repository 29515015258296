import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import _without from 'lodash/without';

import media from './media';
import errors from './errors';

import {
  ACTION_LOAD_OWN_POSTS__REQUEST,
  ACTION_LOAD_OWN_POSTS__SUCCESS,
  ACTION_LOAD_OWN_POSTS__FAIL,
  ACTION_ADD_OWN_POSTS,
  ACTION_SET_OWN_POST_IDS,
  ACTION_ADD_OWN_POST_IDS,
  ACTION_SET_OWN_POSTS_PRESERVE,
  ACTION_SET_OWN_POST_ACTIVE_PAGE,
  ACTION_CREATE_POST__REQUEST,
  ACTION_CREATE_POST__SUCCESS,
  ACTION_DELETE_POST__REQUEST,
  ACTION_DELETE_POST__SUCCESS,
  ACTION_DELETE_POST__FAIL,
  ACTION_SET_ACCESS_TYPE,
  ACTION_ADD_SENT_MEDIA,
  ACTION_SET_SENT_MEDIA,
  ACTION_REMOVE_MEDIA,
  ACTION_SET_MEDIA_SHOW_THUMB,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_POSTS:
      return { ...state, ...action.payload.posts };

    case ACTION_DELETE_POST__SUCCESS:
      return _omit(state, action.payload.postId);

    default:
      return state;
  }
};

const postIds = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_POST_IDS: {
      const items = _union(
        state.items,
        action.payload.postIds.items
      );

      return {
        items,
        count: action.payload.postIds.count,
        limit: action.payload.postIds.limit,
      };
    }

    case ACTION_SET_OWN_POST_IDS:
      return action.payload.postIds;

    case ACTION_DELETE_POST__SUCCESS:
      return {
        items: _without(state.items, action.payload.postId),
        count: state.count - 1,
        limit: state.limit,
      };

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_POSTS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_OWN_POSTS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_OWN_POSTS__SUCCESS:
    case ACTION_LOAD_OWN_POSTS__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_POST_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

const shouldLoadFirstPage = (state = false, action) => {
  switch (action.type) {
    case ACTION_CREATE_POST__REQUEST:
      return false;

    case ACTION_CREATE_POST__SUCCESS:
      return true;

    default:
      return state;
  }
};

const shouldReloadActivePage = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_POST__REQUEST:
      return false;

    case ACTION_DELETE_POST__SUCCESS:
      return true;

    default:
      return state;
  }
};

const isPostBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_POST__REQUEST:
      return { ...state, [action.payload.postId]: true };

    case ACTION_DELETE_POST__SUCCESS:
    case ACTION_DELETE_POST__FAIL:
      return _omit(state, action.payload.postId);

    default:
      return state;
  }
};

const accessType = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_ACCESS_TYPE:
      return action.payload.accessType;

    default:
      return state;
  }
};

const sentMedia = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_SENT_MEDIA:
      return {
        ...state,
        [action.payload.accessType]: [
          ...(state[action.payload.accessType] || []), action.payload.media,
        ],
      };

    case ACTION_SET_SENT_MEDIA:
      return action.payload.sentMedia || {};

    case ACTION_SET_MEDIA_SHOW_THUMB:
      return {
        ...state,
        [action.payload.accessType]: state[action.payload.accessType].map(
          m => ((m.id === action.payload.mediaId && m.type === action.payload.type)
            ? { ...m, showThumb: action.payload.showThumb }
            : m)
        ),
      };

    case ACTION_REMOVE_MEDIA:
      return {
        ...state,
        [action.payload.accessType]: state[action.payload.accessType].filter(
          ({ id, type }) => !(id === action.payload.mediaId && type === action.payload.type)
        ),
      };

    case ACTION_CREATE_POST__SUCCESS:
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  postIds,
  paginationMode,
  activePage,
  shouldLoadFirstPage,
  shouldReloadActivePage,
  isPostBeingDeleted,
  accessType,
  sentMedia,
  media,
  errors,
});
