export const rouletteImagePath = '/static/images/roulette';

export const rouletteData = [];
for (let i = 0; i < 12; i++) {
  rouletteData.push({
    image: {
      uri: `${rouletteImagePath}/sector/${i}.png`,
      // offsetY: 130,
      // sizeMultiplier: 1.6,
      offsetY: 2,
      sizeMultiplier: 2.21,
    },
  });
}

export const roulettePointerPath = `${rouletteImagePath}/pointer.png`;

export const roulettePlayPath = `${rouletteImagePath}/play.png`;
