import { combineReducers } from 'redux';
import _union from 'lodash/union';
import _without from 'lodash/without';

import errors from './errors';

import { ACTION_DELETE_NEWS_FEED } from '../../newsFeed/actionTypes';
import {
  ACTION_SET_MODEL,
  ACTION_LOAD_MODEL_PRIVATE_PHOTOS__SUCCESS,
  ACTION_LOAD_MODEL_PUBLIC_PHOTOS__SUCCESS,
  ACTION_LOAD_MODEL_VIDEOS__SUCCESS,
  ACTION_LOAD_MODEL_EROSTORIES__SUCCESS,
  ACTION_LOAD_MODEL_GIFTS__SUCCESS,
  ACTION_LOAD_MODEL_FEEDBACKS__SUCCESS,
  ACTION_LOAD_MODEL_NEWS_FEED__SUCCESS,
  ACTION_LOAD_MODEL_PERSONAL_CONTACT__SUCCESS,
  ACTION_UPDATE_MODEL_RATING__SUCCESS,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_MODEL:
      return action.payload.model;

    case ACTION_LOAD_MODEL_PRIVATE_PHOTOS__SUCCESS: {
      const items = _union(
        state.privatePhotos.items,
        action.payload.privatePhotos.items
      );

      return {
        ...state,
        privatePhotos: {
          items,
          count: action.payload.privatePhotos.count,
          limit: action.payload.privatePhotos.limit,
        },
      };
    }

    case ACTION_LOAD_MODEL_PUBLIC_PHOTOS__SUCCESS: {
      const items = _union(
        state.publicPhotos.items,
        action.payload.publicPhotos.items
      );

      return {
        ...state,
        publicPhotos: {
          items,
          count: action.payload.publicPhotos.count,
          limit: action.payload.publicPhotos.limit,
        },
      };
    }

    case ACTION_LOAD_MODEL_VIDEOS__SUCCESS: {
      const items = _union(
        state.videos.items,
        action.payload.videos.items
      );

      return {
        ...state,
        videos: {
          items,
          count: action.payload.videos.count,
          limit: action.payload.videos.limit,
        },
      };
    }

    case ACTION_LOAD_MODEL_EROSTORIES__SUCCESS: {
      const items = _union(
        state.stories.items,
        action.payload.stories.items
      );

      return {
        ...state,
        stories: {
          items,
          count: action.payload.stories.count,
          limit: action.payload.stories.limit,
        },
      };
    }

    case ACTION_LOAD_MODEL_GIFTS__SUCCESS: {
      const items = _union(
        state.gifts.items,
        action.payload.gifts.items
      );

      return {
        ...state,
        gifts: {
          items,
          count: action.payload.gifts.count,
          limit: action.payload.gifts.limit,
        },
      };
    }

    case ACTION_LOAD_MODEL_FEEDBACKS__SUCCESS: {
      const items = _union(
        state.feedbacks.items,
        action.payload.feedbacks.items
      );

      return {
        ...state,
        feedbacks: {
          items,
          count: action.payload.feedbacks.count,
          limit: action.payload.feedbacks.limit,
        },
      };
    }

    case ACTION_UPDATE_MODEL_RATING__SUCCESS:
      return {
        ...state,
        canVote: false,
        rating: action.payload.rating.rating,
      };

    case ACTION_LOAD_MODEL_NEWS_FEED__SUCCESS: {
      const items = _union(
        state.newsFeed.items,
        action.payload.newsFeed.items
      );

      return {
        ...state,
        newsFeed: {
          items,
          count: action.payload.newsFeed.count,
          limit: action.payload.newsFeed.limit,
          staff: action.payload.newsFeed.staff,
          shouldReloadActivePage: false,
        },
      };
    }

    case ACTION_DELETE_NEWS_FEED: {
      if (!state) {
        return state;
      }

      return {
        ...state,
        newsFeed: {
          items: _without(state.newsFeed.items, action.payload.newsFeedId),
          count: state.newsFeed.count - 1,
          limit: state.newsFeed.limit,
          staff: state.newsFeed.staff,
          shouldReloadActivePage: true,
        },
      };
    }

    default:
      return state;
  }
};

const personalContact = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_MODEL_PERSONAL_CONTACT__SUCCESS:
      return action.payload.personalContact;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  personalContact,
  errors,
});
