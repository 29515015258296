import {
  ACTION_VALIDATE_TOKEN__REQUEST,
  ACTION_VALIDATE_TOKEN__SUCCESS,
  ACTION_VALIDATE_TOKEN__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionValidateTokenSuccess = (token, login) => ({
  type: ACTION_VALIDATE_TOKEN__SUCCESS,
  payload: { token, login },
});

const actionValidateTokenFail = error => ({
  type: ACTION_VALIDATE_TOKEN__FAIL,
  payload: { error },
});

export const actionValidateTokenRequest = token => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_VALIDATE_TOKEN__REQUEST,
    });

    return apiClientHelper.post(
      'password_reset/validate',
      { data: { token } }
    ).then(
      ({ login }) => {
        dispatch(actionValidateTokenSuccess(token, login));
      },
      error => {
        dispatch(actionValidateTokenFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
