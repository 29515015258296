import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_SHOW_PERMISSION_REQUEST_PANEL,
  ACTION_HIDE_PERMISSION_REQUEST_PANEL,
} from '../actionTypes';


const defaultPermissionRequestPanelState = {
  isVisible: false,
  isSuppressed: false,
};

const permissionRequestPanel = (state = defaultPermissionRequestPanelState, action) => {
  switch (action.type) {
    case ACTION_SHOW_PERMISSION_REQUEST_PANEL:
      return { ...state, isVisible: !state.isSuppressed };

    case ACTION_HIDE_PERMISSION_REQUEST_PANEL:
      return {
        ...state,
        isVisible: false,
        isSuppressed: action.payload.suppress,
      };

    default:
      return state;
  }
};

export default combineReducers({
  permissionRequestPanel,
  errors,
});
