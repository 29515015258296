/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { Slide, ToastContainer, toast } from 'react-toastify';
import loadable from '@loadable/component';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useMaybeHandleCriticalError } from 'react-redux-app/lib/modules/api/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';
import { useIsAuthenticated } from '../../../auth/hooks';
import useNavigateToProfileOverlay from '../../../profile/hooks/useNavigateToProfileOverlay';

import { apiClientHelper } from '../../../core/api/ApiClientHelper';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { getDialogUrl } from '../../utils/url';
import { getNotificationPermissionState } from '../../../core/pushNotification/utils';

import { CHAT_STATUS__OFFLINE, CHAT_STATUS__ONLINE } from '../../../core/constants/chat';
import { SID_COOKIE } from '../../../core/api/constants';
import { GIFT_SIZE__SMALL } from '../../../gift/constants';
import { PERMISSION_STATE__GRANTED } from '../../../core/pushNotification/constants';
import {
  WEB_SOCKET__BALANCE_CHANGED,
  WEB_SOCKET__FAVORITE_STATUS_CHANGED,
  WEB_SOCKET__GIFT_RECEIVED,
  WEB_SOCKET__ONLINE_FAVORITES_NUMBER_CHANGED,
  WEB_SOCKET__PRIVATE_MESSAGE_RECEIVED,
  WEB_SOCKET__SITE_BAN_DROPPED,
  WEB_SOCKET__SITE_BAN_SET,
  WEB_SOCKET__UNREAD_FEED_COUNT_CHANGED,
  WEB_SOCKET__UNREAD_MAIL_COUNT_CHANGED,
} from '../../constants/webSocket';

import { getWebSocket } from '../../../initSettings/selectors';

import { actionShowGiftDialog } from '../../../gift/actions/dialogs/giftDialog';
import { actionSuggestPushNotificationSubscription } from '../../../core/pushNotification/actions/panel';
import { actionUpdateLoggedUser } from '../../../auth/actions/updateLoggedUser';
import { actionCallOnEnterHooks } from '../../actions/callOnEnterHooks';

import EllipsisText from '../../../core/components/EllipsisText';

import { cookie, theming } from 'react-redux-app/lib/modules/core/utils';
import { WEB_SOCKET } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const Gift = loadable(() => import('../../../gift/components/Gift'));

const themeApi = [
  'toast',
  'toast_message',
  'toast_model',
  'toast_gift',
  'toast__body',
  'toast__header',
  'toast__header_message',
  'toast__header_model',
  'toast__header_gift',
  'toast__header__close',
  'toast__thumbAndContent',
  'toast__thumb',
  'toast__content',
  'toast__content_message',
  'toast__content_model',
  'toast__content_gift',
  'comment',
  'gift',
];

const NOTIFICATION_PERIOD = 1000 * 60 * 10;

const typeToUserUpdateMap = {
  [WEB_SOCKET__BALANCE_CHANGED]: data => ({
    credits: parseFloat(data.customerBalance),
    revenue: parseFloat(data.sellerBalance),
  }),
  [WEB_SOCKET__UNREAD_FEED_COUNT_CHANGED]: data => ({
    unreadNewsFeedNumber: data.unreadNotificationsCount,
  }),
  [WEB_SOCKET__UNREAD_MAIL_COUNT_CHANGED]: data => ({
    unreadMessagesNumber: data.unreadNotificationsCount,
  }),
  [WEB_SOCKET__ONLINE_FAVORITES_NUMBER_CHANGED]: data => ({
    onlineFavoritesNumber: data.onlineFavoritesNumber,
  }),
};

const propTypes = {
  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const WebSocket = ({ theme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const webSocket = useShortSelector(getWebSocket);
  const langForUrl = useCurrentLangForUrl();
  const isAuthenticated = useIsAuthenticated();

  const client = useRef();
  const modelToStatusMap = useRef({});
  const modelToDateMap = useRef({});

  const maybeHandleCriticalError = useMaybeHandleCriticalError();
  const navigateToProfileOverlay = useNavigateToProfileOverlay();

  const toString = (channel, type) => `${channel}_${type}`;

  const webSocketMap = useMemo(() => {
    const map = {};
    Object.keys(webSocket.events).forEach(action => {
      const { channel, event } = webSocket.events[action];

      map[toString(channel, event)] = action;
    });
    return map;
  }, [webSocket.events]);

  const callIfPushNotificationsDisabled = callback => {
    getNotificationPermissionState().then(permissionState => {
      if (permissionState !== PERMISSION_STATE__GRANTED) {
        callback();
      }
      dispatch(actionSuggestPushNotificationSubscription());
    });
  };

  const showToast = (
    toastClassName, headerClassName, contentClassName,
    header, thumb, content,
    onContentClick
  ) => {
    toast(({ closeToast }) => (
      <div>
        <div className={classnames(theme.toast__header, headerClassName)}>
          <EllipsisText text={header} />

          <i
            className={classnames('material-icons-outlined', theme.toast__header__close)}
            onClick={() => { closeToast(); }}
          >
            close
          </i>
        </div>

        <div className={theme.toast__thumbAndContent}>
          <img className={theme.toast__thumb} src={thumb} />

          <div
            className={classnames(theme.toast__content, contentClassName)}
            onClick={() => {
              closeToast();
              onContentClick();
            }}
          >
            {content}
          </div>
        </div>
      </div>
    ), {
      className: classnames(theme.toast, toastClassName),
      bodyClassName: theme.toast__body,
    });
  };

  const showMessage = messageId => {
    apiClientHelper.get(
      `message/${messageId}/for_websocket`
    ).then(
      ({ peer, message }) => {
        const content = [message.body];
        if (message.subject) {
          content.unshift(
            <strong key="subject">{message.subject}</strong>,
            <br key="divider" />
          );
        }

        showToast(
          theme.toast_message,
          theme.toast__header_message,
          theme.toast__content_message,
          l('header.message', { nick: peer.nick }),
          peer.profilePhoto,
          content,
          () => { navigate(getDialogUrl(peer.id, langForUrl)); }
        );
      },
      ({ globalError }) => { maybeHandleCriticalError(globalError); }
    );
  };

  const showModel = (modelId, status) => {
    const prevStatus = modelToStatusMap.current[modelId] || CHAT_STATUS__OFFLINE;
    modelToStatusMap.current[modelId] = status;

    if (!(prevStatus === CHAT_STATUS__OFFLINE && status === CHAT_STATUS__ONLINE)) {
      return;
    }

    const prevDate = modelToDateMap.current[modelId];
    const date = new Date().getTime();

    if (prevDate && (date - prevDate) < NOTIFICATION_PERIOD) {
      return;
    }

    modelToDateMap.current[modelId] = date;

    apiClientHelper.get(
      `model/${modelId}/for_websocket`
    ).then(
      ({ id, nick, imageUrl }) => {
        showToast(
          theme.toast_model,
          theme.toast__header_model,
          theme.toast__content_model,
          l('header.model-online', { nick }),
          imageUrl,
          l('msg.enter-personal-room'),
          () => { navigateToProfileOverlay(id); }
        );
      },
      ({ globalError }) => { maybeHandleCriticalError(globalError); }
    );
  };

  const showGift = id => {
    apiClientHelper.get(
      `gift/${id}`
    ).then(
      ({
        giftSource,
        senderId,
        senderNick,
        senderThumbUrlAbs,
        senderProfileHidden,
        comment,
      }) => {
        const content = [
          <div key="comment" className={theme.comment}>
            {comment || l('msg.no-comment')}
          </div>,
          <Gift
            type={giftSource}
            size={GIFT_SIZE__SMALL}
            key="gift"
            className={theme.gift}
          />,
        ];

        showToast(
          theme.toast_gift,
          theme.toast__header_gift,
          theme.toast__content_gift,
          l('header.gift', { nick: senderNick }),
          senderThumbUrlAbs,
          content,
          () => {
            dispatch(actionShowGiftDialog(
              giftSource,
              senderId,
              senderNick,
              senderProfileHidden,
              comment
            ));
          }
        );
      },
      ({ globalError }) => { maybeHandleCriticalError(globalError); }
    );
  };

  const typeToCallbackMap = {
    [WEB_SOCKET__FAVORITE_STATUS_CHANGED]: data => () => { showModel(data.modelId, data.status); },
    [WEB_SOCKET__GIFT_RECEIVED]: data => () => { showGift(data.id); },
    [WEB_SOCKET__PRIVATE_MESSAGE_RECEIVED]: data => () => { showMessage(data.messageId); },
  };

  const initWebSocket = () => {
    client.current = new W3CWebSocket(webSocket.url);

    client.current.onopen = () => {
      client.current.send(cookie.getCookie(SID_COOKIE));
    };

    client.current.onmessage = ({ data: dataString }) => {
      const { channel, type, data } = JSON.parse(dataString);

      const action = webSocketMap[toString(channel, type)];
      switch (action) {
        case WEB_SOCKET__BALANCE_CHANGED:
        case WEB_SOCKET__ONLINE_FAVORITES_NUMBER_CHANGED:
        case WEB_SOCKET__UNREAD_FEED_COUNT_CHANGED:
        case WEB_SOCKET__UNREAD_MAIL_COUNT_CHANGED:
          dispatch(actionUpdateLoggedUser(typeToUserUpdateMap[action](data)));
          break;

        case WEB_SOCKET__FAVORITE_STATUS_CHANGED:
        case WEB_SOCKET__GIFT_RECEIVED:
        case WEB_SOCKET__PRIVATE_MESSAGE_RECEIVED:
          callIfPushNotificationsDisabled(typeToCallbackMap[action](data));
          break;

        case WEB_SOCKET__SITE_BAN_DROPPED:
        case WEB_SOCKET__SITE_BAN_SET:
          dispatch(actionCallOnEnterHooks());
          break;

        default:
      }
    };
  };

  useEffect(() => {
    if (isAuthenticated) {
      initWebSocket();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ToastContainer
      position={toast.POSITION.BOTTOM_RIGHT}
      autoClose={10000}
      closeButton={false}
      hideProgressBar
      transition={Slide}
      pauseOnFocusLoss={false}
      closeOnClick={false}
    />
  );
};

WebSocket.propTypes = propTypes;

export default theming.theme(
  WEB_SOCKET,
  baseCssJson,
  themeApi
)(WebSocket);
