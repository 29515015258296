import { combineReducers } from 'redux';

import errors from './errors';

import { ACTION_VALIDATE_TOKEN__SUCCESS } from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_VALIDATE_TOKEN__SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
