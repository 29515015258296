import config from '../../../../../../config';

import { url } from 'react-redux-app/lib/modules/routing/utils';
import { stripLangFromUri } from '../../core/i18n/utils';

import {
  PATH_REGISTER_USER,
  PATH_REGISTER_MODEL,
  PATH_RESTORE_PASSWORD,
  PATH_RESET_PASSWORD,
  PATH_AGENCY_LOGIN,

  PATH_PROFILE,
  PATH_BLOG,
  PATH_CONTACT_US,
  PATH_VIDEOS,
  PATH_PHOTOS,
  PATH_EROSTORIES,
  PATH_AWARDS,
  PATH_UNDERWEAR,
  PATH_INTERVIEWS,
  PATH_SITE_NEWS,
  PATH_ARTICLE,
  PATH_LINKS,
  PATH_LATEST_ITEMS,
  PATH_SEARCH,
  PATH_SUGAR_BABY,
  indexPathAliases,

  PATH_RULES_HOW_IT_WORKS,
  PATH_RULES_VIDEO_CHAT,
  PATH_RULES_VIDEO_CHAT_AGREEMENT,
  PATH_RULES_182257,
  PATH_RULES_TERMS,
  PATH_RULES_PRIVACY,
  PATH_RULES_REFUND,
  PATH_RULES_REWARDS,

  PATH_RIVSTREAMER,
  PATH_LARIX,
  PATH_WEBCAM_MODEL_WORKSPACE_HINTS,

  PATH_LEGAL_EU_DSA,
  PATH_LEGAL_CONTENT_REMOVAL,
  PATH_LEGAL_DMCA_COMPLAINT,

  PATH_EVENT,
  PATH_EVENT_AWARDS,

  PATH_LOADING,
  PATH_NOT_FOUND,
  PATH_SYSTEM_MESSAGE,
  PATH_SYSTEM_ERROR,
  PATH_SITEMAP,

  PATH_ACCOUNT_SETTINGS,
  PATH_NOTIFICATION_SETTINGS,
  PATH_PAYOUT_SETTINGS,
  PATH_PARTNERS,
  PATH_CHANGE_PASSWORD,
  PATH_DELETE_ACCOUNT,
  PATH_PUBLIC_PHOTOS,
  PATH_WEBCAM_SETTINGS,
  PATH_SPEED_TEST,
  PATH_CREDITS,
  PATH_REVENUE,
  PATH_PURCHASES,
  PATH_ITEMS_FOR_SALE,
  PATH_FEEDBACK,
  PATH_FAVORITES,
  PATH_AFFILIATES,
  PATH_BANNED_USERS,
  PATH_BLACKLIST,
  PATH_VISITORS,
  PATH_DIALOGS,
  PATH_DIALOG,
  PATH_INTERVIEW,
  PATH_INTERVIEW_FORMS,
  PATH_OWN_INTERVIEWS,
  PATH_REQUESTS,
  PATH_INTERVIEWER,
  PATH_MAKE_FIRST_DEPOSIT,
  PATH_MAKE_NEW_DEPOSIT,
  PATH_APPOINTMENTS,
  PATH_WALLET_ADDRESS,
  PATH_PAYOUT_REQUEST_NOTICE,
  PATH_NEWS_FEED,
  PATH_BLOG_SETTINGS,
  PATH_EMOTICON_SHOP,
  PATH_PRIZES,

  PATH_BALANCE_ADJUSTMENTS,
  PATH_BALANCE_LOSSES_COMPENSATIONS,
  PATH_BALANCE_EXPIRED_CREDITS,
  PATH_BALANCE_PURCHASES,
  PATH_BALANCE_FEES,
  PATH_BALANCE_SALES,
  PATH_BALANCE_PURCHASED_SERVICES,
  PATH_BALANCE_REVENUE_CONVERSIONS,

  PATH_SIGNUP_INFO,
  PATH_SIGNUP,

  PATH_UNBAN,
} from '../constants/url';

import { TAB_QUERY_PARAM } from '../../core/tabs/constants';
import { MODEL_QUERY_PARAM } from '../../agency/constants';


export const getUrl = (lang, urlArg) => (
  `${lang ? `/${lang}` : ''}${!urlArg.startsWith('/') ? '/' : ''}${urlArg}`
);

export const getIndexUrl = lang => getUrl(lang, '');

export const getRegisterUserUrl = lang => getUrl(lang, PATH_REGISTER_USER);
export const getRegisterModelUrl = lang => getUrl(lang, PATH_REGISTER_MODEL);
export const getRestorePasswordUrl = lang => getUrl(lang, PATH_RESTORE_PASSWORD);
export const getResetPasswordUrl = (token, lang) => (
  getUrl(lang, `${PATH_RESET_PASSWORD}/${token}`)
);
export const getAgencyLoginUrl = lang => getUrl(lang, PATH_AGENCY_LOGIN);

export const getProfileUrl = (id, lang) => getUrl(lang, `${PATH_PROFILE}/${id}`);
export const getBlogUrl = (username, lang) => getUrl(lang, `${PATH_BLOG}/${username}`);
export const getPostUrl = (username, postId, lang) => (
  getUrl(lang, `${getBlogUrl(username, lang)}/${postId}`)
);
export const getContactUsUrl = lang => getUrl(lang, PATH_CONTACT_US);
export const getVideosUrl = lang => getUrl(lang, PATH_VIDEOS);
export const getPhotosUrl = lang => getUrl(lang, PATH_PHOTOS);
export const getErostroiesUrl = lang => getUrl(lang, PATH_EROSTORIES);
export const getUnderwearUrl = lang => getUrl(lang, PATH_UNDERWEAR);
export const getInterviewsUrl = lang => getUrl(lang, PATH_INTERVIEWS);
export const getAwardsUrl = lang => getUrl(lang, PATH_AWARDS);
export const getSiteNewsUrl = lang => getUrl(lang, PATH_SITE_NEWS);
export const getArticleUrl = (id, lang) => getUrl(lang, `${PATH_ARTICLE}/${id}`);
export const getLinksUrl = lang => getUrl(lang, PATH_LINKS);
export const getLatestItemsUrl = lang => getUrl(lang, PATH_LATEST_ITEMS);
export const getSearchUrl = lang => getUrl(lang, PATH_SEARCH);
export const getSugarBabyUrl = lang => getUrl(lang, PATH_SUGAR_BABY);

export const getHowItWorksUrl = lang => getUrl(lang, PATH_RULES_HOW_IT_WORKS);
export const getVideoChatRulesUrl = lang => getUrl(lang, PATH_RULES_VIDEO_CHAT);
export const getVideoChatAgreementUrl = lang => getUrl(lang, PATH_RULES_VIDEO_CHAT_AGREEMENT);
export const get182257Url = lang => getUrl(lang, PATH_RULES_182257);
export const getTermsUrl = lang => getUrl(lang, PATH_RULES_TERMS);
export const getPrivacyUrl = lang => getUrl(lang, PATH_RULES_PRIVACY);
export const getRefundUrl = lang => getUrl(lang, PATH_RULES_REFUND);
export const getRewardsUrl = lang => getUrl(lang, PATH_RULES_REWARDS);

export const getLarixUrl = lang => getUrl(lang, PATH_LARIX);
export const getRivstreamerUrl = lang => getUrl(lang, PATH_RIVSTREAMER);
export const getWebcamModelWorkspaceHintsUrl = lang => (
  getUrl(lang, PATH_WEBCAM_MODEL_WORKSPACE_HINTS)
);

export const getEuDsaUrl = lang => getUrl(lang, PATH_LEGAL_EU_DSA);
export const getContentRemovalUrl = lang => getUrl(lang, PATH_LEGAL_CONTENT_REMOVAL);
export const getDmcaComplaintUrl = lang => getUrl(lang, PATH_LEGAL_DMCA_COMPLAINT);

export const getEventUrl = lang => getUrl(lang, PATH_EVENT);
export const getEventAwardsUrl = lang => getUrl(lang, PATH_EVENT_AWARDS);

export const getLoadingUrl = lang => getUrl(lang, PATH_LOADING);
export const getNotFoundUrl = lang => getUrl(lang, PATH_NOT_FOUND);
export const getSystemMessageUrl = (message, lang) => (
  url.appendQueryParams(getUrl(lang, PATH_SYSTEM_MESSAGE), { message })
);
export const getSystemErrorUrl = (message, lang) => (
  url.appendQueryParams(getUrl(lang, PATH_SYSTEM_ERROR), { message })
);
export const getSitemapUrl = lang => getUrl(lang, PATH_SITEMAP);

const getUrlWithTab = (lang, urlArg, tab = null) => (
  url.appendQueryParams(getUrl(lang, urlArg), { [TAB_QUERY_PARAM]: tab })
);
export const getAccountSettingsUrl = (lang, tab = null) => (
  getUrlWithTab(lang, PATH_ACCOUNT_SETTINGS, tab)
);
export const getNotificationSettingsUrl = (lang, tab = null) => (
  getUrlWithTab(lang, PATH_NOTIFICATION_SETTINGS, tab)
);
export const getPayoutSettingsUrl = lang => getUrl(lang, PATH_PAYOUT_SETTINGS);
export const getPartnersUrl = lang => getUrl(lang, PATH_PARTNERS);
export const getChangePasswordUrl = lang => getUrl(lang, PATH_CHANGE_PASSWORD);
export const getDeleteAccountUrl = lang => getUrl(lang, PATH_DELETE_ACCOUNT);
export const getPublicPhotosUrl = lang => getUrl(lang, PATH_PUBLIC_PHOTOS);
export const getWebcamSettingsUrl = (lang, tab = null) => (
  getUrlWithTab(lang, PATH_WEBCAM_SETTINGS, tab)
);
export const getSpeedTestUrl = lang => getUrl(lang, PATH_SPEED_TEST);
export const getCreditsUrl = (lang, tab = null) => getUrlWithTab(lang, PATH_CREDITS, tab);
export const getRevenueUrl = (lang, tab = null) => getUrlWithTab(lang, PATH_REVENUE, tab);
export const getPurchasesUrl = lang => getUrl(lang, PATH_PURCHASES);
export const getItemsForSaleUrl = (lang, tab = null) => (
  getUrlWithTab(lang, PATH_ITEMS_FOR_SALE, tab)
);
export const getFeedbackUrl = lang => getUrl(lang, PATH_FEEDBACK);
export const getFavoritesUrl = lang => getUrl(lang, PATH_FAVORITES);
export const getAffiliatesUrl = lang => getUrl(lang, PATH_AFFILIATES);
export const getBannedUsersUrl = lang => getUrl(lang, PATH_BANNED_USERS);
export const getBlackListUrl = lang => getUrl(lang, PATH_BLACKLIST);
export const getVisitorsUrl = lang => getUrl(lang, PATH_VISITORS);
export const getDialogsUrl = lang => getUrl(lang, PATH_DIALOGS);
export const getDialogUrl = (peerId, lang) => getUrl(lang, `${PATH_DIALOG}/${peerId}`);
export const getInterviewUrl = (id, lang) => getUrl(lang, `${PATH_INTERVIEW}/${id}`);
export const getInterviewFormsUrl = lang => getUrl(lang, PATH_INTERVIEW_FORMS);
export const getOwnInterviewsUrl = lang => getUrl(lang, PATH_OWN_INTERVIEWS);
export const getRequestsUrl = (lang, tab = null) => getUrlWithTab(lang, PATH_REQUESTS, tab);
export const getInterviewerUrl = (formId, lang) => getUrl(lang, `${PATH_INTERVIEWER}/${formId}`);
export const getMakeFirstDepositUrl = lang => getUrl(lang, PATH_MAKE_FIRST_DEPOSIT);
export const getMakeNewDepositUrl = lang => getUrl(lang, PATH_MAKE_NEW_DEPOSIT);
export const getAppointmentsUrl = lang => getUrl(lang, PATH_APPOINTMENTS);
export const getWalletAddressUrl = (link, lang) => getUrl(lang, `${PATH_WALLET_ADDRESS}/${link}`);
export const getPayoutRequestNoticeUrl = lang => getUrl(lang, PATH_PAYOUT_REQUEST_NOTICE);
export const getNewsFeedUrl = lang => getUrl(lang, PATH_NEWS_FEED);
export const getBlogSettingsUrl = lang => getUrl(lang, PATH_BLOG_SETTINGS);
export const getEmoticonShopUrl = lang => getUrl(lang, PATH_EMOTICON_SHOP);
export const getPrizesUrl = lang => getUrl(lang, PATH_PRIZES);

export const getBalanceAdjustmentsUrl = lang => getUrl(lang, PATH_BALANCE_ADJUSTMENTS);
export const getLossesCompensationsUrl = lang => getUrl(lang, PATH_BALANCE_LOSSES_COMPENSATIONS);
export const getExpiredCreditsUrl = lang => getUrl(lang, PATH_BALANCE_EXPIRED_CREDITS);
export const getPurchaseHistoryUrl = lang => getUrl(lang, PATH_BALANCE_PURCHASES);
export const getFeesUrl = lang => getUrl(lang, PATH_BALANCE_FEES);
export const getSalesUrl = lang => getUrl(lang, PATH_BALANCE_SALES);
export const getPurchasedServicesUrl = lang => getUrl(lang, PATH_BALANCE_PURCHASED_SERVICES);
export const getRevenueConversionsUrl = lang => getUrl(lang, PATH_BALANCE_REVENUE_CONVERSIONS);

export const getSignupInfoUrl = lang => getUrl(lang, PATH_SIGNUP_INFO);
export const getSignupUrl = lang => getUrl(lang, PATH_SIGNUP);
export const getAgencyModelSignupUrl = (modelId, lang) => (
  url.appendQueryParams(getSignupUrl(lang), { [MODEL_QUERY_PARAM]: modelId })
);

export const getUnbanUrl = lang => getUrl(lang, PATH_UNBAN);

export const getPublicRoomUrl = lang => getUrl(lang, config.publicRoomUrl);

export const getIsIndexPageAlias = location => {
  const strippedCurrentUri = stripLangFromUri(location.pathname).slice(1);
  return indexPathAliases.some(alias => strippedCurrentUri.startsWith(alias));
};
