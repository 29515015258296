import _omit from 'lodash/omit';

import { getGridFilter } from '../utils/revenueConversion';

import { ACTION_ADD_REVENUE_CONVERSIONS } from '../actionTypes';

import { REVENUE_CONVERSIONS_GRID_NAME } from '../constants/revenueConversion';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddRevenueConversions = revenueConversions => ({
  type: ACTION_ADD_REVENUE_CONVERSIONS,
  payload: { revenueConversions },
});

export const actionLoadRevenueConversionsRequest = getActionLoadGridDataRequest(
  REVENUE_CONVERSIONS_GRID_NAME,
  getGridFilter,
  'revenue_to_credits/list',
  actionAddRevenueConversions,
  filter => ({ ..._omit(filter, ['statuses']), 'statuses[]': filter.statuses })
);
