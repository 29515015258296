import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_SET_PROFILE_PHOTOS,

  ACTION_LOAD_PROFILE_PHOTOS__REQUEST,
  ACTION_LOAD_PROFILE_PHOTOS__SUCCESS,
  ACTION_LOAD_PROFILE_PHOTOS__FAIL,
} from '../actionTypes/profilePhoto';

import { OWN_ITEMS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionSetProfilePhotos = profilePhotos => ({
  type: ACTION_SET_PROFILE_PHOTOS,
  payload: { profilePhotos },
});

const actionLoadProfilePhotosSuccess = () => ({
  type: ACTION_LOAD_PROFILE_PHOTOS__SUCCESS,
});

const actionLoadProfilePhotosFail = error => ({
  type: ACTION_LOAD_PROFILE_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadProfilePhotosRequest = ({ isOnlyApproved = false } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PROFILE_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      'account/profile_photo/list',
      { params: { isOnlyApproved } },
      OWN_ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetProfilePhotos(result));

          return dispatch(actionLoadProfilePhotosSuccess());
        }

        // Preload photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          dispatch(actionSetProfilePhotos(result));
          dispatch(actionLoadProfilePhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadProfilePhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
