export const ACTION_ADD_NEWS = 'ACTION_ADD_NEWS';

export const ACTION_ADD_NEWS_FEED = 'ACTION_ADD_NEWS_FEED';
export const ACTION_ADD_PUBLIC_PHOTOS = 'ACTION_ADD_PUBLIC_PHOTOS';
export const ACTION_ADD_COMMENT_ITEMS = 'ACTION_ADD_COMMENT_ITEMS';

export const ACTION_ADD_NEWS_FEED_IDS = 'ACTION_ADD_NEWS_FEED_IDS';
export const ACTION_SET_NEWS_FEED_IDS = 'ACTION_SET_NEWS_FEED_IDS';

export const ACTION_LOAD_NEWS_FEED__REQUEST = 'ACTION_LOAD_NEWS_FEED__REQUEST';
export const ACTION_LOAD_NEWS_FEED__SUCCESS = 'ACTION_LOAD_NEWS_FEED__SUCCESS';
export const ACTION_LOAD_NEWS_FEED__FAIL = 'ACTION_LOAD_NEWS_FEED__FAIL';

export const ACTION_SET_NEWS_FEED_PRESERVE = 'ACTION_SET_NEWS_FEED_PRESERVE';
export const ACTION_SET_NEWS_FEED_ACTIVE_PAGE = 'ACTION_SET_NEWS_FEED_ACTIVE_PAGE';

export const ACTION_DELETE_NEWS_FEED = 'ACTION_DELETE_NEWS_FEED';
