export const GIFT_TYPE__BEAR = 'bear';
export const GIFT_TYPE__BEAR_CUB = 'bear_cub';
export const GIFT_TYPE__BIRKIN = 'birkin';
export const GIFT_TYPE__BRACELET = 'bracelet';
export const GIFT_TYPE__BUNNY_EARS_FAN = 'bunny_ears_fan';
export const GIFT_TYPE__CAKE = 'cake';
export const GIFT_TYPE__CANDY = 'candy';
export const GIFT_TYPE__CELLPHONE = 'cellphone';
export const GIFT_TYPE__CHOCO = 'choco';
export const GIFT_TYPE__CHOCOLATES = 'chocolates';
export const GIFT_TYPE__CHRISTMAS_TREE = 'christmas_tree';
export const GIFT_TYPE__CINEMA_EVENING = 'cinema_evening';
export const GIFT_TYPE__CITRUS_BEER = 'citrus_beer';
export const GIFT_TYPE__CLEANING = 'cleaning';
export const GIFT_TYPE__CLOVER = 'clover';
export const GIFT_TYPE__COCKTAIL = 'cocktail';
export const GIFT_TYPE__CRISTAL = 'cristal';
export const GIFT_TYPE__DIAMOND = 'diamond';
export const GIFT_TYPE__EASTER_BUNNY_EGG = 'easter_bunny_egg';
export const GIFT_TYPE__EASTER_EGG = 'easter_egg';
export const GIFT_TYPE__EROTIC_LINGERIE = 'erotic_lingerie';
export const GIFT_TYPE__EVENING_DRESS = 'evening_dress';
export const GIFT_TYPE__FONDO_TETTE = 'fondo-tette';
export const GIFT_TYPE__FOOD_PROCESSOR = 'food_processor';
export const GIFT_TYPE__FRUIT_POPSICLE = 'fruit_popsicle';
export const GIFT_TYPE__GIFT_CARD = 'gift_card';
export const GIFT_TYPE__HEART = 'heart';
export const GIFT_TYPE__KISS = 'kiss';
export const GIFT_TYPE__LAPTOP = 'laptop';
export const GIFT_TYPE__LINGERIE = 'lingerie';
export const GIFT_TYPE__MAKEUP_SET = 'makeup_set';
export const GIFT_TYPE__MIMOSA = 'mimosa';
export const GIFT_TYPE__PERFUME = 'perfume';
export const GIFT_TYPE__PERIGNON = 'perignon';
export const GIFT_TYPE__PINK_BOX = 'pink_box';
export const GIFT_TYPE__PINK_DIAMOND = 'pink_diamond';
export const GIFT_TYPE__PINK_SHOES = 'pink_shoes';
export const GIFT_TYPE__PRIZE = 'prize';
export const GIFT_TYPE__PURPLE_BOX = 'purple_box';
export const GIFT_TYPE__RED_BOX = 'red_box';
export const GIFT_TYPE__RING = 'ring';
export const GIFT_TYPE__ROSE = 'rose';
export const GIFT_TYPE__ROSE_BOUQUET = 'rose_bouquet';
export const GIFT_TYPE__SHOES = 'shoes';
export const GIFT_TYPE__SMARTWATCH = 'smartwatch';
export const GIFT_TYPE__SNOWFLAKE = 'snowflake';
export const GIFT_TYPE__SPORTS_CAR = 'sports_car';
export const GIFT_TYPE__STRAWBERRY = 'strawberry';
export const GIFT_TYPE__SUMMER_SET = 'summer_set';
export const GIFT_TYPE__TEDDY_BEAR = 'teddy_bear';
export const GIFT_TYPE__TREASURE = 'treasure';
export const GIFT_TYPE__VACUUM_CLEANER = 'vacuum_cleaner';
export const GIFT_TYPE__VOYAGE = 'voyage';
export const GIFT_TYPE__VUITTON = 'vuitton';
export const GIFT_TYPE__WASHING_MACHINE = 'washing_machine';
export const GIFT_TYPE__WATCH = 'watch';
export const GIFT_TYPE__YELLOW_DIAMOND = 'yellow_diamond';

export const giftTypes = [
  GIFT_TYPE__BEAR,
  GIFT_TYPE__BEAR_CUB,
  GIFT_TYPE__BIRKIN,
  GIFT_TYPE__BRACELET,
  GIFT_TYPE__BUNNY_EARS_FAN,
  GIFT_TYPE__CAKE,
  GIFT_TYPE__CANDY,
  GIFT_TYPE__CELLPHONE,
  GIFT_TYPE__CHOCO,
  GIFT_TYPE__CHOCOLATES,
  GIFT_TYPE__CHRISTMAS_TREE,
  GIFT_TYPE__CINEMA_EVENING,
  GIFT_TYPE__CITRUS_BEER,
  GIFT_TYPE__CLEANING,
  GIFT_TYPE__CLOVER,
  GIFT_TYPE__COCKTAIL,
  GIFT_TYPE__CRISTAL,
  GIFT_TYPE__DIAMOND,
  GIFT_TYPE__EASTER_BUNNY_EGG,
  GIFT_TYPE__EASTER_EGG,
  GIFT_TYPE__EROTIC_LINGERIE,
  GIFT_TYPE__EVENING_DRESS,
  GIFT_TYPE__FONDO_TETTE,
  GIFT_TYPE__FOOD_PROCESSOR,
  GIFT_TYPE__FRUIT_POPSICLE,
  GIFT_TYPE__GIFT_CARD,
  GIFT_TYPE__HEART,
  GIFT_TYPE__KISS,
  GIFT_TYPE__LAPTOP,
  GIFT_TYPE__LINGERIE,
  GIFT_TYPE__MAKEUP_SET,
  GIFT_TYPE__MIMOSA,
  GIFT_TYPE__PERFUME,
  GIFT_TYPE__PERIGNON,
  GIFT_TYPE__PINK_BOX,
  GIFT_TYPE__PINK_DIAMOND,
  GIFT_TYPE__PINK_SHOES,
  GIFT_TYPE__PRIZE,
  GIFT_TYPE__PURPLE_BOX,
  GIFT_TYPE__RED_BOX,
  GIFT_TYPE__RING,
  GIFT_TYPE__ROSE,
  GIFT_TYPE__ROSE_BOUQUET,
  GIFT_TYPE__SHOES,
  GIFT_TYPE__SMARTWATCH,
  GIFT_TYPE__SNOWFLAKE,
  GIFT_TYPE__SPORTS_CAR,
  GIFT_TYPE__STRAWBERRY,
  GIFT_TYPE__SUMMER_SET,
  GIFT_TYPE__TEDDY_BEAR,
  GIFT_TYPE__TREASURE,
  GIFT_TYPE__VACUUM_CLEANER,
  GIFT_TYPE__VOYAGE,
  GIFT_TYPE__VUITTON,
  GIFT_TYPE__WASHING_MACHINE,
  GIFT_TYPE__WATCH,
  GIFT_TYPE__YELLOW_DIAMOND,
];

export const GIFT_SIZE__SMALL = 'GIFT_SIZE__SMALL';
export const GIFT_SIZE__NORMAL = 'GIFT_SIZE__NORMAL';
export const GIFT_SIZE__BIG = 'GIFT_SIZE__BIG';

export const giftSizes = [
  GIFT_SIZE__SMALL,
  GIFT_SIZE__NORMAL,
  GIFT_SIZE__BIG,
];

export const SENDER_NICK_VISIBILITY__HIDDEN = 'hidden';
export const SENDER_NICK_VISIBILITY__RECEIVER = 'receiver';
export const SENDER_NICK_VISIBILITY__ALL = 'all';
