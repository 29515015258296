import { combineReducers } from 'redux';

import restorePasswordError from './restorePasswordError';
import validateTokenError from './validateTokenError';
import resetPasswordError from './resetPasswordError';
import changePasswordError from './changePasswordError';


export default combineReducers({
  restorePasswordError,
  validateTokenError,
  resetPasswordError,
  changePasswordError,
});
