import {
  ACTION_LOAD_BLUE_CHECK_SETTINGS__REQUEST,
  ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS,
  ACTION_LOAD_BLUE_CHECK_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadBlueCheckSettingsSuccess = blueCheckSettings => ({
  type: ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS,
  payload: { blueCheckSettings },
});

const actionLoadBlueCheckSettingsFail = error => ({
  type: ACTION_LOAD_BLUE_CHECK_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadBlueCheckSettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_BLUE_CHECK_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'account/bluecheck'
    ).then(
      blueCheckSettings => {
        dispatch(actionLoadBlueCheckSettingsSuccess(blueCheckSettings));
      },
      error => {
        dispatch(actionLoadBlueCheckSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
