import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles/index.css.json';


const propTypes = {
  children: PropTypes.node,
  text: PropTypes.node,
  className: PropTypes.string,
};

const EllipsisText = ({
  children = null,
  text = null,
  className = null,
}) => (children || text) && (
  <div className={classnames(styles.ellipsisText, className)}>
    {children || text}
  </div>
);

EllipsisText.propTypes = propTypes;

export default EllipsisText;
