import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsAuthenticated, useUserId } from '../../../auth/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { getHeaderBanner, getHeaderBannerModels } from '../../../initSettings/selectors';

import sendGiftHOC from '../../../gift/components/sendGiftHOC';

import { ActionLink } from 'react-redux-app/lib/modules/actionLink/components';
import { SimpleLink } from 'react-redux-app/lib/modules/link/components';
import ProfileLink from '../../../profile/components/ProfileLink';

import styles from './styles/index.css.json';


const SendGift = sendGiftHOC(ActionLink);

const propTypes = {
  className: PropTypes.string.isRequired,
};

const Banner = ({ className }) => {
  const banner = useShortSelector(getHeaderBanner);
  const models = useShortSelector(getHeaderBannerModels);
  const isAuthenticated = useIsAuthenticated();
  const currentUserId = useUserId();

  const [currentBanner, setCurrentBanner] = useState(0);

  const banners = useMemo(() => {
    const result = [];

    if (banner) {
      result.push(
        <SimpleLink to={banner.href} isExternal>
          <img className={styles.img} src={banner.img} />
        </SimpleLink>
      );
    }

    if (models) {
      models.forEach(({
        id,
        nick,
        imageUrl,
        isBlueChecked,
      }) => {
        result.push(
          <div className={styles.birthday}>
            <div className={styles.avatar}>
              <ProfileLink id={id} nick={nick}>
                <img className={styles.avatar__img} src={imageUrl} />
              </ProfileLink>
              <div className={styles.avatar__gift} />
            </div>
            <div className={styles.info}>
              <div className={styles.text}>
                <span>
                  {l('msg.happy-birthday')}
                  &nbsp;
                </span>
                <span className={styles.nick}>
                  <ProfileLink id={id} nick={nick} isBlueChecked={isBlueChecked} isEllipsisText />
                  !
                </span>
              </div>
              {id !== currentUserId && (
                <div>
                  <SendGift id={id} nick={nick} />
                </div>
              )}
            </div>
          </div>
        );
      });
    }

    return result;
  }, [banner, models, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentBanner(0);

    let intervalId;

    if (banners.length > 1) {
      intervalId = setInterval(() => {
        setCurrentBanner(prevState => (prevState + 1) % banners.length);
      }, 6000);
    }

    return () => { clearInterval(intervalId); };
  }, [banners]); // eslint-disable-line react-hooks/exhaustive-deps

  if (banners.length === 0) {
    return null;
  }

  return (
    <div className={classnames(styles.banner, className)}>
      {banners[currentBanner]}
    </div>
  );
};

Banner.propTypes = propTypes;

export default Banner;
