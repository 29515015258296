export const LANG__IT = 'it';
export const LANG__EN = 'en';
export const LANG__ES = 'es';
export const LANG__DE = 'de';
export const LANG__FR = 'fr';
export const LANG__GR = 'gr';
export const LANG__RU = 'ru';

export const langs = [
  LANG__EN,
  LANG__IT,
  LANG__ES,
  LANG__DE,
  LANG__FR,
  LANG__GR,
  LANG__RU,
];

export const langLangKeys = {
  [LANG__EN]: 'msg.lang.en',
  [LANG__IT]: 'msg.lang.it',
  [LANG__ES]: 'msg.lang.es',
  [LANG__DE]: 'msg.lang.de',
  [LANG__FR]: 'msg.lang.fr',
  [LANG__GR]: 'msg.lang.gr',
  [LANG__RU]: 'msg.lang.ru',
};
