import {
  ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST,
  ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS,
  ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


/* eslint-disable arrow-parens */
const actionLoadVideochatExternalApplicationSettingsSuccess = (
  videochatExternalApplicationSettings
) => ({
  type: ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS,
  payload: { videochatExternalApplicationSettings },
});
/* eslint-enable arrow-parens */

const actionLoadVideochatExternalApplicationSettingsFail = error => ({
  type: ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadVideochatExternalApplicationSettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'videochat/settings'
    ).then(
      videochatExternalApplicationSettings => {
        dispatch(actionLoadVideochatExternalApplicationSettingsSuccess(
          videochatExternalApplicationSettings
        ));
      },
      error => {
        dispatch(actionLoadVideochatExternalApplicationSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
