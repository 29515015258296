import { combineReducers } from 'redux';

import loadItemsError from './loadItemsError';
import loadItemInfoError from './loadItemInfoError';
import leaveCommentError from './leaveCommentError';
import deleteCommentError from './deleteCommentError';


export default combineReducers({
  loadItemsError,
  loadItemInfoError,
  leaveCommentError,
  deleteCommentError,
});
