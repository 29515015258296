import { combineReducers } from 'redux';

import loadPublicPhotosError from './loadPublicPhotosError';
import createPublicPhotoError from './createPublicPhotoError';
import deletePublicPhotoError from './deletePublicPhotoError';
import updatePublicPhotoError from './updatePublicPhotoError';


export default combineReducers({
  loadPublicPhotosError,
  createPublicPhotoError,
  deletePublicPhotoError,
  updatePublicPhotoError,
});
