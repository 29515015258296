import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_LOAD_LATEST_PRIVATE_PHOTOS__REQUEST,
  ACTION_LOAD_LATEST_PRIVATE_PHOTOS__SUCCESS,
  ACTION_LOAD_LATEST_PRIVATE_PHOTOS__FAIL,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';
import { actionSetPrivatePhotos } from '../../saleItem/actions/privatePhoto';


const actionLoadLatestPrivatePhotoSuccess = () => ({
  type: ACTION_LOAD_LATEST_PRIVATE_PHOTOS__SUCCESS,
});

const actionLoadLatestPrivatePhotoFail = error => ({
  type: ACTION_LOAD_LATEST_PRIVATE_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadLatestPrivatePhotosRequest = (
  { page, limit = 22 } = {}
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LATEST_PRIVATE_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      `private_photo/latest/${page || 1}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        if (!dom.isBrowser()) {
          dispatch(actionAddSaleItems(items));
          dispatch(actionSetPrivatePhotos(result));

          return dispatch(actionLoadLatestPrivatePhotoSuccess());
        }

        // Preload privatePhoto thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          dispatch(actionAddSaleItems(items));
          dispatch(actionSetPrivatePhotos(result));
          dispatch(actionLoadLatestPrivatePhotoSuccess());
        });
      },
      error => {
        dispatch(actionLoadLatestPrivatePhotoFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
