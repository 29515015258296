import React from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

import useCurrentLangForUrl from '../../hooks/useCurrentLangForUrl';

import { getLangUrlPart } from '../../utils';


export default Link => {
  const propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isExternal: PropTypes.bool,
  };

  const LangLink = props => {
    const { to, isExternal = false } = props;

    const langForUrl = useCurrentLangForUrl();

    let toWithLang = to;
    if (!isExternal) {
      const langUrlPart = getLangUrlPart(langForUrl);
      if (to?.pathname) {
        toWithLang = { ...to, pathname: `${langUrlPart}${to.pathname}` };
      } else {
        toWithLang = `${langUrlPart}${to}`;
      }
    }

    const propsToOmit = [];
    if (!isExternal) {
      propsToOmit.push('isExternal');
    }
    const linkProps = {
      ..._omit(props, propsToOmit),
      to: toWithLang,
    };

    return <Link {...linkProps} />;
  };

  LangLink.propTypes = propTypes;

  return LangLink;
};
