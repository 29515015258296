import { schema } from 'normalizr';


export const ITEM_SCHEMA = new schema.Entity(
  'items'
);

export const ITEMS_SCHEMA = {
  items: [ITEM_SCHEMA],
};
