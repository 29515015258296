import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_PURCHASED_EROSTORIES,
  ACTION_SET_PURCHASED_EROSTORIES,

  ACTION_SET_PURCHASED_EROSTORIES_PRESERVE,
  ACTION_SET_PURCHASED_EROSTORY_ACTIVE_PAGE,

  ACTION_LOAD_PURCHASED_EROSTORIES__REQUEST,
  ACTION_LOAD_PURCHASED_EROSTORIES__SUCCESS,
  ACTION_LOAD_PURCHASED_EROSTORIES__FAIL,
} from '../actionTypes/erostory';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getErostoryPaginationMode } from '../selectors/erostory';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';


const actionAddPurchasedErostories = erostories => ({
  type: ACTION_ADD_PURCHASED_EROSTORIES,
  payload: { erostories },
});

const actionSetPurchasedErostories = erostories => ({
  type: ACTION_SET_PURCHASED_EROSTORIES,
  payload: { erostories },
});

export const actionSetPurchasedErostoriesPreserve = () => ({
  type: ACTION_SET_PURCHASED_EROSTORIES_PRESERVE,
});

const actionSetPurchasedErostoryActivePage = activePage => ({
  type: ACTION_SET_PURCHASED_EROSTORY_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadPurchasedErostoriesSuccess = () => ({
  type: ACTION_LOAD_PURCHASED_EROSTORIES__SUCCESS,
});

const actionLoadPurchasedErostoriesFail = error => ({
  type: ACTION_LOAD_PURCHASED_EROSTORIES__FAIL,
  payload: { error },
});

export const actionLoadPurchasedErostoriesRequest = ({ page = 1, limit = 20 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getErostoryPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_PURCHASED_EROSTORIES__REQUEST,
    });

    return apiClientHelper.get(
      `story/purchased/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: erostories, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetPurchasedErostories(erostories));
          dispatch(actionSetPurchasedErostoryActivePage(page));

          return dispatch(actionLoadPurchasedErostoriesSuccess());
        }

        // Preload model photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].profilePhotoThumb));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddPurchasedErostories(erostories));
          } else {
            dispatch(actionSetPurchasedErostories(erostories));
          }

          dispatch(actionSetPurchasedErostoryActivePage(page));
          dispatch(actionLoadPurchasedErostoriesSuccess());
        });
      },
      error => {
        dispatch(actionLoadPurchasedErostoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
