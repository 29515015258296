import _get from 'lodash/get';

import formatLangForUrl from '../utils/formatLangForUrl';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getDefaultLang } from '../../../initSettings/selectors';


export const getCurrentLangForUrl = state => (
  formatLangForUrl(getCurrentLang(state), getDefaultLang(state))
);
