export const ACCOUNT_TAB__ACCOUNT = 'account';
export const ACCOUNT_TAB__BLUE_CHECK = 'blueCheck';
export const ACCOUNT_TAB__PREFERENCES = 'preferences';
export const ACCOUNT_TAB__PRESENTATION = 'presentation';
export const ACCOUNT_TAB__PRIVACY = 'privacy';
export const ACCOUNT_TAB__PROFILE = 'profile';
export const ACCOUNT_TAB__PROFILE_PHOTO = 'profilePhoto';

export const BLOG_TAB__BLUE_CHECK = 'blueCheck';
export const BLOG_TAB__COVER_PHOTO = 'coverPhoto';
export const BLOG_TAB__POSTS = 'posts';
export const BLOG_TAB__PRIVACY = 'privacy';
export const BLOG_TAB__PROFILE = 'profile';
export const BLOG_TAB__PROFILE_PHOTO = 'profilePhoto';

export const blogTabs = [
  BLOG_TAB__COVER_PHOTO,
  BLOG_TAB__PROFILE_PHOTO,
  BLOG_TAB__PROFILE,
  BLOG_TAB__PRIVACY,
  BLOG_TAB__BLUE_CHECK,
  BLOG_TAB__POSTS,
];

export const NOTIFICATION_TAB__PUSH = 'push';
export const NOTIFICATION_TAB__TELEGRAM = 'telegram';

export const notificationTabs = [
  NOTIFICATION_TAB__TELEGRAM,
  NOTIFICATION_TAB__PUSH,
];

export const PAYOUT_TAB__BANK_ACCOUNT_DETAILS = 'bankAccountDetails';
export const PAYOUT_TAB__REVOLUT = 'revolut';
export const PAYOUT_TAB__XCARD = 'xcard';

export const payoutTabs = [
  PAYOUT_TAB__BANK_ACCOUNT_DETAILS,
  // PAYOUT_TAB__XCARD,
  PAYOUT_TAB__REVOLUT,
];

export const WEBCAM_TAB__EXTERNAL_APPLICATION = 'externalApplication';
export const WEBCAM_TAB__VIDEOCHAT = 'videochat';

export const webcamTabs = [
  WEBCAM_TAB__VIDEOCHAT,
  WEBCAM_TAB__EXTERNAL_APPLICATION,
];
