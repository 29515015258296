import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_LOAD_FAVORITES__REQUEST,
  ACTION_LOAD_FAVORITES__SUCCESS,
  ACTION_LOAD_FAVORITES__FAIL,
  ACTION_ADD_FAVORITES,
  ACTION_ADD_FAVORITE_IDS,
  ACTION_SET_FAVORITE_IDS,
  ACTION_SET_FAVORITES_PRESERVE,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_FAVORITES:
      return { ...state, ...action.payload.favorites };

    default:
      return state;
  }
};

const favorites = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_FAVORITE_IDS: {
      const items = _union(
        state.items,
        action.payload.favorites.items
      );

      return {
        items,
        count: action.payload.favorites.count,
        limit: action.payload.favorites.limit,
      };
    }

    case ACTION_SET_FAVORITE_IDS:
      return action.payload.favorites;

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_FAVORITES_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_FAVORITES__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_FAVORITES__SUCCESS:
    case ACTION_LOAD_FAVORITES__FAIL:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  favorites,
  paginationMode,
  errors,
});
