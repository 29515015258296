import { combineReducers } from 'redux';

import createAppointmentError from './createAppointmentError';
import cancelAppointmentError from './cancelAppointmentError';
import approveAppointmentError from './approveAppointmentError';


export default combineReducers({
  createAppointmentError,
  cancelAppointmentError,
  approveAppointmentError,
});
