import { getGridFilter } from '../utils/interviewRequest';

import { ACTION_ADD_INTERVIEW_REQUESTS } from '../actionTypes';

import { INTERVIEW_REQUESTS_GRID_NAME } from '../constants/interviewRequest';

import { INTERVIEW_REQUESTS_SCHEMA } from '../schemas';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddInterviewRequests = interviewRequests => ({
  type: ACTION_ADD_INTERVIEW_REQUESTS,
  payload: { interviewRequests },
});

export const actionLoadInterviewRequestsRequest = getActionLoadGridDataRequest(
  INTERVIEW_REQUESTS_GRID_NAME,
  getGridFilter,
  'account/interview/invite/list',
  actionAddInterviewRequests,
  null,
  INTERVIEW_REQUESTS_SCHEMA
);
