import React from 'react';
import PropTypes from 'prop-types';

import useShowModal from '../../../core/hooks/useShowModal';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { getCreditsUrl } from '../../utils/url';

import { actionGetCredits } from '../../../deposit/actions/getCredits';

import MenuLink from '../MenuLink';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const GetCreditsMenuLink = ({ className }) => {
  const showModal = useShowModal();

  return (
    <MenuLink to={getCreditsUrl()} onClick={showModal(actionGetCredits())} className={className}>
      {l('msg.get-credits')}
    </MenuLink>
  );
};

GetCreditsMenuLink.propTypes = propTypes;

export default GetCreditsMenuLink;
