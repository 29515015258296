import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


export const getNewsItem = (state, id) => _get(state, `newsFeed.newsById.${id}`);
export const getNewsFeed = (state, id) => _get(state, `newsFeed.byId.${id}`);
export const getNewsFeedContent = (state, id) => _get(getNewsFeed(state, id), 'content');
export const getNewsFeedActor = (state, id) => _get(getNewsFeed(state, id), 'actor');
export const getNewsFeedLang = (state, id) => _get(getNewsFeed(state, id), 'lang');
export const getPublicPhoto = (state, id) => _get(state, `newsFeed.pubicPhotosById.${id}`);
export const getCommentItem = (state, id) => _get(state, `newsFeed.commentItemsById.${id}`);

const newsFeedSelector = state => _get(state, 'newsFeed.newsFeed');
export const getNewsFeedOrder = state => _get(newsFeedSelector(state), 'order');
export const getNewsFeedIds = state => _get(newsFeedSelector(state), 'items');
const getNewsFeedTotalCount = state => _get(newsFeedSelector(state), 'count');
const getNewsFeedLimit = state => _get(newsFeedSelector(state), 'limit');
export const getIsLastNewsFeedLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getNewsFeedIds, getNewsFeedTotalCount, getNewsFeedLimit
);
export const getNewsFeedTotalPagesCount = state => getTotalPagesCount(
  state, getNewsFeedTotalCount, getNewsFeedLimit
);

export const getNewsFeedPaginationMode = state => _get(state, 'newsFeed.paginationMode');
export const getNewsFeedActivePage = state => _get(state, 'newsFeed.activePage');
export const getShouldReloadNewsFeedActivePage = state => (
  _get(state, 'newsFeed.shouldReloadActivePage')
);

export const getNewsFeedStaff = state => _get(newsFeedSelector(state), 'staff');

export const getIsNewsFeedBeingDeleted = (state, newsFeedId) => (
  !!_get(state, `newsFeed.isNewsFeedBeingDeleted.${newsFeedId}`)
);
