export const ACTION_ADD_INTERVIEWS = 'ACTION_ADD_INTERVIEWS';

export const ACTION_ADD_INTERVIEW_IDS = 'ACTION_ADD_INTERVIEW_IDS';
export const ACTION_SET_INTERVIEW_IDS = 'ACTION_SET_INTERVIEW_IDS';

export const ACTION_LOAD_INTERVIEWS__REQUEST = 'ACTION_LOAD_INTERVIEWS__REQUEST';
export const ACTION_LOAD_INTERVIEWS__SUCCESS = 'ACTION_LOAD_INTERVIEWS__SUCCESS';
export const ACTION_LOAD_INTERVIEWS__FAIL = 'ACTION_LOAD_INTERVIEWS__FAIL';

export const ACTION_SET_INTERVIEWS_PRESERVE = 'ACTION_SET_INTERVIEWS_PRESERVE';

export const ACTION_LOAD_INTERVIEW__REQUEST = 'ACTION_LOAD_INTERVIEW__REQUEST';
export const ACTION_LOAD_INTERVIEW__SUCCESS = 'ACTION_LOAD_INTERVIEW__SUCCESS';
export const ACTION_LOAD_INTERVIEW__FAIL = 'ACTION_LOAD_INTERVIEW__FAIL';

export const ACTION_ADD_INTERVIEW_FORMS = 'ACTION_ADD_INTERVIEW_FORMS';
export const ACTION_ADD_ANSWERED_INTERVIEWS = 'ACTION_ADD_ANSWERED_INTERVIEWS';
export const ACTION_ADD_STAFF_INTERVIEWS = 'ACTION_ADD_STAFF_INTERVIEWS';
export const ACTION_ADD_INTERVIEW_REQUESTS = 'ACTION_ADD_INTERVIEW_REQUESTS';

export const ACTION_LOAD_INTERVIEW_FORM__REQUEST = 'ACTION_LOAD_INTERVIEW_FORM__REQUEST';
export const ACTION_LOAD_INTERVIEW_FORM__SUCCESS = 'ACTION_LOAD_INTERVIEW_FORM__SUCCESS';
export const ACTION_LOAD_INTERVIEW_FORM__FAIL = 'ACTION_LOAD_INTERVIEW_FORM__FAIL';

export const ACTION_CANCEL_INTERVIEW_FORM__REQUEST = 'ACTION_CANCEL_INTERVIEW_FORM__REQUEST';
export const ACTION_CANCEL_INTERVIEW_FORM__SUCCESS = 'ACTION_CANCEL_INTERVIEW_FORM__SUCCESS';
export const ACTION_CANCEL_INTERVIEW_FORM__FAIL = 'ACTION_CANCEL_INTERVIEW_FORM__FAIL';

export const ACTION_CREATE_INTERVIEW_FORM__REQUEST = 'ACTION_CREATE_INTERVIEW_FORM__REQUEST';
export const ACTION_CREATE_INTERVIEW_FORM__SUCCESS = 'ACTION_CREATE_INTERVIEW_FORM__SUCCESS';
export const ACTION_CREATE_INTERVIEW_FORM__FAIL = 'ACTION_CREATE_INTERVIEW_FORM__FAIL';

export const ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__REQUEST = 'ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__REQUEST';
export const ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__SUCCESS = 'ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__SUCCESS';
export const ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__FAIL = 'ACTION_LOAD_CREATE_INTERVIEW_FORM_SETTINGS__FAIL';

export const ACTION_LOAD_INTERVIEWER__REQUEST = 'ACTION_LOAD_INTERVIEWER__REQUEST';
export const ACTION_LOAD_INTERVIEWER__SUCCESS = 'ACTION_LOAD_INTERVIEWER__SUCCESS';
export const ACTION_LOAD_INTERVIEWER__FAIL = 'ACTION_LOAD_INTERVIEWER__FAIL';

export const ACTION_ANSWER_INTERVIEW__REQUEST = 'ACTION_ANSWER_INTERVIEW__REQUEST';
export const ACTION_ANSWER_INTERVIEW__SUCCESS = 'ACTION_ANSWER_INTERVIEW__SUCCESS';
export const ACTION_ANSWER_INTERVIEW__FAIL = 'ACTION_ANSWER_INTERVIEW__FAIL';

export const ACTION_REJECT_INTERVIEW__REQUEST = 'ACTION_REJECT_INTERVIEW__REQUEST';
export const ACTION_REJECT_INTERVIEW__SUCCESS = 'ACTION_REJECT_INTERVIEW__SUCCESS';
export const ACTION_REJECT_INTERVIEW__FAIL = 'ACTION_REJECT_INTERVIEW__FAIL';
