import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getIsAgency } from '../../../auth/selectors';
import { getLoadAgencyModelsError } from '../../selectors/errors';

import { actionLoadAgencyModelsRequest } from '../../actions/loadAgencyModels';


export default ({ getState, dispatch }) => (
  function loadAgencyModelsHandler(nextState, successCallback, errorCallback) {
    if (!getIsAgency(getState())) {
      successCallback();
      return;
    }

    dispatch(
      actionLoadAgencyModelsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadAgencyModelsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            console.consoleError('loadAgencyModelsHandler error', error);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadAgencyModelsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
