import React from 'react';
import { Helmet } from 'react-helmet';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import { getFavicons } from '../../../initSettings/selectors';


export default () => {
  const favicons = useShortSelector(getFavicons);

  const helmetProps = {};

  favicons.forEach(favicon => {
    const tag = {};
    favicon.attrs.forEach(attr => {
      tag[attr.name] = attr.value;
    });
    if (!Array.isArray(helmetProps[favicon.tag])) {
      helmetProps[favicon.tag] = [];
    }
    helmetProps[favicon.tag].push(tag);
  });

  return <Helmet {...helmetProps} />;
};
