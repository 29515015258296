export const ACTION_ADD_MODELS = 'ACTION_ADD_MODELS';
export const ACTION_ADD_MODEL = 'ACTION_ADD_MODEL';

export const ACTION_ADD_MODEL_IDS = 'ACTION_ADD_MODEL_IDS';
export const ACTION_SET_MODEL_IDS = 'ACTION_SET_MODEL_IDS';

export const ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST = 'ACTION_LOAD_CHAT_GALLERY_MODELS__REQUEST';
export const ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS = 'ACTION_LOAD_CHAT_GALLERY_MODELS__SUCCESS';
export const ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL = 'ACTION_LOAD_CHAT_GALLERY_MODELS__FAIL';

export const ACTION_LOAD_CHAT_GALLERY_MODEL__REQUEST = 'ACTION_LOAD_CHAT_GALLERY_MODEL__REQUEST';
export const ACTION_LOAD_CHAT_GALLERY_MODEL__SUCCESS = 'ACTION_LOAD_CHAT_GALLERY_MODEL__SUCCESS';
export const ACTION_LOAD_CHAT_GALLERY_MODEL__FAIL = 'ACTION_LOAD_CHAT_GALLERY_MODEL__FAIL';

export const ACTION_SET_MODEL_CHAT_STATUSES = 'ACTION_SET_MODEL_CHAT_STATUSES';
export const ACTION_UPDATE_MODEL_CHAT_STATUS = 'ACTION_UPDATE_MODEL_CHAT_STATUS';

export const ACTION_SET_MODEL_ORDER = 'ACTION_SET_MODEL_ORDER';
export const ACTION_INSERT_MODEL = 'ACTION_INSERT_MODEL';
export const ACTION_SWAP_MODELS = 'ACTION_SWAP_MODELS';

export const ACTION_SET_CURRENT_PREVIEW_ITEM = 'ACTION_SET_CURRENT_PREVIEW_ITEM';
export const ACTION_SET_PREVIEW_INITIALIZED = 'ACTION_SET_PREVIEW_INITIALIZED';
export const ACTION_SET_PREVIEW_READY = 'ACTION_SET_PREVIEW_READY';

export const ACTION_LOAD_MODELS__REQUEST = 'ACTION_LOAD_MODELS__REQUEST';
export const ACTION_LOAD_MODELS__SUCCESS = 'ACTION_LOAD_MODELS__SUCCESS';
export const ACTION_LOAD_MODELS__FAIL = 'ACTION_LOAD_MODELS__FAIL';
export const ACTION_SET_MODELS_PRESERVE = 'ACTION_SET_MODELS_PRESERVE';

export const ACTION_SET_ALLOWED_TYPES = 'ACTION_SET_ALLOWED_TYPES';

export const ACTION_SET_WEEKEND_IMAGE = 'ACTION_SET_WEEKEND_IMAGE';
export const ACTION_SET_CREDITS_BONUS = 'ACTION_SET_CREDITS_BONUS';

export const ACTION_SAVE_WEEKLY_EVENT_PRIZE__REQUEST = 'ACTION_SAVE_WEEKLY_EVENT_PRIZE__REQUEST';
export const ACTION_SAVE_WEEKLY_EVENT_PRIZE__SUCCESS = 'ACTION_SAVE_WEEKLY_EVENT_PRIZE__SUCCESS';
export const ACTION_SAVE_WEEKLY_EVENT_PRIZE__FAIL = 'ACTION_SAVE_WEEKLY_EVENT_PRIZE__FAIL';
