import { MODAL_DIALOG__MISSED_BONUSES } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowMissedBonusesDialog = bonuses => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__MISSED_BONUSES, { bonuses }))
);

export const actionHideMissedBonusesDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__MISSED_BONUSES))
);
