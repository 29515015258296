export const ACTION_SET_MODEL = 'ACTION_SET_MODEL';

export const ACTION_LOAD_MODEL_NEWS_FEED__REQUEST = 'ACTION_LOAD_MODEL_NEWS_FEED__REQUEST';
export const ACTION_LOAD_MODEL_NEWS_FEED__SUCCESS = 'ACTION_LOAD_MODEL_NEWS_FEED__SUCCESS';
export const ACTION_LOAD_MODEL_NEWS_FEED__FAIL = 'ACTION_LOAD_MODEL_NEWS_FEED__FAIL';

export const ACTION_LOAD_MODEL_PRIVATE_PHOTOS__REQUEST = 'ACTION_LOAD_MODEL_PRIVATE_PHOTOS__REQUEST';
export const ACTION_LOAD_MODEL_PRIVATE_PHOTOS__SUCCESS = 'ACTION_LOAD_MODEL_PRIVATE_PHOTOS__SUCCESS';
export const ACTION_LOAD_MODEL_PRIVATE_PHOTOS__FAIL = 'ACTION_LOAD_MODEL_PRIVATE_PHOTOS__FAIL';

export const ACTION_LOAD_MODEL_PUBLIC_PHOTOS__REQUEST = 'ACTION_LOAD_MODEL_PUBLIC_PHOTOS__REQUEST';
export const ACTION_LOAD_MODEL_PUBLIC_PHOTOS__SUCCESS = 'ACTION_LOAD_MODEL_PUBLIC_PHOTOS__SUCCESS';
export const ACTION_LOAD_MODEL_PUBLIC_PHOTOS__FAIL = 'ACTION_LOAD_MODEL_PUBLIC_PHOTOS__FAIL';

export const ACTION_LOAD_MODEL_VIDEOS__REQUEST = 'ACTION_LOAD_MODEL_VIDEOS__REQUEST';
export const ACTION_LOAD_MODEL_VIDEOS__SUCCESS = 'ACTION_LOAD_MODEL_VIDEOS__SUCCESS';
export const ACTION_LOAD_MODEL_VIDEOS__FAIL = 'ACTION_LOAD_MODEL_VIDEOS__FAIL';

export const ACTION_LOAD_MODEL_EROSTORIES__REQUEST = 'ACTION_LOAD_MODEL_EROSTORIES__REQUEST';
export const ACTION_LOAD_MODEL_EROSTORIES__SUCCESS = 'ACTION_LOAD_MODEL_EROSTORIES__SUCCESS';
export const ACTION_LOAD_MODEL_EROSTORIES__FAIL = 'ACTION_LOAD_MODEL_EROSTORIES__FAIL';

export const ACTION_LOAD_MODEL_GIFTS__REQUEST = 'ACTION_LOAD_MODEL_GIFTS__REQUEST';
export const ACTION_LOAD_MODEL_GIFTS__SUCCESS = 'ACTION_LOAD_MODEL_GIFTS__SUCCESS';
export const ACTION_LOAD_MODEL_GIFTS__FAIL = 'ACTION_LOAD_MODEL_GIFTS__FAIL';

export const ACTION_LOAD_MODEL_FEEDBACKS__REQUEST = 'ACTION_LOAD_MODEL_FEEDBACKS__REQUEST';
export const ACTION_LOAD_MODEL_FEEDBACKS__SUCCESS = 'ACTION_LOAD_MODEL_FEEDBACKS__SUCCESS';
export const ACTION_LOAD_MODEL_FEEDBACKS__FAIL = 'ACTION_LOAD_MODEL_FEEDBACKS__FAIL';

export const ACTION_LOAD_MODEL_PERSONAL_CONTACT__REQUEST = 'ACTION_LOAD_MODEL_PERSONAL_CONTACT__REQUEST';
export const ACTION_LOAD_MODEL_PERSONAL_CONTACT__SUCCESS = 'ACTION_LOAD_MODEL_PERSONAL_CONTACT__SUCCESS';
export const ACTION_LOAD_MODEL_PERSONAL_CONTACT__FAIL = 'ACTION_LOAD_MODEL_PERSONAL_CONTACT__FAIL';

export const ACTION_UPDATE_MODEL_RATING__REQUEST = 'ACTION_UPDATE_MODEL_RATING__REQUEST';
export const ACTION_UPDATE_MODEL_RATING__SUCCESS = 'ACTION_UPDATE_MODEL_RATING__SUCCESS';
export const ACTION_UPDATE_MODEL_RATING__FAIL = 'ACTION_UPDATE_MODEL_RATING__FAIL';
