import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const publicPhotoSelector = state => _get(state, 'ownSaleItem.publicPhoto');
const publicPhotosSelector = state => _get(publicPhotoSelector(state), 'publicPhotos');
export const getPublicPhotoIds = state => _get(publicPhotosSelector(state), 'items');
const getPublicPhotosTotalCount = state => _get(publicPhotosSelector(state), 'count');
const getPublicPhotosLimit = state => _get(publicPhotosSelector(state), 'limit');
export const getIsLastPublicPhotoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getPublicPhotoIds, getPublicPhotosTotalCount, getPublicPhotosLimit
);
export const getPublicPhotoTotalPagesCount = state => getTotalPagesCount(
  state, getPublicPhotosTotalCount, getPublicPhotosLimit
);

export const getPublicPhotoPaginationMode = state => (
  _get(publicPhotoSelector(state), 'paginationMode')
);
export const getPublicPhotoActivePage = state => _get(publicPhotoSelector(state), 'activePage');
export const getShouldLoadPublicPhotoFirstPage = state => (
  _get(publicPhotoSelector(state), 'shouldLoadFirstPage')
);
export const getShouldReloadPublicPhotoActivePage = state => (
  _get(publicPhotoSelector(state), 'shouldReloadActivePage')
);

export const getIsPublicPhotoBeingDeleted = (state, publicPhotoId) => (
  !!_get(publicPhotoSelector(state), `isPublicPhotoBeingDeleted.${publicPhotoId}`)
);

const errorsSelector = state => _get(publicPhotoSelector(state), 'errors');
export const getLoadPublicPhotosError = state => (
  _get(errorsSelector(state), 'loadPublicPhotosError')
);
export const getCreatePublicPhotoError = state => (
  _get(errorsSelector(state), 'createPublicPhotoError')
);
export const getDeletePublicPhotoError = state => (
  _get(errorsSelector(state), 'deletePublicPhotoError')
);
export const getUpdatePublicPhotoError = state => (
  _get(errorsSelector(state), 'updatePublicPhotoError')
);
