import { combineReducers } from 'redux';

import loadLandingPageError from './loadLandingPageError';
import visitLinkError from './visitLinkError';


export default combineReducers({
  loadLandingPageError,
  visitLinkError,
});
