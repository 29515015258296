import { combineReducers } from 'redux';

import loadGiftsError from './loadGiftsError';
import sendGiftError from './sendGiftError';


export default combineReducers({
  loadGiftsError,
  sendGiftError,
});
