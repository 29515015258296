import { takeLatest, put } from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { getUri } from 'react-redux-app/lib/modules/routing/utils';
import { getMediaViewerCloseUrl, getMediaViewerFilter } from '../utils';

import { ACTION_SUBSCRIBE__SUCCESS, ACTION_BUY_POST__SUCCESS } from '../../blog/actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


function* reloadMediaViewer(history, action) {
  try {
    if (!getMediaViewerFilter(history.location)) {
      return;
    }

    console.consoleLog('reload MediaViewer by action:', action);

    const currentUri = getUri(history.location);
    history.push(getMediaViewerCloseUrl(history.location));

    setTimeout(() => { history.push(currentUri); }, 200);
  } catch (e) {
    yield put(actionAddException(e));
  }
}

export default function* saga(history) {
  yield takeLatest([
    ACTION_SUBSCRIBE__SUCCESS,
    ACTION_BUY_POST__SUCCESS,
  ], reloadMediaViewer, history);
}
