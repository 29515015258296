import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_LOAD_SITEMAP__SUCCESS,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_SITEMAP__SUCCESS:
      return action.payload.sitemap;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
