import { useLocation, useNavigate } from 'react-router-dom';

import { getProfileOverlayLocation } from '../utils';


export default () => {
  const location = useLocation();
  const navigate = useNavigate();

  return id => navigate(getProfileOverlayLocation(location, id));
};
