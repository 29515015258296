import { MODAL_DIALOG__GIFT_EVENT } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowGiftEventDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__GIFT_EVENT))
);

export const actionHideGiftEventDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__GIFT_EVENT))
);
