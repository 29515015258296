import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadEmoticonsError } from '../../selectors/errors';

import { actionLoadEmoticonsRequest } from '../../actions/loadEmoticons';


export default ({ getState, dispatch }) => (
  function loadEmoticonsHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadEmoticonsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadEmoticonsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            console.consoleError('loadEmoticonsHandler error', error);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadEmoticonsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
