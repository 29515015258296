import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import preloadImage from '../../core/utils/preloadImage';

import { MODAL_DIALOG__ROULETTE } from '../constants/dialogs';
import { rouletteData, roulettePointerPath, roulettePlayPath } from '../constants/roulette';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';
import { getUserRoulettePrize } from '../selectors';

import { actionShowRouletteDialog } from '../actions/dialogs/rouletteDialog';


export default () => {
  const dispatch = useDispatch();

  const isDialogVisible = useSelector(
    state => isModalVisibleByName(state, MODAL_DIALOG__ROULETTE)
  );
  const prize = useShortSelector(getUserRoulettePrize);

  useEffect(() => {
    if (!prize) {
      return;
    }

    if (!isDialogVisible) {
      const promises = rouletteData.map(({ image }) => preloadImage(image.uri));
      promises.push(preloadImage(roulettePointerPath));
      promises.push(preloadImage(roulettePlayPath));

      Promise.all(promises).then(() => { dispatch(actionShowRouletteDialog()); });
    }
  }, [prize]); // eslint-disable-line react-hooks/exhaustive-deps
};
