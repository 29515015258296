import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_SET_COVER_PHOTOS,

  ACTION_LOAD_COVER_PHOTOS__REQUEST,
  ACTION_LOAD_COVER_PHOTOS__SUCCESS,
  ACTION_LOAD_COVER_PHOTOS__FAIL,
} from '../actionTypes/coverPhoto';

import { OWN_ITEMS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionSetCoverPhotos = coverPhotos => ({
  type: ACTION_SET_COVER_PHOTOS,
  payload: { coverPhotos },
});

const actionLoadCoverPhotosSuccess = () => ({
  type: ACTION_LOAD_COVER_PHOTOS__SUCCESS,
});

const actionLoadCoverPhotosFail = error => ({
  type: ACTION_LOAD_COVER_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadCoverPhotosRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_COVER_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      'account/profile_cover/list',
      {},
      OWN_ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetCoverPhotos(result));

          return dispatch(actionLoadCoverPhotosSuccess());
        }

        // Preload photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].url));

        return Promise.all(promises).then(() => {
          dispatch(actionSetCoverPhotos(result));
          dispatch(actionLoadCoverPhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadCoverPhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
