import {
  ACTION_LOAD_PEER__REQUEST,
  ACTION_LOAD_PEER__SUCCESS,
  ACTION_LOAD_PEER__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadPeerSuccess = peer => ({
  type: ACTION_LOAD_PEER__SUCCESS,
  payload: { peer },
});

const actionLoadPeerFail = error => ({
  type: ACTION_LOAD_PEER__FAIL,
  payload: { error },
});

export const actionLoadPeerRequest = peerId => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PEER__REQUEST,
    });

    return apiClientHelper.get(
      `message/peer/${peerId}`
    ).then(
      result => {
        dispatch(actionLoadPeerSuccess(result));
      },
      error => {
        dispatch(actionLoadPeerFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
