import {
  FILTER__BELOWFOURTY,
  FILTER__MALEMODEL,
  FILTER__MATURE,
  FILTER__MILF,
} from '../../chatGallery/constants';


export const LANDING_ID = 'landing';

export const TYPE__HEADER1 = 'header1';
export const TYPE__HEADER2 = 'header2';
export const TYPE__HEADER3 = 'header3';
export const TYPE__TEXT = 'text';
export const TYPE__GALLERY = 'gallery';
export const TYPE__BUTTON = 'button';
export const TYPE__LINKS = 'links';

// ISEEXYOU, RIV
export const LANDING_PAGE__CAM_GIRLS = 'cam-girls';
export const LANDING_PAGE__CHAT_PORNO = 'chat-porno';
export const LANDING_PAGE__SEXY_CAM = 'sexy-cam';

// ISEEXYOU
export const LANDING_PAGE__CAM_PORNO = 'cam-porno';
export const LANDING_PAGE__CAM_SEX = 'cam-sex';
export const LANDING_PAGE__CHATSEX = 'chatsex';
export const LANDING_PAGE__MATURE_LIVE_CAM = 'mature-live-cam';
export const LANDING_PAGE__MILF_CAM = 'milf-cam';
export const LANDING_PAGE__VIDEOCHAT_ADULTI = 'videochat-adulti';
export const LANDING_PAGE__VIDEOCHAT_PORNO = 'videochat-porno';
export const LANDING_PAGE__WEBCAM_GIRLS = 'webcam-girls';
export const LANDING_PAGE__WEBCAM_PORNO = 'webcam-porno';

// RIV
export const LANDING_PAGE__CHAT_ADULTI = 'chat-adulti';
export const LANDING_PAGE__CHAT_EROTICA = 'chat-erotica';
export const LANDING_PAGE__CHAT_EROTICHE = 'chat-erotiche';
export const LANDING_PAGE__CHAT_SEX = 'chat-sex';
export const LANDING_PAGE__CHAT_SEXY_ITALIANE = 'chat-sexy-italiane';
export const LANDING_PAGE__MATURE_WEBCAM = 'mature-webcam';
export const LANDING_PAGE__PORN_CAM = 'porn-cam';
export const LANDING_PAGE__RAGAZZE_IN_CAM = 'ragazze-in-cam';
export const LANDING_PAGE__RAGAZZE_IN_WEBCAM = 'ragazze-in-webcam';
export const LANDING_PAGE__SESSO_IN_CAM = 'sesso-in-cam';
export const LANDING_PAGE__SESSO_WEBCAM = 'sesso-webcam';
export const LANDING_PAGE__VIDEOCHAT_RAGAZZE = 'videochat-ragazze';
export const LANDING_PAGE__WEBCAM_GAY = 'webcam-gay';
export const LANDING_PAGE__WEBCAM_GIRL = 'webcam-girl';
export const LANDING_PAGE__WEBCAM_HOT = 'webcam-hot';
export const LANDING_PAGE__WEBCAM_SEX = 'webcam-sex';

export const LANDING_PAGE__MEDIA_AND_TV = 'media-and-tv';

export const landingPages = [
  LANDING_PAGE__CAM_GIRLS,
  LANDING_PAGE__CHAT_PORNO,
  LANDING_PAGE__SEXY_CAM,

  LANDING_PAGE__CAM_PORNO,
  LANDING_PAGE__CAM_SEX,
  LANDING_PAGE__CHATSEX,
  LANDING_PAGE__MATURE_LIVE_CAM,
  LANDING_PAGE__MILF_CAM,
  LANDING_PAGE__VIDEOCHAT_ADULTI,
  LANDING_PAGE__VIDEOCHAT_PORNO,
  LANDING_PAGE__WEBCAM_GIRLS,
  LANDING_PAGE__WEBCAM_PORNO,

  LANDING_PAGE__CHAT_ADULTI,
  LANDING_PAGE__CHAT_EROTICA,
  LANDING_PAGE__CHAT_EROTICHE,
  LANDING_PAGE__CHAT_SEX,
  LANDING_PAGE__CHAT_SEXY_ITALIANE,
  LANDING_PAGE__MATURE_WEBCAM,
  LANDING_PAGE__PORN_CAM,
  LANDING_PAGE__RAGAZZE_IN_CAM,
  LANDING_PAGE__RAGAZZE_IN_WEBCAM,
  LANDING_PAGE__SESSO_IN_CAM,
  LANDING_PAGE__SESSO_WEBCAM,
  LANDING_PAGE__VIDEOCHAT_RAGAZZE,
  LANDING_PAGE__WEBCAM_GAY,
  LANDING_PAGE__WEBCAM_GIRL,
  LANDING_PAGE__WEBCAM_HOT,
  LANDING_PAGE__WEBCAM_SEX,

  LANDING_PAGE__MEDIA_AND_TV,
];

export const landingPageToFilterMap = {
  [LANDING_PAGE__CAM_GIRLS]: FILTER__BELOWFOURTY,

  [LANDING_PAGE__MATURE_LIVE_CAM]: FILTER__MATURE,
  [LANDING_PAGE__MILF_CAM]: FILTER__MILF,

  [LANDING_PAGE__MATURE_WEBCAM]: FILTER__MATURE,
  [LANDING_PAGE__RAGAZZE_IN_CAM]: FILTER__BELOWFOURTY,
  [LANDING_PAGE__RAGAZZE_IN_WEBCAM]: FILTER__MATURE,
  [LANDING_PAGE__WEBCAM_GAY]: FILTER__MALEMODEL,
  [LANDING_PAGE__WEBCAM_GIRL]: FILTER__BELOWFOURTY,
};
