import { combineReducers } from 'redux';

import deletePartnerError from './deletePartnerError';
import downloadAgreementError from './downloadAgreementError';
import createPartnerError from './createPartnerError';
import updatePartnerError from './updatePartnerError';
import uploadPartnerFileError from './uploadPartnerFileError';
import loadPartnerNewFileError from './loadPartnerNewFileError';
import loadPartnerSavedFileError from './loadPartnerSavedFileError';


export default combineReducers({
  deletePartnerError,
  downloadAgreementError,
  createPartnerError,
  updatePartnerError,
  uploadPartnerFileError,
  loadPartnerNewFileError,
  loadPartnerSavedFileError,
});
