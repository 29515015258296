import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_OWN_VIDEOS,
  ACTION_SET_OWN_VIDEOS,

  ACTION_SET_OWN_VIDEOS_PRESERVE,
  ACTION_SET_OWN_VIDEO_ACTIVE_PAGE,

  ACTION_LOAD_OWN_VIDEOS__REQUEST,
  ACTION_LOAD_OWN_VIDEOS__SUCCESS,
  ACTION_LOAD_OWN_VIDEOS__FAIL,
} from '../actionTypes/video';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getVideoPaginationMode } from '../selectors/video';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionAddOwnVideos = videos => ({
  type: ACTION_ADD_OWN_VIDEOS,
  payload: { videos },
});

const actionSetOwnVideos = videos => ({
  type: ACTION_SET_OWN_VIDEOS,
  payload: { videos },
});

export const actionSetOwnVideosPreserve = () => ({
  type: ACTION_SET_OWN_VIDEOS_PRESERVE,
});

const actionSetOwnVideoActivePage = activePage => ({
  type: ACTION_SET_OWN_VIDEO_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnVideosSuccess = () => ({
  type: ACTION_LOAD_OWN_VIDEOS__SUCCESS,
});

const actionLoadOwnVideosFail = error => ({
  type: ACTION_LOAD_OWN_VIDEOS__FAIL,
  payload: { error },
});

export const actionLoadOwnVideosRequest = ({
  isOnlyApproved = false, page = 1, limit = 27,
} = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getVideoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_VIDEOS__REQUEST,
    });

    return apiClientHelper.get(
      `account/video/list/${page}`,
      { params: { isOnlyApproved, limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: videos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetOwnVideos(videos));
          dispatch(actionSetOwnVideoActivePage(page));

          return dispatch(actionLoadOwnVideosSuccess());
        }

        // Preload video thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddOwnVideos(videos));
          } else {
            dispatch(actionSetOwnVideos(videos));
          }

          dispatch(actionSetOwnVideoActivePage(page));
          dispatch(actionLoadOwnVideosSuccess());
        });
      },
      error => {
        dispatch(actionLoadOwnVideosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadApprovedOwnVideosRequest = (filter = {}) => (
  actionLoadOwnVideosRequest({ ...filter, isOnlyApproved: true })
);
