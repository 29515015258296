import {
  ACTION_LOAD_PROFILE__REQUEST,
  ACTION_LOAD_PROFILE__FAIL,
  ACTION_LOAD_PROFILE__SUCCESS,
} from '../actionTypes';

import { PROFILE_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionUpdateModelChatStatus } from '../../chatGallery/actions/models';
import { actionAddInterviews } from '../../interview/actions/loadInterviews';
import { actionSetModel } from '../../model/actions/setModel';
import {
  actionAddNewsFeed,
  actionAddPublicPhotos,
  actionAddCommentItems,
} from '../../newsFeed/actions';
import { actionAddSaleItems } from '../../saleItem/actions';
import { actionSetUser } from '../../user/actions/setUser';


const actionLoadProfileSuccess = () => ({
  type: ACTION_LOAD_PROFILE__SUCCESS,
});

const actionLoadProfileFail = error => ({
  type: ACTION_LOAD_PROFILE__FAIL,
  payload: { error },
});

export const actionLoadProfileRequest = id => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PROFILE__REQUEST,
    });

    return apiClientHelper.get(
      `user/${id}/profile`,
      {},
      PROFILE_SCHEMA
    ).then(
      ({ result: profileId, entities }) => {
        dispatch(actionAddSaleItems(entities.items));

        const {
          common,
          model,
        } = entities.profiles[profileId];

        if (model) {
          dispatch(actionUpdateModelChatStatus(profileId, model.status, model.stream));
          dispatch(actionAddInterviews(entities.interviews));
          dispatch(actionAddNewsFeed(entities.newsFeeds));
          dispatch(actionAddPublicPhotos(entities.publicPhotos || {}));
          dispatch(actionAddCommentItems(entities.commentItems || {}));
          dispatch(actionSetModel(model));
        } else {
          dispatch(actionSetUser(common));
        }

        dispatch(actionLoadProfileSuccess());
      },
      error => {
        dispatch(actionLoadProfileFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
