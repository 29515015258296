import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_AGENCY_MODELS,
  ACTION_SET_MODEL_REGISTRATION_STEPS,
  ACTION_UPDATE_MODEL_STATUS__REQUEST,
  ACTION_UPDATE_MODEL_STATUS__SUCCESS,
  ACTION_UPDATE_MODEL_STATUS__FAIL,
  ACTION_LOAD_AGENCY_MODEL__REQUEST,
  ACTION_LOAD_AGENCY_MODEL__SUCCESS,
  ACTION_LOAD_AGENCY_MODELS__SUCCESS,
  ACTION_LOAD_MODEL_REGISTRATION_STEPS__REQUEST,
  ACTION_ROTATE_PHOTO__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_AGENCY_MODELS:
      return { ...state, ...action.payload.models };

    case ACTION_UPDATE_MODEL_STATUS__SUCCESS:
      return _omit(state, action.payload.modelId);

    default:
      return state;
  }
};

const isModelStatusBeingChanged = (state = {}, action) => {
  switch (action.type) {
    case ACTION_UPDATE_MODEL_STATUS__REQUEST:
      return { ...state, [action.payload.modelId]: true };

    case ACTION_UPDATE_MODEL_STATUS__SUCCESS:
    case ACTION_UPDATE_MODEL_STATUS__FAIL:
      return _omit(state, action.payload.modelId);

    default:
      return state;
  }
};

const agencyModel = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_AGENCY_MODEL__REQUEST:
      return null;

    case ACTION_LOAD_AGENCY_MODEL__SUCCESS:
      return action.payload.agencyModel;

    default:
      return state;
  }
};

const agencyModels = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_AGENCY_MODELS__SUCCESS:
      return action.payload.models;

    default:
      return state;
  }
};

const modelRegistrationSteps = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_MODEL_REGISTRATION_STEPS:
      return action.payload.steps;

    default:
      return state;
  }
};

const photoModifications = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ROTATE_PHOTO__SUCCESS:
      return {
        ...state,
        [action.payload.step]: {
          ...state[action.payload.step],
          [action.payload.field]: new Date().getTime(),
        },
      };

    case ACTION_LOAD_MODEL_REGISTRATION_STEPS__REQUEST:
      return {};

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isModelStatusBeingChanged,
  agencyModel,
  agencyModels,
  modelRegistrationSteps,
  photoModifications,
  errors,
});
