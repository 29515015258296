import {
  ACTION_DOWNLOAD_PAYOUT_INVOICE__REQUEST,
  ACTION_DOWNLOAD_PAYOUT_INVOICE__SUCCESS,
  ACTION_DOWNLOAD_PAYOUT_INVOICE__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_DOWNLOAD_PAYOUT_INVOICE__REQUEST:
    case ACTION_DOWNLOAD_PAYOUT_INVOICE__SUCCESS:
      return null;

    case ACTION_DOWNLOAD_PAYOUT_INVOICE__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
