import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'registration.errors');
export const getLoadStepError = state => (
  _get(errorsSelector(state), 'loadStepError')
);
export const getAbortSignupError = state => (
  _get(errorsSelector(state), 'abortSignupError')
);
export const getAccountInfoError = state => (
  _get(errorsSelector(state), 'accountInfoError')
);
export const getIdPhotoError = state => (
  _get(errorsSelector(state), 'idPhotoError')
);
export const getProfilePhotoError = state => (
  _get(errorsSelector(state), 'profilePhotoError')
);
export const getFaceshotError = state => (
  _get(errorsSelector(state), 'faceshotError')
);
export const getDownloadAgreementError = state => (
  _get(errorsSelector(state), 'downloadAgreementError')
);
export const getAgreementError = state => (
  _get(errorsSelector(state), 'agreementError')
);
