import {
  ACTION_LOAD_WALLET_ADDRESS__REQUEST,
  ACTION_LOAD_WALLET_ADDRESS__SUCCESS,
  ACTION_LOAD_WALLET_ADDRESS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadWalletAddressSuccess = address => ({
  type: ACTION_LOAD_WALLET_ADDRESS__SUCCESS,
  payload: { address },
});

const actionLoadWalletAddressFail = error => ({
  type: ACTION_LOAD_WALLET_ADDRESS__FAIL,
  payload: { error },
});

export const actionLoadWalletAddressRequest = currency => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_WALLET_ADDRESS__REQUEST,
    });

    return apiClientHelper.post(
      'deposit/crypto_wallet',
      { data: { cryptoCurrency: currency } }
    ).then(
      result => {
        dispatch(actionLoadWalletAddressSuccess(result));
      },
      error => {
        dispatch(actionLoadWalletAddressFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
