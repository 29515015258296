import { getGridFilter } from '../utils/geoFilter';

import { ACTION_ADD_GEO_FILTERS } from '../actionTypes';

import { GEO_FILTERS_GRID_NAME } from '../constants/geoFilter';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddGeoFilters = geoFilters => ({
  type: ACTION_ADD_GEO_FILTERS,
  payload: { geoFilters },
});

export const actionLoadGeoFiltersRequest = getActionLoadGridDataRequest(
  GEO_FILTERS_GRID_NAME, getGridFilter, 'geo_filter', actionAddGeoFilters
);
