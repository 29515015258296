import {
  ACTION_CONFIRM_EMAIL__REQUEST,
  ACTION_CONFIRM_EMAIL__SUCCESS,
  ACTION_CONFIRM_EMAIL__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionConfirmEmailSuccess = () => ({
  type: ACTION_CONFIRM_EMAIL__SUCCESS,
});

const actionConfirmEmailFail = error => ({
  type: ACTION_CONFIRM_EMAIL__FAIL,
  payload: { error },
});

export const actionConfirmEmailRequest = (userId, token) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_CONFIRM_EMAIL__REQUEST,
    });

    return apiClientHelper.post(
      'email/confirm',
      { data: { userId, token } }
    ).then(
      () => {
        dispatch(actionConfirmEmailSuccess());
      },
      error => {
        dispatch(actionConfirmEmailFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
