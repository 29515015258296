import routes from './modules/app/routes';
import rootReducer from './modules/app/reducer';
import sagas from './modules/app/sagas';

import App from './modules/app/App';

import getApiCriticalErrorHandlers from './modules/app/getApiCriticalErrorHandlers';


(new App(rootReducer, routes, sagas, getApiCriticalErrorHandlers)).start();
