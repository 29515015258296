export const ACTION_RESTORE_PASSWORD__REQUEST = 'ACTION_RESTORE_PASSWORD__REQUEST';
export const ACTION_RESTORE_PASSWORD__SUCCESS = 'ACTION_RESTORE_PASSWORD__SUCCESS';
export const ACTION_RESTORE_PASSWORD__FAIL = 'ACTION_RESTORE_PASSWORD__FAIL';

export const ACTION_VALIDATE_TOKEN__REQUEST = 'ACTION_VALIDATE_TOKEN__REQUEST';
export const ACTION_VALIDATE_TOKEN__SUCCESS = 'ACTION_VALIDATE_TOKEN__SUCCESS';
export const ACTION_VALIDATE_TOKEN__FAIL = 'ACTION_VALIDATE_TOKEN__FAIL';

export const ACTION_RESET_PASSWORD__REQUEST = 'ACTION_RESET_PASSWORD__REQUEST';
export const ACTION_RESET_PASSWORD__SUCCESS = 'ACTION_RESET_PASSWORD__SUCCESS';
export const ACTION_RESET_PASSWORD__FAIL = 'ACTION_RESET_PASSWORD__FAIL';

export const ACTION_CHANGE_PASSWORD__REQUEST = 'ACTION_CHANGE_PASSWORD__REQUEST';
export const ACTION_CHANGE_PASSWORD__SUCCESS = 'ACTION_CHANGE_PASSWORD__SUCCESS';
export const ACTION_CHANGE_PASSWORD__FAIL = 'ACTION_CHANGE_PASSWORD__FAIL';
