import { combineReducers } from 'redux';

import privatePhoto from './privatePhoto';
import video from './video';
import erostory from './erostory';
import underwear from './underwear';

import {
  ACTION_ADD_PERSONAL_CONTACTS,
} from '../actionTypes/personalContact';


const personalContactsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PERSONAL_CONTACTS:
      return { ...state, ...action.payload.personalContacts };

    default:
      return state;
  }
};

export default combineReducers({
  privatePhoto,
  video,
  erostory,
  underwear,
  personalContactsById,
});
