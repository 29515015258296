import {
  ACTION_REQUEST_PAYOUT__SUCCESS,
  ACTION_CANCEL_PAYOUT_REQUEST__REQUEST,
  ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
  ACTION_CANCEL_PAYOUT_REQUEST__FAIL,
  ACTION_SEND_PAYOUT_COMPLAINT__REQUEST,
  ACTION_SEND_PAYOUT_COMPLAINT__SUCCESS,
  ACTION_SEND_PAYOUT_COMPLAINT__FAIL,
} from '../../actionTypes';

import { PAYOUTS_GRID_NAME } from '../../constants/payout';

import { actionLoadPayoutsRequest } from '../../actions/payoutsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  PAYOUTS_GRID_NAME,
  actionLoadPayoutsRequest,
  [ACTION_CANCEL_PAYOUT_REQUEST__REQUEST, ACTION_SEND_PAYOUT_COMPLAINT__REQUEST],
  [ACTION_CANCEL_PAYOUT_REQUEST__FAIL, ACTION_SEND_PAYOUT_COMPLAINT__FAIL],
  [
    ACTION_REQUEST_PAYOUT__SUCCESS,
    ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
    ACTION_SEND_PAYOUT_COMPLAINT__SUCCESS,
  ]
);
