import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';

import styles from './styles/index.css.json';


const propTypes = {
  isHotFlirt: PropTypes.bool,
  className: PropTypes.string,
};

const HotFlirt = ({ isHotFlirt = false, className = null }) => {
  const { formatMessage: fm } = useIntl();

  return isHotFlirt && (
    <span
      title={fm({ id: 'msg.hot-flirt' })}
      className={classnames({ [styles.hotFlirt]: isHotFlirt }, className)}
    />
  );
};

HotFlirt.propTypes = propTypes;

export default HotFlirt;
