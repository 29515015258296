import { MODAL_DIALOG__SOCIAL_LOGIN_ERROR } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowSocialLoginErrorDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__SOCIAL_LOGIN_ERROR))
);

export const actionHideSocialLoginErrorDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__SOCIAL_LOGIN_ERROR))
);
