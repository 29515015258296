import { schema } from 'normalizr';

import { ITEM_SCHEMA } from '../../core/schemas/items';


const PUBLIC_PHOTO_SCHEMA = new schema.Entity(
  'publicPhotos'
);

const COMMENT_ITEM_SCHEMA = new schema.Entity(
  'commentItems'
);

const NEWS_SCHEMA = new schema.Entity(
  'news'
);

export const NEWS_FEED_SCHEMA = new schema.Entity(
  'newsFeeds',
  {
    content: {
      presentation: ITEM_SCHEMA,
      profilePhoto: ITEM_SCHEMA,
      privatePhotos: [[ITEM_SCHEMA]],
      publicPhotos: [[PUBLIC_PHOTO_SCHEMA]],
      story: ITEM_SCHEMA,
      videos: [[ITEM_SCHEMA]],
      declinedPresentation: ITEM_SCHEMA,
      declinedPrivatePhotos: [[ITEM_SCHEMA]],
      declinedPublicPhotos: [[ITEM_SCHEMA]],
      declinedVideos: [[ITEM_SCHEMA]],
      comment: {
        item: COMMENT_ITEM_SCHEMA,
      },
    },
  }
);

export const NEWS_FEEDS_SCHEMA = {
  items: [NEWS_FEED_SCHEMA],
  news: [NEWS_SCHEMA],
};
