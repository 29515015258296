export default str => {
  const arr = str.slice(3).split('_');

  switch (arr[0]) {
    case '46':
      return `${arr[0]}+`;

    default:
      return `${arr[0]} - ${arr[1]}`;
  }
};
