import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const erostorySelector = state => _get(state, 'ownSaleItem.erostory');
const erostoriesSelector = state => _get(erostorySelector(state), 'erostories');
export const getErostoryIds = state => _get(erostoriesSelector(state), 'items');
const getErostoriesTotalCount = state => _get(erostoriesSelector(state), 'count');
const getErostoriesLimit = state => _get(erostoriesSelector(state), 'limit');
export const getIsLastErostoryLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getErostoryIds, getErostoriesTotalCount, getErostoriesLimit
);
export const getErostoryTotalPagesCount = state => getTotalPagesCount(
  state, getErostoriesTotalCount, getErostoriesLimit
);

export const getErostoryPaginationMode = state => _get(erostorySelector(state), 'paginationMode');
export const getErostoryActivePage = state => _get(erostorySelector(state), 'activePage');
export const getShouldLoadErostoryFirstPage = state => (
  _get(erostorySelector(state), 'shouldLoadFirstPage')
);
export const getShouldReloadErostoryActivePage = state => (
  _get(erostorySelector(state), 'shouldReloadActivePage')
);

export const getIsErostoryBeingDeleted = (state, erostoryId) => (
  !!_get(erostorySelector(state), `isErostoryBeingDeleted.${erostoryId}`)
);

const errorsSelector = state => _get(erostorySelector(state), 'errors');
export const getLoadErostoriesError = state => (
  _get(errorsSelector(state), 'loadErostoriesError')
);
export const getCreateErostoryError = state => (
  _get(errorsSelector(state), 'createErostoryError')
);
export const getDeleteErostoryError = state => (
  _get(errorsSelector(state), 'deleteErostoryError')
);
export const getUpdateErostoryError = state => (
  _get(errorsSelector(state), 'updateErostoryError')
);
