import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const PaymentLogos = ({ className }) => (
  <>
    <div className={classnames(styles.logos, className)}>
      <div className={styles.crypto} />
    </div>

    <div className={classnames(styles.logos, className)}>
      <div className={classnames(styles.logo, styles.yowpay)} />
      <div className={classnames(styles.logo, styles.paypal)} />
      <div className={classnames(styles.logo, styles.sepa)} />
    </div>
  </>
);

PaymentLogos.propTypes = propTypes;

export default PaymentLogos;
