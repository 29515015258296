import {
  ACTION_LOGOUT__REQUEST,
  ACTION_LOGOUT__SUCCESS,
  ACTION_LOGOUT__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOGOUT__REQUEST:
    case ACTION_LOGOUT__SUCCESS:
      return null;

    case ACTION_LOGOUT__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
