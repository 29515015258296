import {
  ACTION_ADD_RECORDED_PRIVATES,
  ACTION_UPDATE_RECORDED_PRIVATE,
} from '../actionTypes/recordedPrivate';


export const actionAddRecordedPrivates = recordedPrivates => ({
  type: ACTION_ADD_RECORDED_PRIVATES,
  payload: { recordedPrivates },
});

export const actionUpdateRecordedPrivate = recordedPrivate => ({
  type: ACTION_UPDATE_RECORDED_PRIVATE,
  payload: { recordedPrivate },
});
