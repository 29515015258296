import {
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL,
  ACTION_CANCEL_UNDERWEAR_REQUEST,
  ACTION_CANCEL_UNDERWEAR__SUCCESS,
  ACTION_CANCEL_UNDERWEAR__FAIL,
} from '../../actionTypes';

import { UNDERWEAR_REQUESTS_GRID_NAME } from '../../constants';

import { actionLoadUnderwearRequestsRequest } from '../../actions/underwearRequestsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  UNDERWEAR_REQUESTS_GRID_NAME,
  actionLoadUnderwearRequestsRequest,
  [ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST, ACTION_CANCEL_UNDERWEAR_REQUEST],
  [ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL, ACTION_CANCEL_UNDERWEAR__FAIL],
  [ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS, ACTION_CANCEL_UNDERWEAR__SUCCESS]
);
