import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'password.errors');
export const getRestorePasswordError = state => (
  _get(errorsSelector(state), 'restorePasswordError')
);
export const getValidateTokenError = state => (
  _get(errorsSelector(state), 'validateTokenError')
);
export const getResetPasswordError = state => (
  _get(errorsSelector(state), 'resetPasswordError')
);
export const getChangePasswordError = state => (
  _get(errorsSelector(state), 'changePasswordError')
);
