import { ACTION_SET_WARNING } from '../actionTypes/adultWarning';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_WARNING:
      return action.payload.warning;

    default:
      return state;
  }
};
