import _get from 'lodash/get';


const getAttachmentUploading = state => _get(state, 'sequentialMediaUploader');
export const getIsUploadSessionActive = state => (
  _get(getAttachmentUploading(state), 'isUploadSessionActive')
);
export const getIsGotUploadInterruptionSignal = state => (
  _get(getAttachmentUploading(state), 'isGotUploadInterruptionSignal')
);
export const getCurrentFilePercent = state => (
  _get(getAttachmentUploading(state), 'currentFilePercent')
);
export const getNumberProgress = state => (
  _get(getAttachmentUploading(state), 'numberProgress')
);
export const getNumberTotal = state => (
  _get(getAttachmentUploading(state), 'numberTotal')
);
export const getIsProcessing = state => (
  _get(getAttachmentUploading(state), 'isProcessing')
);
export const getSizeProgress = state => (
  _get(getAttachmentUploading(state), 'sizeProgress')
);
export const getSizeTotal = state => (
  _get(getAttachmentUploading(state), 'sizeTotal')
);
export const geIsUploading = state => (
  _get(getAttachmentUploading(state), 'isUploading')
);
export const getUploadingPhotoThumb = state => (
  _get(getAttachmentUploading(state), 'uploadingPhotoThumb')
);
export const getUploadingErrors = state => (
  _get(getAttachmentUploading(state), 'errors')
);
