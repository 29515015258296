export const ACTION_ADD_OWN_UNDERWEAR = 'ACTION_ADD_OWN_UNDERWEAR';
export const ACTION_SET_OWN_UNDERWEAR = 'ACTION_SET_OWN_UNDERWEAR';

export const ACTION_SET_OWN_UNDERWEAR_PRESERVE = 'ACTION_SET_OWN_UNDERWEAR_PRESERVE';
export const ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE = 'ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE';

export const ACTION_LOAD_OWN_UNDERWEAR__REQUEST = 'ACTION_LOAD_OWN_UNDERWEAR__REQUEST';
export const ACTION_LOAD_OWN_UNDERWEAR__SUCCESS = 'ACTION_LOAD_OWN_UNDERWEAR__SUCCESS';
export const ACTION_LOAD_OWN_UNDERWEAR__FAIL = 'ACTION_LOAD_OWN_UNDERWEAR__FAIL';

export const ACTION_CREATE_UNDERWEAR__REQUEST = 'ACTION_CREATE_UNDERWEAR__REQUEST';
export const ACTION_CREATE_UNDERWEAR__SUCCESS = 'ACTION_CREATE_UNDERWEAR__SUCCESS';
export const ACTION_CREATE_UNDERWEAR__FAIL = 'ACTION_CREATE_UNDERWEAR__FAIL';

export const ACTION_DELETE_UNDERWEAR__REQUEST = 'ACTION_DELETE_UNDERWEAR__REQUEST';
export const ACTION_DELETE_UNDERWEAR__SUCCESS = 'ACTION_DELETE_UNDERWEAR__SUCCESS';
export const ACTION_DELETE_UNDERWEAR__FAIL = 'ACTION_DELETE_UNDERWEAR__FAIL';

export const ACTION_UPDATE_UNDERWEAR__REQUEST = 'ACTION_UPDATE_UNDERWEAR__REQUEST';
export const ACTION_UPDATE_UNDERWEAR__SUCCESS = 'ACTION_UPDATE_UNDERWEAR__SUCCESS';
export const ACTION_UPDATE_UNDERWEAR__FAIL = 'ACTION_UPDATE_UNDERWEAR__FAIL';
