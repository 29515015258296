import { combineReducers } from 'redux';

import loadInterviewsError from './loadInterviewsError';
import loadInterviewError from './loadInterviewError';
import loadInterviewFormError from './loadInterviewFormError';
import cancelInterviewFormError from './cancelInterviewFormError';
import createInterviewFormError from './createInterviewFormError';
import loadCreateInterviewFormSettingsError from './loadCreateInterviewFormSettingsError';
import loadInterviewerError from './loadInterviewerError';
import answerInterviewError from './answerInterviewError';


export default combineReducers({
  loadInterviewsError,
  loadInterviewError,
  loadInterviewFormError,
  cancelInterviewFormError,
  createInterviewFormError,
  loadCreateInterviewFormSettingsError,
  loadInterviewerError,
  answerInterviewError,
});
