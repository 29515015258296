import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../core/api/hooks';
import useIsAuthenticated from './useIsAuthenticated';

import { cookie } from 'react-redux-app/lib/modules/core/utils';

import { MODAL_DIALOG__ADULT_WARNING } from '../../app/constants/dialogs';
import {
  ADVENT_GUEST_COOKIE,
  COOKIE_ADVENT_GUEST_EXPIRES,
  ADVENT_PRIZE_RECEIVED_COOKIE,
} from '../constants';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';
import { getAdventPrizes } from '../../initSettings/selectors';
import { getUserAdventPrizes } from '../selectors';
import { getAcceptPrizeError } from '../selectors/errors';

import { actionAcceptPrizeRequest } from '../actions/acceptPrize';
import { actionShowAdventPrizesDialog } from '../actions/dialogs/adventPrizesDialog';


export default () => {
  const dispatch = useDispatch();

  const isDialogVisible = useSelector(
    state => isModalVisibleByName(state, MODAL_DIALOG__ADULT_WARNING)
  );
  const prizes = useShortSelector(getAdventPrizes);
  const userPrizes = useShortSelector(getUserAdventPrizes);
  const isAuthenticated = useIsAuthenticated();

  const savedUserPrizes = useRef();

  useEffect(() => {
    if (
      prizes
      && !isAuthenticated
      && !isDialogVisible
      && !cookie.getCookie(ADVENT_GUEST_COOKIE)
      && !cookie.getCookie(ADVENT_PRIZE_RECEIVED_COOKIE)
    ) {
      cookie.setCookie(
        ADVENT_GUEST_COOKIE,
        1,
        {
          expires: COOKIE_ADVENT_GUEST_EXPIRES,
          path: '/',
        }
      );

      dispatch(actionShowAdventPrizesDialog(prizes));
    }
  }, [isDialogVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const callActionAcceptPrizeRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getAcceptPrizeError)
  );

  useEffect(() => {
    if (userPrizes && userPrizes[0].id !== savedUserPrizes.current?.[0].id) {
      savedUserPrizes.current = userPrizes;

      callActionAcceptPrizeRequest(
        dispatch(actionAcceptPrizeRequest(userPrizes[0].id)),
        'header.prize.accept',
        () => {
          if (userPrizes[1]) {
            callActionAcceptPrizeRequest(
              dispatch(actionAcceptPrizeRequest(userPrizes[1].id)),
              'header.prize.accept',
              () => { dispatch(actionShowAdventPrizesDialog(userPrizes)); }
            );
          } else {
            dispatch(actionShowAdventPrizesDialog(userPrizes));
          }
        }
      );
    }
  }, [userPrizes]); // eslint-disable-line react-hooks/exhaustive-deps
};
