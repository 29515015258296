import { getGridFilter } from '../utils/model';

import { ACTION_ADD_AGENCY_MODELS } from '../actionTypes';

import { MODELS_GRID_NAME } from '../constants/model';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddAgencyModels = models => ({
  type: ACTION_ADD_AGENCY_MODELS,
  payload: { models },
});

export const actionLoadAgencyModelsRequest = getActionLoadGridDataRequest(
  MODELS_GRID_NAME, getGridFilter, 'agency/model/list', actionAddAgencyModels
);
