import { console, dom } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getIndexUrl } from '../../../app/utils/url';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getValidateTokenError } from '../../selectors/errors';

import { actionValidateTokenRequest } from '../../actions/validateToken';


export default ({ getState, dispatch }) => (
  function restorePasswordHandler(nextState, successCallback, errorCallback) {
    if (dom.isBrowser()) {
      errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
      return;
    }

    dispatch(
      actionValidateTokenRequest(nextState.params.token)
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getValidateTokenError(getState());
        if (maybeHandleCriticalError(nextState.location, error?.globalError, errorCallback)) {
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('restorePasswordHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
