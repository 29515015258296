import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { getOwnSaleItems } from './index';


const profilePhotoSelector = state => _get(state, 'ownSaleItem.profilePhoto');

export const getProfilePhotoIds = state => _get(profilePhotoSelector(state), 'profilePhotos');
export const getApprovedProfilePhotoIds = createSelector(
  getOwnSaleItems,
  getProfilePhotoIds,
  (ownSaleItems, profilePhotoIds) => profilePhotoIds.filter(id => ownSaleItems[id].isApproved)
);

export const getIsProfilePhotoBeingDeleted = (state, profilePhotoId) => (
  !!_get(profilePhotoSelector(state), `isProfilePhotoBeingDeleted.${profilePhotoId}`)
);

const errorsSelector = state => _get(profilePhotoSelector(state), 'errors');
export const getLoadProfilePhotosError = state => (
  _get(errorsSelector(state), 'loadProfilePhotosError')
);
export const getCreateProfilePhotoError = state => (
  _get(errorsSelector(state), 'createProfilePhotoError')
);
export const getDeleteProfilePhotoError = state => (
  _get(errorsSelector(state), 'deleteProfilePhotoError')
);
export const getUpdateProfilePhotoError = state => (
  _get(errorsSelector(state), 'updateProfilePhotoError')
);
export const getActivateProfilePhotoError = state => (
  _get(errorsSelector(state), 'activateProfilePhotoError')
);
