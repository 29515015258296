import {
  ACTION_LOAD_TELEGRAM_SETTINGS__REQUEST,
  ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS,
  ACTION_LOAD_TELEGRAM_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadTelegramSettingsSuccess = telegramSettings => ({
  type: ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS,
  payload: { telegramSettings },
});

const actionLoadTelegramSettingsFail = error => ({
  type: ACTION_LOAD_TELEGRAM_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadTelegramSettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_TELEGRAM_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'telegram/settings'
    ).then(
      telegramSettings => {
        dispatch(actionLoadTelegramSettingsSuccess(telegramSettings));
      },
      error => {
        dispatch(actionLoadTelegramSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
