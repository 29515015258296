import {
  ACTION_LOAD_DIALOGS__REQUEST,
  ACTION_LOAD_DIALOGS__SUCCESS,
  ACTION_LOAD_DIALOGS__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_DIALOGS__REQUEST:
    case ACTION_LOAD_DIALOGS__SUCCESS:
      return null;

    case ACTION_LOAD_DIALOGS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
