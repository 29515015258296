import { schema } from 'normalizr';


const MEDIA_SCHEMA = new schema.Entity(
  'media'
);

export const POST_SCHEMA = new schema.Entity(
  'posts',
  { items: [MEDIA_SCHEMA] }
);

export const POSTS_SCHEMA = {
  items: [POST_SCHEMA],
};

export const BLOG_SCHEMA = new schema.Entity(
  'blogs',
  { posts: { items: [POST_SCHEMA] } }
);
