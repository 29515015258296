import {
  ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddInitSettings } from './addInitSettings';
import {
  actionLoadPartialInitSettingsSuccess,
  actionLoadPartialInitSettingsFail,
} from './loadPartialInitSettings';


export const actionLoadDefaultLangAndUiLangsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'init/partial',
      { params: { fields: 'uiLangs,defaultLang' } }
    ).then(
      settings => {
        dispatch(actionAddInitSettings(settings));

        dispatch(actionLoadPartialInitSettingsSuccess());
      },
      error => {
        dispatch(actionLoadPartialInitSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
