import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';
import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';
import { depositStatuses } from './index';


export const DEPOSITS_GRID_NAME = 'DepositsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'payDate',
  sortDirection: SORT_DIRECTION__DESC,
  statuses: depositStatuses,
};
