import { takeLeading, select, put } from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import {
  createPushManagerSubscription,
  createServiceWorkerRegistration,
  getNotificationPermissionState,
  getPushManagerSubscription,
  getIsWebPushAvailable,
} from '../utils';

import { PERMISSION_STATE__PROMPT, PERMISSION_STATE__GRANTED } from '../constants';

import {
  ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS,
  ACTION_LOGIN_BY_CREDENTIALS__SUCCESS,
  ACTION_REGISTER__SUCCESS,
  ACTION_VISIT_PAGE__SUCCESS,
} from '../../../auth/actionTypes';
import { ACTION_ADD_TO_FAVORITES__SUCCESS } from '../../favoriteMark/actionTypes';
import { ACTION_SUGGEST_PUSH_NOTIFICATION_SUBSCRIPTION } from '../actionTypes';

import { getIsRootMounted } from '../../../app/selectors/isRootMounted';
import { getIsAuthenticated } from '../../../auth/selectors';
import { getWebPushPublicKey } from '../../../initSettings/selectors';

import { actionShowPermissionRequestPanel } from '../actions/panel';
import { actionSubscribeToPushNotificationsRequest } from '../actions/subscribe';


function* registerServiceWorkerAndUpdateSubscription(action) {
  if (!getIsWebPushAvailable()) {
    return;
  }

  if (
    action.type === ACTION_VISIT_PAGE__SUCCESS
    && ((yield select(getIsRootMounted)) || !(yield select(getIsAuthenticated)))
  ) {
    return;
  }

  try {
    const registration = yield createServiceWorkerRegistration();

    const notificationPermissionState = yield getNotificationPermissionState();
    if (notificationPermissionState !== PERMISSION_STATE__GRANTED) {
      return;
    }

    let subscriptionInfo = yield getPushManagerSubscription(registration);
    if (!subscriptionInfo) {
      subscriptionInfo = yield createPushManagerSubscription(
        registration,
        yield select(getWebPushPublicKey)
      );
    }

    yield put(actionSubscribeToPushNotificationsRequest(subscriptionInfo));
  } catch (e) {
    // Ignore errors
    console.consoleError('Error in actionSubscribeToPushNotificationsRequest', e);
  }
}

function* subscribeUserToPushNotifications() {
  if (!getIsWebPushAvailable()) {
    return;
  }

  const permissionState = yield getNotificationPermissionState();
  if (permissionState !== PERMISSION_STATE__PROMPT) {
    return;
  }

  yield put(actionShowPermissionRequestPanel());
}

export default function* saga() {
  yield takeLeading([
    ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS,
    ACTION_LOGIN_BY_CREDENTIALS__SUCCESS,
    ACTION_REGISTER__SUCCESS,
    ACTION_VISIT_PAGE__SUCCESS,
  ], registerServiceWorkerAndUpdateSubscription);

  yield takeLeading([
    ACTION_ADD_TO_FAVORITES__SUCCESS,
    ACTION_SUGGEST_PUSH_NOTIFICATION_SUBSCRIPTION,
  ], subscribeUserToPushNotifications);
}
