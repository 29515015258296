import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS,
} from '../../../actionTypes/video';


const categories = (state = [], action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS:
      return action.payload.categories;

    default:
      return state;
  }
};

export default combineReducers({
  categories,
  errors,
});
