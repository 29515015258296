import { combineReducers } from 'redux';

import loadDialogsError from './loadDialogsError';
import deleteDialogError from './deleteDialogError';


export default combineReducers({
  loadDialogsError,
  deleteDialogError,
});
