import { useMemo } from 'react';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';
import useFilter from './useFilter';

import { getQueryParamToOptionsMap } from '../utils';

import {
  getAllowedSpokenLanguageLangKeys,
  getAllowedSexPreferencesLangKeys,
} from '../../account/selectors';
import { getChatGalleryFilters } from '../../initSettings/selectors';


export default () => {
  const filters = useShortSelector(getChatGalleryFilters);
  const spokenLanguageLangKeys = useShortSelector(getAllowedSpokenLanguageLangKeys);
  const allowedSexPreferencesLangKeys = useShortSelector(getAllowedSexPreferencesLangKeys);

  const filter = useFilter();

  const { formatMessage: fm } = useIntl();

  const queryParamToOptionsMap = useMemo(() => (
    getQueryParamToOptionsMap(filters, allowedSexPreferencesLangKeys, spokenLanguageLangKeys, fm)
  ), []); // eslint-disable-line react-hooks/exhaustive-deps

  return { filter, queryParamToOptionsMap };
};
