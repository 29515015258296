import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const privatePhotoSelector = state => _get(state, 'ownSaleItem.privatePhoto');
const privatePhotosSelector = state => _get(privatePhotoSelector(state), 'privatePhotos');
export const getPrivatePhotoIds = state => _get(privatePhotosSelector(state), 'items');
const getPrivatePhotosTotalCount = state => _get(privatePhotosSelector(state), 'count');
const getPrivatePhotosLimit = state => _get(privatePhotosSelector(state), 'limit');
export const getIsLastPrivatePhotoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getPrivatePhotoIds, getPrivatePhotosTotalCount, getPrivatePhotosLimit
);
export const getPrivatePhotoTotalPagesCount = state => getTotalPagesCount(
  state, getPrivatePhotosTotalCount, getPrivatePhotosLimit
);

export const getPrivatePhotoPaginationMode = state => (
  _get(privatePhotoSelector(state), 'paginationMode')
);
export const getPrivatePhotoActivePage = state => _get(privatePhotoSelector(state), 'activePage');
export const getShouldLoadPrivatePhotoFirstPage = state => (
  _get(privatePhotoSelector(state), 'shouldLoadFirstPage')
);
export const getShouldReloadPrivatePhotoActivePage = state => (
  _get(privatePhotoSelector(state), 'shouldReloadActivePage')
);

export const getIsPrivatePhotoBeingDeleted = (state, privatePhotoId) => (
  !!_get(privatePhotoSelector(state), `isPrivatePhotoBeingDeleted.${privatePhotoId}`)
);

const errorsSelector = state => _get(privatePhotoSelector(state), 'errors');
export const getLoadPrivatePhotosError = state => (
  _get(errorsSelector(state), 'loadPrivatePhotosError')
);
export const getCreatePrivatePhotoError = state => (
  _get(errorsSelector(state), 'createPrivatePhotoError')
);
export const getDeletePrivatePhotoError = state => (
  _get(errorsSelector(state), 'deletePrivatePhotoError')
);
export const getUpdatePrivatePhotoError = state => (
  _get(errorsSelector(state), 'updatePrivatePhotoError')
);
