import { combineReducers } from 'redux';

import buyEmoticonError from './buyEmoticonError';
import loadEmoticonsError from './loadEmoticonsError';


export default combineReducers({
  buyEmoticonError,
  loadEmoticonsError,
});
