import { getIndexUrl } from '../../app/utils/url';

import {
  ACTION_RETURN_TO_AGENCY__REQUEST,
  ACTION_RETURN_TO_AGENCY__SUCCESS,
  ACTION_RETURN_TO_AGENCY__FAIL,
} from '../actionTypes';

import { getCurrentLangForUrl } from '../../core/i18n/selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionSetAuthData } from './auth';


const actionReturnToAgencySuccess = () => ({
  type: ACTION_RETURN_TO_AGENCY__SUCCESS,
});

const actionReturnToAgencyFail = error => ({
  type: ACTION_RETURN_TO_AGENCY__FAIL,
  payload: { error },
});

export const actionReturnToAgencyRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { push } }) => {
    dispatch({
      type: ACTION_RETURN_TO_AGENCY__REQUEST,
    });

    return apiClientHelper.del(
      'login/as'
    ).then(
      auth => {
        dispatch(actionSetAuthData(auth));

        dispatch(actionReturnToAgencySuccess());

        setTimeout(() => { push(getIndexUrl(getCurrentLangForUrl(getState()))); });
      },
      error => {
        dispatch(actionReturnToAgencyFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
