import {
  ACTION_ADD_BLOCKED_USER__REQUEST,
  ACTION_ADD_BLOCKED_USER__SUCCESS,
  ACTION_ADD_BLOCKED_USER__FAIL,
  ACTION_DELETE_BLOCKED_USER__REQUEST,
  ACTION_DELETE_BLOCKED_USER__SUCCESS,
  ACTION_DELETE_BLOCKED_USER__FAIL,
} from '../../actionTypes';

import { BLACKLIST_GRID_NAME } from '../../constants';

import { actionLoadBlackListRequest } from '../../actions/blackListGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  BLACKLIST_GRID_NAME,
  actionLoadBlackListRequest,
  [ACTION_ADD_BLOCKED_USER__REQUEST, ACTION_DELETE_BLOCKED_USER__REQUEST],
  [ACTION_ADD_BLOCKED_USER__FAIL, ACTION_DELETE_BLOCKED_USER__FAIL],
  [ACTION_ADD_BLOCKED_USER__SUCCESS, ACTION_DELETE_BLOCKED_USER__SUCCESS]
);
