import _omit from 'lodash/omit';

import { getGridFilter } from '../utils/payout';

import { ACTION_ADD_PAYOUTS } from '../actionTypes';

import { PAYOUTS_GRID_NAME } from '../constants/payout';

import { PAYOUTS_SCHEMA } from '../schemas';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddPayouts = payouts => ({
  type: ACTION_ADD_PAYOUTS,
  payload: { payouts },
});

export const actionLoadPayoutsRequest = getActionLoadGridDataRequest(
  PAYOUTS_GRID_NAME,
  getGridFilter,
  'payout/list',
  actionAddPayouts,
  filter => ({
    ..._omit(filter, ['statuses', 'methods']),
    'statuses[]': filter.statuses,
    'methods[]': filter.methods,
  }),
  PAYOUTS_SCHEMA
);
