import {
  ACTION_ADD_OWN_MEDIA,
  ACTION_SET_ACCESS_TYPE,
  ACTION_ADD_SENT_MEDIA,
  ACTION_SET_SENT_MEDIA,
  ACTION_REMOVE_MEDIA,
  ACTION_SET_MEDIA_SHOW_THUMB,
} from '../actionTypes';

import {
  POST_SENT_MEDIA_TYPE__UPLOADED_PHOTO,
  POST_SENT_MEDIA_TYPE__UPLOADED_VIDEO,
} from '../constants';

import { getUploadingPhotoThumb } from '../../core/sequentialMediaUploader/selectors';
import { getAccessType } from '../selectors';


export const actionAddOwnMedia = media => ({
  type: ACTION_ADD_OWN_MEDIA,
  payload: { media },
});

export const actionSetAccessType = accessType => ({
  type: ACTION_SET_ACCESS_TYPE,
  payload: { accessType },
});

export const actionAddSentMedia = (id, type = null, thumbUrl = null, duration = null) => (
  (dispatch, getState) => {
    const accessType = getAccessType(getState());
    const thumb = getUploadingPhotoThumb(getState());

    dispatch({
      type: ACTION_ADD_SENT_MEDIA,
      payload: {
        accessType,
        media: {
          id,
          type: type || (thumb
            ? POST_SENT_MEDIA_TYPE__UPLOADED_PHOTO
            : POST_SENT_MEDIA_TYPE__UPLOADED_VIDEO),
          thumbUrl: thumbUrl || thumb,
          duration,
        },
      },
    });
  }
);

export const actionSetSentMedia = sentMedia => ({
  type: ACTION_SET_SENT_MEDIA,
  payload: { sentMedia },
});

export const actionRemoveMedia = (mediaId, type) => (
  (dispatch, getState) => {
    const accessType = getAccessType(getState());

    return dispatch({
      type: ACTION_REMOVE_MEDIA,
      payload: { accessType, mediaId, type },
    });
  }
);

export const actionSetMediaShowThumb = (mediaId, type, showThumb) => (
  (dispatch, getState) => {
    const accessType = getAccessType(getState());

    return dispatch({
      type: ACTION_SET_MEDIA_SHOW_THUMB,
      payload: {
        accessType,
        mediaId,
        type,
        showThumb,
      },
    });
  }
);
