import React from 'react';
import PropTypes from 'prop-types';

import useShowSendGiftDialog from '../../hooks/useShowSendGiftDialog';

import { l } from 'react-redux-app/lib/modules/i18n/utils';


export default (
  Component,
  componentProps
) => {
  const propTypes = {
    id: PropTypes.number.isRequired,
    nick: PropTypes.string.isRequired,
  };

  const SendGift = ({ id, nick }) => {
    const showSendGiftDialog = useShowSendGiftDialog();

    return (
      <Component {...componentProps} onClick={() => { showSendGiftDialog(id, nick); }}>
        {l('action.send-gift')}
      </Component>
    );
  };

  SendGift.propTypes = propTypes;

  return SendGift;
};
