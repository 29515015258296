import { MODAL_DIALOG__BUY_POST } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowBuyPostDialog = (id, price) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__BUY_POST, { id, price }))
);

export const actionHideBuyPostDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__BUY_POST))
);
