import { combineReducers } from 'redux';

import loadCoverPhotosError from './loadCoverPhotosError';
import activateCoverPhotoError from './activateCoverPhotoError';
import createCoverPhotoError from './createCoverPhotoError';
import deleteCoverPhotoError from './deleteCoverPhotoError';


export default combineReducers({
  loadCoverPhotosError,
  activateCoverPhotoError,
  createCoverPhotoError,
  deleteCoverPhotoError,
});
