import {
  ACTION_START_UPLOAD_SESSION,
  ACTION_INTERRUPT_UPLOAD_SESSION,
  ACTION_END_UPLOAD_SESSION,
  ACTION_UPLOAD_MEDIA__REQUEST,
  ACTION_CHANGE_UPLOADING_MEDIA_THUMB,
  ACTION_CHANGE_MEDIA_UPLOAD_PROGRESS,
  ACTION_START_MEDIA_PROCESSING,
  ACTION_UPLOAD_MEDIA__SUCCESS,
  ACTION_UPLOAD_MEDIA__FAIL,
  ACTION_CLEAR_UPLOADING_ERRORS,
} from '../actionTypes';


const defaultUploadState = {
  isUploadSessionActive: false,
  isGotUploadInterruptionSignal: false,
  isUploading: false,
  isProcessing: false,
  uploadingPhotoThumb: null,
  currentFilePercent: 0,
  numberProgress: 0,
  numberTotal: 0,
  sizeProgress: 0,
  sizeTotal: 0,
  errors: [],
};

export default (state = defaultUploadState, action) => {
  switch (action.type) {
    case ACTION_START_UPLOAD_SESSION:
      return {
        ...state,
        isUploadSessionActive: true,
      };

    case ACTION_INTERRUPT_UPLOAD_SESSION:
      return {
        ...state,
        isGotUploadInterruptionSignal: true,
      };

    case ACTION_END_UPLOAD_SESSION:
      return {
        ...defaultUploadState,
        errors: state.errors,
      };

    case ACTION_UPLOAD_MEDIA__REQUEST:
      return {
        ...state,
        isUploading: true,
      };

    case ACTION_CHANGE_UPLOADING_MEDIA_THUMB:
      return {
        ...state,
        uploadingPhotoThumb: action.payload.thumb,
      };

    case ACTION_CHANGE_MEDIA_UPLOAD_PROGRESS: {
      const {
        currentFilePercent,
        numberProgress,
        numberTotal,
        sizeProgress,
        sizeTotal,
      } = action.payload;

      return {
        ...state,
        currentFilePercent,
        numberProgress,
        numberTotal,
        sizeProgress,
        sizeTotal,
      };
    }

    case ACTION_START_MEDIA_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };

    case ACTION_UPLOAD_MEDIA__SUCCESS:
      return {
        ...state,
        isUploading: false,
        isProcessing: false,
        uploadingPhotoThumb: null,
        currentFilePercent: 0,
      };

    case ACTION_UPLOAD_MEDIA__FAIL:
      return {
        ...state,
        isUploading: false,
        isProcessing: false,
        uploadingPhotoThumb: null,
        currentFilePercent: 0,
        errors: [...state.errors, action.payload.error],
      };

    case ACTION_CLEAR_UPLOADING_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};
