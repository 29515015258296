import {
  ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__REQUEST,
  ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS,
  ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadFlowerDeliverySettingsSuccess = flowerDeliverySettings => ({
  type: ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS,
  payload: { flowerDeliverySettings },
});

const actionLoadFlowerDeliverySettingsFail = error => ({
  type: ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadFlowerDeliverySettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'flower_delivery/info'
    ).then(
      flowerDeliverySettings => {
        dispatch(actionLoadFlowerDeliverySettingsSuccess(flowerDeliverySettings));
      },
      error => {
        dispatch(actionLoadFlowerDeliverySettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
