import { SORT_DIRECTION__ASC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const GEO_FILTERS_GRID_NAME = 'GeoFiltersGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'country',
  sortDirection: SORT_DIRECTION__ASC,
};
