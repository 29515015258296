import { getNotFoundUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsCommon } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function commonRequiredHandler(nextState, successCallback, errorCallback) {
    if (!getIsCommon(getState())) {
      errorCallback(getNotFoundUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
