import { useDispatch } from 'react-redux';

import { useCallCancellablePromise } from 'react-redux-app/lib/modules/core/hooks';
import { useMaybeHandleCriticalError } from 'react-redux-app/lib/modules/api/hooks';
import { useUri } from 'react-redux-app/lib/modules/routing/hooks';

import convertApiErrorsToFinalFormErrors from '../utils/convertApiErrorsToFinalFormErrors';

import { FORM_SUBMIT_ERROR } from 'react-redux-app/lib/modules/form/constants';

import { actionAddGlobalError } from 'react-redux-app/lib/modules/globalError/actions';


export default errorSelector => {
  const dispatch = useDispatch();

  const currentUri = useUri();

  const callCancellablePromise = useCallCancellablePromise(errorSelector);
  const maybeHandleCriticalError = useMaybeHandleCriticalError();

  return (promise, successCallback = null, errorCallback = null, callback = null) => (
    new Promise(resolve => {
      callCancellablePromise(
        promise,
        error => {
          if (callback) {
            callback();
          }

          if (!error) {
            if (successCallback) {
              successCallback();
            }

            resolve();
            return;
          }

          const { globalError } = error;

          if (maybeHandleCriticalError(globalError)) {
            resolve(FORM_SUBMIT_ERROR);
            return;
          }

          if (errorCallback) {
            const resultError = errorCallback(error);

            if (resultError) {
              const { errorDialogHeaderLangKey, reloadPageOnGlobalError = true } = resultError;

              if (!errorDialogHeaderLangKey) {
                resolve(resultError);
                return;
              }

              if (globalError) {
                dispatch(actionAddGlobalError({
                  text: globalError.message,
                  header: { id: errorDialogHeaderLangKey },
                  redirectUrl: reloadPageOnGlobalError ? currentUri : null,
                }));

                resolve();
                return;
              }
            }
          }

          resolve(convertApiErrorsToFinalFormErrors(error));
        },
        () => { resolve(); }
      );
    })
  );
};
