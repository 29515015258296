export const ACTION_ADD_VIDEOS = 'ACTION_ADD_VIDEOS';
export const ACTION_SET_VIDEOS = 'ACTION_SET_VIDEOS';

export const ACTION_LOAD_VIDEOS__REQUEST = 'ACTION_LOAD_VIDEOS__REQUEST';
export const ACTION_LOAD_VIDEOS__SUCCESS = 'ACTION_LOAD_VIDEOS__SUCCESS';
export const ACTION_LOAD_VIDEOS__FAIL = 'ACTION_LOAD_VIDEOS__FAIL';

export const ACTION_SET_VIDEOS_PRESERVE = 'ACTION_SET_VIDEOS_PRESERVE';

export const ACTION_LOAD_VIDEO_CATEGORIES__REQUEST = 'ACTION_LOAD_VIDEO_CATEGORIES__REQUEST';
export const ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS = 'ACTION_LOAD_VIDEO_CATEGORIES__SUCCESS';
export const ACTION_LOAD_VIDEO_CATEGORIES__FAIL = 'ACTION_LOAD_VIDEO_CATEGORIES__FAIL';

export const ACTION_LOAD_VIDEO_PREVIEW__REQUEST = 'ACTION_LOAD_VIDEO_PREVIEW__REQUEST';
export const ACTION_LOAD_VIDEO_PREVIEW__SUCCESS = 'ACTION_LOAD_VIDEO_PREVIEW__SUCCESS';
export const ACTION_LOAD_VIDEO_PREVIEW__FAIL = 'ACTION_LOAD_VIDEO_PREVIEW__FAIL';
