import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_SET_AUTH_DATA,
  ACTION_SET_LOGIN_INFO,
  ACTION_UPDATE_LOGGED_USER,
  ACTION_LOAD_SPIN_PRICE__SUCCESS,
  ACTION_SPIN_ROULETTE__SUCCESS,
  ACTION_SET_IS_ROULETTE_SPIN_CONFIRMED,
  ACTION_SET_MODEL_ID,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_AUTH_DATA:
      return action.payload.authData;

    case ACTION_UPDATE_LOGGED_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };

    default:
      return state;
  }
};

const loginInfo = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_LOGIN_INFO:
      return action.payload.loginInfo;

    default:
      return state;
  }
};

const paidRoulette = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_SPIN_PRICE__SUCCESS:
      return { nextSpinPrice: action.payload.spinPrice };

    case ACTION_SPIN_ROULETTE__SUCCESS:
      return action.payload.spin;

    default:
      return state;
  }
};

const isRouletteSpinConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_ROULETTE_SPIN_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

const modelId = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_MODEL_ID:
      return action.payload.modelId;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  loginInfo,
  paidRoulette,
  isRouletteSpinConfirmed,
  modelId,
  errors,
});
