export const ACTION_SET_EMOTICONS = 'ACTION_SET_EMOTICONS';

export const ACTION_SET_EMOTICON_IDS = 'ACTION_SET_EMOTICON_IDS';

export const ACTION_LOAD_EMOTICONS__REQUEST = 'ACTION_LOAD_EMOTICONS__REQUEST';
export const ACTION_LOAD_EMOTICONS__SUCCESS = 'ACTION_LOAD_EMOTICONS__SUCCESS';
export const ACTION_LOAD_EMOTICONS__FAIL = 'ACTION_LOAD_EMOTICONS__FAIL';

export const ACTION_BUY_EMOTICON__REQUEST = 'ACTION_BUY_EMOTICON__REQUEST';
export const ACTION_BUY_EMOTICON__SUCCESS = 'ACTION_BUY_EMOTICON__SUCCESS';
export const ACTION_BUY_EMOTICON__FAIL = 'ACTION_BUY_EMOTICON__FAIL';
