import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';

import { useDidUpdateEffect, useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useCurrentLangForUrl from '../../core/i18n/hooks/useCurrentLangForUrl';

import { runOnEnterHandlers } from 'react-redux-app/lib/modules/routing/utils';
import { stripLangFromUri } from '../../core/i18n/utils';
import { getUrl, getIndexUrl, getIsIndexPageAlias } from '../utils/url';

import { getIsAgency } from '../../auth/selectors';
import { getIsRootMounted } from '../selectors/isRootMounted';

import {
  actionLoadRivstreamerFileUrlRequest,
  actionLoadSiteEventsRequest,
  actionLoadSiteFeedbackSettingsRequest,
  actionLoadBonusesRequest,
  actionLoadModelInspectionSettingsRequest,
} from '../../initSettings/actions/loadPartialInitSettings';
import { actionCallOnEnterHooks } from '../actions/callOnEnterHooks';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { uiLangs } from '../../core/i18n/constants';
import {
  DEPOSIT_REMIND_TYPE__FIRST,
  DEPOSIT_REMIND_TYPE__NEW,
} from '../../auth/constants';
import { FILTER__MOST_POPULAR, FILTER__CURRENT_AWARDS } from '../../chatGallery/constants';
import { PEER__BAN_STAFF } from '../../dialogMessage/constants';
import { landingPages } from '../../landingPage/constants';
import { options } from '../constants';
import {
  PATH_REGISTER_USER,
  PATH_REGISTER_MODEL,
  PATH_RESTORE_PASSWORD,
  PATH_RESET_PASSWORD,
  PATH_AGENCY_LOGIN,

  PATH_PROFILE,
  PATH_BLOG,
  PATH_CONTACT_US,
  PATH_VIDEOS,
  PATH_PHOTOS,
  PATH_EROSTORIES,
  PATH_AWARDS,
  PATH_UNDERWEAR,
  PATH_INTERVIEWS,
  PATH_INTERVIEW,
  PATH_SITE_NEWS,
  PATH_ARTICLE,
  PATH_LINKS,
  PATH_LATEST_ITEMS,
  PATH_SEARCH,
  PATH_SUGAR_BABY,

  indexPathAliases,

  PATH_RULES_HOW_IT_WORKS,
  PATH_RULES_VIDEO_CHAT,
  PATH_RULES_VIDEO_CHAT_AGREEMENT,
  PATH_RULES_182257,
  PATH_RULES_TERMS,
  PATH_RULES_PRIVACY,
  PATH_RULES_REFUND,
  PATH_RULES_REWARDS,

  PATH_RIVSTREAMER,
  PATH_LARIX,
  PATH_WEBCAM_MODEL_WORKSPACE_HINTS,

  PATH_LEGAL_EU_DSA,
  PATH_LEGAL_CONTENT_REMOVAL,
  PATH_LEGAL_DMCA_COMPLAINT,

  PATH_EVENT,
  PATH_EVENT_AWARDS,

  PATH_LOADING,
  PATH_NOT_FOUND,
  PATH_SYSTEM_MESSAGE,
  PATH_SYSTEM_ERROR,
  PATH_SITEMAP,
  PATH_ROBOTS,

  PATH_ACCOUNT_SETTINGS,
  PATH_NOTIFICATION_SETTINGS,
  PATH_PAYOUT_SETTINGS,
  PATH_PARTNERS,
  PATH_CHANGE_PASSWORD,
  PATH_DELETE_ACCOUNT,
  PATH_PUBLIC_PHOTOS,
  PATH_WEBCAM_SETTINGS,
  PATH_SPEED_TEST,
  PATH_FEEDBACK,
  PATH_FAVORITES,
  PATH_AFFILIATES,
  PATH_BANNED_USERS,
  PATH_BLACKLIST,
  PATH_VISITORS,
  PATH_CREDITS,
  PATH_REVENUE,
  PATH_PURCHASES,
  PATH_ITEMS_FOR_SALE,
  PATH_DIALOGS,
  PATH_DIALOG,
  PATH_INTERVIEW_FORMS,
  PATH_OWN_INTERVIEWS,
  PATH_REQUESTS,
  PATH_INTERVIEWER,
  PATH_MAKE_FIRST_DEPOSIT,
  PATH_MAKE_NEW_DEPOSIT,
  PATH_APPOINTMENTS,
  PATH_WALLET_ADDRESS,
  PATH_PAYOUT_REQUEST_NOTICE,
  PATH_NEWS_FEED,
  PATH_BLOG_SETTINGS,
  PATH_EMOTICON_SHOP,
  PATH_PRIZES,
  PATH_FLOWER_DELIVERY,

  PATH_BALANCE_ADJUSTMENTS,
  PATH_BALANCE_LOSSES_COMPENSATIONS,
  PATH_BALANCE_EXPIRED_CREDITS,
  PATH_BALANCE_PURCHASES,
  PATH_BALANCE_FEES,
  PATH_BALANCE_SALES,
  PATH_BALANCE_PURCHASED_SERVICES,
  PATH_BALANCE_REVENUE_CONVERSIONS,

  PATH_SIGNUP_INFO,
  PATH_SIGNUP,

  PATH_UNBAN,
} from '../constants/url';

import {
  commonRequiredHandler,
  guestRequiredHandler,
  loadModelsByFilterHandler,
  modelIncompleteRegistrationRequiredHandler,
  modelRequiredHandler,
  notBannedRequiredHandler,
  notChangePasswordRequiredHandler,
  notChatBannedRequiredHandler,
  notSetWebcamTypeRequiredHandler,
  privateContentForbiddenHandler,
  redirectHandler,
  socialLoginHandler,
  userBannedRequiredHandler,
  userRequiredHandler,
} from './onEnterRouteHandlers';
import {
  loadAccountHandler,
  loadBlogSettingsHandler,
  loadFlowerDeliverySettingsHandler,
  loadNotificationSettingsHandler,
  loadWebcamSettingsHandler,
  proxyPhpActionHandler,
  proxyPhpResetEmailHandler,
} from '../../account/routes/onEnterRouteHandlers';
import { loadRefererCodeHandler } from '../../affiliate/routes/onEnterRouteHandlers';
import { loadAgencyModelsHandler } from '../../agency/routes/onEnterRouteHandlers';
import {
  checkAuthHandler,
  resetDepositRemindHandler,
  visitPageHandler,
} from '../../auth/routes/onEnterRouteHandlers';
import { loadAwardsHandler } from '../../award/routes/onEnterRouteHandlers';
import { loadBlogHandler } from '../../blog/routes/onEnterRouteHandlers';
import { loadStepHandler } from '../../registration/routes/onEnterRouteHandlers';
import {
  checkAliasHandler,
  loadChatGalleryDataHandler,
  loadModelsHandler,
} from '../../chatGallery/routes/onEnterRouteHandlers';
import { checkLangHandler } from '../../core/i18n/routes/onEnterRoutehandlers';
import {
  loadDepositSettingsHandler,
  loadWalletAddressHandler,
} from '../../deposit/routes/onEnterRouteHandlers';
import { loadDialogsHandler } from '../../dialog/routes/onEnterRouteHandlers';
import { loadDialogHandler } from '../../dialogMessage/routes/onEnterRouteHandlers';
import { loadEmoticonsHandler } from '../../emoticonShop/routes/onEnterRouteHandlers';
import { loadEventAwardsHandler } from '../../eventAward/routes/onEnterRouteHandlers';
import { loadFavoritesHandler } from '../../favorite/routes/onEnterRouteHandlers';
import {
  initSettingsHandler,
  checkStaticPageHandler,
  loadPartialInitSettingsHandler,
} from '../../initSettings/routes/onEnterRouteHandlers';
import {
  loadInterviewsHandler,
  loadInterviewHandler,
  loadInterviewerHandler,
  loadCreateInterviewFormSettingsHandler,
} from '../../interview/routes/onEnterRouteHandlers';
import { loadLandingPageHandler } from '../../landingPage/routes/onEnterRouteHandlers';
import { loadLatestItemsHandler } from '../../latestItem/routes/onEnterRouteHandlers';
import { loadMediaViewerDataHandler } from '../../mediaViewer/routes/onEnterRouteHandlers';
import { loadNewsFeedHandler } from '../../newsFeed/routes/onEnterRouteHandlers';
import {
  loadPublicPhotosHandler,
  loadItemsForSaleHandler,
} from '../../ownSaleItem/routes/onEnterRouteHandlers';
import { restorePasswordHandler } from '../../password/routes/onEnterRouteHandlers';
import { loadProfileHandler } from '../../profile/routes/onEnterRouteHandlers';
import { loadPurchasedItemsHandler } from '../../purchase/routes/onEnterRouteHandlers';
import {
  loadLoginInfoHandler,
  loadRevenueSettingsHandler,
} from '../../revenue/routes/onEnterRouteHandlers';
import {
  loadVideosHandler,
  loadPrivatePhotosHandler,
  loadErostoriesHandler,
  loadUnderwearHandler,
} from '../../saleItem/routes/onEnterRouteHandlers';
import { loadSitemapHandler } from '../../sitemap/routes/onEnterRouteHandlers';
import { loadSiteNewsHandler } from '../../siteNews/routes/onEnterRouteHandlers';
import { loadVisitorsHandler } from '../../visitor/routes/onEnterRouteHandlers';

import Loading from '../components/Loading';
import Layout from '../components/Layout';


const InternalError = loadable(() => import('react-redux-app/lib/modules/internalError/components/InternalError'));
const InternalErrorPage = () => <InternalError fallbackLink="/" />;

const EventPage = loadable(() => import('../components/pages/EventPage'), options);
const IndexPage = loadable(() => import('../components/pages/IndexPage'), options);
const HowItWorksPage = loadable(() => import('../components/pages/HowItWorksPage'), options);
const InfoPage = loadable(() => import('../components/pages/InfoPage'), options);
const LarixPage = loadable(() => import('../components/pages/LarixPage'), options);
const NotFoundPage = loadable(() => import('../components/pages/NotFoundPage'), options);
const RequestsPage = loadable(() => import('../components/pages/RequestsPage'), options);
const RivstreamerPage = loadable(() => import('../components/pages/RivstreamerPage'), options);
const SystemErrorPage = loadable(() => import('../components/pages/SystemErrorPage'), options);
const SystemMessagePage = loadable(() => import('../components/pages/SystemMessagePage'), options);
const AccountSettingsPage = loadable(() => import('../../account/components/pages/AccountSettingsPage'), options);
const BlogSettingsPage = loadable(() => import('../../account/components/pages/BlogSettingsPage'), options);
const DeleteAccountPage = loadable(() => import('../../account/components/pages/DeleteAccountPage'), options);
const FlowerDeliverySettingsPage = loadable(() => import('../../account/components/pages/FlowerDeliverySettingsPage'), options);
const NotificationSettingsPage = loadable(() => import('../../account/components/pages/NotificationSettingsPage'), options);
const PayoutSettingsPage = loadable(() => import('../../account/components/pages/PayoutSettingsPage'), options);
const SpeedTestPage = loadable(() => import('../../account/components/pages/SpeedTestPage'), options);
const WebcamSettingsPage = loadable(() => import('../../account/components/pages/WebcamSettingsPage'), options);
const AffiliatesPage = loadable(() => import('../../affiliate/components/pages/AffiliatesPage'), options);
const AppointmentsPage = loadable(() => import('../../appointment/components/pages/AppointmentsPage'), options);
const AgencyLoginPage = loadable(() => import('../../auth/components/pages/AgencyLoginPage'), options);
const MakeFirstDepositPage = loadable(() => import('../../auth/components/pages/MakeFirstDepositPage'), options);
const MakeNewDepositPage = loadable(() => import('../../auth/components/pages/MakeNewDepositPage'), options);
const ModelRegistrationPage = loadable(() => import('../../auth/components/pages/ModelRegistrationPage'), options);
const UnbanPage = loadable(() => import('../../auth/components/pages/UnbanPage'), options);
const UserRegistrationPage = loadable(() => import('../../auth/components/pages/UserRegistrationPage'), options);
const AwardsPage = loadable(() => import('../../award/components/pages/AwardsPage'), options);
const BalanceAdjustmentsPage = loadable(() => import('../../balance/components/pages/BalanceAdjustmentsPage'), options);
const ExpiredCreditsPage = loadable(() => import('../../balance/components/pages/ExpiredCreditsPage'), options);
const FeesPage = loadable(() => import('../../balance/components/pages/FeesPage'), options);
const LossesCompensationsPage = loadable(() => import('../../balance/components/pages/LossesCompensationsPage'), options);
const PurchasedServicesPage = loadable(() => import('../../balance/components/pages/PurchasedServicesPage'), options);
const PurchaseHistoryPage = loadable(() => import('../../balance/components/pages/PurchaseHistoryPage'), options);
const RevenueConversionsPage = loadable(() => import('../../balance/components/pages/RevenueConversionsPage'), options);
const SalesPage = loadable(() => import('../../balance/components/pages/SalesPage'), options);
const BannedUsersPage = loadable(() => import('../../bannedUser/components/pages/BannedUsersPage'), options);
const BlackListPage = loadable(() => import('../../blackList/components/pages/BlackListPage'), options);
const BlogPage = loadable(() => import('../../blog/components/pages/BlogPage'), options);
const PostPage = loadable(() => import('../../blog/components/pages/PostPage'), options);
const ModelsPage = loadable(() => import('../../chatGallery/components/pages/ModelsPage'), options);
const SugarBabyPage = loadable(() => import('../../chatGallery/components/pages/SugarBabyPage'), options);
const ContactUsPage = loadable(() => import('../../contactUs/components/pages/ContactUsPage'), options);
const DepositPage = loadable(() => import('../../deposit/components/pages/DepositPage'), options);
const WalletAddressPage = loadable(() => import('../../deposit/components/pages/WalletAddressPage'), options);
const DialogsPage = loadable(() => import('../../dialog/components/pages/DialogsPage'), options);
const DialogPage = loadable(() => import('../../dialogMessage/components/pages/DialogPage'), options);
const EmoticonShopPage = loadable(() => import('../../emoticonShop/components/pages/EmoticonShopPage'), options);
const EventAwardsPage = loadable(() => import('../../eventAward/components/pages/EventAwardsPage'), options);
const FavoritesPage = loadable(() => import('../../favorite/components/pages/FavoritesPage'), options);
const FeedbackPage = loadable(() => import('../../feedback/components/pages/FeedbackPage'), options);
const ArticlePage = loadable(() => import('../../initSettings/components/pages/ArticlePage'), options);
const LinksPage = loadable(() => import('../../initSettings/components/pages/LinksPage'), options);
const RobotsPage = loadable(() => import('../../initSettings/components/pages/RobotsPage'), options);
const StaticPage = loadable(() => import('../../initSettings/components/pages/StaticPage'), options);
const InterviewerPage = loadable(() => import('../../interview/components/pages/InterviewerPage'), options);
const InterviewFormsPage = loadable(() => import('../../interview/components/pages/InterviewFormsPage'), options);
const InterviewPage = loadable(() => import('../../interview/components/pages/InterviewPage'), options);
const InterviewsPage = loadable(() => import('../../interview/components/pages/InterviewsPage'), options);
const OwnInterviewsPage = loadable(() => import('../../interview/components/pages/OwnInterviewsPage'), options);
const LandingPage = loadable(() => import('../../landingPage/components/pages/LandingPage'), options);
const LatestItemsPage = loadable(() => import('../../latestItem/components/pages/LatestItemsPage'), options);
const ContentRemovalPage = loadable(() => import('../../legal/components/pages/ContentRemovalPage'), options);
const DmcaComplaintPage = loadable(() => import('../../legal/components/pages/DmcaComplaintPage'), options);
const EuDsaPage = loadable(() => import('../../legal/components/pages/EuDsaPage'), options);
const NewsFeedPage = loadable(() => import('../../newsFeed/components/pages/NewsFeedPage'), options);
const ItemsForSalePage = loadable(() => import('../../ownSaleItem/components/pages/ItemsForSalePage'), options);
const PublicPhotosPage = loadable(() => import('../../ownSaleItem/components/pages/PublicPhotosPage'), options);
const PartnersPage = loadable(() => import('../../partner/components/pages/PartnersPage'), options);
const ChangePasswordPage = loadable(() => import('../../password/components/pages/ChangePasswordPage'), options);
const ResetPasswordPage = loadable(() => import('../../password/components/pages/ResetPasswordPage'), options);
const RestorePasswordPage = loadable(() => import('../../password/components/pages/RestorePasswordPage'), options);
const PrizesPage = loadable(() => import('../../prize/components/pages/PrizesPage'), options);
const ProfilePage = loadable(() => import('../../profile/components/pages/ProfilePage'), options);
const PurchasesPage = loadable(() => import('../../purchase/components/pages/PurchasesPage'), options);
const SignupInfoPage = loadable(() => import('../../registration/components/pages/SignupInfoPage'), options);
const SignupPage = loadable(() => import('../../registration/components/pages/SignupPage'), options);
const PayoutRequestNoticePage = loadable(() => import('../../revenue/components/pages/PayoutRequestNoticePage'), options);
const RevenuePage = loadable(() => import('../../revenue/components/pages/RevenuePage'), options);
const ErostoriesPage = loadable(() => import('../../saleItem/components/pages/ErostoriesPage'), options);
const PrivatePhotosPage = loadable(() => import('../../saleItem/components/pages/PrivatePhotosPage'), options);
const UnderwearPage = loadable(() => import('../../saleItem/components/pages/UnderwearPage'), options);
const VideosPage = loadable(() => import('../../saleItem/components/pages/VideosPage'), options);
const SitemapPage = loadable(() => import('../../sitemap/components/pages/SitemapPage'), options);
const SiteNewsPage = loadable(() => import('../../siteNews/components/pages/SiteNewsPage'), options);
const VisitorsPage = loadable(() => import('../../visitor/components/pages/VisitorsPage'), options);

const routes = [];

const DataLoader = ({ component: Component, componentProps, isMultiLangContent }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const isRootMounted = useShortSelector(getIsRootMounted);
  const langForUrl = useCurrentLangForUrl();

  const pathname = getIsIndexPageAlias(location) ? getIndexUrl(langForUrl) : location.pathname;

  const [shouldLoad, setShouldLoad] = useState(isRootMounted);
  const [savedPathname, setSavedPathname] = useState(pathname);

  const shouldLoadOrPathnameChanged = shouldLoad || pathname !== savedPathname;

  useEffect(() => {
    if (shouldLoadOrPathnameChanged) {
      runOnEnterHandlers(
        false,
        routes,
        location,
        store,
        navigate,
        () => {
          setShouldLoad(false);
          setSavedPathname(pathname);
        }
      );
    }
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useDidUpdateEffect(() => {
    if (isMultiLangContent) {
      navigate(getUrl(langForUrl, stripLangFromUri(savedPathname)));

      dispatch(actionCallOnEnterHooks());
    }
  }, [langForUrl]);

  return shouldLoadOrPathnameChanged ? <Loading /> : <Component {...componentProps} />;
};

const getElement = (component, componentProps = {}, isMultiLangContent = false) => (
  <DataLoader {...{ component, componentProps, isMultiLangContent }} />
);

const getInfoElement = (headerLangKey, contentLangKey) => getElement(
  InfoPage, { headerLangKey, contentLangKey }
);

const getRoute = (path, element, onEnter = null) => {
  const result = {
    element: React.isValidElement(element) ? element : getElement(element),
    onEnter,
  };
  return path === true ? { index: true, ...result } : { path, ...result };
};

const guestRoutes = [
  getRoute(PATH_REGISTER_USER, UserRegistrationPage, [
    loadPartialInitSettingsHandler(actionLoadBonusesRequest),
  ]),
  getRoute(PATH_REGISTER_MODEL, ModelRegistrationPage),
  getRoute(PATH_RESTORE_PASSWORD, RestorePasswordPage),
  getRoute(PATH_AGENCY_LOGIN, AgencyLoginPage),
];

const commonRoutes = [
  getRoute(PATH_INTERVIEW_FORMS, InterviewFormsPage, [loadCreateInterviewFormSettingsHandler]),
  getRoute(PATH_APPOINTMENTS, AppointmentsPage),
  getRoute(PATH_EMOTICON_SHOP, EmoticonShopPage, [loadEmoticonsHandler]),
  getRoute(PATH_PRIZES, PrizesPage, [loadPartialInitSettingsHandler(actionLoadSiteEventsRequest)]),
];

const modelRoutes = [
  getRoute(PATH_ITEMS_FOR_SALE, ItemsForSalePage, [loadItemsForSaleHandler]),
  getRoute(PATH_SPEED_TEST, SpeedTestPage),
  getRoute(PATH_PARTNERS, PartnersPage),
  getRoute(PATH_OWN_INTERVIEWS, OwnInterviewsPage),
  getRoute(`${PATH_INTERVIEWER}/:formId`, InterviewerPage, [loadInterviewerHandler]),
  getRoute(PATH_REQUESTS, RequestsPage),
  getRoute(PATH_BANNED_USERS, BannedUsersPage),
  /* getRoute(PATH_BLOG_SETTINGS, BlogSettingsPage, [
    privateContentForbiddenHandler, loadBlogSettingsHandler,
  ]), */
  getRoute(PATH_FLOWER_DELIVERY, FlowerDeliverySettingsPage, [loadFlowerDeliverySettingsHandler]),
];

const userRoutes = [
  getRoute(PATH_ACCOUNT_SETTINGS, AccountSettingsPage, [loadAccountHandler]),
  getRoute(PATH_NOTIFICATION_SETTINGS, NotificationSettingsPage, [
    loadNotificationSettingsHandler,
  ]),
  getRoute(PATH_PAYOUT_SETTINGS, PayoutSettingsPage, [loadRevenueSettingsHandler(false)]),
  getRoute(PATH_DELETE_ACCOUNT, DeleteAccountPage),
  getRoute(PATH_PUBLIC_PHOTOS, PublicPhotosPage, [loadPublicPhotosHandler]),
  getRoute(PATH_FEEDBACK, FeedbackPage, [
    loadPartialInitSettingsHandler(actionLoadSiteFeedbackSettingsRequest),
  ]),
  getRoute(PATH_AFFILIATES, AffiliatesPage, [loadRefererCodeHandler]),
  getRoute(PATH_BLACKLIST, BlackListPage),
  getRoute(PATH_FAVORITES, FavoritesPage, [loadFavoritesHandler]),
  getRoute(PATH_VISITORS, VisitorsPage, [loadVisitorsHandler]),
  getRoute(PATH_CREDITS, DepositPage, [loadDepositSettingsHandler]),
  getRoute(PATH_PURCHASES, PurchasesPage, [loadPurchasedItemsHandler]),
  getRoute(PATH_MAKE_FIRST_DEPOSIT, MakeFirstDepositPage, [
    resetDepositRemindHandler(DEPOSIT_REMIND_TYPE__FIRST),
  ]),
  getRoute(PATH_MAKE_NEW_DEPOSIT, MakeNewDepositPage, [
    resetDepositRemindHandler(DEPOSIT_REMIND_TYPE__NEW),
  ]),
  getRoute(PATH_DIALOGS, DialogsPage, [loadDialogsHandler]),
  getRoute(`${PATH_DIALOG}/:peerId`, DialogPage, [loadDialogHandler()]),
  getRoute(`${PATH_WALLET_ADDRESS}/:currency`, WalletAddressPage, [loadWalletAddressHandler]),
  getRoute(PATH_REVENUE, RevenuePage, [loadRevenueSettingsHandler(false), loadAgencyModelsHandler]),
  getRoute(PATH_PAYOUT_REQUEST_NOTICE, PayoutRequestNoticePage, [loadLoginInfoHandler]),
  getRoute(PATH_NEWS_FEED, NewsFeedPage, [loadNewsFeedHandler]),
  getRoute(PATH_BALANCE_ADJUSTMENTS, BalanceAdjustmentsPage, [loadAgencyModelsHandler]),
  getRoute(PATH_BALANCE_LOSSES_COMPENSATIONS, LossesCompensationsPage, [loadAgencyModelsHandler]),
  getRoute(PATH_BALANCE_EXPIRED_CREDITS, ExpiredCreditsPage),
  getRoute(PATH_BALANCE_PURCHASES, PurchaseHistoryPage),
  getRoute(PATH_BALANCE_FEES, FeesPage),
  getRoute(PATH_BALANCE_SALES, SalesPage, [loadAgencyModelsHandler]),
  getRoute(PATH_BALANCE_PURCHASED_SERVICES, PurchasedServicesPage, [loadAgencyModelsHandler]),
  getRoute(PATH_BALANCE_REVENUE_CONVERSIONS, RevenueConversionsPage),
];

const registrationRoutes = [
  getRoute(PATH_SIGNUP_INFO, SignupInfoPage, [modelIncompleteRegistrationRequiredHandler]),
  getRoute(PATH_SIGNUP, SignupPage, [userRequiredHandler, loadStepHandler]),
];

const publicRoutes = [
  getRoute(true, IndexPage, [
    loadChatGalleryDataHandler,
    loadVideosHandler(true),
    loadRevenueSettingsHandler(true),
    loadPartialInitSettingsHandler(actionLoadModelInspectionSettingsRequest, getIsAgency),
  ]),
  getRoute(`${PATH_PROFILE}/:id`, ProfilePage, [notChatBannedRequiredHandler, loadProfileHandler]),
  // getRoute(`${PATH_BLOG}/:username`, BlogPage, [loadBlogHandler]),
  // getRoute(`${PATH_BLOG}/:username/:postId`, PostPage, [loadBlogHandler]),
  getRoute(PATH_CONTACT_US, ContactUsPage),
  getRoute(PATH_VIDEOS, VideosPage, [privateContentForbiddenHandler, loadVideosHandler(false)]),
  getRoute(PATH_PHOTOS, PrivatePhotosPage, [
    privateContentForbiddenHandler, loadPrivatePhotosHandler,
  ]),
  getRoute(PATH_EROSTORIES, ErostoriesPage, [loadErostoriesHandler]),
  getRoute(PATH_UNDERWEAR, UnderwearPage, [loadUnderwearHandler]),
  getRoute(PATH_INTERVIEWS, getElement(InterviewsPage, {}, true), [loadInterviewsHandler]),
  getRoute(`${PATH_INTERVIEW}/:id`, InterviewPage, [loadInterviewHandler]),
  getRoute(PATH_AWARDS, AwardsPage, [loadAwardsHandler]),
  getRoute(PATH_LATEST_ITEMS, LatestItemsPage, [loadLatestItemsHandler]),
  getRoute(PATH_SEARCH, ModelsPage, [loadModelsHandler]),
  getRoute(PATH_SUGAR_BABY, SugarBabyPage, [loadModelsByFilterHandler(FILTER__CURRENT_AWARDS)]),
];
indexPathAliases.forEach(path => {
  publicRoutes.push({
    path: `${path}:filter`,
    element: getElement(IndexPage),
    onServerEnter: [checkAliasHandler],
    onEnter: [loadChatGalleryDataHandler, loadVideosHandler(true)],
  });
});

const allRoutes = [
  { onEnter: [guestRequiredHandler], children: guestRoutes },

  {
    onEnter: [
      notBannedRequiredHandler, notChangePasswordRequiredHandler, notSetWebcamTypeRequiredHandler,
    ],
    onServerEnter: [loadMediaViewerDataHandler],
    children: [
      {
        onEnter: [userRequiredHandler],
        children: [
          { onEnter: [commonRequiredHandler], children: commonRoutes },
          { onEnter: [modelRequiredHandler], children: modelRoutes },
          ...userRoutes,
        ],
      },
      ...registrationRoutes,
      ...publicRoutes,
    ],
  },

  getRoute(PATH_UNBAN, UnbanPage, [userBannedRequiredHandler, loadDialogHandler(PEER__BAN_STAFF)]),
  getRoute(PATH_CHANGE_PASSWORD, ChangePasswordPage, [userRequiredHandler]),
  getRoute(PATH_WEBCAM_SETTINGS, WebcamSettingsPage, [
    notBannedRequiredHandler, notChangePasswordRequiredHandler,
    modelRequiredHandler, loadWebcamSettingsHandler,
  ]),
  getRoute(`${PATH_RESET_PASSWORD}/:token`, <ResetPasswordPage />, [restorePasswordHandler]),

  getRoute(PATH_RULES_HOW_IT_WORKS, HowItWorksPage),
  getRoute(PATH_RULES_VIDEO_CHAT, getInfoElement(
    'header.video-chat-rules', 'html-text.msg.video-chat-rules'
  )),
  getRoute(PATH_RULES_VIDEO_CHAT_AGREEMENT, getInfoElement(
    'header.video-chat-agreement', 'html-text.msg.video-chat-agreement'
  )),
  getRoute(PATH_RULES_182257, getInfoElement('header.182257', 'html-text.msg.182257')),
  getRoute(PATH_RULES_TERMS, getInfoElement(
    'header.terms-and-conditions', 'html-text.msg.terms-and-conditions'
  )),
  getRoute(PATH_RULES_PRIVACY, getInfoElement(
    'header.privacy-policy', 'html-text.msg.privacy-policy'
  )),
  getRoute(PATH_RULES_REFUND, getInfoElement(
    'header.refund-policy', 'html-text.msg.refund-policy'
  )),
  getRoute(PATH_RULES_REWARDS, getInfoElement(
    'header.rewards-rules', 'html-text.msg.rewards-rules'
  )),
  getRoute(PATH_LARIX, LarixPage),
  getRoute(PATH_RIVSTREAMER, RivstreamerPage, [
    loadPartialInitSettingsHandler(actionLoadRivstreamerFileUrlRequest),
  ]),
  getRoute(PATH_WEBCAM_MODEL_WORKSPACE_HINTS, getInfoElement(
    'header.webcam-model-workspace-hints', 'html-text.msg.webcam-model-workspace-hints'
  )),
  getRoute(PATH_LEGAL_EU_DSA, EuDsaPage),
  getRoute(PATH_LEGAL_CONTENT_REMOVAL, ContentRemovalPage),
  getRoute(PATH_LEGAL_DMCA_COMPLAINT, DmcaComplaintPage),
  getRoute(PATH_EVENT, EventPage),
  getRoute(PATH_EVENT_AWARDS, EventAwardsPage, [loadEventAwardsHandler]),
  getRoute(PATH_SITE_NEWS, getElement(SiteNewsPage, {}, true), [loadSiteNewsHandler]),
  getRoute(`${PATH_ARTICLE}/:id`, ArticlePage),
  getRoute(PATH_LINKS, LinksPage),
  getRoute(':stub.html', StaticPage, [checkStaticPageHandler]),

  getRoute(PATH_LOADING, <Loading />),
  getRoute(PATH_NOT_FOUND, NotFoundPage, [loadModelsByFilterHandler(FILTER__MOST_POPULAR)]),
  getRoute(PATH_SYSTEM_MESSAGE, <SystemMessagePage />),
  getRoute(PATH_SYSTEM_ERROR, <SystemErrorPage />),
  getRoute('action.php', <span />, [proxyPhpActionHandler]),
  getRoute('email_reset.php', <span />, [proxyPhpResetEmailHandler]),
];
landingPages.forEach(page => {
  allRoutes.push(getRoute(page, LandingPage, [loadLandingPageHandler]));
});

const mainRoute = children => ({
  element: <Layout />,
  onServerEnter: [
    checkLangHandler,
    redirectHandler,
    initSettingsHandler,
    checkAuthHandler,
    socialLoginHandler,
  ],
  onEnter: [visitPageHandler],
  children,
});

const innerRoute = mainRoute(allRoutes);

routes.push(
  getRoute(INTERNAL_ERROR_URI, <InternalErrorPage />),
  { path: '/', children: [innerRoute] }
);

uiLangs.forEach(uiLang => {
  routes.push({ path: `/${uiLang}/`, children: [innerRoute] });
});

routes.push(getRoute(PATH_SITEMAP, <SitemapPage />, [initSettingsHandler, loadSitemapHandler]));
routes.push(getRoute(PATH_ROBOTS, <RobotsPage />, [initSettingsHandler]));
routes.push(mainRoute([getRoute('*', NotFoundPage, [
  loadModelsByFilterHandler(FILTER__MOST_POPULAR),
])]));

export default routes;
