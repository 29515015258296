import { MODAL_DIALOG__PAYMENT_RESULT_NOTIFICATION } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowPaymentResultNotificationDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__PAYMENT_RESULT_NOTIFICATION))
);

export const actionHidePaymentResultNotificationDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__PAYMENT_RESULT_NOTIFICATION))
);
