import {
  ACTION_LOAD_NEWS_FEED__REQUEST,
  ACTION_LOAD_NEWS_FEED__SUCCESS,
  ACTION_LOAD_NEWS_FEED__FAIL,

  ACTION_ADD_NEWS,

  ACTION_ADD_NEWS_FEED,
  ACTION_ADD_NEWS_FEED_IDS,
  ACTION_SET_NEWS_FEED_IDS,
  ACTION_SET_NEWS_FEED_PRESERVE,
  ACTION_SET_NEWS_FEED_ACTIVE_PAGE,
  ACTION_ADD_PUBLIC_PHOTOS,
  ACTION_ADD_COMMENT_ITEMS,

  ACTION_DELETE_NEWS_FEED,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { NEWS_FEEDS_SCHEMA } from '../schemas';

import { getNewsFeedPaginationMode } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';


const actionAddNews = news => ({
  type: ACTION_ADD_NEWS,
  payload: { news },
});

export const actionAddNewsFeed = newsFeed => ({
  type: ACTION_ADD_NEWS_FEED,
  payload: { newsFeed },
});

const actionAddNewsFeedIds = newsFeed => ({
  type: ACTION_ADD_NEWS_FEED_IDS,
  payload: { newsFeed },
});

const actionSetNewsFeedIds = newsFeed => ({
  type: ACTION_SET_NEWS_FEED_IDS,
  payload: { newsFeed },
});

export const actionSetNewsFeedPreserve = () => ({
  type: ACTION_SET_NEWS_FEED_PRESERVE,
});

const actionSetNewsFeedActivePage = activePage => ({
  type: ACTION_SET_NEWS_FEED_ACTIVE_PAGE,
  payload: { activePage },
});

export const actionAddPublicPhotos = publicPhotos => ({
  type: ACTION_ADD_PUBLIC_PHOTOS,
  payload: { publicPhotos },
});

export const actionAddCommentItems = commentItems => ({
  type: ACTION_ADD_COMMENT_ITEMS,
  payload: { commentItems },
});

const actionLoadNewsFeedSuccess = () => ({
  type: ACTION_LOAD_NEWS_FEED__SUCCESS,
});

const actionLoadNewsFeedFail = error => ({
  type: ACTION_LOAD_NEWS_FEED__FAIL,
  payload: { error },
});

export const actionLoadNewsFeedRequest = ({ page = 1, limit = 10 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getNewsFeedPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_NEWS_FEED__REQUEST,
    });

    return apiClientHelper.get(
      `news_feed/list/${page}`,
      { params: { limit } },
      NEWS_FEEDS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddSaleItems(entities.items || {}));
        dispatch(actionAddPublicPhotos(entities.publicPhotos || {}));
        dispatch(actionAddCommentItems(entities.commentItems || {}));
        dispatch(actionAddNewsFeed(entities.newsFeeds || {}));
        dispatch(actionAddNews(entities.news || {}));

        if (preserve) {
          dispatch(actionAddNewsFeedIds(result));
        } else {
          dispatch(actionSetNewsFeedIds(result));
        }

        dispatch(actionSetNewsFeedActivePage(page));
        dispatch(actionLoadNewsFeedSuccess());
      },
      error => {
        dispatch(actionLoadNewsFeedFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionDeleteNewsFeed = newsFeedId => ({
  type: ACTION_DELETE_NEWS_FEED,
  payload: { newsFeedId },
});
