import { getGridFilter } from '../utils';

import { RECORDED_PRIVATES_GRID_NAME } from '../constants';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';
import { actionAddRecordedPrivates } from './recordedPrivates';


export const actionLoadRecordedPrivatesRequest = getActionLoadGridDataRequest(
  RECORDED_PRIVATES_GRID_NAME,
  getGridFilter,
  'account/recorded_private/list',
  actionAddRecordedPrivates
);
