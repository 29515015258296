import {
  ACTION_REJECT_INTERVIEW__REQUEST,
  ACTION_REJECT_INTERVIEW__SUCCESS,
  ACTION_REJECT_INTERVIEW__FAIL,
} from '../../actionTypes';

import { INTERVIEW_REQUESTS_GRID_NAME } from '../../constants/interviewRequest';

import { actionLoadInterviewRequestsRequest } from '../../actions/interviewRequestsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  INTERVIEW_REQUESTS_GRID_NAME,
  actionLoadInterviewRequestsRequest,
  [ACTION_REJECT_INTERVIEW__REQUEST],
  [ACTION_REJECT_INTERVIEW__FAIL],
  [ACTION_REJECT_INTERVIEW__SUCCESS]
);
