import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Col, Row } from 'reactstrap';

import { useDidUpdateEffect, useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../api/hooks';
import useCurrentLangForUrl from '../../../i18n/hooks/useCurrentLangForUrl';

import { LOCAL_STORAGE, setItem } from 'react-redux-app/lib/modules/storage';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { getNotificationSettingsUrl } from '../../../../app/utils/url';
import { l } from 'react-redux-app/lib/modules/i18n/utils';
import { createPushManagerSubscription, getServiceWorkerRegistration } from '../../utils';

import { STORAGE_KEY__SUPPRESS_REQUEST_PERMISSION_PANEL } from '../../constants';
import { NOTIFICATION_TAB__PUSH } from '../../../../account/constants/tabs';

import {
  getIsPermissionRequestPanelSuppressed,
  getIsPermissionRequestPanelVisible,
} from '../../selectors';
import { getWebPushPublicKey } from '../../../../initSettings/selectors';
import { getSubscribeToPushNotificationsError } from '../../selectors/errors';

import { actionHidePermissionRequestPanel } from '../../actions/panel';
import { actionSubscribeToPushNotificationsRequest } from '../../actions/subscribe';

import { Button } from 'react-redux-app/lib/modules/button/components';
import { ButtonSet } from 'react-redux-app/lib/modules/buttonSet/components';
import { ActionLink } from 'react-redux-app/lib/modules/actionLink/components';
import WhiteButton from '../../../components/buttons/WhiteButton';

import styles from './styles/index.css.json';


export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isVisible = useShortSelector(getIsPermissionRequestPanelVisible);
  const isSuppressed = useShortSelector(getIsPermissionRequestPanelSuppressed);
  const webPushPublicKey = useShortSelector(getWebPushPublicKey);
  const langForUrl = useCurrentLangForUrl();

  useDidUpdateEffect(() => {
    if (isSuppressed) {
      setItem(LOCAL_STORAGE, STORAGE_KEY__SUPPRESS_REQUEST_PERMISSION_PANEL, Date.now());
    }
  }, [isSuppressed]);

  const callActionSubscribeToPushNotificationsRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getSubscribeToPushNotificationsError)
  );
  const onSubscribeClick = async e => {
    e.preventDefault();

    let subscriptionInfo;

    try {
      subscriptionInfo = await createPushManagerSubscription(
        await getServiceWorkerRegistration(),
        webPushPublicKey
      );
    } catch (error) {
      // Permission request declined or something else went wrong
      console.consoleError(error);
      dispatch(actionHidePermissionRequestPanel());
      return;
    }

    callActionSubscribeToPushNotificationsRequest(
      dispatch(actionSubscribeToPushNotificationsRequest(subscriptionInfo)),
      'header.push-notification.subscribe',
      () => { dispatch(actionHidePermissionRequestPanel()); }
    );
  };

  const onSettingsClick = () => {
    dispatch(actionHidePermissionRequestPanel());

    navigate(getNotificationSettingsUrl(langForUrl, NOTIFICATION_TAB__PUSH));
  };

  const onClose = () => { dispatch(actionHidePermissionRequestPanel(true)); };

  return isVisible && (
    <Alert color="light" className={styles.alert}>
      <Row>
        <Col md="3" />

        <Col md="6" className={styles.messageCol}>
          <div className={styles.message}>
            {l('msg.push-notification.enable.prefix')}
            &nbsp;
            <ActionLink onClick={onSubscribeClick} className={styles.link}>
              {l('msg.push-notification.enable.postfix')}
            </ActionLink>
          </div>
        </Col>

        <Col md="3" className={styles.buttonsCol}>
          <ButtonSet theme={{ buttonsContainer: styles.buttonsContainer }}>
            <Button onClick={onSettingsClick}>
              {l('msg.settings')}
            </Button>
            <WhiteButton onClick={onClose}>{l('action.close')}</WhiteButton>
          </ButtonSet>
        </Col>
      </Row>
    </Alert>
  );
};
