import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const underwearItemSelector = state => _get(state, 'ownSaleItem.underwear');
const underwearItemsSelector = state => _get(underwearItemSelector(state), 'underwear');
export const getUnderwearIds = state => _get(underwearItemsSelector(state), 'items');
const getUnderwearTotalCount = state => _get(underwearItemsSelector(state), 'count');
const getUnderwearLimit = state => _get(underwearItemsSelector(state), 'limit');
export const getIsLastUnderwearLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getUnderwearIds, getUnderwearTotalCount, getUnderwearLimit
);
export const getUnderwearTotalPagesCount = state => getTotalPagesCount(
  state, getUnderwearTotalCount, getUnderwearLimit
);

export const getUnderwearPaginationMode = state => (
  _get(underwearItemSelector(state), 'paginationMode')
);
export const getUnderwearActivePage = state => _get(underwearItemSelector(state), 'activePage');
export const getShouldLoadUnderwearFirstPage = state => (
  _get(underwearItemSelector(state), 'shouldLoadFirstPage')
);
export const getShouldReloadUnderwearActivePage = state => (
  _get(underwearItemSelector(state), 'shouldReloadActivePage')
);

export const getIsUnderwearBeingDeleted = (state, underwearId) => (
  !!_get(underwearItemSelector(state), `isUnderwearBeingDeleted.${underwearId}`)
);

const errorsSelector = state => _get(underwearItemSelector(state), 'errors');
export const getLoadUnderwearError = state => (
  _get(errorsSelector(state), 'loadUnderwearError')
);
export const getCreateUnderwearError = state => (
  _get(errorsSelector(state), 'createUnderwearError')
);
export const getDeleteUnderwearError = state => (
  _get(errorsSelector(state), 'deleteUnderwearError')
);
export const getUpdateUnderwearError = state => (
  _get(errorsSelector(state), 'updateUnderwearError')
);
