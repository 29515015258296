import {
  ACTION_LOAD_PERSONAL_CONTACTS__REQUEST,
  ACTION_LOAD_PERSONAL_CONTACTS__FAIL,
  ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS,
} from '../../../actionTypes/personalContact';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PERSONAL_CONTACTS__REQUEST:
    case ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS:
      return null;

    case ACTION_LOAD_PERSONAL_CONTACTS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
