import { ACTION_ADD_PARTNERS } from '../actionTypes';

import { PARTNERS_GRID_NAME } from '../constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import {
  actionLoadGridDataRequest,
  actionLoadGridDataSuccess,
  actionLoadGridDataFail,
} from 'react-redux-app/lib/modules/grid/actions';


const actionAddPartners = partners => ({
  type: ACTION_ADD_PARTNERS,
  payload: { partners },
});

export const actionLoadPartnersRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch(actionLoadGridDataRequest(PARTNERS_GRID_NAME));

    return apiClientHelper.get(
      'partner/list',
      {},
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddPartners(entities.items));

        dispatch(actionLoadGridDataSuccess(
          PARTNERS_GRID_NAME, result.items, result.items.length
        ));
      },
      error => {
        dispatch(actionLoadGridDataFail(PARTNERS_GRID_NAME, error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
