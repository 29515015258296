import _get from 'lodash/get';


const personalContactSelector = state => _get(state, 'ownSaleItem.personalContact');
const personalContactDataSelector = state => _get(personalContactSelector(state), 'data');
export const getPersonalContactIsCanSell = state => (
  _get(personalContactDataSelector(state), 'isCanSell')
);
const personalContactInitSelector = state => (
  _get(personalContactDataSelector(state), 'init')
);
export const getPersonalContactIds = state => (
  _get(personalContactInitSelector(state), 'contacts')
);
export const getPersonalContactAvailableTypes = state => (
  _get(personalContactInitSelector(state), 'availableTypes')
);
export const getPersonalContactAvailableCosts = state => (
  _get(personalContactInitSelector(state), 'availableCosts')
);

const errorsSelector = state => _get(personalContactSelector(state), 'errors');
export const getLoadPersonalContactsError = state => (
  _get(errorsSelector(state), 'loadPersonalContactsError')
);
export const getUpdatePersonalContactError = state => (
  _get(errorsSelector(state), 'updatePersonalContactError')
);
