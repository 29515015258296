import {
  ACTION_LOAD_VIDEO_PREVIEW__REQUEST,
  ACTION_LOAD_VIDEO_PREVIEW__SUCCESS,
  ACTION_LOAD_VIDEO_PREVIEW__FAIL,
} from '../../../../actionTypes/video';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEO_PREVIEW__REQUEST:
    case ACTION_LOAD_VIDEO_PREVIEW__SUCCESS:
      return null;

    case ACTION_LOAD_VIDEO_PREVIEW__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
