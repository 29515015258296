import _get from 'lodash/get';


export const getInterviewForms = state => _get(state, 'interview.interviewFormsById');

const currentInterviewFormSelector = state => (
  _get(state, 'interview.interviewForm.interviewForm')
);
export const getInterviewFormTitle = state => (
  _get(currentInterviewFormSelector(state), 'form.title')
);
export const getInterviewFormDate = state => (
  _get(currentInterviewFormSelector(state), 'form.created')
);
export const getInterviewFormLanguage = state => (
  _get(currentInterviewFormSelector(state), 'form.lang')
);
export const getInterviewFormDescription = state => (
  _get(currentInterviewFormSelector(state), 'form.description')
);
export const getInterviewFormRespondentNick = state => (
  _get(currentInterviewFormSelector(state), 'respondentNick')
);
export const getInterviewFormQuestions = state => (
  _get(state, 'interview.interviewForm.questions')
);

export const getIsInterviewFormBeingCancelled = (state, id) => (
  !!_get(state, `interview.isInterviewFormBeingCancelled.${id}`)
);

const interviewSettingsSelector = state => _get(state, 'interview.createInterviewFormSettings');
export const getCreateInterviewFormMinCredits = state => (
  _get(interviewSettingsSelector(state), 'minCredits')
);
export const getCreateInterviewFormPurchasedCredits = state => (
  _get(interviewSettingsSelector(state), 'purchasedCredits')
);

const errorsSelector = state => _get(state, 'interview.errors');
export const getLoadInterviewFormError = state => (
  _get(errorsSelector(state), 'loadInterviewFormError')
);
export const getLoadCreateInterviewFormSettingsError = state => (
  _get(errorsSelector(state), 'loadCreateInterviewFormSettingsError')
);
export const getCancelInterviewFormError = state => (
  _get(errorsSelector(state), 'cancelInterviewFormError')
);
export const getCreateInterviewFormError = state => (
  _get(errorsSelector(state), 'createInterviewFormError')
);
