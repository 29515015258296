import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { getSwitchedFromUser } from '../../../auth/selectors';
import { getReturnToAgencyError } from '../../../auth/selectors/errors';

import { actionReturnToAgencyRequest } from '../../../auth/actions/returnToAgency';

import { Button } from 'react-redux-app/lib/modules/button/components';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const ReturnToAgencyButton = ({ className }) => {
  const dispatch = useDispatch();

  const switchedFromUser = useShortSelector(getSwitchedFromUser);

  const [isBeingProcessed, setIsBeingProcessed] = useState(false);

  const callActionReturnToAgencyRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getReturnToAgencyError)
  );
  const returnToAgency = () => {
    setIsBeingProcessed(true);

    callActionReturnToAgencyRequest(
      dispatch(actionReturnToAgencyRequest()),
      'header.agency.return-to',
      () => { setIsBeingProcessed(false); }
    );
  };

  return switchedFromUser && (
    <Button
      type="button"
      onClick={returnToAgency}
      isSpinnerEnabled={isBeingProcessed}
      className={className}
    >
      {l('action.to-agency')}
    </Button>
  );
};

ReturnToAgencyButton.propTypes = propTypes;

export default ReturnToAgencyButton;
