import _mapValues from 'lodash/mapValues';

import {
  ACTION_ADD_MODELS,
  ACTION_SET_MODEL_CHAT_STATUSES,
  ACTION_UPDATE_MODEL_CHAT_STATUS,
  ACTION_SET_MODEL_ORDER,
  ACTION_SWAP_MODELS,
  ACTION_SET_ALLOWED_TYPES,

  ACTION_SET_WEEKEND_IMAGE,
  ACTION_SET_CREDITS_BONUS,
} from '../actionTypes';


export const actionAddModels = models => ({
  type: ACTION_ADD_MODELS,
  payload: { models },
});

export const actionSetModelChatStatuses = statuses => ({
  type: ACTION_SET_MODEL_CHAT_STATUSES,
  payload: { statuses },
});

export const actionUpdateModelChatStatus = (modelId, status, stream) => ({
  type: ACTION_UPDATE_MODEL_CHAT_STATUS,
  payload: { modelId, status, stream },
});

export const actionSetModelOrder = order => ({
  type: ACTION_SET_MODEL_ORDER,
  payload: { order },
});

export const actionSwapModels = (position1, position2) => ({
  type: ACTION_SWAP_MODELS,
  payload: { position1, position2 },
});

export const actionSetAllowedTypes = allowedTypes => ({
  type: ACTION_SET_ALLOWED_TYPES,
  payload: { allowedTypes },
});

export const getModelsAndStatuses = items => {
  const statuses = {};
  const models = _mapValues(items, (model, id) => {
    const {
      status,
      stream,
      ...rest
    } = model;

    statuses[id] = { status, stream };

    return rest;
  });
  return { statuses, models };
};

export const actionSetWeekendImage = image => ({
  type: ACTION_SET_WEEKEND_IMAGE,
  payload: { image },
});

export const actionSetCreditsBonus = (type, bonus) => ({
  type: ACTION_SET_CREDITS_BONUS,
  payload: { type, bonus },
});
