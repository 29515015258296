import {
  ACTION_SUGGEST_PUSH_NOTIFICATION_SUBSCRIPTION,
  ACTION_HIDE_PERMISSION_REQUEST_PANEL,
  ACTION_SHOW_PERMISSION_REQUEST_PANEL,
} from '../actionTypes';


export const actionSuggestPushNotificationSubscription = () => ({
  type: ACTION_SUGGEST_PUSH_NOTIFICATION_SUBSCRIPTION,
});

export const actionShowPermissionRequestPanel = () => ({
  type: ACTION_SHOW_PERMISSION_REQUEST_PANEL,
});

export const actionHidePermissionRequestPanel = (suppress = false) => ({
  type: ACTION_HIDE_PERMISSION_REQUEST_PANEL,
  payload: { suppress },
});
