import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_LOAD_EROSTORY_CATEGORIES__REQUEST,
  ACTION_LOAD_EROSTORY_CATEGORIES__SUCCESS,
  ACTION_LOAD_EROSTORY_CATEGORIES__FAIL,

  ACTION_ADD_EROSTORIES,
  ACTION_SET_EROSTORIES,

  ACTION_SET_EROSTORIES_PRESERVE,

  ACTION_LOAD_EROSTORIES__REQUEST,
  ACTION_LOAD_EROSTORIES__SUCCESS,
  ACTION_LOAD_EROSTORIES__FAIL,
} from '../actionTypes/erostory';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getErostoryPaginationMode } from '../selectors/erostory';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from './index';


const actionLoadErostoryCategoriesSuccess = categories => ({
  type: ACTION_LOAD_EROSTORY_CATEGORIES__SUCCESS,
  payload: { categories },
});

const actionLoadErostoryCategoriesFail = error => ({
  type: ACTION_LOAD_EROSTORY_CATEGORIES__FAIL,
  payload: { error },
});

export const actionLoadErostoryCategoriesRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_EROSTORY_CATEGORIES__REQUEST,
    });

    return apiClientHelper.get(
      'story/categories'
    ).then(
      ({ categories }) => {
        dispatch(actionLoadErostoryCategoriesSuccess(categories));
      },
      error => {
        dispatch(actionLoadErostoryCategoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

const actionAddErostories = erostories => ({
  type: ACTION_ADD_EROSTORIES,
  payload: { erostories },
});

export const actionSetErostories = erostories => ({
  type: ACTION_SET_EROSTORIES,
  payload: { erostories },
});

export const actionSetErostoriesPreserve = () => ({
  type: ACTION_SET_EROSTORIES_PRESERVE,
});

const actionLoadErostoriesSuccess = () => ({
  type: ACTION_LOAD_EROSTORIES__SUCCESS,
});

const actionLoadErostoriesFail = error => ({
  type: ACTION_LOAD_EROSTORIES__FAIL,
  payload: { error },
});

export const actionLoadErostoriesRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getErostoryPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_EROSTORIES__REQUEST,
    });

    const {
      nick,
      page = 1,
      category,
      orderBy,
      limit = 28,
    } = getFilter(location);

    const params = { limit };
    if (nick) {
      params.nick = nick;
    }
    if (category) {
      params.category = category;
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }

    return apiClientHelper.get(
      `story/list/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result: erostories, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetErostories(erostories));

          return dispatch(actionLoadErostoriesSuccess());
        }

        // Preload model photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].profilePhotoThumb));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddErostories(erostories));
          } else {
            dispatch(actionSetErostories(erostories));
          }

          dispatch(actionLoadErostoriesSuccess());
        });
      },
      error => {
        dispatch(actionLoadErostoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
