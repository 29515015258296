import { useNavigate } from 'react-router-dom';

import { useUri } from 'react-redux-app/lib/modules/routing/hooks';

import { TAB_QUERY_PARAM } from '../constants';

import { url } from 'react-redux-app/lib/modules/routing/utils';


export default () => {
  const navigate = useNavigate();

  const currentUri = useUri();

  return tab => {
    const newUri = url.appendQueryParams(currentUri, { [TAB_QUERY_PARAM]: tab });
    navigate(newUri, { replace: true });
  };
};
