/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert, Container, UncontrolledAlert } from 'reactstrap';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';
import useCurrentLangForUrl from '../../../core/i18n/hooks/useCurrentLangForUrl';

import { getAccountSettingsUrl } from '../../utils/url';

import { ACCOUNT_TAB__ACCOUNT } from '../../../account/constants/tabs';

import { getNotifications, getUserBouncedEmail, getUserNewEmail } from '../../../auth/selectors';
import { getDeleteNotificationError } from '../../../auth/selectors/errors';

import { actionDeleteNotificationRequest } from '../../../auth/actions/deleteNotification';

import HtmlFromLangKey from '../../../core/components/HtmlFromLangKey';
import HtmlText from '../../../core/components/HtmlText';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const Notifications = ({ className }) => {
  const dispatch = useDispatch();

  const bouncedEmail = useShortSelector(getUserBouncedEmail);
  const newEmail = useShortSelector(getUserNewEmail);
  const notifications = useShortSelector(getNotifications) || [];
  const langForUrl = useCurrentLangForUrl();

  const [isVisible, setIsVisible] = useState(() => {
    const obj = {};
    notifications.forEach(({ id, needClose }) => {
      if (needClose) {
        obj[id] = true;
      }
    });
    return obj;
  });

  const callActionDeleteNotificationRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getDeleteNotificationError)
  );
  const deleteNotification = id => {
    callActionDeleteNotificationRequest(
      dispatch(actionDeleteNotificationRequest(id)),
      'header.notification.delete'
    );
  };

  return (bouncedEmail || newEmail || notifications.length > 0) && (
    <Container fluid className={className}>
      {(bouncedEmail || newEmail) && (
        <UncontrolledAlert color="warning" fade={false}>
          <HtmlFromLangKey
            langKey={
              bouncedEmail
                ? 'html-text.msg.unsubscribed-by-bounce'
                : 'html-text.msg.confirm-email-change'
            }
            langKeyParams={{
              email: bouncedEmail || newEmail,
              emailSettingsUrl: getAccountSettingsUrl(langForUrl, ACCOUNT_TAB__ACCOUNT),
            }}
          />
        </UncontrolledAlert>
      )}
      {notifications.map(({
        id,
        message,
        type,
        needClose,
      }) => {
        const htmlMessage = (
          <HtmlText>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </HtmlText>
        );

        return needClose ? (
          <Alert
            color={type}
            fade={false}
            isOpen={isVisible[id]}
            toggle={() => {
              setIsVisible({ ...isVisible, [id]: false });
              deleteNotification(id);
            }}
            key={id}
          >
            {htmlMessage}
          </Alert>
        ) : (
          <UncontrolledAlert color={type} fade={false}>
            {htmlMessage}
          </UncontrolledAlert>
        );
      })}
    </Container>
  );
};

Notifications.propTypes = propTypes;

export default Notifications;
