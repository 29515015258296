import { MODAL_DIALOG__MODEL } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowModelDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__MODEL))
);

export const actionHideModelDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__MODEL))
);
