import React from 'react';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { GO_TO_VIDEOCHAT } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'videochat',
  'videochat__icon',
  'videochat__text',
];

const propTypes = {
  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const GoToVideochat = ({ theme }) => (
  <span className={theme.videochat}>
    <span className={theme.videochat__icon} />
    <span className={theme.videochat__text}>
      {l('msg.go-to-videochat')}
    </span>
  </span>
);

GoToVideochat.propTypes = propTypes;

export default theming.theme(
  GO_TO_VIDEOCHAT,
  baseCssJson,
  themeApi
)(GoToVideochat);
