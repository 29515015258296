import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { refPropType } from '../../../propTypes/ref';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { ICON__PHOTO } from '../../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'photoIcon_small',
  'photoIcon_big',
];

const propTypes = {
  isSmall: PropTypes.bool,
  innerRef: refPropType,
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const PhotoIcon = React.forwardRef(({
  isSmall = false,
  innerRef = null,
  className = null,
  theme,
  ...rest
}, ref) => (
  <div
    {...rest}
    ref={ref}
    className={classnames(
      isSmall ? theme.photoIcon_small : theme.photoIcon_big,
      className
    )}
  />
));

PhotoIcon.propTypes = propTypes;

export default theming.theme(
  ICON__PHOTO,
  baseCssJson,
  themeApi
)(PhotoIcon);
