export const ACTION_SET_PROFILE_PHOTOS = 'ACTION_SET_PROFILE_PHOTOS';

export const ACTION_LOAD_PROFILE_PHOTOS__REQUEST = 'ACTION_LOAD_PROFILE_PHOTOS__REQUEST';
export const ACTION_LOAD_PROFILE_PHOTOS__SUCCESS = 'ACTION_LOAD_PROFILE_PHOTOS__SUCCESS';
export const ACTION_LOAD_PROFILE_PHOTOS__FAIL = 'ACTION_LOAD_PROFILE_PHOTOS__FAIL';

export const ACTION_ACTIVATE_PROFILE_PHOTO__REQUEST = 'ACTION_ACTIVATE_PROFILE_PHOTO__REQUEST';
export const ACTION_ACTIVATE_PROFILE_PHOTO__SUCCESS = 'ACTION_ACTIVATE_PROFILE_PHOTO__SUCCESS';
export const ACTION_ACTIVATE_PROFILE_PHOTO__FAIL = 'ACTION_ACTIVATE_PROFILE_PHOTO__FAIL';

export const ACTION_CREATE_PROFILE_PHOTO__REQUEST = 'ACTION_CREATE_PROFILE_PHOTO__REQUEST';
export const ACTION_CREATE_PROFILE_PHOTO__SUCCESS = 'ACTION_CREATE_PROFILE_PHOTO__SUCCESS';
export const ACTION_CREATE_PROFILE_PHOTO__FAIL = 'ACTION_CREATE_PROFILE_PHOTO__FAIL';

export const ACTION_DELETE_PROFILE_PHOTO__REQUEST = 'ACTION_DELETE_PROFILE_PHOTO__REQUEST';
export const ACTION_DELETE_PROFILE_PHOTO__SUCCESS = 'ACTION_DELETE_PROFILE_PHOTO__SUCCESS';
export const ACTION_DELETE_PROFILE_PHOTO__FAIL = 'ACTION_DELETE_PROFILE_PHOTO__FAIL';

export const ACTION_UPDATE_PROFILE_PHOTO__REQUEST = 'ACTION_UPDATE_PROFILE_PHOTO__REQUEST';
export const ACTION_UPDATE_PROFILE_PHOTO__SUCCESS = 'ACTION_UPDATE_PROFILE_PHOTO__SUCCESS';
export const ACTION_UPDATE_PROFILE_PHOTO__FAIL = 'ACTION_UPDATE_PROFILE_PHOTO__FAIL';
