import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useQueryParam, useUri } from 'react-redux-app/lib/modules/routing/hooks';

import { url } from 'react-redux-app/lib/modules/routing/utils';

import { EXPIRED_QUERY_PARAM, REFERRER_QUERY_PARAM } from '../constants';
import { MODAL_DIALOG__LOGIN } from '../constants/dialogs';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';

import { actionShowLoginDialog } from '../actions/dialogs/loginDialog';


export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDialogVisible = useSelector(state => isModalVisibleByName(state, MODAL_DIALOG__LOGIN));

  const currentUri = useUri();
  const isExpired = !!useQueryParam(EXPIRED_QUERY_PARAM);
  const referrer = useQueryParam(REFERRER_QUERY_PARAM);

  useEffect(() => {
    if (!isExpired && !referrer) {
      return;
    }

    const newUri = url.removeQueryParams(currentUri, [EXPIRED_QUERY_PARAM, REFERRER_QUERY_PARAM]);
    navigate(newUri, { replace: true });

    if (!isDialogVisible) {
      dispatch(actionShowLoginDialog(
        true,
        referrer,
        isExpired ? 'error.session-expired' : null
      ));
    }
  }, [isExpired, referrer]); // eslint-disable-line react-hooks/exhaustive-deps
};
