export const ACTION_SET_COVER_PHOTOS = 'ACTION_SET_COVER_PHOTOS';

export const ACTION_LOAD_COVER_PHOTOS__REQUEST = 'ACTION_LOAD_COVER_PHOTOS__REQUEST';
export const ACTION_LOAD_COVER_PHOTOS__SUCCESS = 'ACTION_LOAD_COVER_PHOTOS__SUCCESS';
export const ACTION_LOAD_COVER_PHOTOS__FAIL = 'ACTION_LOAD_COVER_PHOTOS__FAIL';

export const ACTION_CREATE_COVER_PHOTO__REQUEST = 'ACTION_CREATE_COVER_PHOTO__REQUEST';
export const ACTION_CREATE_COVER_PHOTO__SUCCESS = 'ACTION_CREATE_COVER_PHOTO__SUCCESS';
export const ACTION_CREATE_COVER_PHOTO__FAIL = 'ACTION_CREATE_COVER_PHOTO__FAIL';

export const ACTION_DELETE_COVER_PHOTO__REQUEST = 'ACTION_DELETE_COVER_PHOTO__REQUEST';
export const ACTION_DELETE_COVER_PHOTO__SUCCESS = 'ACTION_DELETE_COVER_PHOTO__SUCCESS';
export const ACTION_DELETE_COVER_PHOTO__FAIL = 'ACTION_DELETE_COVER_PHOTO__FAIL';

export const ACTION_ACTIVATE_COVER_PHOTO__REQUEST = 'ACTION_ACTIVATE_COVER_PHOTO__REQUEST';
export const ACTION_ACTIVATE_COVER_PHOTO__SUCCESS = 'ACTION_ACTIVATE_COVER_PHOTO__SUCCESS';
export const ACTION_ACTIVATE_COVER_PHOTO__FAIL = 'ACTION_ACTIVATE_COVER_PHOTO__FAIL';
