import {
  ACTION_LOAD_GIFTS__REQUEST,
  ACTION_LOAD_GIFTS__SUCCESS,
  ACTION_LOAD_GIFTS__FAIL,
} from '../actionTypes';

import { GIFTS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadGiftsSuccess = (gifts, order, anonymityPrice) => ({
  type: ACTION_LOAD_GIFTS__SUCCESS,
  payload: { gifts, order, settings: { anonymityPrice } },
});

const actionLoadGiftsFail = error => ({
  type: ACTION_LOAD_GIFTS__FAIL,
  payload: { error },
});

export const actionLoadGiftsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_GIFTS__REQUEST,
    });

    return apiClientHelper.get(
      'gift/available',
      {},
      GIFTS_SCHEMA
    ).then(
      ({ result: { gifts: order, anonymityPrice }, entities }) => {
        dispatch(actionLoadGiftsSuccess(entities.items, order, anonymityPrice));
      },
      error => {
        dispatch(actionLoadGiftsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
