import React from 'react';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import { getFavoritesUrl } from '../../utils/url';

import { getOnlineFavoritesNumber } from '../../../auth/selectors';

import MaterialIconMenuLink from '../MaterialIconMenuLink';


export default props => {
  const number = useShortSelector(getOnlineFavoritesNumber);

  return (
    <MaterialIconMenuLink
      to={getFavoritesUrl()}
      type="favorite"
      titleLangKey="msg.favorite"
      number={number}
      {...props}
    />
  );
};
