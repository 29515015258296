import { ITEMS_SCHEMA } from '../../schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import {
  actionLoadGridDataRequest,
  actionLoadGridDataSuccess,
  actionLoadGridDataFail,
} from 'react-redux-app/lib/modules/grid/actions';


export const getActionLoadGridDataRequest = (
  gridName, getGridFilter, apiPath, actionAddItems, modifyFilter = null, schema = null
) => () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch(actionLoadGridDataRequest(gridName));

    const filter = getGridFilter(location);

    return apiClientHelper.get(
      `${apiPath}/${filter.page}`,
      { params: modifyFilter ? modifyFilter(filter) : filter },
      schema || ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddItems(entities.items));

        dispatch(actionLoadGridDataSuccess(gridName, result.items, result.count, result.summary));
      },
      error => {
        dispatch(actionLoadGridDataFail(gridName, error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
