import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const PRIZE_TYPE__CREDITS_BONUS = 'credits-bonus';
export const PRIZE_TYPE__CREDITS_BONUS_PERCENT_500 = 'credits-bonus-percent-500';
export const PRIZE_TYPE__CREDITS_BONUS_PERCENT_1000 = 'credits-bonus-percent-1000';
export const PRIZE_TYPE__EMOTICON = 'emoticon';
export const PRIZE_TYPE__EMOTICON_DISCOUNT = 'emoticon-discount';
export const PRIZE_TYPE__EMOTICON_2_DISCOUNT = 'emoticon-2-discount'; // fake
export const PRIZE_TYPE__FREE_SCOPO = 'free-scopo';
export const PRIZE_TYPE__FREE_SPIN = 'free-spin';
export const PRIZE_TYPE__GIFT_500_DISCOUNT = 'gift-500-discount';
export const PRIZE_TYPE__GIFT_1000_DISCOUNT = 'gift-1000-discount';
export const PRIZE_TYPE__GIFT_1500_DISCOUNT = 'gift-1500-discount';
export const PRIZE_TYPE__JUMPSCARE = 'jumpscare';
export const PRIZE_TYPE__LINGERIE_DISCOUNT = 'lingerie-discount';
export const PRIZE_TYPE__MISS_SPIN = 'miss-spin';
export const PRIZE_TYPE__SCREAMER = 'screamer';

export const prizeTypes = [
  PRIZE_TYPE__CREDITS_BONUS,
  PRIZE_TYPE__CREDITS_BONUS_PERCENT_500,
  PRIZE_TYPE__CREDITS_BONUS_PERCENT_1000,
  PRIZE_TYPE__EMOTICON,
  PRIZE_TYPE__EMOTICON_DISCOUNT,
  PRIZE_TYPE__FREE_SCOPO,
  PRIZE_TYPE__GIFT_500_DISCOUNT,
  PRIZE_TYPE__GIFT_1000_DISCOUNT,
  PRIZE_TYPE__GIFT_1500_DISCOUNT,
  PRIZE_TYPE__LINGERIE_DISCOUNT,
  PRIZE_TYPE__JUMPSCARE,
  PRIZE_TYPE__SCREAMER,
  PRIZE_TYPE__FREE_SPIN,
  PRIZE_TYPE__MISS_SPIN,
];

export const prizeTypeLangKeys = {
  [PRIZE_TYPE__CREDITS_BONUS]: 'msg.prize-type.credits-bonus',
  [PRIZE_TYPE__CREDITS_BONUS_PERCENT_500]: 'msg.prize-type.credits-bonus-percent-500',
  [PRIZE_TYPE__CREDITS_BONUS_PERCENT_1000]: 'msg.prize-type.credits-bonus-percent-1000',
  [PRIZE_TYPE__EMOTICON]: 'msg.prize-type.emoticon',
  [PRIZE_TYPE__EMOTICON_DISCOUNT]: 'msg.prize-type.emoticon-discount',
  [PRIZE_TYPE__EMOTICON_2_DISCOUNT]: 'msg.prize-type.emoticon-2-discount',
  [PRIZE_TYPE__FREE_SCOPO]: 'msg.prize-type.free-scopo',
  [PRIZE_TYPE__FREE_SPIN]: 'msg.prize-type.free-spin',
  [PRIZE_TYPE__GIFT_500_DISCOUNT]: 'msg.prize-type.gift-500-discount',
  [PRIZE_TYPE__GIFT_1000_DISCOUNT]: 'msg.prize-type.gift-1000-discount',
  [PRIZE_TYPE__GIFT_1500_DISCOUNT]: 'msg.prize-type.gift-1500-discount',
  [PRIZE_TYPE__JUMPSCARE]: 'msg.prize-type.jumpscare',
  [PRIZE_TYPE__LINGERIE_DISCOUNT]: 'msg.prize-type.lingerie-discount',
  [PRIZE_TYPE__MISS_SPIN]: 'msg.prize-type.miss-spin',
  [PRIZE_TYPE__SCREAMER]: 'msg.prize-type.screamer',
};

export const prizeTypeWithoutAmountLangKeys = {
  [PRIZE_TYPE__CREDITS_BONUS]: 'msg.credits-bonus',
  [PRIZE_TYPE__CREDITS_BONUS_PERCENT_500]: 'msg.credits-bonus-percent-500',
  [PRIZE_TYPE__CREDITS_BONUS_PERCENT_1000]: 'msg.credits-bonus-percent-1000',
  [PRIZE_TYPE__EMOTICON]: 'msg.prize-type.emoticon',
  [PRIZE_TYPE__EMOTICON_DISCOUNT]: 'msg.emoticon-discount',
  [PRIZE_TYPE__FREE_SCOPO]: 'msg.spy-minutes',
  [PRIZE_TYPE__FREE_SPIN]: 'msg.prize-type.free-spin',
  [PRIZE_TYPE__GIFT_500_DISCOUNT]: 'msg.gift-500-discount',
  [PRIZE_TYPE__GIFT_1000_DISCOUNT]: 'msg.gift-1000-discount',
  [PRIZE_TYPE__GIFT_1500_DISCOUNT]: 'msg.gift-1500-discount',
  [PRIZE_TYPE__JUMPSCARE]: 'msg.prize-type.jumpscare',
  [PRIZE_TYPE__LINGERIE_DISCOUNT]: 'msg.lingerie-discount',
  [PRIZE_TYPE__MISS_SPIN]: 'msg.prize-type.miss-spin',
  [PRIZE_TYPE__SCREAMER]: 'msg.prize-type.screamer',
};

export const videochatPrizeTypes = [
  PRIZE_TYPE__CREDITS_BONUS,
  PRIZE_TYPE__EMOTICON,
  PRIZE_TYPE__FREE_SCOPO,
  PRIZE_TYPE__GIFT_500_DISCOUNT,
  PRIZE_TYPE__GIFT_1000_DISCOUNT,
  PRIZE_TYPE__GIFT_1500_DISCOUNT,
  PRIZE_TYPE__JUMPSCARE,
  PRIZE_TYPE__SCREAMER,
];
export const depositPrizeTypes = [
  PRIZE_TYPE__CREDITS_BONUS_PERCENT_500,
  PRIZE_TYPE__CREDITS_BONUS_PERCENT_1000,
];
export const emoticonPrizeTypes = [
  PRIZE_TYPE__EMOTICON_DISCOUNT,
  PRIZE_TYPE__EMOTICON_2_DISCOUNT,
];
export const lingeriePrizeTypes = [
  PRIZE_TYPE__LINGERIE_DISCOUNT,
];

export const PRIZE_STATUS__ACTIVE = 'active';
export const PRIZE_STATUS__EXPIRED = 'expired';
export const PRIZE_STATUS__USED = 'used';
export const PRIZE_STATUS__USED_OR_EXPIRED = 'used-or-expired';

export const prizeStatuses = [
  PRIZE_STATUS__ACTIVE,
  PRIZE_STATUS__EXPIRED,
  PRIZE_STATUS__USED,
  PRIZE_STATUS__USED_OR_EXPIRED,
];

export const prizeStatusLangKeys = {
  [PRIZE_STATUS__ACTIVE]: 'msg.status.active',
  [PRIZE_STATUS__EXPIRED]: 'msg.status.expired',
  [PRIZE_STATUS__USED]: 'msg.status.used',
  [PRIZE_STATUS__USED_OR_EXPIRED]: 'msg.status.used-or-expired',
};

export const PRIZES_GRID_NAME = 'PrizesGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'date',
  sortDirection: SORT_DIRECTION__DESC,
  types: prizeTypes,
  statuses: prizeStatuses,
};
