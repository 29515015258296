import {
  ACTION_RESET_EMAIL__REQUEST,
  ACTION_RESET_EMAIL__SUCCESS,
  ACTION_RESET_EMAIL__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_RESET_EMAIL__REQUEST:
    case ACTION_RESET_EMAIL__SUCCESS:
      return null;

    case ACTION_RESET_EMAIL__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
