import { FORM_ERROR } from 'final-form';
import _set from 'lodash/set';


export default ({ globalError, fieldErrors }) => {
  const result = {};

  if (globalError) {
    _set(result, FORM_ERROR, [globalError.message]);
  }

  if (fieldErrors) {
    fieldErrors.forEach(({ name, message }) => {
      _set(result, name, message);
    });
  }

  return result;
};
