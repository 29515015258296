/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCurrentLang, useShowKeys } from 'react-redux-app/lib/modules/i18n/hooks';
import { useQueryParam } from 'react-redux-app/lib/modules/routing/hooks';
import useHidePermissionRequestPanel from '../../../core/pushNotification/hooks/useHidePermissionRequestPanel';
import {
  useHandleAdventPrizes,
  useHandleNotLoggedUser,
  useHandleRoulettePrize,
} from '../../../auth/hooks';
import { useCheckIsMobileDevice } from '../../../initSettings/hooks';

import { getContextByColor } from '../../../../themeContexts';
import { stripLangFromUri } from '../../../core/i18n/utils';
import { getTheme } from '../../../core/theme/utils';
import { get182257Url, getTermsUrl, getPrivacyUrl } from '../../utils/url';

import { THEME_QUERY_PARAM } from '../../../core/theme/constants';
import modalNameToComponentMap from '../../modalNameToComponentMap';

import { getTranslationsForCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getThemeColor } from '../../../initSettings/selectors';
import { getShowAdultWarning } from '../../selectors/adultWarning';

import { actionShowAdultWarningDialog } from '../../actions/dialogs/adultWarningDialog';

import ModalsStack from 'react-redux-app/lib/modules/modal/containers/ModalsStack';
import { ThemeProvider } from 'react-redux-app/lib/modules/core/components';
import PermissionRequestPanel from '../../../core/pushNotification/components/PermissionRequestPanel';
import DepositTrackerAndPaymentResultNotification from '../../../deposit/components/DepositTrackerAndPaymentResultNotification';
import MediaViewer from '../../../mediaViewer/components/MediaViewer';
import ProfileOverlay from '../../../profile/components/ProfileOverlay';
import EventBanner from '../EventBanner';
import Header from '../Header';
import Favicon from '../Favicon';
import Footer from '../Footer';
import Notifications from '../Notifications';
import SeoTags from '../SeoTags';
import SeoUrls from '../SeoUrls';
import WebSocket from '../WebSocket';

import styles from './styles/index.css.json';


export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const messages = useShortSelector(getTranslationsForCurrentLang);
  const showAdultWarning = useShortSelector(getShowAdultWarning);
  const color = useShortSelector(getThemeColor);
  const lang = useCurrentLang();

  const queryColor = useQueryParam(THEME_QUERY_PARAM);

  useCheckIsMobileDevice();
  useShowKeys();
  useHidePermissionRequestPanel();
  useHandleNotLoggedUser();
  useHandleAdventPrizes();
  useHandleRoulettePrize();

  const showWarning = (
    showAdultWarning
    && ![get182257Url(), getTermsUrl(), getPrivacyUrl()].includes(stripLangFromUri(pathname))
  );

  if (showWarning) {
    dispatch(actionShowAdultWarningDialog());
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <IntlProvider locale={lang} messages={messages}>
      <ThemeProvider theme={getContextByColor(getTheme(queryColor, color))}>
        <div id="page" className={styles.page}>
          <Favicon />
          <SeoTags />
          <SeoUrls />
          {!showWarning && (
            <>
              <Header />
              <Notifications className={styles.notifications} />
              <EventBanner />
              <div className="main-container">
                <Outlet />
              </div>
              <Footer />
              <ProfileOverlay />
              <MediaViewer />
              <WebSocket />
              <PermissionRequestPanel />
              <DepositTrackerAndPaymentResultNotification />
            </>
          )}
          <ModalsStack modalNameToComponentMap={modalNameToComponentMap} />
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
};
