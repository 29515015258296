import { takeLatest, put, select } from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';

import { ACTION_HIDE_MODAL } from 'react-redux-app/lib/modules/modal/actionTypes';

import { MODAL_DIALOG__LOGIN } from '../../constants/dialogs';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';
import { getIsAuthenticated } from '../../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const getLoginDialogSaga = (gridName, actionHideDialog) => {
  function* handleCloseLoginDialog(action) {
    try {
      if (
        action.payload.name === MODAL_DIALOG__LOGIN
        && (yield select(isModalVisibleByName, gridName))
        && !(yield select(getIsAuthenticated))
      ) {
        console.consoleLog(`close ${gridName} by action:`, action);

        yield put(actionHideDialog());
      }
    } catch (e) {
      yield put(actionAddException(e));
    }
  }

  return function* saga() {
    yield takeLatest([ACTION_HIDE_MODAL], handleCloseLoginDialog);
  };
};
