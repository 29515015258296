import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_LOAD_INTERVIEWS__REQUEST,
  ACTION_LOAD_INTERVIEWS__SUCCESS,
  ACTION_LOAD_INTERVIEWS__FAIL,
  ACTION_ADD_INTERVIEW_IDS,
  ACTION_SET_INTERVIEW_IDS,
  ACTION_ADD_INTERVIEWS,
  ACTION_SET_INTERVIEWS_PRESERVE,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getInterviewPaginationMode } from '../selectors/interview';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const actionAddInterviews = interviews => ({
  type: ACTION_ADD_INTERVIEWS,
  payload: { interviews },
});

const actionAddInterviewIds = interviews => ({
  type: ACTION_ADD_INTERVIEW_IDS,
  payload: { interviews },
});

const actionSetInterviewIds = interviews => ({
  type: ACTION_SET_INTERVIEW_IDS,
  payload: { interviews },
});

const actionLoadInterviewsSuccess = () => ({
  type: ACTION_LOAD_INTERVIEWS__SUCCESS,
});

const actionLoadInterviewsFail = error => ({
  type: ACTION_LOAD_INTERVIEWS__FAIL,
  payload: { error },
});

export const actionSetInterviewsPreserve = () => ({
  type: ACTION_SET_INTERVIEWS_PRESERVE,
});

export const actionLoadInterviewsRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getInterviewPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_INTERVIEWS__REQUEST,
    });

    const { page = 1, nick, limit = 28 } = getFilter(location);

    const params = { limit };
    if (nick) {
      params.nick = nick;
    }

    return apiClientHelper.get(
      `interview/list/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result: interviews, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddInterviews(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetInterviewIds(interviews));

          return dispatch(actionLoadInterviewsSuccess());
        }

        // Preload model photo thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].model.thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddInterviewIds(interviews));
          } else {
            dispatch(actionSetInterviewIds(interviews));
          }

          dispatch(actionLoadInterviewsSuccess());
        });
      },
      error => {
        dispatch(actionLoadInterviewsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
