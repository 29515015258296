import { combineReducers } from 'redux';

import loadLatestPrivatePhotosError from './loadLatestPrivatePhotosError';
import loadLatestPublicPhotosError from './loadLatestPublicPhotosError';
import loadLatestVideosError from './loadLatestVideosError';
import loadLatestErostoriesError from './loadLatestErostoriesError';
import loadLatestUnderwearError from './loadLatestUnderwearError';


export default combineReducers({
  loadLatestPrivatePhotosError,
  loadLatestPublicPhotosError,
  loadLatestVideosError,
  loadLatestErostoriesError,
  loadLatestUnderwearError,
});
