import { MODAL_DIALOG__GET_CREDITS } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowGetCreditsDialog = (error, welcomeBonus, cryptoBonus) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__GET_CREDITS, { error, welcomeBonus, cryptoBonus }))
);

export const actionHideGetCreditsDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__GET_CREDITS))
);
