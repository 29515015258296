import { url } from 'react-redux-app/lib/modules/routing/utils';

import { MODEL_QUERY_PARAM } from '../constants';

import { getModelId } from '../selectors';


export const getUrlWithModelId = (path, location, getState = null) => {
  const modelId = url.parseQuery(location.search)[MODEL_QUERY_PARAM] || getModelId(getState?.());

  return `${path}${modelId ? `/${modelId}` : ''}`;
};
