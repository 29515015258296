import {
  ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__REQUEST,
  ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__SUCCESS,
  ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__FAIL,
} from '../actionTypes';


const actionSubscribeToPushNotificationsSuccess = () => ({
  type: ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__SUCCESS,
});

const actionSubscribeToPushNotificationsFail = error => ({
  type: ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__FAIL,
  payload: { error },
});

export const actionSubscribeToPushNotificationsRequest = subscriptionInfo => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_SUBSCRIBE_TO_PUSH_NOTIFICATIONS__REQUEST,
    });

    return apiClientHelper.post(
      'push_notification/subscription',
      { data: subscriptionInfo }
    ).then(
      () => {
        dispatch(actionSubscribeToPushNotificationsSuccess());
      },
      error => {
        dispatch(actionSubscribeToPushNotificationsFail(error));
      }
    );
  }
);
