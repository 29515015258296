import { removeBlankFields } from 'react-redux-app/lib/modules/core/utils';

import {
  ACTION_LOAD_DEPOSIT_SETTINGS__REQUEST,
  ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS,
  ACTION_LOAD_DEPOSIT_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadDepositSettingsSuccess = settings => ({
  type: ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS,
  payload: { settings },
});

const actionLoadDepositSettingsFail = error => ({
  type: ACTION_LOAD_DEPOSIT_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadDepositSettingsRequest = (
  discountCode = null, welcomeBonus = null, cryptoBonus = null
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_DEPOSIT_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'deposit/init',
      { params: removeBlankFields({ discountCode, welcomeBonus, cryptoBonus }) }
    ).then(
      settings => {
        dispatch(actionLoadDepositSettingsSuccess(settings));
      },
      error => {
        dispatch(actionLoadDepositSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
