import _get from 'lodash/get';


const permissionRequestPanelSelector = state => (
  _get(state, 'pushNotification.permissionRequestPanel')
);

export const getIsPermissionRequestPanelVisible = state => (
  _get(permissionRequestPanelSelector(state), 'isVisible')
);

export const getIsPermissionRequestPanelSuppressed = state => (
  _get(permissionRequestPanelSelector(state), 'isSuppressed')
);
