import { MODAL_DIALOG__BUY_SALE_ITEM } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowBuySaleItemDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__BUY_SALE_ITEM))
);

export const actionHideBuySaleItemDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__BUY_SALE_ITEM))
);
