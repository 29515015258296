import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { getLoadZendeskLoginLinkError } from '../../selectors/errors';

import { actionLoadZendeskLoginLinkRequest } from '../../actions/loadZendeskLoginLink';
import { actionShowFillNameDialog } from '../../actions/dialogs/fillNameDialog';

import { ActionLink } from 'react-redux-app/lib/modules/actionLink/components';
import { Button } from 'react-redux-app/lib/modules/button/components';


const propTypes = {
  isButton: PropTypes.bool,
  isLong: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const Zendesk = ({
  isButton = false,
  isLong = false,
  onClick = null,
  className = null,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [isBeingLoaded, setIsBeingLoaded] = useState(false);

  const callActionLoadZendeskLoginLinkRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadZendeskLoginLinkError)
  );
  const loadZendeskLoginLink = () => {
    setIsBeingLoaded(true);

    callActionLoadZendeskLoginLinkRequest(
      dispatch(actionLoadZendeskLoginLinkRequest(true)),
      'header.zendesk-login-link.load',
      null,
      () => { dispatch(actionShowFillNameDialog()); },
      false,
      () => {
        setIsBeingLoaded(false);
        onClick?.();
      },
      []
    );
  };

  let Component;
  const props = { ...rest, onClick: loadZendeskLoginLink, className };
  if (isButton) {
    Component = Button;
    props.isSpinnerEnabled = isBeingLoaded;
  } else {
    Component = ActionLink;
    props.isDisabled = isBeingLoaded;
  }

  return (
    <Component {...props}>
      {l(isLong ? 'action.open-support-center' : 'msg.help')}
    </Component>
  );
};

Zendesk.propTypes = propTypes;

export default Zendesk;
