import { schema } from 'normalizr';


export const UNDERWEAR_REQUEST_SCHEMA = new schema.Entity(
  'items',
  {},
  { idAttribute: 'saleId' }
);

export const UNDERWEAR_REQUESTS_SCHEMA = {
  items: [UNDERWEAR_REQUEST_SCHEMA],
};
