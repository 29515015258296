import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'blog.errors');
export const getBuyPostError = state => (
  _get(errorsSelector(state), 'buyPostError')
);
export const getLikeMediaError = state => (
  _get(errorsSelector(state), 'likeMediaError')
);
export const getLikePostError = state => (
  _get(errorsSelector(state), 'likePostError')
);
export const getLoadBlogError = state => (
  _get(errorsSelector(state), 'loadBlogError')
);
export const getLoadPostCommentsError = state => (
  _get(errorsSelector(state), 'loadPostCommentsError')
);
export const getLoadPostsError = state => (
  _get(errorsSelector(state), 'loadPostsError')
);
export const getSubscribeError = state => (
  _get(errorsSelector(state), 'subscribe')
);
export const getUnlikeMediaError = state => (
  _get(errorsSelector(state), 'unlikeMediaError')
);
export const getUnlikePostError = state => (
  _get(errorsSelector(state), 'unlikePostError')
);
export const getUnsubscribeError = state => (
  _get(errorsSelector(state), 'unsubscribe')
);
