import { useCurrentLang } from 'react-redux-app/lib/modules/i18n/hooks';
import { useIsForBoys } from '../../initSettings/hooks';

import { getPathnamePrefix } from '../utils';


export default () => {
  const lang = useCurrentLang();
  const isForBoys = useIsForBoys();

  return getPathnamePrefix(isForBoys, lang);
};
