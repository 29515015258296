import {
  ACTION_ADD_OWN_SALE_ITEMS,
  ACTION_ADD_OWN_SALE_ITEM,
  ACTION_DELETE_OWN_SALE_ITEM,
} from '../actionTypes';


export const actionAddOwnSaleItems = items => ({
  type: ACTION_ADD_OWN_SALE_ITEMS,
  payload: { items },
});

export const actionAddOwnSaleItem = item => ({
  type: ACTION_ADD_OWN_SALE_ITEM,
  payload: { item },
});

export const actionDeleteOwnSaleItem = itemId => ({
  type: ACTION_DELETE_OWN_SALE_ITEM,
  payload: { itemId },
});
