import { MODAL_DIALOG__LOGIN } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowLoginDialog = (
  canBeClosed = true,
  redirectUrl = null,
  error = null
) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__LOGIN, {
    canBeClosed,
    redirectUrl,
    error,
  }))
);

export const actionHideLoginDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__LOGIN))
);
