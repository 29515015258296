export default str => {
  const arr = str.slice(6).split('_');

  switch (arr[0]) {
    case 'to':
      return `${arr[1]}`;

    case 'from':
      return `${arr[1]}+`;

    default:
      return `${arr[0]} - ${arr[1]}`;
  }
};
