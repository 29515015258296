import {
  ACTION_ADD_GEO_FILTER__REQUEST,
  ACTION_ADD_GEO_FILTER__SUCCESS,
  ACTION_ADD_GEO_FILTER__FAIL,
  ACTION_DELETE_GEO_FILTER__REQUEST,
  ACTION_DELETE_GEO_FILTER__SUCCESS,
  ACTION_DELETE_GEO_FILTER__FAIL,
} from '../../actionTypes';

import { GEO_FILTERS_GRID_NAME } from '../../constants/geoFilter';

import { actionLoadGeoFiltersRequest } from '../../actions/geoFiltersGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  GEO_FILTERS_GRID_NAME,
  actionLoadGeoFiltersRequest,
  [ACTION_ADD_GEO_FILTER__REQUEST, ACTION_DELETE_GEO_FILTER__REQUEST],
  [ACTION_ADD_GEO_FILTER__FAIL, ACTION_DELETE_GEO_FILTER__FAIL],
  [ACTION_ADD_GEO_FILTER__SUCCESS, ACTION_DELETE_GEO_FILTER__SUCCESS]
);
