import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';

import { getTotalPagesCount } from '../../core/utils/selectors';
import { getTabs } from '../../core/tabs/utils';

import {
  MODEL_TAB__AWARDS,
  MODEL_TAB__EROSTORIES,
  MODEL_TAB__FEEDBACKS,
  MODEL_TAB__GIFTS,
  MODEL_TAB__INTERVIEWS,
  MODEL_TAB__NEWS_FEED,
  MODEL_TAB__PERSONAL_CONTACTS,
  MODEL_TAB__PRIVATE_PHOTOS,
  MODEL_TAB__PROFILE,
  MODEL_TAB__PUBLIC_PHOTOS,
  MODEL_TAB__UNDERWEAR,
  MODEL_TAB__VIDEOS,
} from '../constants/tabs';

import { getIsUnderwearForbidden } from '../../auth/selectors';
import { getSexPreferences } from '../../initSettings/selectors';


export const getModel = state => _get(state, 'model.data');
export const getModelId = state => _get(getModel(state), 'id');
export const getModelNick = state => _get(getModel(state), 'nick');
export const getModelThumbUrl = state => _get(getModel(state), 'thumbUrl');
export const getModelDescription = state => _get(getModel(state), 'description');
export const getModelSexPreferences = createSelector(
  getModel,
  getSexPreferences,
  (model, sexPreferences) => _intersection(_get(model, 'sexPreferences'), sexPreferences)
);
export const getModelRating = state => _get(getModel(state), 'rating');
export const getModelCanVote = state => _get(getModel(state), 'canVote');

const getModelSaleItems = (state, type) => _get(getModel(state), type);
const getModelSaleItemIds = (state, type) => (
  _get(getModelSaleItems(state, type), 'items')
);
const getModelSaleItemsLoadedCount = (state, type) => (
  _get(getModelSaleItemIds(state, type), 'length')
);
const getModelSaleItemsTotalCount = (state, type) => (
  _get(getModelSaleItems(state, type), 'count')
);
const getModelSaleItemsLimit = (state, type) => (
  _get(getModelSaleItems(state, type), 'limit')
);
const isAllModelSaleItemsLoaded = (state, type) => (
  getModelSaleItemsLoadedCount(state, type) === getModelSaleItemsTotalCount(state, type)
);
const getModelSaleItemTotalPagesCount = (state, type) => getTotalPagesCount(
  state,
  stateArg => getModelSaleItemsTotalCount(stateArg, type),
  stateArg => getModelSaleItemsLimit(stateArg, type)
);

export const getModelPrivatePhotoIds = state => (
  getModelSaleItemIds(state, 'privatePhotos')
);
export const getAreAllModelPrivatePhotosLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'privatePhotos')
);
export const getModelPrivatePhotoTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'privatePhotos')
);

export const getModelPublicPhotoIds = state => (
  getModelSaleItemIds(state, 'publicPhotos')
);
export const getAreAllModelPublicPhotosLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'publicPhotos')
);
export const getModelPublicPhotoTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'publicPhotos')
);

export const getModelVideoIds = state => (
  getModelSaleItemIds(state, 'videos')
);
export const getAreAllModelVideosLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'videos')
);
export const getModelVideoTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'videos')
);

export const getModelErostoryIds = state => (
  getModelSaleItemIds(state, 'stories')
);
export const getAreAllModelErostoriesLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'stories')
);
export const getModelErostoryTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'stories')
);

export const getModelGifts = state => (
  getModelSaleItemIds(state, 'gifts')
);
export const getAreAllModelGiftsLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'gifts')
);
export const getModelGiftTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'gifts')
);

export const getModelFeedbacks = state => (
  getModelSaleItemIds(state, 'feedbacks')
);
export const getAreAllModelFeedbacksLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'feedbacks')
);
export const getModelFeedbackTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'feedbacks')
);

export const getModelNewsFeedIds = state => (
  getModelSaleItemIds(state, 'newsFeed')
);
export const getAreAllModelNewsFeedLoaded = state => (
  isAllModelSaleItemsLoaded(state, 'newsFeed')
);
export const getModelNewsFeedTotalPagesCount = state => (
  getModelSaleItemTotalPagesCount(state, 'newsFeed')
);
export const getShouldReloadNewsFeedActivePage = state => (
  _get(getModelSaleItems(state, 'newsFeed'), 'shouldReloadActivePage')
);

export const getModelAwards = state => (
  _get(getModel(state), 'awards')
);
export const getModelUnderwearIds = state => (
  _get(getModel(state), 'underwear')
);
export const getModelInterviewIds = state => (
  _get(getModel(state), 'interviews')
);

export const getModelPersonalContacts = state => (
  _get(getModel(state), 'personalContacts')
);
const modelPersonalContactSelector = state => (
  _get(state, 'model.personalContact')
);
export const getModelPersonalContactType = state => (
  _get(modelPersonalContactSelector(state), 'type')
);
export const getModelPersonalContactData = state => (
  _get(modelPersonalContactSelector(state), 'data')
);
export const getModelPersonalContactIsAvailable = state => (
  _get(modelPersonalContactSelector(state), 'isAvailable')
);

export const getModelTabs = createSelector(
  getModelPublicPhotoIds,
  getModelPrivatePhotoIds,
  getModelVideoIds,
  getModelErostoryIds,
  getIsUnderwearForbidden,
  getModelUnderwearIds,
  getModelGifts,
  getModelInterviewIds,
  getModelFeedbacks,
  getModelAwards,
  getModelPersonalContacts,
  getModelNewsFeedIds,
  (
    modelPublicPhotoIds,
    modelPrivatePhotoIds,
    modelVideoIds,
    modelErostoryIds,
    isUnderwearForbidden,
    modelUnderwearIds,
    modelGifts,
    modelInterviewIds,
    modelFeedbacks,
    modelAwards,
    modelPersonalContacts,
    modelNewsFeedIds
  ) => getTabs({
    [MODEL_TAB__PUBLIC_PHOTOS]: modelPublicPhotoIds.length > 0,
    [MODEL_TAB__PRIVATE_PHOTOS]: modelPrivatePhotoIds.length > 0,
    [MODEL_TAB__VIDEOS]: modelVideoIds.length > 0,
    [MODEL_TAB__EROSTORIES]: modelErostoryIds.length > 0,
    [MODEL_TAB__UNDERWEAR]: !isUnderwearForbidden && modelUnderwearIds.length > 0,
    [MODEL_TAB__GIFTS]: modelGifts.length > 0,
    [MODEL_TAB__INTERVIEWS]: modelInterviewIds.length > 0,
    [MODEL_TAB__FEEDBACKS]: modelFeedbacks.length > 0,
    [MODEL_TAB__AWARDS]: modelAwards.length > 0,
    [MODEL_TAB__PERSONAL_CONTACTS]: modelPersonalContacts.length > 0,
    [MODEL_TAB__NEWS_FEED]: modelNewsFeedIds.length > 0,
    [MODEL_TAB__PROFILE]: true,
  })
);
