import { createSelector } from 'reselect';
import _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';

import { stripLangFromUri } from '../../core/i18n/utils';
import { modifyHelpCamonLiveUrl } from '../utils';

import { THEME__GRAY } from '../../core/theme/constants';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';


const initSettingsDataSelector = state => _get(state, 'initSettings.data');
export const getInitSettings = (state, name) => _get(initSettingsDataSelector(state), name);

export const getStaffUserId = state => getInitSettings(state, 'staffUserId');
export const getBanStaffUserId = state => getInitSettings(state, 'banStaffUserId');
export const getReCaptchaKey = state => getInitSettings(state, 'reCaptchaSiteKey');
export const getBaseHost = state => getInitSettings(state, 'baseHost');
export const getBaseHostWithProtocol = state => getInitSettings(state, 'baseHostWithProtocol');
export const getFavicons = state => getInitSettings(state, 'favicons');
export const getLangToLogo = state => getInitSettings(state, 'langToLogo');
export const getUILangs = state => getInitSettings(state, 'uiLangs');
export const getSocialNetworkLinks = state => getInitSettings(state, 'socialNetworkLinks');
export const getGoogleAnalytics = state => getInitSettings(state, 'googleAnalytics');
export const getGoogleAnalyticsPurchaseEventCodeTemplate = state => (
  getInitSettings(state, 'googleAnalyticsPurchaseEventCodeTemplate')
);
export const getGoogleTagManagerId = state => getInitSettings(state, 'googleTagManagerId');
export const getMetaPixelAnalytics = state => getInitSettings(state, 'metaPixelAnalytics');
export const getTrafficJunkyCodeTemplate = state => (
  getInitSettings(state, 'trafficJunkyCodeTemplate')
);
export const getZendeskWidget = state => getInitSettings(state, 'zendeskWidget');
export const getWebSocket = state => getInitSettings(state, 'webSocket');
export const getAvailableSocialTypes = state => (
  getInitSettings(state, 'socialNetworkAvailableTypes')
);
export const getIsForBoys = state => getInitSettings(state, 'isForBoys');
export const getRivstreamerFileUrl = state => getInitSettings(state, 'rivstreamerFileUrl');
export const getThemeColor = state => getInitSettings(state, 'themeColor') || THEME__GRAY;
export const getChatSettings = state => getInitSettings(state, 'chat');
export const getChatPreviewSettings = state => getInitSettings(state, 'thumbedVideo');
export const getChatGalleryFilters = state => getInitSettings(state, 'chatGalleryFilters');
export const getCountries = state => getInitSettings(state, 'countries');
export const getRegistrationCountries = state => getInitSettings(state, 'registrationCountries');
export const getIsPrivateContentForbidden = state => (
  getInitSettings(state, 'isPrivateContentForbidden')
);
export const getIsVideoSite = state => getInitSettings(state, 'isVideoSite');
export const getIsRivcam = state => getInitSettings(state, 'isRivcam');
export const getIsModelHub = state => getInitSettings(state, 'isModelHub');
export const getDomains = state => getInitSettings(state, 'domains');
export const getAppointmentCancelReasons = state => (
  getInitSettings(state, 'appointmentCancelReasons')
);
export const getWebPushPublicKey = state => getInitSettings(state, 'webPushPublicKey');
export const getIsMobileDevice = state => getInitSettings(state, 'isMobileDevice');
export const getSexPreferences = state => getInitSettings(state, 'sexPreferences');
export const getSpokenLanguages = state => getInitSettings(state, 'spokenLangs');
export const getDefaultLang = state => getInitSettings(state, 'defaultLang');
export const getIsBot = state => getInitSettings(state, 'isBot');
export const getPostMediaMaxNumber = state => (
  getInitSettings(state, 'postSettings.maxItemsNumber')
);
export const getEventBannerUrl = state => getInitSettings(state, 'siteEventBannerUrl');
export const getEventRoulettePrice = state => getInitSettings(state, 'siteEventRoulettePrice');
export const getSiteEvent = state => ({ event: 'San Valentino', credits: 10 });
export const getWeeklyEvent = state => getInitSettings(state, 'weeklyEvent');
export const getWelcomeBonus = state => getInitSettings(state, 'welcomeBonus');
export const getCryptoBonus = state => getInitSettings(state, 'cryptoBonus');
export const getAdventPrizes = state => getInitSettings(state, 'adventPrizes');
export const getAdventInstagramLink = state => getInitSettings(state, 'adventInstagramLink');
export const getSiteEvents = state => getInitSettings(state, 'siteEvents');
export const getEmoticonShopEnabled = state => getInitSettings(state, 'emoticonShopEnabled');
export const getChatCreditsThreshold = state => getInitSettings(state, 'chatCreditsThreshold');
export const getStepsToFieldErrors = state => getInitSettings(state, 'stepsToFieldErrors');

export const getSeoForCurrentLang = state => (
  getInitSettings(state, `seo.${getCurrentLang(state)}`)
);
export const getFrontendToSeoPathMap = state => (
  _get(getSeoForCurrentLang(state), 'frontendToSeoPathMap')
);

export const getUrls = state => getInitSettings(state, 'urls');
export const getNetworkUrls = state => _get(getUrls(state), `network.${getCurrentLang(state)}`);

const landingPagesSelector = state => (
  getInitSettings(state, `landingPages.${getCurrentLang(state)}`)
);
export const getHeaderLandingPages = state => _get(landingPagesSelector(state), 'header');
export const getFooterLandingPages = state => _get(landingPagesSelector(state), 'footer');

const headerBannerSelector = state => getInitSettings(state, 'headerBanner');
export const getHeaderBanner = state => (
  _get(headerBannerSelector(state), `banner.${getCurrentLang(state)}`)
);
export const getHeaderBannerModels = state => _get(headerBannerSelector(state), 'models');

const webcamDefaultsSelector = state => getInitSettings(state, 'webcamSettings');
export const getWebcamMinCost = state => _get(webcamDefaultsSelector(state), 'webcamCostMin');
export const getWebcamMaxCost = state => _get(webcamDefaultsSelector(state), 'webcamCostMax');
export const getWebcamSpyingMinCost = state => _get(webcamDefaultsSelector(state), 'scopoCostMin');
export const getWebcamSpyingMaxCost = state => _get(webcamDefaultsSelector(state), 'scopoCostMax');

const stepSettingsSelector = state => getInitSettings(state, 'registrationStepsSettings');
export const getAvailableDocumentTypes = state => (
  _get(stepSettingsSelector(state), 'availableDocumentTypes')
);
export const getFaceshotMaxSizeBytes = state => (
  _get(stepSettingsSelector(state), 'faceshotMaxSizeBytes')
);
export const getIdScanMaxSizeBytes = state => (
  _get(stepSettingsSelector(state), 'idScanMaxSizeBytes')
);
export const getIdScanMinSizeBytes = state => (
  _get(stepSettingsSelector(state), 'idScanMinSizeBytes')
);
export const getPartnerIdAllowedExtensions = state => (
  _get(stepSettingsSelector(state), 'partnerIdAllowedExtensions')
);
export const getPublicPhotoMaxSizeBytes = state => (
  _get(stepSettingsSelector(state), 'publicPhotoMaxSizeBytes')
);

const videoSettingsSelector = state => getInitSettings(state, 'videoSettings');
export const getVideoFileSizeMax = state => _get(videoSettingsSelector(state), 'fileSizeMax');
export const getVideoAllowedExtensions = state => (
  _get(videoSettingsSelector(state), 'allowedExtensions')
);
export const getVideoMinCost = state => _get(videoSettingsSelector(state), 'costMin');
export const getVideoMaxCost = state => _get(videoSettingsSelector(state), 'costMax');
export const getVideoCategories = state => _get(videoSettingsSelector(state), 'categories');
export const getVideoEditExpirePeriod = state => (
  _get(videoSettingsSelector(state), 'editExpirePeriodInDays')
);
export const getPresentationsMaxNumber = state => (
  _get(videoSettingsSelector(state), 'presentationsMaxNumber')
);

const photoSettingsSelector = state => getInitSettings(state, 'photoSettings');
export const getPhotoFileSizeMax = state => _get(photoSettingsSelector(state), 'fileSizeMax');
export const getPhotoAllowedExtensions = state => (
  _get(photoSettingsSelector(state), 'allowedExtensions')
);
export const getProfilePhotoMustMakeDeposit = state => (
  _get(photoSettingsSelector(state), 'profile.mustMakeDeposit')
);
export const getProfilePhotoMaxNumber = state => (
  _get(photoSettingsSelector(state), 'profile.maxNumber')
);
export const getProfilePhotoMinWidth = state => (
  _get(photoSettingsSelector(state), 'profile.widthMin')
);
export const getProfilePhotoMinHeight = state => (
  _get(photoSettingsSelector(state), 'profile.heightMin')
);
export const getCoverPhotoMaxNumber = state => (
  _get(photoSettingsSelector(state), 'cover.maxNumber')
);
export const getCoverPhotoMinWidth = state => (
  _get(photoSettingsSelector(state), 'cover.widthMin')
);
export const getCoverPhotoMinHeight = state => (
  _get(photoSettingsSelector(state), 'cover.heightMin')
);
export const getPublicPhotoMinWidth = state => (
  _get(photoSettingsSelector(state), 'public.widthMin')
);
export const getPublicPhotoMinHeight = state => (
  _get(photoSettingsSelector(state), 'public.heightMin')
);
export const getPrivatePhotoMinWidth = state => (
  _get(photoSettingsSelector(state), 'private.widthMin')
);
export const getPrivatePhotoMinHeight = state => (
  _get(photoSettingsSelector(state), 'private.heightMin')
);
export const getPrivatePhotoMinCost = state => (
  _get(photoSettingsSelector(state), 'private.costMin')
);
export const getPrivatePhotoMaxCost = state => (
  _get(photoSettingsSelector(state), 'private.costMax')
);
export const getPrivatePhotoCategories = state => (
  _get(photoSettingsSelector(state), 'private.categories')
);
export const getUnderwearMinWidth = state => (
  _get(photoSettingsSelector(state), 'underwear.widthMin')
);
export const getUnderwearMinHeight = state => (
  _get(photoSettingsSelector(state), 'underwear.heightMin')
);
export const getUnderwearMinCost = state => (
  _get(photoSettingsSelector(state), 'underwear.costMin')
);
export const getUnderwearMaxCost = state => (
  _get(photoSettingsSelector(state), 'underwear.costMax')
);
export const getPostPhotoMinWidth = state => (
  _get(photoSettingsSelector(state), 'post.widthMin')
);
export const getPostPhotoMinHeight = state => (
  _get(photoSettingsSelector(state), 'post.heightMin')
);

const storySettingsSelector = state => getInitSettings(state, 'storySettings');
export const getStoryFileSizeMax = state => _get(storySettingsSelector(state), 'fileSizeMax');
export const getStoryFileSizeMin = state => _get(storySettingsSelector(state), 'fileSizeMin');
export const getStoryAllowedExtensions = state => (
  _get(storySettingsSelector(state), 'allowedExtensions')
);
export const getStoryMinCost = state => _get(storySettingsSelector(state), 'costMin');
export const getStoryMaxCost = state => _get(storySettingsSelector(state), 'costMax');
export const getStoryCategories = state => _get(storySettingsSelector(state), 'categories');

const siteFeedbackSettingsSelector = state => getInitSettings(state, 'siteFeedback');
export const getSubjectIds = state => _get(siteFeedbackSettingsSelector(state), 'subjectIds');
export const getIsAllowed = state => _get(siteFeedbackSettingsSelector(state), 'isAllowed');

const documentUploadSettingsSelector = state => getInitSettings(state, 'documentUploadSettings');
export const getDocumentMaxSizeBytes = state => (
  _get(documentUploadSettingsSelector(state), 'maxSizeBytes')
);
export const getDocumentMinSizeBytes = state => (
  _get(documentUploadSettingsSelector(state), 'minSizeBytes')
);

export const getLocalHourShift = createSelector(
  state => getInitSettings(state, 'serverTimeZone'),
  serverTimeZone => ((-1) * serverTimeZone - new Date().getTimezoneOffset() / 60)
);

export const getSalePercents = createSelector(
  state => getInitSettings(state, 'salePercents'),
  salePercents => {
    const percents = {};
    Object.keys(salePercents).forEach(saleType => {
      percents[_camelCase(`percent ${saleType.replace('-', ' ')}`)] = salePercents[saleType];
    });
    return percents;
  }
);

const requisitesSelector = state => getInitSettings(state, 'requisites');
export const getSiteName = state => _get(requisitesSelector(state), 'siteName');
export const getCompanyName = state => _get(requisitesSelector(state), 'companyName');
export const getVat = state => _get(requisitesSelector(state), 'VAT');
export const getAddress = state => _get(requisitesSelector(state), 'address');
export const getRegistrationNumber = state => (
  _get(requisitesSelector(state), 'registrationNumber')
);
export const getSupportEmail = state => _get(requisitesSelector(state), 'supportEmail');
export const getSupportPhoneNumber = state => _get(requisitesSelector(state), 'supportPhoneNumber');
export const getPayeerCompanyName = state => _get(requisitesSelector(state), 'payeerCompanyName');
export const getPayeerAddress = state => _get(requisitesSelector(state), 'payeerAddress');
const getSupportUrlWithoutModification = state => _get(requisitesSelector(state), 'supportUrl');
export const getSupportUrl = state => modifyHelpCamonLiveUrl(
  getSupportUrlWithoutModification(state), getCurrentLang(state)
);
export const getSupportDomain = state => (
  getSupportUrlWithoutModification(state).replace('https://', '')
);

const wlSettingsSelector = state => getInitSettings(state, 'wlSettings');
export const getIsWhitelabel = state => _get(wlSettingsSelector(state), 'isWhitelabel');

const wlContentSelector = state => _get(wlSettingsSelector(state), 'settings');
export const getRcid = state => _get(wlContentSelector(state), 'rcid');
export const getStaticPageIdWithLang = (state, location) => {
  const urlToIdWithLang = _get(wlContentSelector(state), 'staticPagesUrlToIdWithLang');
  return urlToIdWithLang && urlToIdWithLang[stripLangFromUri(location.pathname).slice(1)];
};
const staticPageSelector = (state, id) => _get(wlContentSelector(state), `staticPages.${id}`);
export const getStaticPage = (state, id, lang) => _get(staticPageSelector(state, id), lang);
export const getStaticPageByLocation = (state, location, forCurrentLang = true) => {
  const idWithLang = getStaticPageIdWithLang(state, location);
  if (!idWithLang) {
    return null;
  }
  return forCurrentLang
    ? getStaticPage(state, idWithLang.id, getCurrentLang(state))
    : staticPageSelector(state, idWithLang.id);
};
const linksAndCategoriesSelector = state => _get(wlContentSelector(state), 'linksAndCategories');
export const getCategoriesForLang = state => (
  _get(linksAndCategoriesSelector(state), `categories.${getCurrentLang(state)}`)
);
export const getLinksForLang = state => (
  _get(linksAndCategoriesSelector(state), `links.${getCurrentLang(state)}`)
);

export const getArticles = state => _get(wlContentSelector(state), 'articles');
export const getArticle = (state, id) => (
  getArticles(state).find(article => article.id === parseInt(id, 10))
);

const linksExchangeSelector = state => _get(wlContentSelector(state), 'linksExchange');
export const getBanners = state => _get(linksExchangeSelector(state), 'banners');
export const getLinks = state => _get(linksExchangeSelector(state), 'links');
export const getDoesLinksExchangeExist = state => (
  linksExchangeSelector(state) && (getBanners(state).length > 0 || getLinks(state).length > 0)
);
