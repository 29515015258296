import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_LOAD_GIFTS__SUCCESS,
  ACTION_SELECT_GIFT,
  ACTION_SET_IS_GIFT_SENDING_CONFIRMED,
} from '../actionTypes';


const byId = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.gifts;

    default:
      return state;
  }
};

const order = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.order;

    default:
      return state;
  }
};

const settings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.settings;

    default:
      return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case ACTION_SELECT_GIFT:
      return action.payload.giftId;

    case ACTION_LOAD_GIFTS__SUCCESS:
      return action.payload.order[0];

    default:
      return state;
  }
};

const isSendingConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_GIFT_SENDING_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  order,
  settings,
  selected,
  isSendingConfirmed,
  errors,
});
