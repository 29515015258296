import _get from 'lodash/get';


export const getModels = state => _get(state, 'agency.byId');
export const getModel = (state, id) => _get(getModels(state), id);
export const getIsModelStatusBeingChanged = (state, modelId) => (
  !!_get(state, `agency.isModelStatusBeingChanged.${modelId}`)
);

export const getAgencyModel = state => _get(state, 'agency.agencyModel');
export const getAgencyModels = state => _get(state, 'agency.agencyModels');
export const getPhotoModifications = state => _get(state, 'agency.photoModifications');

const modelRegistrationStepsSelector = state => _get(state, 'agency.modelRegistrationSteps');
export const getModelId = state => (
  _get(modelRegistrationStepsSelector(state), 'id')
);
export const getModelPersonalInfo = state => (
  _get(modelRegistrationStepsSelector(state), 'personalInfo')
);
export const getModelProfilePhoto = state => (
  _get(modelRegistrationStepsSelector(state), 'profilePhoto')
);
export const getModelRegistrationSteps = state => (
  _get(modelRegistrationStepsSelector(state), 'steps')
);
