import { MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowBuySimpleSaleItemDialog = (id, cost) => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM, { id, cost }))
);

export const actionHideBuySimpleSaleItemDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM))
);
