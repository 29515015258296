/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useCallCancellablePromiseAndHandleErrorOrExecute } from '../../../core/api/hooks';
import { useIsAuthenticated, useIsModel } from '../../hooks';

import { rouletteImagePath } from '../../constants/roulette';

import { getEventRoulettePrice } from '../../../initSettings/selectors';
import { getLoadSpinPriceError } from '../../selectors/errors';

import { actionLoadSpinPriceRequest } from '../../actions/loadSpinPrice';
import { actionShowPaidRouletteDialog } from '../../actions/dialogs/paidRouletteDialog';

import styles from './styles/index.css.json';


const propTypes = {
  className: PropTypes.string.isRequired,
};

const PaidRouletteLink = ({ className }) => {
  const dispatch = useDispatch();

  const roulettePrice = useShortSelector(getEventRoulettePrice);
  const isAuthenticated = useIsAuthenticated();
  const isModel = useIsModel();

  const showDialog = () => { dispatch(actionShowPaidRouletteDialog()); };

  const callActionLoadSpinPriceRequest = (
    useCallCancellablePromiseAndHandleErrorOrExecute(getLoadSpinPriceError)
  );
  const showRoulette = () => {
    if (isAuthenticated) {
      callActionLoadSpinPriceRequest(
        dispatch(actionLoadSpinPriceRequest()),
        'header.spin-price.load',
        showDialog
      );
    } else {
      showDialog();
    }
  };

  return roulettePrice && !isModel && (
    <img
      src={`${rouletteImagePath}/roulette.gif`}
      className={classnames(styles.rouletteLink, className)}
      onClick={showRoulette}
    />
  );
};

PaidRouletteLink.propTypes = propTypes;

export default PaidRouletteLink;
