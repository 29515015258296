import { combineReducers } from 'redux';

import {
  ACTION_SET_PUBLIC_PHOTOS,
} from '../../actionTypes/publicPhoto';

import { defaultItemsState } from '../../../core/constants/items';


const publicPhotos = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_SET_PUBLIC_PHOTOS:
      return action.payload.publicPhotos;

    default:
      return state;
  }
};

export default combineReducers({
  publicPhotos,
});
