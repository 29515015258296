import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useUri } from 'react-redux-app/lib/modules/routing/hooks';
import { useFilter } from '../../../chatGallery/hooks';
import { useIsForBoys } from '../../../initSettings/hooks';

import { url } from 'react-redux-app/lib/modules/routing/utils';
import { changeLangForRequestUri, stripLangFromUri } from '../../../core/i18n/utils';
import formatLangForUrl from '../../../core/i18n/utils/formatLangForUrl';
import { getPathname, getPathnamePrefix } from '../../../chatGallery/utils';
import { getUrl, getIsIndexPageAlias } from '../../utils/url';

import { REFERER_COOKIE, RCID_COOKIE } from '../../../core/api/constants';
import { loadableItemsQueryParams } from '../../../core/loadableItems/constants';

import {
  getBaseHostWithProtocol,
  getUILangs,
  getDefaultLang,
  getStaticPageByLocation,
} from '../../../initSettings/selectors';


export default () => {
  const location = useLocation();

  const baseHostWithProtocol = useShortSelector(getBaseHostWithProtocol);
  const defaultLang = useShortSelector(getDefaultLang);
  const uiLangs = useShortSelector(getUILangs);
  const staticPage = useSelector(state => getStaticPageByLocation(state, location, false));
  const isForBoys = useIsForBoys();

  const currentUri = useUri();
  const filter = useFilter();

  const link = [];

  const getAliasPathname = lang => getPathname(
    formatLangForUrl(lang, defaultLang),
    getPathnamePrefix(isForBoys, lang),
    filter,
    lang
  );

  const isIndexPageAlias = getIsIndexPageAlias(location);
  const strippedCurrentUri = url.removeQueryParams(currentUri, [
    REFERER_COOKIE, RCID_COOKIE, ...loadableItemsQueryParams,
  ]);
  const canonicalUri = isIndexPageAlias
    ? getAliasPathname(defaultLang)
    : stripLangFromUri(strippedCurrentUri);
  link.push({
    rel: 'canonical',
    href: `${baseHostWithProtocol}${canonicalUri}`,
  });

  uiLangs.forEach(uiLang => {
    if (staticPage && !staticPage[uiLang]) {
      return;
    }

    let modifiedCurrentUri;
    if (isIndexPageAlias) {
      modifiedCurrentUri = uiLang === defaultLang ? canonicalUri : getAliasPathname(uiLang);
    } else {
      modifiedCurrentUri = staticPage
        ? getUrl(formatLangForUrl(uiLang, defaultLang), staticPage[uiLang].url)
        : changeLangForRequestUri(strippedCurrentUri, uiLang, defaultLang);
    }

    link.push({
      rel: 'alternate',
      hrefLang: uiLang,
      href: `${baseHostWithProtocol}${modifiedCurrentUri}`,
    });
  });

  return <Helmet link={link} />;
};
