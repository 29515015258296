import _get from 'lodash/get';

import { dom } from 'react-redux-app/lib/modules/core/utils';

import config from '../../../../../../../config';


export default (domain, rcid, path) => {
  const rcidInt = parseInt(rcid, 10);

  const apiDomain = (!dom.isBrowser() && config.apiDomain.server) || (
    _get(config.apiDomain, [domain, 'path', path]) || _get(config.apiDomain, [domain, 'domain'])
    || _get(config.apiDomain, `default.path.${path}`) || config.apiDomain.default.domain
  );

  return `https://${apiDomain}/v1/${domain}${rcidInt ? `_${rcidInt}` : ''}/${path}`;
};
