import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'dialog.errors');
export const getLoadDialogsError = state => (
  _get(errorsSelector(state), 'loadDialogsError')
);
export const getDeleteDialogError = state => (
  _get(errorsSelector(state), 'deleteDialogError')
);
