import { combineReducers } from 'redux';

import addToFavoritesError from './addToFavoritesError';
import removeFromFavoritesError from './removeFromFavoritesError';


export default combineReducers({
  addToFavoritesError,
  removeFromFavoritesError,
});
