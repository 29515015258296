import { SORT_DIRECTION__ASC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const UNDERWEAR_REQUEST_STATUS__PENDING = 'pending';
export const UNDERWEAR_REQUEST_STATUS__SENT = 'sent';
export const UNDERWEAR_REQUEST_STATUS__CANCELED = 'canceled';

export const underwearStatuses = [
  UNDERWEAR_REQUEST_STATUS__PENDING,
  UNDERWEAR_REQUEST_STATUS__SENT,
  UNDERWEAR_REQUEST_STATUS__CANCELED,
];

export const underwearRequestStatusLangKeys = {
  [UNDERWEAR_REQUEST_STATUS__PENDING]: 'msg.status.pending',
  [UNDERWEAR_REQUEST_STATUS__SENT]: 'msg.status.sent',
  [UNDERWEAR_REQUEST_STATUS__CANCELED]: 'msg.status.canceled',
};

export const UNDERWEAR_REQUESTS_GRID_NAME = 'UnderwearRequestsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'created',
  sortDirection: SORT_DIRECTION__ASC,
  statuses: [UNDERWEAR_REQUEST_STATUS__PENDING],
};
