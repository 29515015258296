import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_PURCHASED_VIDEOS,
  ACTION_SET_PURCHASED_VIDEOS,

  ACTION_SET_PURCHASED_VIDEOS_PRESERVE,
  ACTION_SET_PURCHASED_VIDEO_ACTIVE_PAGE,

  ACTION_LOAD_PURCHASED_VIDEOS__REQUEST,
  ACTION_LOAD_PURCHASED_VIDEOS__SUCCESS,
  ACTION_LOAD_PURCHASED_VIDEOS__FAIL,
} from '../actionTypes/video';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getVideoPaginationMode } from '../selectors/video';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';


const actionAddPurchasedVideos = videos => ({
  type: ACTION_ADD_PURCHASED_VIDEOS,
  payload: { videos },
});

const actionSetPurchasedVideos = videos => ({
  type: ACTION_SET_PURCHASED_VIDEOS,
  payload: { videos },
});

export const actionSetPurchasedVideosPreserve = () => ({
  type: ACTION_SET_PURCHASED_VIDEOS_PRESERVE,
});

const actionSetPurchasedVideoActivePage = activePage => ({
  type: ACTION_SET_PURCHASED_VIDEO_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadPurchasedVideosSuccess = () => ({
  type: ACTION_LOAD_PURCHASED_VIDEOS__SUCCESS,
});

const actionLoadPurchasedVideosFail = error => ({
  type: ACTION_LOAD_PURCHASED_VIDEOS__FAIL,
  payload: { error },
});

export const actionLoadPurchasedVideosRequest = ({ page = 1, limit = 27 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getVideoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_PURCHASED_VIDEOS__REQUEST,
    });

    return apiClientHelper.get(
      `video/purchased/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: videos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetPurchasedVideos(videos));
          dispatch(actionSetPurchasedVideoActivePage(page));

          return dispatch(actionLoadPurchasedVideosSuccess());
        }

        // Preload video thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].src));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddPurchasedVideos(videos));
          } else {
            dispatch(actionSetPurchasedVideos(videos));
          }

          dispatch(actionSetPurchasedVideoActivePage(page));
          dispatch(actionLoadPurchasedVideosSuccess());
        });
      },
      error => {
        dispatch(actionLoadPurchasedVideosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
