import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const videoSelector = state => _get(state, 'purchase.video');
const videosSelector = state => _get(videoSelector(state), 'videos');
export const getVideoIds = state => _get(videosSelector(state), 'items');
const getVideosTotalCount = state => _get(videosSelector(state), 'count');
const getVideosLimit = state => _get(videosSelector(state), 'limit');
export const getIsLastVideoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getVideoIds, getVideosTotalCount, getVideosLimit
);
export const getVideoTotalPagesCount = state => getTotalPagesCount(
  state, getVideosTotalCount, getVideosLimit
);

export const getVideoPaginationMode = state => _get(videoSelector(state), 'paginationMode');
export const getVideoActivePage = state => _get(videoSelector(state), 'activePage');

export const getLoadVideosError = state => _get(videoSelector(state), 'errors.loadVideosError');
