import { dom } from 'react-redux-app/lib/modules/core/utils';
import { getUri } from 'react-redux-app/lib/modules/routing/utils';

import {
  ACTION_BUY_CREDITS_LOCALLY__REQUEST,
  ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
  ACTION_BUY_CREDITS_LOCALLY__FAIL,
  ACTION_CLEAR_GATE_RESULT,
} from '../actionTypes';

import { getDepositDiscountCode } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionLoadDepositSettingsRequest } from './loadDepositSettings';


const actionBuyCreditsLocallySuccess = gateResult => ({
  type: ACTION_BUY_CREDITS_LOCALLY__SUCCESS,
  payload: { gateResult },
});

const actionBuyCreditsLocallyFail = error => ({
  type: ACTION_BUY_CREDITS_LOCALLY__FAIL,
  payload: { error },
});

export const actionBuyCreditsLocallyRequest = values => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_BUY_CREDITS_LOCALLY__REQUEST,
    });

    return apiClientHelper.post(
      'deposit/credit_card',
      {
        data: {
          ...values,
          discountCode: getDepositDiscountCode(getState()),
          returnUrl: getUri(location),
        },
      }
    ).then(
      ({ gateResult }) => {
        if (gateResult.challenge) {
          dom.redirect(gateResult.challenge);
          return null;
        }

        dispatch(actionBuyCreditsLocallySuccess(gateResult));
        return dispatch(actionLoadDepositSettingsRequest(getDepositDiscountCode(getState())));
      },
      error => {
        dispatch(actionBuyCreditsLocallyFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionClearGateResult = () => ({
  type: ACTION_CLEAR_GATE_RESULT,
});
