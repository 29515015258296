import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLatestSelector } from 'react-redux-app/lib/modules/core/hooks';

import { WEEKEND_ID, CRYPTO_ID } from '../constants';
import { MODAL_DIALOG__CRYPTO_BONUS, MODAL_DIALOG__WEEKEND_BONUS } from '../constants/dialogs';

import { isModalVisibleByName } from 'react-redux-app/lib/modules/modal/selectors';
import { getCreditsBonus } from '../selectors/models';

import { actionSetCreditsBonus } from '../actions/models';
import { actionHideCryptoBonusDialog } from '../actions/dialogs/cryptoBonusDialog';
import { actionHideWeekendBonusDialog } from '../actions/dialogs/weekendBonusDialog';


export default (
  type,
  ttl = null
) => {
  const dispatch = useDispatch();

  const isWeekendBonusDialogVisible = useSelector(
    state => isModalVisibleByName(state, MODAL_DIALOG__WEEKEND_BONUS)
  );
  const isCryptoBonusDialogVisible = useSelector(
    state => isModalVisibleByName(state, MODAL_DIALOG__CRYPTO_BONUS)
  );
  const prize = useLatestSelector(state => getCreditsBonus(state, type));

  const [savedTtl, setSavedTtl] = useState(ttl);

  useEffect(() => {
    if (!ttl) {
      return undefined;
    }

    const intervalId = setInterval(() => {
      setSavedTtl(prevState => {
        const result = prevState - 1;

        if (result <= 0) {
          setTimeout(() => {
            if (type === WEEKEND_ID && isWeekendBonusDialogVisible) {
              dispatch(actionHideWeekendBonusDialog());
            }
            if (type === CRYPTO_ID && isCryptoBonusDialogVisible) {
              dispatch(actionHideCryptoBonusDialog());
            }
            if (prize.current) {
              dispatch(actionSetCreditsBonus(type, null));
            }
          });
        }

        return result;
      });
    }, 1000);

    return () => { clearInterval(intervalId); };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return savedTtl * 1000;
};
