import { combineReducers } from 'redux';

import { ACTION_SET_ACTIVE_PREVIEW_VIDEO_UUID } from '../actionTypes';


const activeVideoPreviewUuid = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_ACTIVE_PREVIEW_VIDEO_UUID:
      return action.payload.videoUuid;

    default:
      return state;
  }
};

export default combineReducers({
  activeVideoPreviewUuid,
});
