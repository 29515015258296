export const ACTION_ADD_RECORDED_PRIVATES = 'ACTION_ADD_RECORDED_PRIVATES';

export const ACTION_POST_RECORDED_PRIVATE__REQUEST = 'ACTION_POST_RECORDED_PRIVATE__REQUEST';
export const ACTION_POST_RECORDED_PRIVATE__SUCCESS = 'ACTION_POST_RECORDED_PRIVATE__SUCCESS';
export const ACTION_POST_RECORDED_PRIVATE__FAIL = 'ACTION_POST_RECORDED_PRIVATE__FAIL';

export const ACTION_DELETE_RECORDED_PRIVATE__REQUEST = 'ACTION_DELETE_RECORDED_PRIVATE__REQUEST';
export const ACTION_DELETE_RECORDED_PRIVATE__SUCCESS = 'ACTION_DELETE_RECORDED_PRIVATE__SUCCESS';
export const ACTION_DELETE_RECORDED_PRIVATE__FAIL = 'ACTION_DELETE_RECORDED_PRIVATE__FAIL';

export const ACTION_UPDATE_RECORDED_PRIVATE = 'ACTION_UPDATE_RECORDED_PRIVATE';
