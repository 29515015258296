import {
  ACTION_APPROVE_APPOINTMENT__REQUEST,
  ACTION_APPROVE_APPOINTMENT__SUCCESS,
  ACTION_APPROVE_APPOINTMENT__FAIL,
  ACTION_CANCEL_APPOINTMENT__REQUEST,
  ACTION_CANCEL_APPOINTMENT__SUCCESS,
  ACTION_CANCEL_APPOINTMENT__FAIL,
  ACTION_CREATE_APPOINTMENT__REQUEST,
  ACTION_CREATE_APPOINTMENT__SUCCESS,
  ACTION_CREATE_APPOINTMENT__FAIL,
} from '../../actionTypes';

import { APPOINTMENTS_GRID_NAME } from '../../constants';

import { actionLoadAppointmentsRequest } from '../../actions/appointmentsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  APPOINTMENTS_GRID_NAME,
  actionLoadAppointmentsRequest,
  [
    ACTION_APPROVE_APPOINTMENT__REQUEST,
    ACTION_CANCEL_APPOINTMENT__REQUEST,
    ACTION_CREATE_APPOINTMENT__REQUEST,
  ],
  [
    ACTION_APPROVE_APPOINTMENT__FAIL,
    ACTION_CANCEL_APPOINTMENT__FAIL,
    ACTION_CREATE_APPOINTMENT__FAIL,
  ],
  [
    ACTION_APPROVE_APPOINTMENT__SUCCESS,
    ACTION_CANCEL_APPOINTMENT__SUCCESS,
    ACTION_CREATE_APPOINTMENT__SUCCESS,
  ]
);
