import { MODAL_DIALOG__ROULETTE } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowRouletteDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__ROULETTE))
);

export const actionHideRouletteDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__ROULETTE))
);
