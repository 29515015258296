import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsAuthenticated } from '../../../auth/hooks';

import { getHeaderLandingPages } from '../../../initSettings/selectors';

import FavoritesMenuLink from '../FavoritesMenuLink';
import GetCreditsMenuLink from '../GetCreditsMenuLink';
import GoToVideochat from '../GoToVideochat';
import MenuItemDropdown from '../MenuItemDropdown';
import MenuLink from '../MenuLink';
import MessagesMenuLink from '../MessagesMenuLink';
import UserDropdownMenu from '../UserDropdownMenu';
import VideochatLink from '../VideochatLink';
import AuthButtons from '../AuthButtons';
import MenuItems from '../MenuItems';
import LanguageSelector from '../LanguageSelector';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { MENU } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


export const themeApi = [
  'menu',
  'menu__item',
  'menu__item_high',
];

const propTypes = {
  className: PropTypes.string.isRequired,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const Menu = ({ className, theme }) => {
  const landingPages = useShortSelector(getHeaderLandingPages);
  const isAuthenticated = useIsAuthenticated();

  const itemClassName = classnames(theme.menu__item, theme.menu__item_high);

  return (
    <div className={classnames(theme.menu, className)}>
      <VideochatLink linkComponent={MenuLink} className={theme.menu__item}>
        <GoToVideochat />
      </VideochatLink>

      <MenuItems
        itemComponent={MenuLink}
        itemComponentProps={{ end: true }}
        dropdownComponent={MenuItemDropdown}
        isHeader
        className={itemClassName}
      />

      {landingPages && (
        <MenuItemDropdown
          itemLangKey="msg.content"
          items={landingPages}
          className={theme.menu__item}
        />
      )}

      <FavoritesMenuLink className={itemClassName} />
      <MessagesMenuLink className={itemClassName} />
      <GetCreditsMenuLink className={itemClassName} />

      <LanguageSelector className={theme.menu__item} />

      {isAuthenticated ? (
        <UserDropdownMenu className={theme.menu__item} />
      ) : (
        <AuthButtons isLoginLink className={theme.menu__item} />
      )}
    </div>
  );
};

Menu.propTypes = propTypes;

export default theming.theme(
  MENU,
  baseCssJson,
  themeApi
)(Menu);
