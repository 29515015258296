import { MODAL_DIALOG__ADULT_WARNING } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowAdultWarningDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__ADULT_WARNING))
);

export const actionHideAdultWarningDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__ADULT_WARNING))
);
