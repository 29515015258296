import { combineReducers } from 'redux';

import acceptPrizeError from './acceptPrizeError';
import authError from './authError';
import deleteNotificationError from './deleteNotificationError';
import fillNameError from './fillNameError';
import loadLoginInfoError from './loadLoginInfoError';
import loadSpinPriceError from './loadSpinPriceError';
import loadZendeskLoginLinkError from './loadZendeskLoginLinkError';
import loginAsModelError from './loginAsModelError';
import loginByAuthTokenError from './loginByAuthTokenError';
import loginByCredentialsError from './loginByCredentialsError';
import loginBySocialError from './loginBySocialError';
import logoutError from './logoutError';
import registerError from './registerError';
import resetDepositRemindError from './resetDepositRemindError';
import returnToAgencyError from './returnToAgencyError';
import spinRouletteError from './spinRouletteError';
import unbanError from './unbanError';
import visitPageError from './visitPageError';


export default combineReducers({
  acceptPrizeError,
  authError,
  deleteNotificationError,
  fillNameError,
  loadLoginInfoError,
  loadSpinPriceError,
  loadZendeskLoginLinkError,
  loginAsModelError,
  loginByAuthTokenError,
  loginByCredentialsError,
  loginBySocialError,
  logoutError,
  registerError,
  resetDepositRemindError,
  returnToAgencyError,
  spinRouletteError,
  unbanError,
  visitPageError,
});
