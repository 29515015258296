export const getTabs = tabToCheckMap => {
  const tabs = [];

  Object.keys(tabToCheckMap).forEach(tab => {
    if (tabToCheckMap[tab]) {
      tabs.push(tab);
    }
  });

  return tabs;
};
