export const ACTION_ADD_OWN_EROSTORIES = 'ACTION_ADD_OWN_EROSTORIES';
export const ACTION_SET_OWN_EROSTORIES = 'ACTION_SET_OWN_EROSTORIES';

export const ACTION_SET_OWN_EROSTORIES_PRESERVE = 'ACTION_SET_OWN_EROSTORIES_PRESERVE';
export const ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE = 'ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE';

export const ACTION_LOAD_OWN_EROSTORIES__REQUEST = 'ACTION_LOAD_OWN_EROSTORIES__REQUEST';
export const ACTION_LOAD_OWN_EROSTORIES__SUCCESS = 'ACTION_LOAD_OWN_EROSTORIES__SUCCESS';
export const ACTION_LOAD_OWN_EROSTORIES__FAIL = 'ACTION_LOAD_OWN_EROSTORIES__FAIL';

export const ACTION_CREATE_EROSTORY__REQUEST = 'ACTION_CREATE_EROSTORY__REQUEST';
export const ACTION_CREATE_EROSTORY__SUCCESS = 'ACTION_CREATE_EROSTORY__SUCCESS';
export const ACTION_CREATE_EROSTORY__FAIL = 'ACTION_CREATE_EROSTORY__FAIL';

export const ACTION_DELETE_EROSTORY__REQUEST = 'ACTION_DELETE_EROSTORY__REQUEST';
export const ACTION_DELETE_EROSTORY__SUCCESS = 'ACTION_DELETE_EROSTORY__SUCCESS';
export const ACTION_DELETE_EROSTORY__FAIL = 'ACTION_DELETE_EROSTORY__FAIL';

export const ACTION_UPDATE_EROSTORY__REQUEST = 'ACTION_UPDATE_EROSTORY__REQUEST';
export const ACTION_UPDATE_EROSTORY__SUCCESS = 'ACTION_UPDATE_EROSTORY__SUCCESS';
export const ACTION_UPDATE_EROSTORY__FAIL = 'ACTION_UPDATE_EROSTORY__FAIL';
