import { getGridFilter } from '../utils';

import { ACTION_ADD_BANNED_USERS } from '../actionTypes';

import { BANNED_USERS_GRID_NAME } from '../constants';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddBannedUsers = bannedUsers => ({
  type: ACTION_ADD_BANNED_USERS,
  payload: { bannedUsers },
});

export const actionLoadBannedUsersRequest = getActionLoadGridDataRequest(
  BANNED_USERS_GRID_NAME, getGridFilter, 'ban_user/list', actionAddBannedUsers
);
