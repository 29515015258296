import moment from 'moment';

import { SORT_DIRECTION__ASC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const APPOINTMENT_STATUS__PENDING = 'pending';
export const APPOINTMENT_STATUS__APPROVED = 'approved';
export const APPOINTMENT_STATUS__CANCELED = 'canceled';

export const appointmentStatuses = [
  APPOINTMENT_STATUS__PENDING,
  APPOINTMENT_STATUS__APPROVED,
  APPOINTMENT_STATUS__CANCELED,
];

export const appointmentStatusLangKeys = {
  [APPOINTMENT_STATUS__PENDING]: 'msg.status.pending',
  [APPOINTMENT_STATUS__APPROVED]: 'msg.status.approved',
  [APPOINTMENT_STATUS__CANCELED]: 'msg.status.canceled',
};

export const APPOINTMENTS_GRID_NAME = 'AppointmentsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'created',
  sortDirection: SORT_DIRECTION__ASC,
  dateFrom: Math.floor(moment().startOf('month').format('x') / 1000),
  dateTo: Math.floor(moment().endOf('month').format('x') / 1000),
  statuses: appointmentStatuses,
};
