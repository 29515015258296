import _get from 'lodash/get';

import { getIsAuthenticated } from '../../auth/selectors';
import { getIsBot } from '../../initSettings/selectors';


export const getShowAdultWarning = state => (
  !getIsBot(state)
  && !getIsAuthenticated(state)
  && !_get(state, 'adultWarning')
);
