import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';
import { getIndexUrl, getIsIndexPageAlias } from '../../../app/utils/url';
import {
  getPathnamePrefix,
  getPathname,
  getFilter,
  getIsChatGalleryCategory,
} from '../../utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';
import { ORDER_BY_QUERY_PARAM, ORDER__LAST_SEEN } from '../../../core/switch/constants';

import { getCurrentLang } from 'react-redux-app/lib/modules/i18n/selectors';
import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import {
  getIsForBoys,
  getChatGalleryFilters,
  getChatPreviewSettings,
  getIsVideoSite,
  getIsModelHub,
} from '../../../initSettings/selectors';
import { getLoadPartialInitSettingsError } from '../../../initSettings/selectors/errors';
import { getLoadLandingPageError } from '../../../landingPage/selectors/errors';
import { getLoadChatGalleryModelsError, getLoadModelsError } from '../../selectors/models';

import { actionLoadChatGallerySettingsRequest } from '../../../initSettings/actions/loadPartialInitSettings';
import { actionLoadLandingPageRequest } from '../../../landingPage/actions/loadLandingPage';
import { actionLoadChatGalleryModelsRequest } from '../../actions/loadChatGalleryModels';
import { actionLoadModelsRequest } from '../../actions/loadModels';


export default ({ getState, dispatch }) => (
  function loadChatGalleryDataHandler(nextState, successCallback, errorCallback) {
    if (getIsVideoSite(getState()) || getIsModelHub(getState())) {
      successCallback();
      return;
    }

    const filter = getFilter(nextState.params.filter);
    const { filterName, filterValue } = filter;

    const lang = getCurrentLang(getState());
    const pathname = getIsIndexPageAlias(nextState.location)
      ? getPathname(
        getCurrentLangForUrl(getState()),
        getPathnamePrefix(getIsForBoys(getState()), lang),
        filter,
        lang
      )
      : nextState.location.pathname;
    const indexUrl = getIndexUrl(getCurrentLangForUrl(getState()));

    Promise.all([
      dispatch(actionLoadChatGallerySettingsRequest()),
      dispatch(actionLoadLandingPageRequest(true, pathname)),
      dispatch(
        getIsChatGalleryCategory(filterName)
          ? actionLoadChatGalleryModelsRequest(filterValue)
          : actionLoadModelsRequest({
            [filterName]: filterValue,
            [ORDER_BY_QUERY_PARAM]: ORDER__LAST_SEEN,
          }, true)
      ),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const loadLandingPageError = getLoadLandingPageError(getState());
        if (maybeHandleCriticalError(
          nextState.location, loadLandingPageError?.globalError, errorCallback
        )) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError, fieldErrors } of [
          getLoadChatGalleryModelsError(getState()),
          getLoadModelsError(getState()),
          getLoadPartialInitSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            if (fieldErrors) {
              errorCallback(indexUrl);
            } else {
              console.consoleError('loadChatGalleryDataHandler error', globalError);
              errorCallback(INTERNAL_ERROR_URI);
            }
          }
          return;
        }

        if (
          _isEmpty(getChatPreviewSettings(getState()))
          || _isEmpty(getChatGalleryFilters(getState()))
        ) {
          console.consoleError('loadChatGalleryDataHandler - unable to initialize application');
          errorCallback(INTERNAL_ERROR_URI);
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadChatGalleryDataHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
