import { useDidUpdateEffect, useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useHandleErrorOrExecute from './useHandleErrorOrExecute';

import { getId } from 'react-redux-app/lib/modules/api/utils';


export default (
  errorSelector,
  errorDialogHeaderLangKey,
  id = null
) => {
  const error = useShortSelector(errorSelector);

  const handleErrorOrExecute = useHandleErrorOrExecute();

  useDidUpdateEffect(() => {
    if (error) {
      const errorId = getId(error);
      if (id && errorId && id !== errorId) {
        return;
      }

      handleErrorOrExecute(error, errorDialogHeaderLangKey);
    }
  }, [error]);
};
