import {
  LANG__EN,
  LANG__IT,
  LANG__ES,
  LANG__DE,
  LANG__FR,
  LANG__GR,
  LANG__RU,
} from '../../core/language/constants';
import {
  PATH_GIRLS,
  PATH_BOYS,
  PATH_RAGAZZE,
  PATH_RAGAZZI,
} from '../../app/constants/url';
import {
  SEX_PREFERENCES__SPLOSHING,
  SEX_PREFERENCES__BDSM,
  SEX_PREFERENCES__ANAL,
  SEX_PREFERENCES__CROSSDRESSING,
  SEX_PREFERENCES__FETISH,
  SEX_PREFERENCES__GROUP,
  SEX_PREFERENCES__SLAVE,
  SEX_PREFERENCES__ORAL,
  SEX_PREFERENCES__BALLOONS,
  SEX_PREFERENCES__FEET,

  ETHNIC__WHITE,
  ETHNIC__ASIAN,
  ETHNIC__HISPANIC,
  ETHNIC__MIDDLE_EASTERN,
  ETHNIC__MULTICULTURAL,
  ETHNIC__NATIVE_AMERICAN,
  ETHNIC__PACIFIC_ISLANDER,
  ETHNIC__SOUTH_ASIAN,
  ETHNIC__BLACK,

  HAIR_COLOR__BLACK,
  HAIR_COLOR__AUBURN,
  HAIR_COLOR__BLOND,
  HAIR_COLOR__CHESTNUT,

  HEIGHT__TO_150,
  HEIGHT__151_155,
  HEIGHT__156_160,
  HEIGHT__161_165,
  HEIGHT__166_170,
  HEIGHT__171_175,
  HEIGHT__176_180,
  HEIGHT__181_185,
  HEIGHT__186_190,
  HEIGHT__FROM_191,

  BODY_TYPE__PETITE,
  BODY_TYPE__SKINNY,
  BODY_TYPE__AVERAGE,
  BODY_TYPE__ATHLETIC,
  BODY_TYPE__MUSCULAR,
  BODY_TYPE__ABOVE_AVERAGE,
  BODY_TYPE__LARGE,
  BODY_TYPE__EXTRA_LARGE,

  BREAST_SIZE__TINY,
  BREAST_SIZE__NORMAL,
  BREAST_SIZE__BIG,
  BREAST_SIZE__HUGE,

  PUBES__SHAVED,
  PUBES__HAIRY,
} from '../../account/constants';
import {
  FILTER_QUERY_PARAM,
  PRICE_RANGE_QUERY_PARAM,
  AGE_RANGE_QUERY_PARAM,
  SEX_PREFERENCES_QUERY_PARAM,
  ETHNIC_QUERY_PARAM,
  HAIR_QUERY_PARAM,
  HEIGHT_QUERY_PARAM,
  BODY_QUERY_PARAM,
  BREAST_QUERY_PARAM,
  PUBES_QUERY_PARAM,
  LANGUAGE_QUERY_PARAM,

  FILTER__NEW,
  FILTER__MOST_POPULAR,
  FILTER__HOT_FLIRT,
  FILTER__BIG_BREAST,
  FILTER__LESBIANS,
  FILTER__YOUNG,
  FILTER__COUPLE,
  FILTER__TRANSSEXUAL,
  FILTER__CHUBBY,
  FILTER__OLD,
  FILTER__BIG_COCK,
  FILTER__SMALL_COCK,
  FILTER__MUSCLE,

  PRICE__TO_20,
  PRICE__20_25,
  PRICE__25_30,
  PRICE__30_35,
  PRICE__35_40,
  PRICE__40_45,
  PRICE__45_50,
  PRICE__FROM_50,

  AGE__18_22,
  AGE__23_27,
  AGE__28_35,
  AGE__36_45,
  AGE__46_120,
} from './index';


export const isForBoysToPathMap = {
  [true]: { [LANG__IT]: PATH_RAGAZZI, [LANG__EN]: PATH_BOYS },
  [false]: { [LANG__IT]: PATH_RAGAZZE, [LANG__EN]: PATH_GIRLS },
};

export const filterToUrlMap = {
  [FILTER_QUERY_PARAM]: {
    [FILTER__NEW]: { [LANG__IT]: 'nuove-arrivate', [LANG__EN]: 'new-girls' },
    [FILTER__MOST_POPULAR]: { [LANG__IT]: 'piu-popolare', [LANG__EN]: 'most-popular' },
    [FILTER__HOT_FLIRT]: { [LANG__IT]: 'hot-flirt', [LANG__EN]: 'hot-flirt' },
    [FILTER__BIG_BREAST]: { [LANG__IT]: 'tette-grandi', [LANG__EN]: 'big-tits' },
    [FILTER__LESBIANS]: { [LANG__IT]: 'lesbiche', [LANG__EN]: 'lesbians' },
    [FILTER__YOUNG]: { [LANG__IT]: 'giovani', [LANG__EN]: 'young' },
    [FILTER__COUPLE]: { [LANG__IT]: 'coppia', [LANG__EN]: 'couple' },
    [FILTER__TRANSSEXUAL]: { [LANG__IT]: 'transessuale', [LANG__EN]: 'transsexual' },
    [FILTER__CHUBBY]: { [LANG__IT]: 'chubby', [LANG__EN]: 'chubby' },
    [FILTER__OLD]: { [LANG__IT]: 'old', [LANG__EN]: 'old' },
    [FILTER__BIG_COCK]: { [LANG__IT]: 'big-cock', [LANG__EN]: 'big-cock' },
    [FILTER__SMALL_COCK]: { [LANG__IT]: 'small-cock', [LANG__EN]: 'small-cock' },
    [FILTER__MUSCLE]: { [LANG__IT]: 'muscle', [LANG__EN]: 'muscle' },
  },
  [PRICE_RANGE_QUERY_PARAM]: {
    [PRICE__TO_20]: { [LANG__IT]: 'prezzo-a-20', [LANG__EN]: 'price-to-20' },
    [PRICE__20_25]: { [LANG__IT]: 'prezzo-20-25', [LANG__EN]: 'price-20-25' },
    [PRICE__25_30]: { [LANG__IT]: 'prezzo-25-30', [LANG__EN]: 'price-25-30' },
    [PRICE__30_35]: { [LANG__IT]: 'prezzo-30-35', [LANG__EN]: 'price-30-35' },
    [PRICE__35_40]: { [LANG__IT]: 'prezzo-35-40', [LANG__EN]: 'price-35-40' },
    [PRICE__40_45]: { [LANG__IT]: 'prezzo-40-45', [LANG__EN]: 'price-40-45' },
    [PRICE__45_50]: { [LANG__IT]: 'prezzo-45-50', [LANG__EN]: 'price-45-50' },
    [PRICE__FROM_50]: { [LANG__IT]: 'prezzo-da-50', [LANG__EN]: 'price-from-50' },
  },
  [AGE_RANGE_QUERY_PARAM]: {
    [AGE__18_22]: { [LANG__IT]: 'eta-18-22', [LANG__EN]: 'age-18-22' },
    [AGE__23_27]: { [LANG__IT]: 'eta-23-27', [LANG__EN]: 'age-23-27' },
    [AGE__28_35]: { [LANG__IT]: 'eta-28-35', [LANG__EN]: 'age-28-35' },
    [AGE__36_45]: { [LANG__IT]: 'eta-36-45', [LANG__EN]: 'age-36-45' },
    [AGE__46_120]: { [LANG__IT]: 'eta-da-46', [LANG__EN]: 'age-from-46' },
  },
  [SEX_PREFERENCES_QUERY_PARAM]: {
    [SEX_PREFERENCES__SPLOSHING]: { [LANG__IT]: 'sploshing', [LANG__EN]: 'sploshing' },
    [SEX_PREFERENCES__BDSM]: { [LANG__IT]: 'bdsm', [LANG__EN]: 'bdsm' },
    [SEX_PREFERENCES__ANAL]: { [LANG__IT]: 'anal', [LANG__EN]: 'anal' },
    [SEX_PREFERENCES__CROSSDRESSING]: { [LANG__IT]: 'travestimento', [LANG__EN]: 'cross-dressing' },
    [SEX_PREFERENCES__FETISH]: { [LANG__IT]: 'fetish', [LANG__EN]: 'fetish' },
    [SEX_PREFERENCES__GROUP]: { [LANG__IT]: 'sesso-di-gruppo', [LANG__EN]: 'group-sex' },
    [SEX_PREFERENCES__SLAVE]: { [LANG__IT]: 'schiavo-padrone', [LANG__EN]: 'slave-master' },
    [SEX_PREFERENCES__ORAL]: { [LANG__IT]: 'oral', [LANG__EN]: 'oral' },
    [SEX_PREFERENCES__BALLOONS]: { [LANG__IT]: 'balloons', [LANG__EN]: 'balloons' },
    [SEX_PREFERENCES__FEET]: { [LANG__IT]: 'piedi', [LANG__EN]: 'feet' },
  },
  [ETHNIC_QUERY_PARAM]: {
    [ETHNIC__WHITE]: { [LANG__IT]: 'bianca', [LANG__EN]: 'white' },
    [ETHNIC__ASIAN]: { [LANG__IT]: 'asiatica', [LANG__EN]: 'asian' },
    [ETHNIC__HISPANIC]: { [LANG__IT]: 'ispanica', [LANG__EN]: 'hispanic' },
    [ETHNIC__MIDDLE_EASTERN]: { [LANG__IT]: 'medio-orientale', [LANG__EN]: 'middle-eastern' },
    [ETHNIC__MULTICULTURAL]: { [LANG__IT]: 'multietnica', [LANG__EN]: 'multicultural' },
    [ETHNIC__NATIVE_AMERICAN]: { [LANG__IT]: 'nativo-americano', [LANG__EN]: 'native-american' },
    [ETHNIC__PACIFIC_ISLANDER]: { [LANG__IT]: 'isole-del-pacifico', [LANG__EN]: 'pacific-islander' },
    [ETHNIC__SOUTH_ASIAN]: { [LANG__IT]: 'asia-del-sud', [LANG__EN]: 'south-asian' },
    [ETHNIC__BLACK]: { [LANG__IT]: 'nera', [LANG__EN]: 'black' },
  },
  [HAIR_QUERY_PARAM]: {
    [HAIR_COLOR__BLACK]: { [LANG__IT]: 'capelli-neri', [LANG__EN]: 'black-hair' },
    [HAIR_COLOR__AUBURN]: { [LANG__IT]: 'capelli-rossi', [LANG__EN]: 'auburn-hair' },
    [HAIR_COLOR__BLOND]: { [LANG__IT]: 'capelli-blondi', [LANG__EN]: 'blond-hair' },
    [HAIR_COLOR__CHESTNUT]: { [LANG__IT]: 'capelli-castani', [LANG__EN]: 'chestnut-hair' },
  },
  [HEIGHT_QUERY_PARAM]: {
    [HEIGHT__TO_150]: { [LANG__IT]: 'altezza-a-150', [LANG__EN]: 'height-to-150' },
    [HEIGHT__151_155]: { [LANG__IT]: 'altezza-151-155', [LANG__EN]: 'height-151-155' },
    [HEIGHT__156_160]: { [LANG__IT]: 'altezza-156-160', [LANG__EN]: 'height-156-160' },
    [HEIGHT__161_165]: { [LANG__IT]: 'altezza-161-165', [LANG__EN]: 'height-161-165' },
    [HEIGHT__166_170]: { [LANG__IT]: 'altezza-166-170', [LANG__EN]: 'height-166-170' },
    [HEIGHT__171_175]: { [LANG__IT]: 'altezza-171-175', [LANG__EN]: 'height-171-175' },
    [HEIGHT__176_180]: { [LANG__IT]: 'altezza-176-180', [LANG__EN]: 'height-176-180' },
    [HEIGHT__181_185]: { [LANG__IT]: 'altezza-181-185', [LANG__EN]: 'height-181-185' },
    [HEIGHT__186_190]: { [LANG__IT]: 'altezza-186-190', [LANG__EN]: 'height-186-190' },
    [HEIGHT__FROM_191]: { [LANG__IT]: 'altezza-da-191', [LANG__EN]: 'height-from-191' },
  },
  [BODY_QUERY_PARAM]: {
    [BODY_TYPE__PETITE]: { [LANG__IT]: 'corporatura-minuta', [LANG__EN]: 'petite-body' },
    [BODY_TYPE__SKINNY]: { [LANG__IT]: 'corporatura-magro', [LANG__EN]: 'skinny-body' },
    [BODY_TYPE__AVERAGE]: { [LANG__IT]: 'corporatura-media', [LANG__EN]: 'average-body' },
    [BODY_TYPE__ATHLETIC]: { [LANG__IT]: 'corporatura-atletica', [LANG__EN]: 'athletic-body' },
    [BODY_TYPE__MUSCULAR]: { [LANG__IT]: 'corporatura-muscoloso', [LANG__EN]: 'muscular-body' },
    [BODY_TYPE__ABOVE_AVERAGE]: { [LANG__IT]: 'corporatura-sopra-la-media', [LANG__EN]: 'above-average-body' },
    [BODY_TYPE__LARGE]: { [LANG__IT]: 'corporatura-robusto', [LANG__EN]: 'large-body' },
    [BODY_TYPE__EXTRA_LARGE]: { [LANG__IT]: 'corporatura-obeso', [LANG__EN]: 'extra-large-body' },
  },
  [BREAST_QUERY_PARAM]: {
    [BREAST_SIZE__TINY]: { [LANG__IT]: 'seno-piccolo', [LANG__EN]: 'small-breast' },
    [BREAST_SIZE__NORMAL]: { [LANG__IT]: 'seno-normale', [LANG__EN]: 'normal-breast' },
    [BREAST_SIZE__BIG]: { [LANG__IT]: 'seno-grande', [LANG__EN]: 'big-breast' },
    [BREAST_SIZE__HUGE]: { [LANG__IT]: 'seno-enorme', [LANG__EN]: 'huge-breast' },
  },
  [PUBES_QUERY_PARAM]: {
    [PUBES__SHAVED]: { [LANG__IT]: 'pube-rasato', [LANG__EN]: 'shaved-pubes' },
    [PUBES__HAIRY]: { [LANG__IT]: 'pube-peloso', [LANG__EN]: 'hairy-pubes' },
  },
  [LANGUAGE_QUERY_PARAM]: {
    [LANG__EN]: { [LANG__IT]: 'inglese', [LANG__EN]: 'english' },
    [LANG__IT]: { [LANG__IT]: 'italiano', [LANG__EN]: 'italian' },
    [LANG__ES]: { [LANG__IT]: 'spagnolo', [LANG__EN]: 'spanish' },
    [LANG__DE]: { [LANG__IT]: 'tedesco', [LANG__EN]: 'german' },
    [LANG__FR]: { [LANG__IT]: 'francese', [LANG__EN]: 'french' },
    [LANG__GR]: { [LANG__IT]: 'greco', [LANG__EN]: 'greek' },
    [LANG__RU]: { [LANG__IT]: 'russo', [LANG__EN]: 'russian' },
  },
};
