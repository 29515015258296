import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';
import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';
import { revenueConversionStatuses } from './index';


export const REVENUE_CONVERSIONS_GRID_NAME = 'RevenueConversionsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'created',
  sortDirection: SORT_DIRECTION__DESC,
  statuses: revenueConversionStatuses,
};
