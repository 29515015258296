import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'latestItem.errors');
export const getLoadLatestPublicPhotosError = state => (
  _get(errorsSelector(state), 'loadLatestPublicPhotosError')
);
export const getLoadLatestPrivatePhotosError = state => (
  _get(errorsSelector(state), 'loadLatestPrivatePhotosError')
);
export const getLoadLatestVideosError = state => (
  _get(errorsSelector(state), 'loadLatestVideosError')
);
export const getLoadLatestErostoriesError = state => (
  _get(errorsSelector(state), 'loadLatestErostoriesError')
);
export const getLoadLatestUnderwearError = state => (
  _get(errorsSelector(state), 'loadLatestUnderwearError')
);
