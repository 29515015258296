import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_LOAD_OWN_POST_PHOTOS__REQUEST,
  ACTION_LOAD_OWN_POST_PHOTOS__SUCCESS,
  ACTION_LOAD_OWN_POST_PHOTOS__FAIL,
  ACTION_SET_OWN_POST_PHOTOS,
  ACTION_ADD_OWN_POST_PHOTOS,
  ACTION_SET_OWN_POST_PHOTOS_PRESERVE,
  ACTION_SET_OWN_POST_PHOTO_ACTIVE_PAGE,
} from '../../actionTypes/photo';

import { defaultItemsState } from '../../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../../core/loadableItems/constants';


const photos = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_POST_PHOTOS: {
      const items = _union(
        state.items,
        action.payload.photos.items
      );

      return {
        items,
        count: action.payload.photos.count,
        limit: action.payload.photos.limit,
      };
    }

    case ACTION_SET_OWN_POST_PHOTOS:
      return action.payload.photos;

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_POST_PHOTOS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_OWN_POST_PHOTOS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_OWN_POST_PHOTOS__SUCCESS:
    case ACTION_LOAD_OWN_POST_PHOTOS__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_POST_PHOTO_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

export default combineReducers({
  photos,
  paginationMode,
  activePage,
  errors,
});
