import {
  ACTION_SAVE_WEEKLY_EVENT_PRIZE__REQUEST,
  ACTION_SAVE_WEEKLY_EVENT_PRIZE__SUCCESS,
  ACTION_SAVE_WEEKLY_EVENT_PRIZE__FAIL,
} from '../../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_SAVE_WEEKLY_EVENT_PRIZE__REQUEST:
    case ACTION_SAVE_WEEKLY_EVENT_PRIZE__SUCCESS:
      return null;

    case ACTION_SAVE_WEEKLY_EVENT_PRIZE__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
