import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { menuItemsPropType } from '../../propTypes';

import LangLink from '../../../core/i18n/components/LangLink';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { MENU_LINK } from '../../constants/theme';
import baseCssJson from '../MenuLink/styles/base/index.css.json';
import { themeApi } from '../MenuLink';

import styles from './styles/index.css.json';


const propTypes = {
  itemLangKey: PropTypes.string.isRequired,
  items: menuItemsPropType.isRequired,
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const MenuItemDropdown = ({
  itemLangKey,
  items,
  className = null,
  theme,
}) => (
  <UncontrolledDropdown className={className}>
    <DropdownToggle tag="span" className={classnames(theme.menuLink, styles.toggle)}>
      {l(itemLangKey)}
    </DropdownToggle>

    <DropdownMenu end>
      {items.map(({ name, langKey, path }) => (
        <DropdownItem tag={LangLink} to={path} key={path}>
          {name || l(langKey)}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);

MenuItemDropdown.propTypes = propTypes;

export default theming.theme(
  MENU_LINK,
  baseCssJson,
  themeApi
)(MenuItemDropdown);
