import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'mediaViewer.errors');
export const getLoadMediaViewerItemsError = state => (
  _get(errorsSelector(state), 'loadItemsError')
);
export const getLoadMediaViewerItemInfoError = state => (
  _get(errorsSelector(state), 'loadItemInfoError')
);
export const getLeaveCommentError = state => (
  _get(errorsSelector(state), 'leaveCommentError')
);
export const getDeleteCommentError = state => (
  _get(errorsSelector(state), 'deleteCommentError')
);
