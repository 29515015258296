import React from 'react';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';

import { getDialogsUrl } from '../../utils/url';

import { getUnreadMessagesNumber } from '../../../auth/selectors';

import MaterialIconMenuLink from '../MaterialIconMenuLink';


export default props => {
  const number = useShortSelector(getUnreadMessagesNumber);

  return (
    <MaterialIconMenuLink
      to={getDialogsUrl()}
      type="chat_bubble"
      titleLangKey="msg.messages"
      number={number}
      {...props}
    />
  );
};
