import {
  takeLatest,
  put,
  putResolve,
  select,
} from 'redux-saga/effects';

import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalErrorWithHistory } from 'react-redux-app/lib/modules/api/utils';
import { getRevenueUrl } from '../../app/utils/url';

import { ACTION_UPDATE_LOGGED_USER } from '../../auth/actionTypes';
import {
  ACTION_REQUEST_PAYOUT__SUCCESS,
  ACTION_UPDATE_BANK_ACCOUNT_DETAILS__SUCCESS,
  ACTION_CANCEL_BANK_ACCOUNT__SUCCESS,
  ACTION_ORDER_XCARD__SUCCESS,
  ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
} from '../actionTypes';

import { getCurrentLangForUrl } from '../../core/i18n/selectors';
import { getLoadRevenueSettingsError } from '../selectors/errors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionLoadRevenueSettingsRequest } from '../actions/loadRevenueSettings';


function* reloadRevenueSettings(history, action) {
  try {
    console.consoleLog('reload RevenueSettings by action:', action);

    yield putResolve(actionLoadRevenueSettingsRequest());

    const error = yield select(getLoadRevenueSettingsError);
    if (error) {
      maybeHandleCriticalErrorWithHistory(history, error.globalError);
    }
  } catch (e) {
    yield put(actionAddException(e));
  }
}

export default function* saga(history) {
  const langForUrl = yield select(getCurrentLangForUrl);
  yield takeLatest(action => (
    action.type === ACTION_UPDATE_LOGGED_USER
    && action.payload.user?.revenue
    && history.location.pathname === getRevenueUrl(langForUrl)
  ), reloadRevenueSettings, history);

  yield takeLatest([
    ACTION_REQUEST_PAYOUT__SUCCESS,
    ACTION_UPDATE_BANK_ACCOUNT_DETAILS__SUCCESS,
    ACTION_CANCEL_BANK_ACCOUNT__SUCCESS,
    ACTION_ORDER_XCARD__SUCCESS,
    ACTION_CANCEL_PAYOUT_REQUEST__SUCCESS,
  ], reloadRevenueSettings, history);
}
