import _get from 'lodash/get';

import { useDataLoader } from 'react-redux-app/lib/modules/api/hooks';

import { apiClientHelper } from '../ApiClientHelper';


export default (
  path,
  paramName,
  getExtraParams = null,
  dataKey = null
) => useDataLoader(
  apiClientHelper, path, paramName, getExtraParams, dataKey
);
