import { CRYPTO_BONUS_COOKIE } from '../../chatGallery/constants';

import { getCryptoBonus } from '../../initSettings/selectors';

import { actionGetCredits } from '../../deposit/actions/getCredits';


export const actionGetCryptoBonusCredits = () => (
  (dispatch, getState, { getCookie, deleteCookie }) => {
    if (getCryptoBonus(getState()) && getCookie(CRYPTO_BONUS_COOKIE)) {
      deleteCookie(CRYPTO_BONUS_COOKIE, { path: '/' });

      dispatch(actionGetCredits(null, false, true));
    }
  }
);
