import { combineReducers } from 'redux';

import loadUnderwearError from './loadUnderwearError';
import createUnderwearError from './createUnderwearError';
import deleteUnderwearError from './deleteUnderwearError';
import updateUnderwearError from './updateUnderwearError';


export default combineReducers({
  loadUnderwearError,
  createUnderwearError,
  deleteUnderwearError,
  updateUnderwearError,
});
