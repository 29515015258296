import { combineReducers } from 'redux';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import _unionWith from 'lodash/unionWith';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_ADD_NEWS,
  ACTION_ADD_NEWS_FEED,
  ACTION_ADD_NEWS_FEED_IDS,
  ACTION_SET_NEWS_FEED_IDS,
  ACTION_LOAD_NEWS_FEED__REQUEST,
  ACTION_LOAD_NEWS_FEED__SUCCESS,
  ACTION_LOAD_NEWS_FEED__FAIL,
  ACTION_SET_NEWS_FEED_PRESERVE,
  ACTION_SET_NEWS_FEED_ACTIVE_PAGE,
  ACTION_ADD_PUBLIC_PHOTOS,
  ACTION_ADD_COMMENT_ITEMS,
  ACTION_DELETE_NEWS_FEED,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_NEWS_FEED:
      return { ...state, ...action.payload.newsFeed };

    case ACTION_DELETE_NEWS_FEED:
      return _omit(state, action.payload.newsFeedId);

    default:
      return state;
  }
};

const newsFeed = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_NEWS_FEED_IDS: {
      const items = _union(
        state.items,
        action.payload.newsFeed.items
      );
      const order = _unionWith(
        state.order,
        action.payload.newsFeed.order,
        _isEqual
      );

      return {
        items,
        order,
        count: action.payload.newsFeed.count,
        limit: action.payload.newsFeed.limit,
        staff: action.payload.newsFeed.staff,
      };
    }

    case ACTION_DELETE_NEWS_FEED: {
      if (!state.order) {
        return state;
      }

      return {
        ...state,
        items: _without(state.items, action.payload.newsFeedId),
        order: state.order.filter(item => (item.id !== action.payload.newsFeedId)),
        count: state.count - 1,
      };
    }

    case ACTION_SET_NEWS_FEED_IDS:
      return action.payload.newsFeed;

    default:
      return state;
  }
};

const pubicPhotosById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_PUBLIC_PHOTOS:
      return { ...state, ...action.payload.publicPhotos };

    default:
      return state;
  }
};

const commentItemsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_COMMENT_ITEMS:
      return { ...state, ...action.payload.commentItems };

    default:
      return state;
  }
};

const newsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_NEWS:
      return { ...state, ...action.payload.news };

    default:
      return state;
  }
};

const isNewsFeedBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_NEWS_FEED:
      return _omit(state, action.payload.newsFeedId);

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_NEWS_FEED_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_NEWS_FEED__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_NEWS_FEED__SUCCESS:
    case ACTION_LOAD_NEWS_FEED__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_NEWS_FEED_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

const shouldReloadActivePage = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_NEWS_FEED:
      return true;

    case ACTION_LOAD_NEWS_FEED__SUCCESS:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  newsFeed,
  commentItemsById,
  pubicPhotosById,
  newsById,
  isNewsFeedBeingDeleted,
  paginationMode,
  activePage,
  shouldReloadActivePage,
  errors,
});
