export const deepMergeStyles = (style = {}, baseStyles) => {
  if (!baseStyles) {
    return style;
  }
  return Object.keys(baseStyles).reduce((result, key) => ({
    ...result, [key]: style[key] ? `${style[key]} ${baseStyles[key]}` : baseStyles[key],
  }), style);
};

/* eslint global-require: "off", import/no-dynamic-require: "off" */
export const getContextByColor = color => require(`./${color}/index.js`).default;
