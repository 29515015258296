export const CURRENCY__EUR = 'EUR';
export const CURRENCY__USD = 'USD';
export const CURRENCY__GBP = 'GBP';
export const CURRENCY__RUB = 'RUB';
export const CURRENCY__CHF = 'CHF';
export const CURRENCY__CRD = 'CRD';

export const CURRENCY__BTC = 'BTC';
export const CURRENCY__ETH = 'ETH';
export const CURRENCY__LTC = 'LTC';
export const CURRENCY__BCH = 'BCH';
export const CURRENCY__XRP = 'XRP';
export const CURRENCY__USDT_TRC20 = 'USDT_TRC20';
export const CURRENCY__USDC_TRC20 = 'USDC_TRC20';
export const CURRENCY__USDT_ERC20 = 'USDT_ERC20';
export const CURRENCY__USDC_ERC20 = 'USDC_ERC20';
export const CURRENCY__EURT_ERC20 = 'EURT_ERC20';
export const CURRENCY__PYUSD_ERC20 = 'PYUSD_ERC20';
export const CURRENCY__TRX = 'TRX';
export const CURRENCY__DOGE = 'DOGE';

export const currencies = [
  CURRENCY__EUR,
  CURRENCY__USD,
  CURRENCY__GBP,
  CURRENCY__RUB,
  CURRENCY__CHF,
  CURRENCY__CRD,

  CURRENCY__BTC,
  CURRENCY__ETH,
  CURRENCY__LTC,
  CURRENCY__BCH,
  CURRENCY__XRP,
  CURRENCY__USDT_TRC20,
  CURRENCY__USDC_TRC20,
  CURRENCY__USDT_ERC20,
  CURRENCY__USDC_ERC20,
  CURRENCY__EURT_ERC20,
  CURRENCY__PYUSD_ERC20,
  CURRENCY__TRX,
  CURRENCY__DOGE,
];

export const currencyToSignMap = {
  [CURRENCY__EUR]: '€',
  [CURRENCY__USD]: '$',
  [CURRENCY__GBP]: '£',
  [CURRENCY__RUB]: 'р.',
  [CURRENCY__CHF]: 'CHF',
  [CURRENCY__CRD]: 'CRD',

  [CURRENCY__BTC]: 'mBTC',
  [CURRENCY__ETH]: 'ETH',
  [CURRENCY__LTC]: 'LTC',
  [CURRENCY__BCH]: 'BCH',
  [CURRENCY__XRP]: 'XRP',
  [CURRENCY__USDT_TRC20]: 'USDT_TRC20',
  [CURRENCY__USDC_TRC20]: 'USDC_TRC20',
  [CURRENCY__USDT_ERC20]: 'USDT_ERC20',
  [CURRENCY__USDC_ERC20]: 'USDC_ERC20',
  [CURRENCY__EURT_ERC20]: 'EURT_ERC20',
  [CURRENCY__PYUSD_ERC20]: 'PYUSD_ERC20',
  [CURRENCY__TRX]: 'TRX',
  [CURRENCY__DOGE]: 'DOGE',
};
