import {
  ACTION_LOAD_LATEST_UNDERWEAR__REQUEST,
  ACTION_LOAD_LATEST_UNDERWEAR__SUCCESS,
  ACTION_LOAD_LATEST_UNDERWEAR__FAIL,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';
import { actionSetUnderwear } from '../../saleItem/actions/underwear';


const actionLoadLatestUnderwearSuccess = () => ({
  type: ACTION_LOAD_LATEST_UNDERWEAR__SUCCESS,
});

const actionLoadLatestUnderwearFail = error => ({
  type: ACTION_LOAD_LATEST_UNDERWEAR__FAIL,
  payload: { error },
});

export const actionLoadLatestUnderwearRequest = (
  { page, limit = 14 } = {}
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LATEST_UNDERWEAR__REQUEST,
    });

    return apiClientHelper.get(
      `underwear/list/${page || 1}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));
        dispatch(actionSetUnderwear(result));
        dispatch(actionLoadLatestUnderwearSuccess());
      },
      error => {
        dispatch(actionLoadLatestUnderwearFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
