import {
  ACTION_COMPLETE_SOCIAL_ATTACHMENT__REQUEST,
  ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS,
  ACTION_COMPLETE_SOCIAL_ATTACHMENT__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionCompleteSocialAttachmentSuccess = socialSettings => ({
  type: ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS,
  payload: { socialSettings },
});

const actionCompleteSocialAttachmentFail = error => ({
  type: ACTION_COMPLETE_SOCIAL_ATTACHMENT__FAIL,
  payload: { error },
});

export const actionCompleteSocialAttachmentRequest = socialToken => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_COMPLETE_SOCIAL_ATTACHMENT__REQUEST,
    });

    return apiClientHelper.post(
      'social_network/connect/after_redirect',
      { data: { socialToken } }
    ).then(
      socialSettings => {
        dispatch(actionCompleteSocialAttachmentSuccess(socialSettings));
      },
      error => {
        dispatch(actionCompleteSocialAttachmentFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
