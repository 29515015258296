import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';
import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';
import { payoutMethods, PAYOUT_STATUS__APPROVED, PAYOUT_STATUS__PENDING } from './index';


export const PAYOUTS_GRID_NAME = 'PayoutsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'requestDate',
  sortDirection: SORT_DIRECTION__DESC,
  statuses: [PAYOUT_STATUS__PENDING, PAYOUT_STATUS__APPROVED],
  methods: payoutMethods,
};
