import { combineReducers } from 'redux';

import loadChatGalleryModelsError from './loadChatGalleryModelsError';
import loadChatGalleryModelError from './loadChatGalleryModelError';
import loadModelsError from './loadModelsError';
import saveWeeklyEventPrizeError from './saveWeeklyEventPrizeError';


export default combineReducers({
  loadChatGalleryModelsError,
  loadChatGalleryModelError,
  loadModelsError,
  saveWeeklyEventPrizeError,
});
