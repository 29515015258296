import {
  ACTION_ADD_INIT_SETTINGS_SEO,

  ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddTranslations } from 'react-redux-app/lib/modules/i18n/actions';
import {
  actionLoadPartialInitSettingsSuccess,
  actionLoadPartialInitSettingsFail,
} from './loadPartialInitSettings';


const actionAddInitSettingsSeo = seo => ({
  type: ACTION_ADD_INIT_SETTINGS_SEO,
  payload: { seo },
});

export const actionLoadTranslationsAndSeoRequest = uiLang => (
  (dispatch, getState, { apiClient, apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PARTIAL_INIT_SETTINGS__REQUEST,
    });

    apiClient.setUiLang(uiLang);

    return apiClientHelper.get(
      'init/partial',
      { params: { fields: 'responseLang,translations,seo' } }
    ).then(
      ({ responseLang, translations, seo }) => {
        dispatch(actionAddInitSettingsSeo({ [responseLang]: seo }));
        dispatch(actionAddTranslations({ [responseLang]: translations }));

        dispatch(actionLoadPartialInitSettingsSuccess());
      },
      error => {
        dispatch(actionLoadPartialInitSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
