import _flatten from 'lodash/flatten';
import _pickBy from 'lodash/pickBy';

import {
  ACTION_ADD_MEDIA_VIEWER_ITEMS,
  ACTION_SET_MEDIA_VIEWER_ITEMS,
  ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST,
  ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS,
  ACTION_LOAD_MEDIA_VIEWER_ITEMS__FAIL,
} from '../actionTypes';

import { MEDIA_VIEWER_ITEM_LIMIT } from '../constants';

import { ITEM_SCHEMA, ITEMS_SCHEMA } from '../../core/schemas/items';
import { OWN_ITEMS_SCHEMA } from '../../ownSaleItem/schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const actionAddMediaViewerItems = items => ({
  type: ACTION_ADD_MEDIA_VIEWER_ITEMS,
  payload: { items },
});

const actionSetMediaViewerItems = items => ({
  type: ACTION_SET_MEDIA_VIEWER_ITEMS,
  payload: { items },
});

const actionLoadMediaViewerItemsSuccess = items => ({
  type: ACTION_LOAD_MEDIA_VIEWER_ITEMS__SUCCESS,
  payload: { items },
});

const actionLoadMediaViewerItemsFail = error => ({
  type: ACTION_LOAD_MEDIA_VIEWER_ITEMS__FAIL,
  payload: { error },
});

const actionLoadMediaViewerItemsRequest = (
  path,
  params = {},
  isBought = false,
  schema = ITEMS_SCHEMA
) => () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_MEDIA_VIEWER_ITEMS__REQUEST,
    });

    return apiClientHelper.get(
      path,
      {
        params: {
          ...params,
          limit: MEDIA_VIEWER_ITEM_LIMIT,
        },
      },
      schema
    ).then(
      ({ result, entities }) => {
        // result - number, array, object
        const resultIds = _flatten([result.items || result]);
        const ids = isBought
          ? resultIds.filter(id => entities.items[id].isBought)
          : resultIds;
        const items = isBought
          ? _pickBy(entities.items, item => item.isBought)
          : (entities.items || {});

        dispatch(actionSetMediaViewerItems(items));
        dispatch(actionLoadMediaViewerItemsSuccess(ids));
      },
      error => {
        dispatch(actionLoadMediaViewerItemsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadMediaViewerPublicPhotosRequest = userId => (
  actionLoadMediaViewerItemsRequest(`user/${userId}/public_photos/1`)
);
export const actionLoadMediaViewerOwnPublicPhotosRequest = () => (
  actionLoadMediaViewerItemsRequest('account/public_photo/list/1')
);
export const actionLoadMediaViewerPrivatePhotosRequest = modelId => (
  actionLoadMediaViewerItemsRequest('private_photo/purchased/list/1', { modelId })
);
export const actionLoadMediaViewerOwnPrivatePhotosRequest = () => (
  actionLoadMediaViewerItemsRequest('account/private_photo/list/1')
);
export const actionLoadMediaViewerLatestPublicPhotosRequest = () => (
  actionLoadMediaViewerItemsRequest('public_photo/latest/1')
);
export const actionLoadMediaViewerLatestPrivatePhotosRequest = () => (
  actionLoadMediaViewerItemsRequest('private_photo/latest/1', {}, true)
);
export const actionLoadMediaViewerProfilePhotoRequest = itemId => (
  actionLoadMediaViewerItemsRequest(`profile_photo/${itemId}`, {}, false, ITEM_SCHEMA)
);
export const actionLoadMediaViewerOwnProfilePhotosRequest = () => (
  actionLoadMediaViewerItemsRequest('account/profile_photo/list', {}, false, OWN_ITEMS_SCHEMA)
);
export const actionLoadMediaViewerPostMediaRequest = postId => (
  actionLoadMediaViewerItemsRequest(`blog/post/${postId}/item/list`, {}, false, OWN_ITEMS_SCHEMA)
);
