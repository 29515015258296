import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { actionSignalRootMounted } from '../../actions/signalRootMounted';

import styles from '../Html/styles/index.css.json';


const propTypes = {
  children: PropTypes.node.isRequired,
};

const Root = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionSignalRootMounted());

    const html = document.getElementsByTagName('html')[0];
    html.classList.remove(styles.html_hidden);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return children;
};

Root.propTypes = propTypes;

export default Root;
