import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getResetDepositRemindError } from '../../selectors/errors';

import { actionResetDepositRemindRequest } from '../../actions/resetDepositRemind';


export default depositRemindType => ({ getState, dispatch }) => (
  function resetDepositRemindHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionResetDepositRemindRequest(depositRemindType)
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getResetDepositRemindError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            console.consoleError('resetDepositRemindHandler error', error);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('resetDepositRemindHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
