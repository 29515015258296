import React from 'react';
import PropTypes from 'prop-types';

import { gatewayAnswerPropType } from '../../propTypes';

import { GATE_ANSWER__YES } from '../../constants';

import GoogleAnalyticsPurchaseEventTracker from '../GoogleAnalyticsPurchaseEventTracker';
import TrafficJunkyTracker from '../TrafficJunkyTracker';


const propTypes = {
  answer: gatewayAnswerPropType,
  id: PropTypes.number,
  amount: PropTypes.number,
};

const DepositTracker = ({ answer = null, id = null, amount = null }) => (
  answer === GATE_ANSWER__YES && id && amount && (
    <>
      <TrafficJunkyTracker depositId={id} amount={amount} />
      <GoogleAnalyticsPurchaseEventTracker depositId={id} amount={amount} />
    </>
  )
);

DepositTracker.propTypes = propTypes;

export default DepositTracker;
