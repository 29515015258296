import {
  ACTION_LOAD_LATEST_VIDEOS__REQUEST,
  ACTION_LOAD_LATEST_VIDEOS__SUCCESS,
  ACTION_LOAD_LATEST_VIDEOS__FAIL,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';
import { actionSetVideos } from '../../saleItem/actions/video';


const actionLoadLatestVideosSuccess = () => ({
  type: ACTION_LOAD_LATEST_VIDEOS__SUCCESS,
});

const actionLoadLatestVideosFail = error => ({
  type: ACTION_LOAD_LATEST_VIDEOS__FAIL,
  payload: { error },
});

export const actionLoadLatestVideosRequest = (
  { page, limit = 10 } = {}
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LATEST_VIDEOS__REQUEST,
    });

    return apiClientHelper.get(
      'video/latest',
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));
        dispatch(actionSetVideos(result));
        dispatch(actionLoadLatestVideosSuccess());
      },
      error => {
        dispatch(actionLoadLatestVideosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
