import { combineReducers } from 'redux';

import loadModelNewsFeedError from './loadModelNewsFeedError';
import loadModelPrivatePhotosError from './loadModelPrivatePhotosError';
import loadModelPublicPhotosError from './loadModelPublicPhotosError';
import loadModelVideosError from './loadModelVideosError';
import loadModelErostoriesError from './loadModelErostoriesError';
import loadModelGiftsError from './loadModelGiftsError';
import loadModelFeedbacksError from './loadModelFeedbacksError';
import updateModelRatingError from './updateModelRatingError';
import loadModelPersonalContactError from './loadModelPersonalContactError';


export default combineReducers({
  loadModelNewsFeedError,
  loadModelPrivatePhotosError,
  loadModelPublicPhotosError,
  loadModelVideosError,
  loadModelErostoriesError,
  loadModelGiftsError,
  loadModelFeedbacksError,
  updateModelRatingError,
  loadModelPersonalContactError,
});
