import { combineReducers } from 'redux';

import errors from './errors';

import {
  ACTION_ADD_APPOINTMENTS,
  ACTION_SET_IS_APPOINTMENT_CREATION_CONFIRMED,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_APPOINTMENTS:
      return { ...state, ...action.payload.appointments };

    default:
      return state;
  }
};

const isCreationConfirmed = (state = false, action) => {
  switch (action.type) {
    case ACTION_SET_IS_APPOINTMENT_CREATION_CONFIRMED:
      return action.payload.isConfirmed;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isCreationConfirmed,
  errors,
});
