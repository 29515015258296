import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'gift.errors');
export const getLoadGiftsError = state => (
  _get(errorsSelector(state), 'loadGiftsError')
);
export const getSendGiftError = state => (
  _get(errorsSelector(state), 'sendGiftError')
);
