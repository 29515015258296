import {
  getFilterByName,
  getModifiedLocationByFilter,
  getModifiedLocationByModifiedFilter,
} from 'react-redux-app/lib/modules/queryFilter/utils';

import {
  MEDIA_VIEWER__QUERY_FILTER_NAME,
  MEDIA_VIEWER__TYPE__PUBLIC_PHOTO,
  MEDIA_VIEWER__TYPE__PRIVATE_PHOTO,
  MEDIA_VIEWER__TYPE__PROFILE_PHOTO,
  MEDIA_VIEWER__TYPE__POST_MEDIA,
  MEDIA_VIEWER__ALBUM__OWN,
  MEDIA_VIEWER__ALBUM__LATEST,
  mediaViewerTypes,
} from '../constants';

import {
  actionLoadMediaViewerPublicPhotosRequest,
  actionLoadMediaViewerOwnPublicPhotosRequest,
  actionLoadMediaViewerPrivatePhotosRequest,
  actionLoadMediaViewerOwnPrivatePhotosRequest,
  actionLoadMediaViewerLatestPublicPhotosRequest,
  actionLoadMediaViewerLatestPrivatePhotosRequest,
  actionLoadMediaViewerProfilePhotoRequest,
  actionLoadMediaViewerOwnProfilePhotosRequest,
  actionLoadMediaViewerPostMediaRequest,
} from '../actions/loadMediaViewerItems';

import {
  actionLoadMediaViewerPublicPhotoInfoRequest,
  actionLoadMediaViewerPrivatePhotoInfoRequest,
  actionLoadMediaViewerProfilePhotoInfoRequest,
  actionLoadMediaViewerPostMediaInfoRequest,
} from '../actions/loadMediaViewerItemInfo';


export const getMediaViewerCloseUrl = location => {
  const modifiedLocation = getModifiedLocationByFilter(
    location, MEDIA_VIEWER__QUERY_FILTER_NAME, null
  );
  modifiedLocation.hash = location.hash;
  return modifiedLocation;
};

export const getMediaViewerItemUrl = (location, type, album, id) => {
  const modifiedLocation = getModifiedLocationByModifiedFilter(
    location, MEDIA_VIEWER__QUERY_FILTER_NAME, { type, album, id }
  );
  modifiedLocation.hash = location.hash;
  return modifiedLocation;
};

export const getMediaViewerFilter = location => {
  const mediaViewerFilter = getFilterByName(location, MEDIA_VIEWER__QUERY_FILTER_NAME);
  if (
    !mediaViewerFilter
    || !mediaViewerTypes.includes(mediaViewerFilter.type)
    || (mediaViewerFilter.type === MEDIA_VIEWER__TYPE__PUBLIC_PHOTO && !mediaViewerFilter.album)
  ) {
    return null;
  }
  return mediaViewerFilter;
};

export const getActionLoadMediaViewerItemsRequest = location => {
  const mediaViewerFilter = getMediaViewerFilter(location);
  if (!mediaViewerFilter) {
    return null;
  }

  const { type, album, id } = mediaViewerFilter;

  if (type === MEDIA_VIEWER__TYPE__PUBLIC_PHOTO) {
    switch (album) {
      case MEDIA_VIEWER__ALBUM__OWN:
        return actionLoadMediaViewerOwnPublicPhotosRequest();

      case MEDIA_VIEWER__ALBUM__LATEST:
        return actionLoadMediaViewerLatestPublicPhotosRequest();

      default:
        return actionLoadMediaViewerPublicPhotosRequest(album);
    }
  }
  if (type === MEDIA_VIEWER__TYPE__PRIVATE_PHOTO) {
    switch (album) {
      case MEDIA_VIEWER__ALBUM__OWN:
        return actionLoadMediaViewerOwnPrivatePhotosRequest();

      case MEDIA_VIEWER__ALBUM__LATEST:
        return actionLoadMediaViewerLatestPrivatePhotosRequest();

      default:
        return actionLoadMediaViewerPrivatePhotosRequest(album);
    }
  }
  if (type === MEDIA_VIEWER__TYPE__PROFILE_PHOTO) {
    switch (album) {
      case MEDIA_VIEWER__ALBUM__OWN:
        return actionLoadMediaViewerOwnProfilePhotosRequest();

      default:
        return actionLoadMediaViewerProfilePhotoRequest(id);
    }
  }
  if (type === MEDIA_VIEWER__TYPE__POST_MEDIA) {
    if (album.startsWith('p')) {
      return actionLoadMediaViewerPostMediaRequest(parseInt(album.slice(1), 10));
    }
  }
  return null;
};

export const getActionLoadMediaViewerItemInfoRequest = location => {
  const mediaViewerFilter = getMediaViewerFilter(location);
  if (!mediaViewerFilter) {
    return null;
  }

  const { type, id } = mediaViewerFilter;

  if (type === MEDIA_VIEWER__TYPE__PUBLIC_PHOTO) {
    return actionLoadMediaViewerPublicPhotoInfoRequest(id);
  }
  if (type === MEDIA_VIEWER__TYPE__PRIVATE_PHOTO) {
    return actionLoadMediaViewerPrivatePhotoInfoRequest(id);
  }
  if (type === MEDIA_VIEWER__TYPE__PROFILE_PHOTO) {
    return actionLoadMediaViewerProfilePhotoInfoRequest(id);
  }
  if (type === MEDIA_VIEWER__TYPE__POST_MEDIA) {
    return actionLoadMediaViewerPostMediaInfoRequest(id);
  }
  return null;
};
