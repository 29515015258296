import { combineReducers } from 'redux';

import loadPersonalContactsError from './loadPersonalContactsError';
import updatePersonalContactError from './updatePersonalContactError';


export default combineReducers({
  loadPersonalContactsError,
  updatePersonalContactError,
});
