import {
  ACTION_UPDATE_EROSTORY__REQUEST,
  ACTION_UPDATE_EROSTORY__SUCCESS,
  ACTION_UPDATE_EROSTORY__FAIL,
} from '../../../actionTypes/erostory';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_UPDATE_EROSTORY__REQUEST:
    case ACTION_UPDATE_EROSTORY__SUCCESS:
      return null;

    case ACTION_UPDATE_EROSTORY__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
