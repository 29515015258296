import {
  ACTION_DIALOGS_MOUNTED,
  ACTION_DIALOGS_UNMOUNTED,
  ACTION_ENABLE_DIALOGS,
  ACTION_DISABLE_DIALOGS,
} from '../actionTypes';


export const actionDialogsMounted = () => ({
  type: ACTION_DIALOGS_MOUNTED,
});

export const actionDialogsUnmounted = () => ({
  type: ACTION_DIALOGS_UNMOUNTED,
});

export const actionEnableDialogs = () => ({
  type: ACTION_ENABLE_DIALOGS,
});

export const actionDisableDialogs = () => ({
  type: ACTION_DISABLE_DIALOGS,
});
