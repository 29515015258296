export const ACTION_ADD_AGENCY_MODELS = 'ACTION_ADD_AGENCY_MODELS';
export const ACTION_SET_MODEL_REGISTRATION_STEPS = 'ACTION_SET_MODEL_REGISTRATION_STEPS';

export const ACTION_UPDATE_MODEL_STATUS__REQUEST = 'ACTION_UPDATE_MODEL_STATUS__REQUEST';
export const ACTION_UPDATE_MODEL_STATUS__SUCCESS = 'ACTION_UPDATE_MODEL_STATUS__SUCCESS';
export const ACTION_UPDATE_MODEL_STATUS__FAIL = 'ACTION_UPDATE_MODEL_STATUS__FAIL';

export const ACTION_LOAD_AGENCY_MODEL__REQUEST = 'ACTION_LOAD_AGENCY_MODEL__REQUEST';
export const ACTION_LOAD_AGENCY_MODEL__SUCCESS = 'ACTION_LOAD_AGENCY_MODEL__SUCCESS';
export const ACTION_LOAD_AGENCY_MODEL__FAIL = 'ACTION_LOAD_AGENCY_MODEL__FAIL';

export const ACTION_LOAD_AGENCY_MODELS__REQUEST = 'ACTION_LOAD_AGENCY_MODELS__REQUEST';
export const ACTION_LOAD_AGENCY_MODELS__SUCCESS = 'ACTION_LOAD_AGENCY_MODELS__SUCCESS';
export const ACTION_LOAD_AGENCY_MODELS__FAIL = 'ACTION_LOAD_AGENCY_MODELS__FAIL';

export const ACTION_LOAD_MODEL_REGISTRATION_STEPS__REQUEST = 'ACTION_LOAD_MODEL_REGISTRATION_STEPS__REQUEST';
export const ACTION_LOAD_MODEL_REGISTRATION_STEPS__SUCCESS = 'ACTION_LOAD_MODEL_REGISTRATION_STEPS__SUCCESS';
export const ACTION_LOAD_MODEL_REGISTRATION_STEPS__FAIL = 'ACTION_LOAD_MODEL_REGISTRATION_STEPS__FAIL';

export const ACTION_APPROVE_PROFILE_PHOTO__REQUEST = 'ACTION_APPROVE_PROFILE_PHOTO__REQUEST';
export const ACTION_APPROVE_PROFILE_PHOTO__SUCCESS = 'ACTION_APPROVE_PROFILE_PHOTO__SUCCESS';
export const ACTION_APPROVE_PROFILE_PHOTO__FAIL = 'ACTION_APPROVE_PROFILE_PHOTO__FAIL';

export const ACTION_CANCEL_PROFILE_PHOTO__REQUEST = 'ACTION_CANCEL_PROFILE_PHOTO__REQUEST';
export const ACTION_CANCEL_PROFILE_PHOTO__SUCCESS = 'ACTION_CANCEL_PROFILE_PHOTO__SUCCESS';
export const ACTION_CANCEL_PROFILE_PHOTO__FAIL = 'ACTION_CANCEL_PROFILE_PHOTO__FAIL';

export const ACTION_INSPECT_MODEL__REQUEST = 'ACTION_INSPECT_MODEL__REQUEST';
export const ACTION_INSPECT_MODEL__SUCCESS = 'ACTION_INSPECT_MODEL__SUCCESS';
export const ACTION_INSPECT_MODEL__FAIL = 'ACTION_INSPECT_MODEL__FAIL';

export const ACTION_ROTATE_PHOTO__REQUEST = 'ACTION_ROTATE_PHOTO__REQUEST';
export const ACTION_ROTATE_PHOTO__SUCCESS = 'ACTION_ROTATE_PHOTO__SUCCESS';
export const ACTION_ROTATE_PHOTO__FAIL = 'ACTION_ROTATE_PHOTO__FAIL';
