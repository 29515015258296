import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_LOAD_VISITORS__REQUEST,
  ACTION_LOAD_VISITORS__SUCCESS,
  ACTION_LOAD_VISITORS__FAIL,
  ACTION_ADD_VISITORS,
  ACTION_ADD_VISITOR_IDS,
  ACTION_SET_VISITOR_IDS,
  ACTION_SET_VISITORS_PRESERVE,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_VISITORS:
      return { ...state, ...action.payload.visitors };

    default:
      return state;
  }
};

const visitors = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_VISITOR_IDS: {
      const items = _union(
        state.items,
        action.payload.visitors.items
      );

      return {
        items,
        count: action.payload.visitors.count,
        limit: action.payload.visitors.limit,
      };
    }

    case ACTION_SET_VISITOR_IDS:
      return action.payload.visitors;

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_VISITORS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_VISITORS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_VISITORS__SUCCESS:
    case ACTION_LOAD_VISITORS__FAIL:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  visitors,
  paginationMode,
  errors,
});
