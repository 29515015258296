export const ACTION_ADD_EROSTORIES = 'ACTION_ADD_EROSTORIES';
export const ACTION_SET_EROSTORIES = 'ACTION_SET_EROSTORIES';

export const ACTION_LOAD_EROSTORIES__REQUEST = 'ACTION_LOAD_EROSTORIES__REQUEST';
export const ACTION_LOAD_EROSTORIES__SUCCESS = 'ACTION_LOAD_EROSTORIES__SUCCESS';
export const ACTION_LOAD_EROSTORIES__FAIL = 'ACTION_LOAD_EROSTORIES__FAIL';

export const ACTION_SET_EROSTORIES_PRESERVE = 'ACTION_SET_EROSTORIES_PRESERVE';

export const ACTION_LOAD_EROSTORY_CATEGORIES__REQUEST = 'ACTION_LOAD_EROSTORY_CATEGORIES__REQUEST';
export const ACTION_LOAD_EROSTORY_CATEGORIES__SUCCESS = 'ACTION_LOAD_EROSTORY_CATEGORIES__SUCCESS';
export const ACTION_LOAD_EROSTORY_CATEGORIES__FAIL = 'ACTION_LOAD_EROSTORY_CATEGORIES__FAIL';
