export const ACTION_LOAD_STEP__REQUEST = 'ACTION_LOAD_STEP__REQUEST';
export const ACTION_LOAD_STEP__SUCCESS = 'ACTION_LOAD_STEP__SUCCESS';
export const ACTION_LOAD_STEP__FAIL = 'ACTION_LOAD_STEP__FAIL';

export const ACTION_ABORT_SIGNUP__REQUEST = 'ACTION_ABORT_SIGNUP__REQUEST';
export const ACTION_ABORT_SIGNUP__SUCCESS = 'ACTION_ABORT_SIGNUP__SUCCESS';
export const ACTION_ABORT_SIGNUP__FAIL = 'ACTION_ABORT_SIGNUP__FAIL';

export const ACTION_ACCOUNT_INFO__REQUEST = 'ACTION_ACCOUNT_INFO__REQUEST';
export const ACTION_ACCOUNT_INFO__SUCCESS = 'ACTION_ACCOUNT_INFO__SUCCESS';
export const ACTION_ACCOUNT_INFO__FAIL = 'ACTION_ACCOUNT_INFO__FAIL';

export const ACTION_ID_PHOTO__REQUEST = 'ACTION_ID_PHOTO__REQUEST';
export const ACTION_ID_PHOTO__SUCCESS = 'ACTION_ID_PHOTO__SUCCESS';
export const ACTION_ID_PHOTO__FAIL = 'ACTION_ID_PHOTO__FAIL';

export const ACTION_PROFILE_PHOTO__REQUEST = 'ACTION_PROFILE_PHOTO__REQUEST';
export const ACTION_PROFILE_PHOTO__SUCCESS = 'ACTION_PROFILE_PHOTO__SUCCESS';
export const ACTION_PROFILE_PHOTO__FAIL = 'ACTION_PROFILE_PHOTO__FAIL';

export const ACTION_FACESHOT__REQUEST = 'ACTION_FACESHOT__REQUEST';
export const ACTION_FACESHOT__SUCCESS = 'ACTION_FACESHOT__SUCCESS';
export const ACTION_FACESHOT__FAIL = 'ACTION_FACESHOT__FAIL';

export const ACTION_DOWNLOAD_AGREEMENT__REQUEST = 'ACTION_DOWNLOAD_AGREEMENT__REQUEST';
export const ACTION_DOWNLOAD_AGREEMENT__SUCCESS = 'ACTION_DOWNLOAD_AGREEMENT__SUCCESS';
export const ACTION_DOWNLOAD_AGREEMENT__FAIL = 'ACTION_DOWNLOAD_AGREEMENT__FAIL';

export const ACTION_AGREEMENT__REQUEST = 'ACTION_AGREEMENT__REQUEST';
export const ACTION_AGREEMENT__SUCCESS = 'ACTION_AGREEMENT__SUCCESS';
export const ACTION_AGREEMENT__FAIL = 'ACTION_AGREEMENT__FAIL';

export const ACTION_SET_IS_AGREEMENT_ACCEPTED = 'ACTION_SET_IS_AGREEMENT_ACCEPTED';
