import { combineReducers } from 'redux';

import loadStepError from './loadStepError';
import abortSignupError from './abortSignupError';
import accountInfoError from './accountInfoError';
import idPhotoError from './idPhotoError';
import profilePhotoError from './profilePhotoError';
import faceshotError from './faceshotError';
import agreementError from './agreementError';
import downloadAgreementError from './downloadAgreementError';


export default combineReducers({
  loadStepError,
  abortSignupError,
  accountInfoError,
  idPhotoError,
  profilePhotoError,
  faceshotError,
  agreementError,
  downloadAgreementError,
});
