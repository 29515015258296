import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getItem, LOCAL_STORAGE } from 'react-redux-app/lib/modules/storage';

import { STORAGE_KEY__SUPPRESS_REQUEST_PERMISSION_PANEL } from '../constants';

import { actionHidePermissionRequestPanel } from '../actions/panel';


export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionHidePermissionRequestPanel(
      !!getItem(LOCAL_STORAGE, STORAGE_KEY__SUPPRESS_REQUEST_PERMISSION_PANEL)
    ));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
