export const ACTION_ADD_APPOINTMENTS = 'ACTION_ADD_APPOINTMENTS';

export const ACTION_CREATE_APPOINTMENT__REQUEST = 'ACTION_CREATE_APPOINTMENT__REQUEST';
export const ACTION_CREATE_APPOINTMENT__SUCCESS = 'ACTION_CREATE_APPOINTMENT__SUCCESS';
export const ACTION_CREATE_APPOINTMENT__FAIL = 'ACTION_CREATE_APPOINTMENT__FAIL';

export const ACTION_SET_IS_APPOINTMENT_CREATION_CONFIRMED = 'ACTION_SET_IS_APPOINTMENT_CREATION_CONFIRMED';

export const ACTION_CANCEL_APPOINTMENT__REQUEST = 'ACTION_CANCEL_APPOINTMENT__REQUEST';
export const ACTION_CANCEL_APPOINTMENT__SUCCESS = 'ACTION_CANCEL_APPOINTMENT__SUCCESS';
export const ACTION_CANCEL_APPOINTMENT__FAIL = 'ACTION_CANCEL_APPOINTMENT__FAIL';

export const ACTION_APPROVE_APPOINTMENT__REQUEST = 'ACTION_APPROVE_APPOINTMENT__REQUEST';
export const ACTION_APPROVE_APPOINTMENT__SUCCESS = 'ACTION_APPROVE_APPOINTMENT__SUCCESS';
export const ACTION_APPROVE_APPOINTMENT__FAIL = 'ACTION_APPROVE_APPOINTMENT__FAIL';
