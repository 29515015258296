export const ACTION_SET_OWN_VIDEOS = 'ACTION_SET_OWN_VIDEOS';
export const ACTION_ADD_OWN_VIDEOS = 'ACTION_ADD_OWN_VIDEOS';

export const ACTION_SET_OWN_VIDEOS_PRESERVE = 'ACTION_SET_OWN_VIDEOS_PRESERVE';
export const ACTION_SET_OWN_VIDEO_ACTIVE_PAGE = 'ACTION_SET_OWN_VIDEO_ACTIVE_PAGE';

export const ACTION_LOAD_OWN_VIDEOS__REQUEST = 'ACTION_LOAD_OWN_VIDEOS__REQUEST';
export const ACTION_LOAD_OWN_VIDEOS__SUCCESS = 'ACTION_LOAD_OWN_VIDEOS__SUCCESS';
export const ACTION_LOAD_OWN_VIDEOS__FAIL = 'ACTION_LOAD_OWN_VIDEOS__FAIL';

export const ACTION_CREATE_VIDEO__REQUEST = 'ACTION_CREATE_VIDEO__REQUEST';
export const ACTION_CREATE_VIDEO__SUCCESS = 'ACTION_CREATE_VIDEO__SUCCESS';
export const ACTION_CREATE_VIDEO__FAIL = 'ACTION_CREATE_VIDEO__FAIL';

export const ACTION_DELETE_VIDEO__REQUEST = 'ACTION_DELETE_VIDEO__REQUEST';
export const ACTION_DELETE_VIDEO__SUCCESS = 'ACTION_DELETE_VIDEO__SUCCESS';
export const ACTION_DELETE_VIDEO__FAIL = 'ACTION_DELETE_VIDEO__FAIL';

export const ACTION_UPDATE_VIDEO__REQUEST = 'ACTION_UPDATE_VIDEO__REQUEST';
export const ACTION_UPDATE_VIDEO__SUCCESS = 'ACTION_UPDATE_VIDEO__SUCCESS';
export const ACTION_UPDATE_VIDEO__FAIL = 'ACTION_UPDATE_VIDEO__FAIL';
