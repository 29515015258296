import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'initSettings.errors');
export const getLoadInitSettingsError = state => (
  _get(errorsSelector(state), 'loadInitSettingsError')
);
export const getLoadPartialInitSettingsError = state => (
  _get(errorsSelector(state), 'loadPartialInitSettingsError')
);
