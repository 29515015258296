export const ACTION_CLEAR_PARTNER_STATE = 'ACTION_CLEAR_PARTNER_STATE';

export const ACTION_ADD_PARTNERS = 'ACTION_ADD_PARTNERS';

export const ACTION_CREATE_PARTNER__REQUEST = 'ACTION_CREATE_PARTNER__REQUEST';
export const ACTION_CREATE_PARTNER__SUCCESS = 'ACTION_CREATE_PARTNER__SUCCESS';
export const ACTION_CREATE_PARTNER__FAIL = 'ACTION_CREATE_PARTNER__FAIL';

export const ACTION_DELETE_PARTNER__REQUEST = 'ACTION_DELETE_PARTNER__REQUEST';
export const ACTION_DELETE_PARTNER__SUCCESS = 'ACTION_DELETE_PARTNER__SUCCESS';
export const ACTION_DELETE_PARTNER__FAIL = 'ACTION_DELETE_PARTNER__FAIL';

export const ACTION_UPDATE_PARTNER__REQUEST = 'ACTION_UPDATE_PARTNER__REQUEST';
export const ACTION_UPDATE_PARTNER__SUCCESS = 'ACTION_UPDATE_PARTNER__SUCCESS';
export const ACTION_UPDATE_PARTNER__FAIL = 'ACTION_UPDATE_PARTNER__FAIL';

export const ACTION_UPLOAD_PARTNER_FILE__REQUEST = 'ACTION_UPLOAD_PARTNER_FILE__REQUEST';
export const ACTION_UPLOAD_PARTNER_FILE__SUCCESS = 'ACTION_UPLOAD_PARTNER_FILE__SUCCESS';
export const ACTION_UPLOAD_PARTNER_FILE__FAIL = 'ACTION_UPLOAD_PARTNER_FILE__FAIL';

export const ACTION_LOAD_PARTNER_NEW_FILE__REQUEST = 'ACTION_LOAD_PARTNER_NEW_FILE__REQUEST';
export const ACTION_LOAD_PARTNER_NEW_FILE__SUCCESS = 'ACTION_LOAD_PARTNER_NEW_FILE__SUCCESS';
export const ACTION_LOAD_PARTNER_NEW_FILE__FAIL = 'ACTION_LOAD_PARTNER_NEW_FILE__FAIL';

export const ACTION_LOAD_PARTNER_SAVED_FILE__REQUEST = 'ACTION_LOAD_PARTNER_SAVED_FILE__REQUEST';
export const ACTION_LOAD_PARTNER_SAVED_FILE__SUCCESS = 'ACTION_LOAD_PARTNER_SAVED_FILE__SUCCESS';
export const ACTION_LOAD_PARTNER_SAVED_FILE__FAIL = 'ACTION_LOAD_PARTNER_SAVED_FILE__FAIL';

export const ACTION_DOWNLOAD_PARTNER_AGREEMENT__REQUEST = 'ACTION_DOWNLOAD_PARTNER_AGREEMENT__REQUEST';
export const ACTION_DOWNLOAD_PARTNER_AGREEMENT__SUCCESS = 'ACTION_DOWNLOAD_PARTNER_AGREEMENT__SUCCESS';
export const ACTION_DOWNLOAD_PARTNER_AGREEMENT__FAIL = 'ACTION_DOWNLOAD_PARTNER_AGREEMENT__FAIL';
