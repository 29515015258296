import {
  ACTION_LOAD_INTERVIEWER__REQUEST,
  ACTION_LOAD_INTERVIEWER__SUCCESS,
  ACTION_LOAD_INTERVIEWER__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadInterviewerSuccess = interviewer => ({
  type: ACTION_LOAD_INTERVIEWER__SUCCESS,
  payload: { interviewer },
});

const actionLoadInterviewerFail = error => ({
  type: ACTION_LOAD_INTERVIEWER__FAIL,
  payload: { error },
});

export const actionLoadInterviewerRequest = id => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_INTERVIEWER__REQUEST,
    });

    return apiClientHelper.get(
      `account/interview/form_with_questions/${id}`
    ).then(
      interviewer => {
        dispatch(actionLoadInterviewerSuccess(interviewer));
      },
      error => {
        dispatch(actionLoadInterviewerFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
