import { combineReducers } from 'redux';
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';

import errors from './errors';

import { ACTION_ADD_AWARDS } from '../../../award/actionTypes';
import { ACTION_SET_BLOG } from '../../../blog/actionTypes';
import { ACTION_ADD_MODELS } from '../../../chatGallery/actionTypes';
import { ACTION_ADD_FAVORITES } from '../../../favorite/actionTypes';
import { ACTION_SET_MODEL } from '../../../model/actionTypes';
import { ACTION_SET_USER } from '../../../user/actionTypes';
import { ACTION_ADD_VISITORS } from '../../../visitor/actionTypes';
import {
  ACTION_ADD_TO_FAVORITES__SUCCESS,
  ACTION_REMOVE_FROM_FAVORITES__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_TO_FAVORITES__SUCCESS:
      return { ...state, [action.payload.userId]: true };

    case ACTION_REMOVE_FROM_FAVORITES__SUCCESS:
      return { ...state, [action.payload.userId]: false };

    case ACTION_ADD_MODELS:
      return { ...state, ..._mapValues(action.payload.models, model => model.isFavorite) };

    case ACTION_SET_MODEL:
      return { ...state, [action.payload.model.id]: action.payload.model.isFavorite };

    case ACTION_SET_USER:
      return { ...state, [action.payload.user.id]: action.payload.user.isFavorite };

    case ACTION_ADD_FAVORITES:
      return { ...state, ..._mapValues(action.payload.favorites, () => true) };

    case ACTION_ADD_VISITORS:
      return { ...state, ..._mapValues(action.payload.visitors, visitor => visitor.isFavorite) };

    case ACTION_ADD_AWARDS:
      return _mapValues(action.payload.awards, award => award.isFavorite);

    case ACTION_SET_BLOG:
      return { ...state, [action.payload.blog.id]: action.payload.blog.isFavorite };

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  errors,
});
