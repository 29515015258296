import { combineReducers } from 'redux';

import { ACTION_SET_COLS_SIZE } from '../actionTypes';

import { GRID_COLS_SIZE__MEDIUM } from '../constants';


const gridColsSize = (state = GRID_COLS_SIZE__MEDIUM, action) => {
  switch (action.type) {
    case ACTION_SET_COLS_SIZE:
      return action.payload.colsSize;

    default:
      return state;
  }
};

export default combineReducers({
  gridColsSize,
});
