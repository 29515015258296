import { combineReducers } from 'redux';

import cancelBankAccountError from './cancelBankAccountError';
import cancelPayoutRequestError from './cancelPayoutRequestError';
import connectRevolutError from './connectRevolutError';
import deletePayoutRequestNoticeError from './deletePayoutRequestNoticeError';
import downloadPayoutInvoiceError from './downloadPayoutInvoiceError';
import loadBankAccountFileError from './loadBankAccountFileError';
import loadLatestSalesError from './loadLatestSalesError';
import loadPayoutComplaintSettingsError from './loadPayoutComplaintSettingsError';
import loadRevenueSettingsError from './loadRevenueSettingsError';
import orderXCardError from './orderXCardError';
import requestPayoutError from './requestPayoutError';
import sendPayoutComplaintError from './sendPayoutComplaintError';
import submitFiscalNumberError from './submitFiscalNumberError';
import updateBankAccountDetailsError from './updateBankAccountDetailsError';


export default combineReducers({
  cancelBankAccountError,
  cancelPayoutRequestError,
  connectRevolutError,
  deletePayoutRequestNoticeError,
  downloadPayoutInvoiceError,
  loadBankAccountFileError,
  loadLatestSalesError,
  loadPayoutComplaintSettingsError,
  loadRevenueSettingsError,
  orderXCardError,
  requestPayoutError,
  sendPayoutComplaintError,
  submitFiscalNumberError,
  updateBankAccountDetailsError,
});
