import { combineReducers } from 'redux';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_CLEAR_DIALOG_MESSAGE_STATE,
  ACTION_LOAD_PEER__SUCCESS,
  ACTION_ADD_DIALOG_MESSAGES,
  ACTION_ADD_OLD_DIALOG_MESSAGES_IDS,
  ACTION_DELETE_MESSAGES__FAIL,
  ACTION_DELETE_MESSAGES__REQUEST,
  ACTION_DELETE_MESSAGES__SUCCESS,
  ACTION_CHOOSE_MESSAGE,
  ACTION_UNCHOOSE_MESSAGE,
  ACTION_ADD_NEW_DIALOG_MESSAGES_IDS,
  ACTION_CLEAR_NEW_MESSAGE_COUNT,
  ACTION_SEND_MESSAGE__SUCCESS,
  ACTION_ADD_ATTACHMENT,
  ACTION_DELETE_ATTACHMENT__SUCCESS,
} from '../actionTypes';

import { defaultItemsState } from '../../core/constants/items';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_DIALOG_MESSAGES:
      return { ...state, ...action.payload.dialogMessages };

    case ACTION_DELETE_MESSAGES__SUCCESS:
      return _omit(state, action.payload.ids);

    default:
      return state;
  }
};

const dialogMessages = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OLD_DIALOG_MESSAGES_IDS: {
      const items = [
        ...action.payload.dialogMessages.items,
        ...state.items,
      ];

      return {
        items,
        count: action.payload.dialogMessages.count,
        limit: action.payload.dialogMessages.limit,
      };
    }

    case ACTION_ADD_NEW_DIALOG_MESSAGES_IDS: {
      const items = [
        ...state.items,
        ...action.payload.dialogMessages.items,
      ];

      return {
        items,
        count: action.payload.dialogMessages.count,
        limit: action.payload.dialogMessages.limit,
      };
    }

    case ACTION_DELETE_MESSAGES__SUCCESS:
      return {
        items: _difference(state.items, action.payload.ids),
        count: state.count - action.payload.ids.length,
        limit: state.limit,
      };

    default:
      return state;
  }
};

const areMessagesBeingDeleted = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_MESSAGES__REQUEST:
      return true;

    case ACTION_DELETE_MESSAGES__SUCCESS:
    case ACTION_DELETE_MESSAGES__FAIL:
      return false;

    default:
      return state;
  }
};

const chosenMessages = (state = [], action) => {
  switch (action.type) {
    case ACTION_CHOOSE_MESSAGE:
      return [...state, action.payload.messageId];

    case ACTION_UNCHOOSE_MESSAGE:
      return state.filter(id => id !== action.payload.messageId);

    case ACTION_DELETE_MESSAGES__SUCCESS:
      return [];

    default:
      return state;
  }
};

const newMessagesCount = (state = 0, action) => {
  switch (action.type) {
    case ACTION_ADD_NEW_DIALOG_MESSAGES_IDS:
      return state + action.payload.dialogMessages.items.length;

    case ACTION_CLEAR_NEW_MESSAGE_COUNT:
      return 0;

    default:
      return state;
  }
};

const peer = (state = {}, action) => {
  switch (action.type) {
    case ACTION_LOAD_PEER__SUCCESS:
      return action.payload.peer;

    default:
      return state;
  }
};

const attachments = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_ATTACHMENT:
      return { ...state, [action.payload.attachment.id]: action.payload.attachment };

    case ACTION_DELETE_ATTACHMENT__SUCCESS:
      return _omit(state, action.payload.attachmentId);

    case ACTION_SEND_MESSAGE__SUCCESS:
      return {};

    default:
      return state;
  }
};

const dialogMessageReducer = combineReducers({
  byId,
  dialogMessages,
  areMessagesBeingDeleted,
  chosenMessages,
  peer,
  newMessagesCount,
  attachments,
  errors,
});

export default (state, action) => (
  action.type === ACTION_CLEAR_DIALOG_MESSAGE_STATE
    ? dialogMessageReducer(undefined, action)
    : dialogMessageReducer(state, action)
);
