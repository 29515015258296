import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsAuthenticated } from '../../../auth/hooks';

import { getAccountSettingsUrl } from '../../utils/url';

import { getHeaderLandingPages } from '../../../initSettings/selectors';

import LangLink from '../../../core/i18n/components/LangLink';
import AuthButtons from '../AuthButtons';
import DropdownItemSubmenu from '../DropdownItemSubmenu';
import MenuItems from '../MenuItems';
import ProfilePhotoPreview from '../ProfilePhotoPreview';
import UserMenuHeader from '../UserMenuHeader';
import UserMenuItems from '../UserMenuItems';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { MENU_DROPDOWN } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'dropdownMenu',
  'dropdownMenu__toggle',
  'dropdownMenu__dropdown',
  'dropdownMenu__dropdown__photo',
];

const propTypes = {
  className: PropTypes.string.isRequired,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const DropdownMenuWrapper = ({ className, theme }) => {
  const landingPages = useShortSelector(getHeaderLandingPages);
  const isAuthenticated = useIsAuthenticated();

  return (
    <UncontrolledDropdown className={classnames(theme.dropdownMenu, className)}>
      <DropdownToggle
        tag="i"
        className={classnames('material-icons-outlined', theme.dropdownMenu__toggle)}
      >
        menu
      </DropdownToggle>

      <DropdownMenu end className={theme.dropdownMenu__dropdown}>
        {!isAuthenticated ? <AuthButtons isDropdownItem /> : (
          <>
            <DropdownItem
              tag={LangLink}
              to={getAccountSettingsUrl()}
              className={theme.dropdownMenu__dropdown__photo}
            >
              <ProfilePhotoPreview isMenu />
            </DropdownItem>

            <UserMenuHeader />
          </>
        )}

        <DropdownItem divider />

        <MenuItems
          itemComponent={DropdownItem}
          itemComponentProps={{ tag: LangLink }}
          dropdownComponent={DropdownItemSubmenu}
        />

        {landingPages && <DropdownItemSubmenu itemLangKey="msg.content" items={landingPages} />}

        {isAuthenticated && (
          <>
            <DropdownItem divider />

            <UserMenuItems />
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

DropdownMenuWrapper.propTypes = propTypes;

export default theming.theme(
  MENU_DROPDOWN,
  baseCssJson,
  themeApi
)(DropdownMenuWrapper);
