import { combineReducers } from 'redux';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _union from 'lodash/union';
import _uniq from 'lodash/uniq';

import errors from './errors';

import {
  ACTION_LOGIN_BY_CREDENTIALS__SUCCESS,
  ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS,
} from '../../../auth/actionTypes';
import {
  ACTION_ADD_MODELS,
  ACTION_ADD_MODEL,
  ACTION_SET_MODEL_CHAT_STATUSES,
  ACTION_UPDATE_MODEL_CHAT_STATUS,
  ACTION_SET_MODEL_ORDER,
  ACTION_INSERT_MODEL,
  ACTION_SWAP_MODELS,
  ACTION_LOAD_MODELS__SUCCESS,
  ACTION_LOAD_MODELS__REQUEST,
  ACTION_LOAD_MODELS__FAIL,
  ACTION_SET_MODELS_PRESERVE,
  ACTION_SET_MODEL_IDS,
  ACTION_ADD_MODEL_IDS,
  ACTION_SET_ALLOWED_TYPES,
  ACTION_SET_WEEKEND_IMAGE,
  ACTION_SET_CREDITS_BONUS,
} from '../../actionTypes';

import { defaultItemsState } from '../../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../../core/loadableItems/constants';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_MODELS:
      return { ...state, ...action.payload.models };

    case ACTION_ADD_MODEL:
      return { ...state, [action.payload.model.id]: action.payload.model };

    case ACTION_LOGIN_BY_CREDENTIALS__SUCCESS:
    case ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS:
      return {};

    default:
      return state;
  }
};

const chatStatus = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_MODEL_CHAT_STATUSES:
      return action.payload.statuses;

    case ACTION_UPDATE_MODEL_CHAT_STATUS:
      return {
        ...state,
        [action.payload.modelId]: {
          status: action.payload.status,
          stream: action.payload.stream,
        },
      };

    default:
      return state;
  }
};

const isTypeAllowed = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_ALLOWED_TYPES:
      // _merge mutates first argument
      return _merge(_cloneDeep(state), action.payload.allowedTypes);

    default:
      return state;
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case ACTION_SET_MODEL_ORDER:
      return action.payload.order;

    case ACTION_INSERT_MODEL: {
      const { modelId, position } = action.payload;

      const newState = [...state];
      newState.splice(position, 1, modelId);

      return _uniq(newState);
    }

    case ACTION_SWAP_MODELS: {
      const { position1, position2 } = action.payload;

      const newState = [...state];

      [newState[position1], newState[position2]] = [newState[position2], newState[position1]];

      return newState;
    }

    case ACTION_LOGIN_BY_CREDENTIALS__SUCCESS:
    case ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS:
      return [];

    default:
      return state;
  }
};

const weekendImage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_WEEKEND_IMAGE:
      return action.payload.image;

    default:
      return state;
  }
};

const creditsBonusByType = (state = {}, action) => {
  switch (action.type) {
    case ACTION_SET_CREDITS_BONUS:
      return { ...state, [action.payload.type]: action.payload.bonus };

    default:
      return state;
  }
};

const models = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_MODEL_IDS: {
      const items = _union(
        state.items,
        action.payload.models.items
      );

      return {
        items,
        count: action.payload.models.count,
        limit: action.payload.models.limit,
      };
    }

    case ACTION_SET_MODEL_IDS:
      return action.payload.models;

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_MODELS_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_MODELS__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_MODELS__SUCCESS:
    case ACTION_LOAD_MODELS__FAIL:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  chatStatus,
  isTypeAllowed,
  order,
  weekendImage,
  creditsBonusByType,
  models,
  paginationMode,
  errors,
});
