import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__REQUEST,
  ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS,
  ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadMediaViewerItemInfoSuccess = itemInfo => ({
  type: ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__SUCCESS,
  payload: { itemInfo },
});

const actionLoadMediaViewerItemInfoFail = error => ({
  type: ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__FAIL,
  payload: { error },
});

const actionLoadMediaViewerItemInfoRequest = (path, preloadPhoto, preloadVideo) => () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_MEDIA_VIEWER_ITEM_INFO__REQUEST,
    });

    return apiClientHelper.get(
      path
    ).then(
      itemInfo => {
        if (preloadPhoto || preloadVideo) {
          return preloadImage(
            preloadPhoto ? itemInfo.photo.previewUrl : itemInfo.video.thumbUrl
          ).then(() => {
            dispatch(actionLoadMediaViewerItemInfoSuccess(itemInfo));
          });
        }

        return dispatch(actionLoadMediaViewerItemInfoSuccess(itemInfo));
      },
      error => {
        dispatch(actionLoadMediaViewerItemInfoFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadMediaViewerProfilePhotoInfoRequest = itemId => (
  actionLoadMediaViewerItemInfoRequest(`profile_photo/${itemId}/show`)
);
export const actionLoadMediaViewerPublicPhotoInfoRequest = itemId => (
  actionLoadMediaViewerItemInfoRequest(`public_photo/${itemId}/show`)
);
export const actionLoadMediaViewerPrivatePhotoInfoRequest = (itemId, preloadPhoto = false) => (
  actionLoadMediaViewerItemInfoRequest(`private_photo/${itemId}/show`, preloadPhoto, false)
);
export const actionLoadMediaViewerVideoInfoRequest = (itemId, preloadVideo = false) => (
  actionLoadMediaViewerItemInfoRequest(`video/${itemId}/show`, false, preloadVideo)
);
export const actionLoadMediaViewerPostMediaInfoRequest = itemId => (
  actionLoadMediaViewerItemInfoRequest(`blog/post/item/${itemId}/show`)
);
