import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_LOAD_OWN_UNDERWEAR__REQUEST,
  ACTION_LOAD_OWN_UNDERWEAR__SUCCESS,
  ACTION_LOAD_OWN_UNDERWEAR__FAIL,
  ACTION_SET_OWN_UNDERWEAR,
  ACTION_ADD_OWN_UNDERWEAR,
  ACTION_SET_OWN_UNDERWEAR_PRESERVE,
  ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE,
  ACTION_CREATE_UNDERWEAR__REQUEST,
  ACTION_CREATE_UNDERWEAR__SUCCESS,
  ACTION_DELETE_UNDERWEAR__REQUEST,
  ACTION_DELETE_UNDERWEAR__SUCCESS,
  ACTION_DELETE_UNDERWEAR__FAIL,
} from '../../actionTypes/underwear';

import { defaultItemsState } from '../../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../../core/loadableItems/constants';


const underwear = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_UNDERWEAR: {
      const items = _union(
        state.items,
        action.payload.underwear.items
      );

      return {
        items,
        count: action.payload.underwear.count,
        limit: action.payload.underwear.limit,
      };
    }

    case ACTION_SET_OWN_UNDERWEAR:
      return action.payload.underwear;

    case ACTION_DELETE_UNDERWEAR__SUCCESS:
      return {
        items: _without(state.items, action.payload.underwearId),
        count: state.count - 1,
        limit: state.limit,
      };

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_UNDERWEAR_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_OWN_UNDERWEAR__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_OWN_UNDERWEAR__SUCCESS:
    case ACTION_LOAD_OWN_UNDERWEAR__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

const shouldLoadFirstPage = (state = false, action) => {
  switch (action.type) {
    case ACTION_CREATE_UNDERWEAR__REQUEST:
      return false;

    case ACTION_CREATE_UNDERWEAR__SUCCESS:
      return true;

    default:
      return state;
  }
};

const shouldReloadActivePage = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_UNDERWEAR__REQUEST:
      return false;

    case ACTION_DELETE_UNDERWEAR__SUCCESS:
      return true;

    default:
      return state;
  }
};

const isUnderwearBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_UNDERWEAR__REQUEST:
      return { ...state, [action.payload.underwearId]: true };

    case ACTION_DELETE_UNDERWEAR__SUCCESS:
    case ACTION_DELETE_UNDERWEAR__FAIL:
      return _omit(state, action.payload.underwearId);

    default:
      return state;
  }
};

export default combineReducers({
  underwear,
  paginationMode,
  activePage,
  shouldLoadFirstPage,
  shouldReloadActivePage,
  isUnderwearBeingDeleted,
  errors,
});
