import {
  ACTION_LOAD_REFERER_CODE__REQUEST,
  ACTION_LOAD_REFERER_CODE__SUCCESS,
  ACTION_LOAD_REFERER_CODE__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadRefererCodeSuccess = refererCode => ({
  type: ACTION_LOAD_REFERER_CODE__SUCCESS,
  payload: { refererCode },
});

const actionLoadRefererCodeFail = error => ({
  type: ACTION_LOAD_REFERER_CODE__FAIL,
  payload: { error },
});

export const actionLoadRefererCodeRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_REFERER_CODE__REQUEST,
    });

    return apiClientHelper.get(
      'affiliate/referer_code'
    ).then(
      ({ refererCode }) => {
        dispatch(actionLoadRefererCodeSuccess(refererCode));
      },
      error => {
        dispatch(actionLoadRefererCodeFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
