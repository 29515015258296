import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const erostorySelector = state => _get(state, 'saleItem.erostory');
const categoriesSelector = state => _get(erostorySelector(state), 'categories');
export const getErostoryCategories = state => _get(categoriesSelector(state), 'categories');

const erostoriesSelector = state => _get(erostorySelector(state), 'erostories');
export const getErostoryIds = state => _get(erostoriesSelector(state), 'items');
export const getErostoriesTotalCount = state => _get(erostoriesSelector(state), 'count');
const getErostoriesLimit = state => _get(erostoriesSelector(state), 'limit');
export const getIsLastErostoryLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getErostoryIds, getErostoriesTotalCount, getErostoriesLimit
);
export const getErostoryTotalPagesCount = state => getTotalPagesCount(
  state, getErostoriesTotalCount, getErostoriesLimit
);

export const getErostoryPaginationMode = state => _get(erostorySelector(state), 'paginationMode');

export const getLoadErostoryCategoriesError = state => (
  _get(categoriesSelector(state), 'errors.loadErostoryCategoriesError')
);
export const getLoadErostoriesError = state => (
  _get(erostorySelector(state), 'errors.loadErostoriesError')
);
