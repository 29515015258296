import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'dialogMessage.errors');
export const getLoadDialogMessagesError = state => (
  _get(errorsSelector(state), 'loadDialogMessagesError')
);
export const getDeleteMessagesError = state => (
  _get(errorsSelector(state), 'deleteMessagesError')
);
export const getLoadPeerError = state => (
  _get(errorsSelector(state), 'loadPeerError')
);
export const getDeleteAttachmentError = state => (
  _get(errorsSelector(state), 'deleteAttachmentError')
);
export const getSendDialogMessageError = state => (
  _get(errorsSelector(state), 'sendDialogMessageError')
);
