import { combineReducers } from 'redux';

import loadPrivatePhotosError from './loadPrivatePhotosError';
import createPrivatePhotoError from './createPrivatePhotoError';
import deletePrivatePhotoError from './deletePrivatePhotoError';
import updatePrivatePhotoError from './updatePrivatePhotoError';


export default combineReducers({
  loadPrivatePhotosError,
  createPrivatePhotoError,
  deletePrivatePhotoError,
  updatePrivatePhotoError,
});
