import _omit from 'lodash/omit';

import { getGridFilter } from '../utils';

import { ACTION_ADD_APPOINTMENTS } from '../actionTypes';

import { APPOINTMENTS_GRID_NAME } from '../constants';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddAppointments = appointments => ({
  type: ACTION_ADD_APPOINTMENTS,
  payload: { appointments },
});

export const actionLoadAppointmentsRequest = getActionLoadGridDataRequest(
  APPOINTMENTS_GRID_NAME,
  getGridFilter,
  'appointment/list',
  actionAddAppointments,
  filter => ({ ..._omit(filter, ['statuses']), 'statuses[]': filter.statuses })
);
