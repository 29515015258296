import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_SET_SITE_NEWS_IDS,
  ACTION_ADD_SITE_NEWS,

  ACTION_LOAD_SITE_NEWS__REQUEST,
  ACTION_LOAD_SITE_NEWS__SUCCESS,
  ACTION_LOAD_SITE_NEWS__FAIL,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionSetSiteNewsIds = siteNewsIds => ({
  type: ACTION_SET_SITE_NEWS_IDS,
  payload: { siteNewsIds },
});

const actionAddSiteNews = siteNews => ({
  type: ACTION_ADD_SITE_NEWS,
  payload: { siteNews },
});

const actionLoadSiteNewsSuccess = () => ({
  type: ACTION_LOAD_SITE_NEWS__SUCCESS,
});

const actionLoadSiteNewsFail = error => ({
  type: ACTION_LOAD_SITE_NEWS__FAIL,
  payload: { error },
});

export const actionLoadSiteNewsRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    dispatch({
      type: ACTION_LOAD_SITE_NEWS__REQUEST,
    });

    const { page = 1, limit = 7 } = getFilter(location);

    return apiClientHelper.get(
      `site_news/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddSiteNews(entities.items));

        dispatch(actionSetSiteNewsIds(result));
        dispatch(actionLoadSiteNewsSuccess());
      },
      error => {
        dispatch(actionLoadSiteNewsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
