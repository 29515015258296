import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_LOAD_LATEST_PUBLIC_PHOTOS__REQUEST,
  ACTION_LOAD_LATEST_PUBLIC_PHOTOS__SUCCESS,
  ACTION_LOAD_LATEST_PUBLIC_PHOTOS__FAIL,
} from '../actionTypes';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from '../../saleItem/actions';
import { actionSetPublicPhotos } from '../../saleItem/actions/publicPhoto';


const actionLoadLatestPublicPhotosSuccess = () => ({
  type: ACTION_LOAD_LATEST_PUBLIC_PHOTOS__SUCCESS,
});

const actionLoadLatestPublicPhotosFail = error => ({
  type: ACTION_LOAD_LATEST_PUBLIC_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadLatestPublicPhotosRequest = (
  { page, limit = 22 } = {}
) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_LATEST_PUBLIC_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      `public_photo/latest/${page || 1}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result, entities }) => {
        const items = entities.items || {};

        if (!dom.isBrowser()) {
          dispatch(actionAddSaleItems(items));
          dispatch(actionSetPublicPhotos(result));

          return dispatch(actionLoadLatestPublicPhotosSuccess());
        }

        // Preload publicPhoto thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          dispatch(actionAddSaleItems(items));
          dispatch(actionSetPublicPhotos(result));
          dispatch(actionLoadLatestPublicPhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadLatestPublicPhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
