import {
  ACTION_ADD_SALE_ITEMS,
  ACTION_ADD_VIEW_SALE_ITEM_URL,
  ACTION_SET_VIEW_SALE_ITEM_ID,
} from '../actionTypes';


export const actionAddSaleItems = items => ({
  type: ACTION_ADD_SALE_ITEMS,
  payload: { items },
});

export const actionAddViewSaleItemUrl = viewUrl => ({
  type: ACTION_ADD_VIEW_SALE_ITEM_URL,
  payload: { viewUrl },
});

export const actionSetViewSaleItemId = viewId => ({
  type: ACTION_SET_VIEW_SALE_ITEM_ID,
  payload: { viewId },
});
