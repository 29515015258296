export const SEXUAL_ORIENTATION__HETERO = 'hetero';
export const SEXUAL_ORIENTATION__LESBIAN = 'lesbian';
export const SEXUAL_ORIENTATION__GAY = 'gay';
export const SEXUAL_ORIENTATION__BISEXUAL = 'bisexual';

export const sexualOrientationLangKeys = {
  [SEXUAL_ORIENTATION__HETERO]: 'msg.sexual-orientation.hetero',
  [SEXUAL_ORIENTATION__LESBIAN]: 'msg.sexual-orientation.lesbian',
  [SEXUAL_ORIENTATION__GAY]: 'msg.sexual-orientation.gay',
  [SEXUAL_ORIENTATION__BISEXUAL]: 'msg.sexual-orientation.bisexual',
};

export const AGE__SHOW_REAL_AGE = 'real';
export const AGE__SHOW_ADVERTISED_AGE = 'advertised';
export const AGE__HIDE_MY_AGE = 'hide';

export const ageLangKeys = {
  [AGE__SHOW_REAL_AGE]: 'msg.age.real',
  [AGE__SHOW_ADVERTISED_AGE]: 'msg.age.advertised',
  [AGE__HIDE_MY_AGE]: 'msg.age.hide',
};

export const HEIGHT__TO_150 = 'to_150';
export const HEIGHT__151_155 = '151_155';
export const HEIGHT__156_160 = '156_160';
export const HEIGHT__161_165 = '161_165';
export const HEIGHT__166_170 = '166_170';
export const HEIGHT__171_175 = '171_175';
export const HEIGHT__176_180 = '176_180';
export const HEIGHT__181_185 = '181_185';
export const HEIGHT__186_190 = '186_190';
export const HEIGHT__FROM_191 = 'from_191';

export const heights = [
  HEIGHT__TO_150,
  HEIGHT__151_155,
  HEIGHT__156_160,
  HEIGHT__161_165,
  HEIGHT__166_170,
  HEIGHT__171_175,
  HEIGHT__176_180,
  HEIGHT__181_185,
  HEIGHT__186_190,
  HEIGHT__FROM_191,
];

export const BODY_TYPE__PETITE = 'petite';
export const BODY_TYPE__SKINNY = 'skinny';
export const BODY_TYPE__AVERAGE = 'average';
export const BODY_TYPE__ATHLETIC = 'athletic';
export const BODY_TYPE__MUSCULAR = 'muscular';
export const BODY_TYPE__ABOVE_AVERAGE = 'above_average';
export const BODY_TYPE__LARGE = 'large';
export const BODY_TYPE__EXTRA_LARGE = 'extra_large';

export const bodyTypeLangKeys = {
  [BODY_TYPE__PETITE]: 'msg.body-type.petite',
  [BODY_TYPE__SKINNY]: 'msg.body-type.skinny',
  [BODY_TYPE__AVERAGE]: 'msg.body-type.average',
  [BODY_TYPE__ATHLETIC]: 'msg.body-type.athletic',
  [BODY_TYPE__MUSCULAR]: 'msg.body-type.muscular',
  [BODY_TYPE__ABOVE_AVERAGE]: 'msg.body-type.above-average',
  [BODY_TYPE__LARGE]: 'msg.body-type.large',
  [BODY_TYPE__EXTRA_LARGE]: 'msg.body-type.extra-large',
};

export const ETHNIC__WHITE = 'white';
export const ETHNIC__ASIAN = 'asian';
export const ETHNIC__HISPANIC = 'hispanic';
export const ETHNIC__MIDDLE_EASTERN = 'middle-eastern';
export const ETHNIC__MULTICULTURAL = 'multicultural';
export const ETHNIC__NATIVE_AMERICAN = 'native-american';
export const ETHNIC__PACIFIC_ISLANDER = 'pacific-islander';
export const ETHNIC__SOUTH_ASIAN = 'south-asian';
export const ETHNIC__BLACK = 'black';

export const ethnicLangKeys = {
  [ETHNIC__WHITE]: 'msg.ethnic.white',
  [ETHNIC__ASIAN]: 'msg.ethnic.asian',
  [ETHNIC__HISPANIC]: 'msg.ethnic.hispanic',
  [ETHNIC__MIDDLE_EASTERN]: 'msg.ethnic.middle-eastern',
  [ETHNIC__MULTICULTURAL]: 'msg.ethnic.multicultural',
  [ETHNIC__NATIVE_AMERICAN]: 'msg.ethnic.native-american',
  [ETHNIC__PACIFIC_ISLANDER]: 'msg.ethnic.pacific-islander',
  [ETHNIC__SOUTH_ASIAN]: 'msg.ethnic.south-asian',
  [ETHNIC__BLACK]: 'msg.ethnic.black',
};

export const COCK__SMALL = 'small';
export const COCK__BIG = 'big';

export const cockLangKeys = {
  [COCK__SMALL]: 'msg.cock.small',
  [COCK__BIG]: 'msg.cock.big',
};

export const SEX_PREFERENCES__SPLOSHING = 'sploshing';
export const SEX_PREFERENCES__BDSM = 'bdsm';
export const SEX_PREFERENCES__ANAL = 'anal';
export const SEX_PREFERENCES__CROSSDRESSING = 'crossdressing';
export const SEX_PREFERENCES__FETISH = 'fetish';
export const SEX_PREFERENCES__GROUP = 'group';
export const SEX_PREFERENCES__PISSING = 'pissing';
export const SEX_PREFERENCES__SLAVE = 'slave';
export const SEX_PREFERENCES__ORAL = 'oral';
export const SEX_PREFERENCES__BALLOONS = 'balloons';
export const SEX_PREFERENCES__FEET = 'feet';

export const sexPreferencesLangKeys = {
  [SEX_PREFERENCES__SPLOSHING]: 'msg.sex-preferences.sploshing',
  [SEX_PREFERENCES__BDSM]: 'msg.sex-preferences.bdsm',
  [SEX_PREFERENCES__ANAL]: 'msg.sex-preferences.anal',
  [SEX_PREFERENCES__CROSSDRESSING]: 'msg.sex-preferences.crossdressing',
  [SEX_PREFERENCES__FETISH]: 'msg.sex-preferences.fetish',
  [SEX_PREFERENCES__GROUP]: 'msg.sex-preferences.group',
  [SEX_PREFERENCES__PISSING]: 'msg.sex-preferences.pissing',
  [SEX_PREFERENCES__SLAVE]: 'msg.sex-preferences.slave',
  [SEX_PREFERENCES__ORAL]: 'msg.sex-preferences.oral',
  [SEX_PREFERENCES__BALLOONS]: 'msg.sex-preferences.balloons',
  [SEX_PREFERENCES__FEET]: 'msg.sex-preferences.feet',
};

export const PUBES__SHAVED = 'shaved';
export const PUBES__HAIRY = 'hairy';

export const pubesLangKeys = {
  [PUBES__SHAVED]: 'msg.pubes.shaved',
  [PUBES__HAIRY]: 'msg.pubes.hairy',
};

export const HAIR_COLOR__BLACK = 'black';
export const HAIR_COLOR__AUBURN = 'auburn';
export const HAIR_COLOR__BLOND = 'blond';
export const HAIR_COLOR__CHESTNUT = 'chestnut';

export const hairColorLangKeys = {
  [HAIR_COLOR__BLACK]: 'msg.hair-color.black',
  [HAIR_COLOR__AUBURN]: 'msg.hair-color.auburn',
  [HAIR_COLOR__BLOND]: 'msg.hair-color.blond',
  [HAIR_COLOR__CHESTNUT]: 'msg.hair-color.chestnut',
};

export const BREAST_SIZE__TINY = 'tiny';
export const BREAST_SIZE__NORMAL = 'normal';
export const BREAST_SIZE__BIG = 'big';
export const BREAST_SIZE__HUGE = 'huge';

export const breastSizeLangKeys = {
  [BREAST_SIZE__TINY]: 'msg.breast-size.tiny',
  [BREAST_SIZE__NORMAL]: 'msg.breast-size.normal',
  [BREAST_SIZE__BIG]: 'msg.breast-size.big',
  [BREAST_SIZE__HUGE]: 'msg.breast-size.huge',
};

export const VISIBILITY_EVERYBODY = 'everybody';
export const VISIBILITY__MODELS = 'models';
export const VISIBILITY__NOBODY = 'nobody';

export const privacyLangKeys = {
  [VISIBILITY_EVERYBODY]: 'msg.visibility.everybody',
  [VISIBILITY__MODELS]: 'msg.visibility.models',
  [VISIBILITY__NOBODY]: 'msg.visibility.nobody',
};

export const privacyHelpLangKeys = {
  [VISIBILITY_EVERYBODY]: 'msg.visibility.everybody.help',
  [VISIBILITY__MODELS]: 'msg.visibility.models.help',
  [VISIBILITY__NOBODY]: 'msg.visibility.nobody.help',
};

export const BLUE_CHECK_STATUS__DISABLED = 'disabled';
export const BLUE_CHECK_STATUS__PENDING = 'pending';
export const BLUE_CHECK_STATUS__ACTIVE = 'active';
export const BLUE_CHECK_STATUS__SUSPENDED = 'suspended';
export const BLUE_CHECK_STATUS__CANCELED = 'canceled';
export const BLUE_CHECK_STATUS__DECLINED = 'declined';

export const blueCheckStatusLangKeys = {
  [BLUE_CHECK_STATUS__DISABLED]: 'msg.status.disabled',
  [BLUE_CHECK_STATUS__PENDING]: 'msg.status.pending',
  [BLUE_CHECK_STATUS__ACTIVE]: 'msg.status.active',
  [BLUE_CHECK_STATUS__SUSPENDED]: 'msg.status.suspended',
  [BLUE_CHECK_STATUS__CANCELED]: 'msg.status.canceled',
  [BLUE_CHECK_STATUS__DECLINED]: 'msg.status.declined',
};
