import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'emoticonShop.errors');
export const getLoadEmoticonsError = state => (
  _get(errorsSelector(state), 'loadEmoticonsError')
);
export const getBuyEmoticonError = state => (
  _get(errorsSelector(state), 'buyEmoticonError')
);
