import React from 'react';

import { Button, button } from 'react-redux-app/lib/modules/button/components';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { BUTTON__WHITE } from '../../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const { themeApi } = button;

const WhiteButton = props => <Button {...props} />;

export default theming.theme(
  BUTTON__WHITE,
  baseCssJson,
  themeApi
)(WhiteButton);
