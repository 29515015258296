export const ACTION_ADD_UNDERWEAR = 'ACTION_ADD_UNDERWEAR';
export const ACTION_SET_UNDERWEAR = 'ACTION_SET_UNDERWEAR';

export const ACTION_BUY_UNDERWEAR__REQUEST = 'ACTION_BUY_UNDERWEAR__REQUEST';
export const ACTION_BUY_UNDERWEAR__SUCCESS = 'ACTION_BUY_UNDERWEAR__SUCCESS';
export const ACTION_BUY_UNDERWEAR__FAIL = 'ACTION_BUY_UNDERWEAR__FAIL';

export const ACTION_LOAD_UNDERWEAR__REQUEST = 'ACTION_LOAD_UNDERWEAR__REQUEST';
export const ACTION_LOAD_UNDERWEAR__SUCCESS = 'ACTION_LOAD_UNDERWEAR__SUCCESS';
export const ACTION_LOAD_UNDERWEAR__FAIL = 'ACTION_LOAD_UNDERWEAR__FAIL';

export const ACTION_SET_UNDERWEAR_PRESERVE = 'ACTION_SET_UNDERWEAR_PRESERVE';
