import _random from 'lodash/random';

import { multiSelect } from 'react-redux-app/lib/modules/form/utils';
import {
  getAgeOptions,
  getPriceOptions,
  getHeightOptions,
} from '../../core/utils/getOptions';
import { getUrl } from '../../app/utils/url';

import {
  CHAT_STATUS__OFFLINE,
  CHAT_STATUS__ONLINE,
  CHAT_STATUS__SPYCAM,
} from '../../core/constants/chat';
import { LANG__EN } from '../../core/language/constants';
import {
  bodyTypeLangKeys,
  ethnicLangKeys,
  pubesLangKeys,
  hairColorLangKeys,
  breastSizeLangKeys,
} from '../../account/constants';
import {
  RIV_CHAT_STATUS__PUBLIC,
  RIV_CHAT_STATUS__PERSONAL,
  RIV_CHAT_STATUS__PRIVATE_SPYCAM,
  RIV_CHAT_STREAM__OFF,
  FILTER_QUERY_PARAM,
  PRICE_RANGE_QUERY_PARAM,
  AGE_RANGE_QUERY_PARAM,
  SEX_PREFERENCES_QUERY_PARAM,
  ETHNIC_QUERY_PARAM,
  HAIR_QUERY_PARAM,
  HEIGHT_QUERY_PARAM,
  BODY_QUERY_PARAM,
  BREAST_QUERY_PARAM,
  PUBES_QUERY_PARAM,
  LANGUAGE_QUERY_PARAM,
  WEEKEND_ID,
  WELCOME_ID,
  CRYPTO_ID,
  CRYPTO_BONUS_COOKIE,
  WELCOME_BONUS_ACTIVATED_COOKIE,
} from '../constants';
import { isForBoysToPathMap, filterToUrlMap } from '../constants/url';

import { getIsAuthenticated } from '../../auth/selectors';
import { getWelcomeBonus, getCryptoBonus } from '../../initSettings/selectors';

import {
  actionSetModelOrder,
  actionSetWeekendImage,
  actionSetCreditsBonus,
} from '../actions/models';


export const getChatStatus = (rivChatStatus, rivChatStream) => {
  let status = CHAT_STATUS__OFFLINE;

  if (rivChatStream !== RIV_CHAT_STREAM__OFF) {
    if ([RIV_CHAT_STATUS__PUBLIC, RIV_CHAT_STATUS__PERSONAL].includes(rivChatStatus)) {
      status = CHAT_STATUS__ONLINE;
    } else if (rivChatStatus === RIV_CHAT_STATUS__PRIVATE_SPYCAM) {
      status = CHAT_STATUS__SPYCAM;
    }
  }

  return status;
};

export const getChatStatusOrNull = (rivChatStatus, rivChatStream) => (
  (rivChatStatus === undefined || rivChatStream === undefined)
    ? null
    : getChatStatus(rivChatStatus, rivChatStream)
);

export const getType = filter => filter || 'all';

export const getQueryParamToOptionsMap = (
  filters, allowedSexPreferencesLangKeys, spokenLanguageLangKeys, fm = null
) => ({
  [FILTER_QUERY_PARAM]: multiSelect.getOptions(filters),
  [PRICE_RANGE_QUERY_PARAM]: getPriceOptions(),
  [AGE_RANGE_QUERY_PARAM]: getAgeOptions(),
  [SEX_PREFERENCES_QUERY_PARAM]: multiSelect.getOptions(allowedSexPreferencesLangKeys, fm),
  [ETHNIC_QUERY_PARAM]: multiSelect.getOptions(ethnicLangKeys, fm),
  [HAIR_QUERY_PARAM]: multiSelect.getOptions(hairColorLangKeys, fm),
  [HEIGHT_QUERY_PARAM]: getHeightOptions(),
  [BODY_QUERY_PARAM]: multiSelect.getOptions(bodyTypeLangKeys, fm),
  [BREAST_QUERY_PARAM]: multiSelect.getOptions(breastSizeLangKeys, fm),
  [PUBES_QUERY_PARAM]: multiSelect.getOptions(pubesLangKeys, fm),
  [LANGUAGE_QUERY_PARAM]: multiSelect.getOptions(spokenLanguageLangKeys, fm),
});

export const getPathnamePrefix = (isForBoys, lang) => (
  isForBoysToPathMap[isForBoys][lang] || isForBoysToPathMap[isForBoys][LANG__EN]
);

export const getPathname = (
  langForUrl,
  pathnamePrefix,
  { filterName, filterValue },
  lang
) => getUrl(
  langForUrl,
  `/${pathnamePrefix}${filterToUrlMap[filterName]?.[filterValue]?.[lang] || filterToUrlMap[filterName]?.[filterValue]?.[LANG__EN]}`
);

export const getFilter = rawFilter => {
  // eslint-disable-next-line no-restricted-syntax
  for (const filterName of Object.keys(filterToUrlMap)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const filterValue of Object.keys(filterToUrlMap[filterName])) {
      if (Object.values(filterToUrlMap[filterName][filterValue]).includes(rawFilter)) {
        return { filterName, filterValue };
      }
    }
  }

  return {};
};

export const getIsChatGalleryCategory = category => !category || category === FILTER_QUERY_PARAM;

export const setCreditsBonuses = (dispatch, loginInfo) => {
  if (loginInfo.weeklyEventPrize) {
    dispatch(actionSetCreditsBonus(WEEKEND_ID, loginInfo.weeklyEventPrize));
  }
  if (loginInfo.cryptoEventPrize) {
    dispatch(actionSetCreditsBonus(CRYPTO_ID, loginInfo.cryptoEventPrize));
  }
};

const getRandom = randomNumber => {
  if (!randomNumber) {
    return _random(1, 6);
  }

  return randomNumber < 4 ? _random(randomNumber + 2, 7) : _random(1, randomNumber - 2);
};

export const setModelOrder = (
  dispatch, getState, getCookie, models, result, isWithBonus = true
) => {
  const order = [...models];

  if (isWithBonus) {
    let randomNumber;

    if (!getIsAuthenticated(getState())) {
      if (getWelcomeBonus(getState()) && !getCookie(WELCOME_BONUS_ACTIVATED_COOKIE)) {
        randomNumber = getRandom(randomNumber);
        order.splice(randomNumber, 0, WELCOME_ID);
      }

      if (getCryptoBonus(getState()) && getCookie(CRYPTO_BONUS_COOKIE)) {
        randomNumber = getRandom(randomNumber);
        order.splice(randomNumber, 0, CRYPTO_ID);
      }
    } else {
      if (result.isWeeklyEventPrizeAvailable) {
        randomNumber = getRandom(randomNumber);
        order.splice(randomNumber, 0, WEEKEND_ID);

        dispatch(actionSetWeekendImage(_random(1, 2)));
      }

      if (result.isCryptoEventPrizeAvailable) {
        randomNumber = getRandom(randomNumber);
        order.splice(randomNumber, 0, CRYPTO_ID);
      }

      setCreditsBonuses(dispatch, result);
    }
  }

  dispatch(actionSetModelOrder(order));
};
