export const ACTION_LOAD_ACCOUNT__REQUEST = 'ACTION_LOAD_ACCOUNT__REQUEST';
export const ACTION_LOAD_ACCOUNT__SUCCESS = 'ACTION_LOAD_ACCOUNT__SUCCESS';
export const ACTION_LOAD_ACCOUNT__FAIL = 'ACTION_LOAD_ACCOUNT__FAIL';

export const ACTION_UPDATE_ACCOUNT__REQUEST = 'ACTION_UPDATE_ACCOUNT__REQUEST';
export const ACTION_UPDATE_ACCOUNT__SUCCESS = 'ACTION_UPDATE_ACCOUNT__SUCCESS';
export const ACTION_UPDATE_ACCOUNT__FAIL = 'ACTION_UPDATE_ACCOUNT__FAIL';

export const ACTION_DELETE_ACCOUNT__REQUEST = 'ACTION_DELETE_ACCOUNT__REQUEST';
export const ACTION_DELETE_ACCOUNT__SUCCESS = 'ACTION_DELETE_ACCOUNT__SUCCESS';
export const ACTION_DELETE_ACCOUNT__FAIL = 'ACTION_DELETE_ACCOUNT__FAIL';

export const ACTION_UPDATE_EMAIL__REQUEST = 'ACTION_UPDATE_EMAIL__REQUEST';
export const ACTION_UPDATE_EMAIL__SUCCESS = 'ACTION_UPDATE_EMAIL__SUCCESS';
export const ACTION_UPDATE_EMAIL__FAIL = 'ACTION_UPDATE_EMAIL__FAIL';

export const ACTION_CONFIRM_EMAIL__REQUEST = 'ACTION_CONFIRM_EMAIL__REQUEST';
export const ACTION_CONFIRM_EMAIL__SUCCESS = 'ACTION_CONFIRM_EMAIL__SUCCESS';
export const ACTION_CONFIRM_EMAIL__FAIL = 'ACTION_CONFIRM_EMAIL__FAIL';

export const ACTION_OPTOUT_EMAIL__REQUEST = 'ACTION_OPTOUT_EMAIL__REQUEST';
export const ACTION_OPTOUT_EMAIL__SUCCESS = 'ACTION_OPTOUT_EMAIL__SUCCESS';
export const ACTION_OPTOUT_EMAIL__FAIL = 'ACTION_OPTOUT_EMAIL__FAIL';

export const ACTION_RESET_EMAIL__REQUEST = 'ACTION_RESET_EMAIL__REQUEST';
export const ACTION_RESET_EMAIL__SUCCESS = 'ACTION_RESET_EMAIL__SUCCESS';
export const ACTION_RESET_EMAIL__FAIL = 'ACTION_RESET_EMAIL__FAIL';

export const ACTION_LOAD_PREFERENCES__REQUEST = 'ACTION_LOAD_PREFERENCES__REQUEST';
export const ACTION_LOAD_PREFERENCES__SUCCESS = 'ACTION_LOAD_PREFERENCES__SUCCESS';
export const ACTION_LOAD_PREFERENCES__FAIL = 'ACTION_LOAD_PREFERENCES__FAIL';

export const ACTION_UPDATE_PREFERENCES__REQUEST = 'ACTION_UPDATE_PREFERENCES__REQUEST';
export const ACTION_UPDATE_PREFERENCES__SUCCESS = 'ACTION_UPDATE_PREFERENCES__SUCCESS';
export const ACTION_UPDATE_PREFERENCES__FAIL = 'ACTION_UPDATE_PREFERENCES__FAIL';

export const ACTION_LOAD_MODEL_PROFILE__REQUEST = 'ACTION_LOAD_MODEL_PROFILE__REQUEST';
export const ACTION_LOAD_MODEL_PROFILE__SUCCESS = 'ACTION_LOAD_MODEL_PROFILE__SUCCESS';
export const ACTION_LOAD_MODEL_PROFILE__FAIL = 'ACTION_LOAD_MODEL_PROFILE__FAIL';

export const ACTION_UPDATE_MODEL_PROFILE__REQUEST = 'ACTION_UPDATE_MODEL_PROFILE__REQUEST';
export const ACTION_UPDATE_MODEL_PROFILE__SUCCESS = 'ACTION_UPDATE_MODEL_PROFILE__SUCCESS';
export const ACTION_UPDATE_MODEL_PROFILE__FAIL = 'ACTION_UPDATE_MODEL_PROFILE__FAIL';

export const ACTION_LOAD_WEBCAM_SETTINGS__REQUEST = 'ACTION_LOAD_WEBCAM_SETTINGS__REQUEST';
export const ACTION_LOAD_WEBCAM_SETTINGS__SUCCESS = 'ACTION_LOAD_WEBCAM_SETTINGS__SUCCESS';
export const ACTION_LOAD_WEBCAM_SETTINGS__FAIL = 'ACTION_LOAD_WEBCAM_SETTINGS__FAIL';

export const ACTION_UPDATE_WEBCAM_SETTINGS__REQUEST = 'ACTION_UPDATE_WEBCAM_SETTINGS__REQUEST';
export const ACTION_UPDATE_WEBCAM_SETTINGS__SUCCESS = 'ACTION_UPDATE_WEBCAM_SETTINGS__SUCCESS';
export const ACTION_UPDATE_WEBCAM_SETTINGS__FAIL = 'ACTION_UPDATE_WEBCAM_SETTINGS__FAIL';

export const ACTION_LOAD_BLUE_CHECK_SETTINGS__REQUEST = 'ACTION_LOAD_BLUE_CHECK_SETTINGS__REQUEST';
export const ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS = 'ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS';
export const ACTION_LOAD_BLUE_CHECK_SETTINGS__FAIL = 'ACTION_LOAD_BLUE_CHECK_SETTINGS__FAIL';

export const ACTION_UPDATE_BLUE_CHECK_SETTINGS__REQUEST = 'ACTION_UPDATE_BLUE_CHECK_SETTINGS__REQUEST';
export const ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS = 'ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS';
export const ACTION_UPDATE_BLUE_CHECK_SETTINGS__FAIL = 'ACTION_UPDATE_BLUE_CHECK_SETTINGS__FAIL';

export const ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__REQUEST = 'ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__REQUEST';
export const ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__SUCCESS = 'ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__SUCCESS';
export const ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__FAIL = 'ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__FAIL';

export const ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST = 'ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST';
export const ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS = 'ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS';
export const ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL = 'ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL';

export const ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST = 'ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST';
export const ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS = 'ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS';
export const ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL = 'ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL';

export const ACTION_SEND_SPEED_TEST_RESULT__REQUEST = 'ACTION_SEND_SPEED_TEST_RESULT__REQUEST';
export const ACTION_SEND_SPEED_TEST_RESULT__SUCCESS = 'ACTION_SEND_SPEED_TEST_RESULT__SUCCESS';
export const ACTION_SEND_SPEED_TEST_RESULT__FAIL = 'ACTION_SEND_SPEED_TEST_RESULT__FAIL';

export const ACTION_LOAD_PRIVACY__REQUEST = 'ACTION_LOAD_PRIVACY__REQUEST';
export const ACTION_LOAD_PRIVACY__SUCCESS = 'ACTION_LOAD_PRIVACY__SUCCESS';
export const ACTION_LOAD_PRIVACY__FAIL = 'ACTION_LOAD_PRIVACY__FAIL';

export const ACTION_UPDATE_PRIVACY__REQUEST = 'ACTION_UPDATE_PRIVACY__REQUEST';
export const ACTION_UPDATE_PRIVACY__SUCCESS = 'ACTION_UPDATE_PRIVACY__SUCCESS';
export const ACTION_UPDATE_PRIVACY__FAIL = 'ACTION_UPDATE_PRIVACY__FAIL';

export const ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__REQUEST = 'ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__REQUEST';
export const ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS = 'ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS';
export const ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__FAIL = 'ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__FAIL';

export const ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__REQUEST = 'ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__REQUEST';
export const ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__SUCCESS = 'ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__SUCCESS';
export const ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__FAIL = 'ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__FAIL';

export const ACTION_ADD_GEO_FILTERS = 'ACTION_ADD_GEO_FILTERS';

export const ACTION_ADD_GEO_FILTER__REQUEST = 'ACTION_ADD_GEO_FILTER__REQUEST';
export const ACTION_ADD_GEO_FILTER__SUCCESS = 'ACTION_ADD_GEO_FILTER__SUCCESS';
export const ACTION_ADD_GEO_FILTER__FAIL = 'ACTION_ADD_GEO_FILTER__FAIL';

export const ACTION_DELETE_GEO_FILTER__REQUEST = 'ACTION_DELETE_GEO_FILTER__REQUEST';
export const ACTION_DELETE_GEO_FILTER__SUCCESS = 'ACTION_DELETE_GEO_FILTER__SUCCESS';
export const ACTION_DELETE_GEO_FILTER__FAIL = 'ACTION_DELETE_GEO_FILTER__FAIL';

export const ACTION_LOAD_COUNTRIES__REQUEST = 'ACTION_LOAD_COUNTRIES__REQUEST';
export const ACTION_LOAD_COUNTRIES__SUCCESS = 'ACTION_LOAD_COUNTRIES__SUCCESS';
export const ACTION_LOAD_COUNTRIES__FAIL = 'ACTION_LOAD_COUNTRIES__FAIL';

export const ACTION_LOAD_TELEGRAM_SETTINGS__REQUEST = 'ACTION_LOAD_TELEGRAM_SETTINGS__REQUEST';
export const ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS = 'ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS';
export const ACTION_LOAD_TELEGRAM_SETTINGS__FAIL = 'ACTION_LOAD_TELEGRAM_SETTINGS__FAIL';

export const ACTION_CONNECT_TO_TELEGRAM__REQUEST = 'ACTION_CONNECT_TO_TELEGRAM__REQUEST';
export const ACTION_CONNECT_TO_TELEGRAM__SUCCESS = 'ACTION_CONNECT_TO_TELEGRAM__SUCCESS';
export const ACTION_CONNECT_TO_TELEGRAM__FAIL = 'ACTION_CONNECT_TO_TELEGRAM__FAIL';

export const ACTION_DISCONNECT_FROM_TELEGRAM__REQUEST = 'ACTION_DISCONNECT_FROM_TELEGRAM__REQUEST';
export const ACTION_DISCONNECT_FROM_TELEGRAM__SUCCESS = 'ACTION_DISCONNECT_FROM_TELEGRAM__SUCCESS';
export const ACTION_DISCONNECT_FROM_TELEGRAM__FAIL = 'ACTION_DISCONNECT_FROM_TELEGRAM__FAIL';

export const ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__REQUEST = 'ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__REQUEST';
export const ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__SUCCESS = 'ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__SUCCESS';
export const ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__FAIL = 'ACTION_UPDATE_TELEGRAM_NOTIFICATIONS__FAIL';

export const ACTION_LOAD_PUSH_SETTINGS__REQUEST = 'ACTION_LOAD_PUSH_SETTINGS__REQUEST';
export const ACTION_LOAD_PUSH_SETTINGS__SUCCESS = 'ACTION_LOAD_PUSH_SETTINGS__SUCCESS';
export const ACTION_LOAD_PUSH_SETTINGS__FAIL = 'ACTION_LOAD_PUSH_SETTINGS__FAIL';

export const ACTION_UPDATE_PUSH_SETTINGS__REQUEST = 'ACTION_UPDATE_PUSH_SETTINGS__REQUEST';
export const ACTION_UPDATE_PUSH_SETTINGS__SUCCESS = 'ACTION_UPDATE_PUSH_SETTINGS__SUCCESS';
export const ACTION_UPDATE_PUSH_SETTINGS__FAIL = 'ACTION_UPDATE_PUSH_SETTINGS__FAIL';

export const ACTION_LOAD_SOCIAL_SETTINGS__REQUEST = 'ACTION_LOAD_SOCIAL_SETTINGS__REQUEST';
export const ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS = 'ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS';
export const ACTION_LOAD_SOCIAL_SETTINGS__FAIL = 'ACTION_LOAD_SOCIAL_SETTINGS__FAIL';

export const ACTION_ATTACH_SOCIAL__REQUEST = 'ACTION_ATTACH_SOCIAL__REQUEST';
export const ACTION_ATTACH_SOCIAL__SUCCESS = 'ACTION_ATTACH_SOCIAL__SUCCESS';
export const ACTION_ATTACH_SOCIAL__FAIL = 'ACTION_ATTACH_SOCIAL__FAIL';

export const ACTION_COMPLETE_SOCIAL_ATTACHMENT__REQUEST = 'ACTION_COMPLETE_SOCIAL_ATTACHMENT__REQUEST';
export const ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS = 'ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS';
export const ACTION_COMPLETE_SOCIAL_ATTACHMENT__FAIL = 'ACTION_COMPLETE_SOCIAL_ATTACHMENT__FAIL';

export const ACTION_DETACH_SOCIAL__REQUEST = 'ACTION_DETACH_SOCIAL__REQUEST';
export const ACTION_DETACH_SOCIAL__SUCCESS = 'ACTION_DETACH_SOCIAL__SUCCESS';
export const ACTION_DETACH_SOCIAL__FAIL = 'ACTION_DETACH_SOCIAL__FAIL';

export const ACTION_ADD_WHITELIST = 'ACTION_ADD_WHITELIST';

export const ACTION_ADD_WHITELISTED_USER__REQUEST = 'ACTION_ADD_WHITELISTED_USER__REQUEST';
export const ACTION_ADD_WHITELISTED_USER__SUCCESS = 'ACTION_ADD_WHITELISTED_USER__SUCCESS';
export const ACTION_ADD_WHITELISTED_USER__FAIL = 'ACTION_ADD_WHITELISTED_USER__FAIL';

export const ACTION_DELETE_WHITELISTED_USER__REQUEST = 'ACTION_DELETE_WHITELISTED_USER__REQUEST';
export const ACTION_DELETE_WHITELISTED_USER__SUCCESS = 'ACTION_DELETE_WHITELISTED_USER__SUCCESS';
export const ACTION_DELETE_WHITELISTED_USER__FAIL = 'ACTION_DELETE_WHITELISTED_USER__FAIL';

export const ACTION_LOAD_BLOG_SETTINGS__REQUEST = 'ACTION_LOAD_BLOG_SETTINGS__REQUEST';
export const ACTION_LOAD_BLOG_SETTINGS__SUCCESS = 'ACTION_LOAD_BLOG_SETTINGS__SUCCESS';
export const ACTION_LOAD_BLOG_SETTINGS__FAIL = 'ACTION_LOAD_BLOG_SETTINGS__FAIL';

export const ACTION_UPDATE_BLOG_SETTINGS__REQUEST = 'ACTION_UPDATE_BLOG_SETTINGS__REQUEST';
export const ACTION_UPDATE_BLOG_SETTINGS__SUCCESS = 'ACTION_UPDATE_BLOG_SETTINGS__SUCCESS';
export const ACTION_UPDATE_BLOG_SETTINGS__FAIL = 'ACTION_UPDATE_BLOG_SETTINGS__FAIL';

export const ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__REQUEST = 'ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__REQUEST';
export const ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS = 'ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS';
export const ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__FAIL = 'ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__FAIL';

export const ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__REQUEST = 'ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__REQUEST';
export const ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__SUCCESS = 'ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__SUCCESS';
export const ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__FAIL = 'ACTION_UPDATE_FLOWER_DELIVERY_SETTINGS__FAIL';
