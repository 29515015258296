import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_SET_PRESENTATIONS,
  ACTION_DELETE_PRESENTATION__REQUEST,
  ACTION_DELETE_PRESENTATION__SUCCESS,
  ACTION_DELETE_PRESENTATION__FAIL,
} from '../../actionTypes/presentation';


const presentations = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_PRESENTATIONS:
      return action.payload.presentations;

    case ACTION_DELETE_PRESENTATION__SUCCESS:
      return _without(state, action.payload.presentationId);

    default:
      return state;
  }
};

const isPresentationBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_PRESENTATION__REQUEST:
      return { ...state, [action.payload.presentationId]: true };

    case ACTION_DELETE_PRESENTATION__SUCCESS:
    case ACTION_DELETE_PRESENTATION__FAIL:
      return _omit(state, action.payload.presentationId);

    default:
      return state;
  }
};

export default combineReducers({
  presentations,
  isPresentationBeingDeleted,
  errors,
});
