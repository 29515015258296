import { MODAL_DIALOG__CRYPTO_BONUS } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowCryptoBonusDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__CRYPTO_BONUS))
);

export const actionHideCryptoBonusDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__CRYPTO_BONUS))
);
