import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _union from 'lodash/union';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_LOAD_OWN_EROSTORIES__REQUEST,
  ACTION_LOAD_OWN_EROSTORIES__SUCCESS,
  ACTION_LOAD_OWN_EROSTORIES__FAIL,
  ACTION_SET_OWN_EROSTORIES,
  ACTION_ADD_OWN_EROSTORIES,
  ACTION_SET_OWN_EROSTORIES_PRESERVE,
  ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE,
  ACTION_CREATE_EROSTORY__REQUEST,
  ACTION_CREATE_EROSTORY__SUCCESS,
  ACTION_DELETE_EROSTORY__REQUEST,
  ACTION_DELETE_EROSTORY__SUCCESS,
  ACTION_DELETE_EROSTORY__FAIL,
} from '../../actionTypes/erostory';

import { defaultItemsState } from '../../../core/constants/items';
import {
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
} from '../../../core/loadableItems/constants';


const erostories = (state = defaultItemsState, action) => {
  switch (action.type) {
    case ACTION_ADD_OWN_EROSTORIES: {
      const items = _union(
        state.items,
        action.payload.erostories.items
      );

      return {
        items,
        count: action.payload.erostories.count,
        limit: action.payload.erostories.limit,
      };
    }

    case ACTION_SET_OWN_EROSTORIES:
      return action.payload.erostories;

    case ACTION_DELETE_EROSTORY__SUCCESS:
      return {
        items: _without(state.items, action.payload.erostoryId),
        count: state.count - 1,
        limit: state.limit,
      };

    default:
      return state;
  }
};

const paginationMode = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_EROSTORIES_PRESERVE:
      return PAGINATION_MODE__SCROLL;

    case ACTION_LOAD_OWN_EROSTORIES__REQUEST:
      return state || PAGINATION_MODE__SWITCH;

    case ACTION_LOAD_OWN_EROSTORIES__SUCCESS:
    case ACTION_LOAD_OWN_EROSTORIES__FAIL:
      return null;

    default:
      return state;
  }
};

const activePage = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE:
      return action.payload.activePage;

    default:
      return state;
  }
};

const shouldLoadFirstPage = (state = false, action) => {
  switch (action.type) {
    case ACTION_CREATE_EROSTORY__REQUEST:
      return false;

    case ACTION_CREATE_EROSTORY__SUCCESS:
      return true;

    default:
      return state;
  }
};

const shouldReloadActivePage = (state = false, action) => {
  switch (action.type) {
    case ACTION_DELETE_EROSTORY__REQUEST:
      return false;

    case ACTION_DELETE_EROSTORY__SUCCESS:
      return true;

    default:
      return state;
  }
};

const isErostoryBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_EROSTORY__REQUEST:
      return { ...state, [action.payload.erostoryId]: true };

    case ACTION_DELETE_EROSTORY__SUCCESS:
    case ACTION_DELETE_EROSTORY__FAIL:
      return _omit(state, action.payload.erostoryId);

    default:
      return state;
  }
};

export default combineReducers({
  erostories,
  paginationMode,
  activePage,
  shouldLoadFirstPage,
  shouldReloadActivePage,
  isErostoryBeingDeleted,
  errors,
});
