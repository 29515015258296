import { useMemo, useState } from 'react';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useSwitchTab from './useSwitchTab';
import useTab from './useTab';


export default (
  staticTabs = null,
  tabsSelector = null,
  isInternal = false
) => {
  const stateTabs = useShortSelector(tabsSelector);

  const switchTab = useSwitchTab();
  const externalTab = useTab();

  const [internalTab, setInternalTab] = useState();

  const tab = isInternal ? internalTab : externalTab;
  const tabs = staticTabs || stateTabs;
  const tabIndex = useMemo(() => Math.max(0, tabs.indexOf(tab)), [tabs, tab]);

  const onTabSelect = index => { (isInternal ? setInternalTab : switchTab)(tabs[index]); };

  return {
    tab: tabs[tabIndex],
    tabIndex,
    isActiveTab: t => tabIndex === tabs.indexOf(t),
    hasTab: t => tabs.includes(t),
    onTabSelect,
  };
};
