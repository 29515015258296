import {
  ACTION_RESET_DEPOSIT_REMIND__REQUEST,
  ACTION_RESET_DEPOSIT_REMIND__SUCCESS,
  ACTION_RESET_DEPOSIT_REMIND__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionResetDepositRemindRequestSuccess = () => ({
  type: ACTION_RESET_DEPOSIT_REMIND__SUCCESS,
});

const actionResetDepositRemindRequestFail = error => ({
  type: ACTION_RESET_DEPOSIT_REMIND__FAIL,
  payload: { error },
});

export const actionResetDepositRemindRequest = resetDepositRemindType => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_RESET_DEPOSIT_REMIND__REQUEST,
    });

    return apiClientHelper.post(
      'deposit_remind/reset',
      { data: { type: resetDepositRemindType } }
    ).then(
      result => {
        dispatch(actionResetDepositRemindRequestSuccess());
      },
      error => {
        dispatch(actionResetDepositRemindRequestFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
