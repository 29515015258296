export const SID_HEADER = 'RIV-API-SID';
export const SID_COOKIE = 'PHPSESSID';

export const AF_USER_TOKEN_ID_HEADER = 'RIV-API-AF-User-Token-Id';
export const AF_USER_TOKEN_ID_COOKIE = 'RIV_EVENTS_USER_TOKEN_ID';
export const COOKIE_AF_USER_TOKEN_ID_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;

export const REFERER_COOKIE = 'referer';
export const COOKIE_REFERER_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;

export const RCID_COOKIE = 'rcid';
export const COOKIE_RCID_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;

export const USER_AGENT_UIDS_HEADER = 'RIV-API-UA-UIDs';
export const USER_AGENT_UIDS_COOKIE = 'uaUids';
export const COOKIE_USER_AGENT_UIDS_EXPIRES = 1000 * 60 * 60 * 24 * 365 * 10;
