import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const underwearItemSelector = state => _get(state, 'saleItem.underwear');
const underwearItemsSelector = state => _get(underwearItemSelector(state), 'underwear');
export const getUnderwearIds = state => _get(underwearItemsSelector(state), 'items');
export const getUnderwearTotalCount = state => _get(underwearItemsSelector(state), 'count');
const getUnderwearLimit = state => _get(underwearItemsSelector(state), 'limit');
export const getIsLastUnderwearLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getUnderwearIds, getUnderwearTotalCount, getUnderwearLimit
);
export const getUnderwearTotalPagesCount = state => getTotalPagesCount(
  state, getUnderwearTotalCount, getUnderwearLimit
);

export const getUnderwearPaginationMode = state => (
  _get(underwearItemSelector(state), 'paginationMode')
);

export const getLoadUnderwearError = state => (
  _get(underwearItemSelector(state), 'errors.loadUnderwearError')
);
