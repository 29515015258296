import {
  ACTION_SET_AUTH_DATA,
  ACTION_AUTH__REQUEST,
  ACTION_AUTH__SUCCESS,
  ACTION_AUTH__FAIL,
} from '../actionTypes';

import { WL_TEMPLATE_NOT_SUPPORTED } from '../../core/api/constants/errors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


export const actionSetAuthData = authData => ({
  type: ACTION_SET_AUTH_DATA,
  payload: { authData },
});

const actionAuthSuccess = () => ({
  type: ACTION_AUTH__SUCCESS,
});

const actionAuthFail = error => ({
  type: ACTION_AUTH__FAIL,
  payload: { error },
});

export const actionAuthRequest = (authRequired = true) => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_AUTH__REQUEST,
    });

    return apiClientHelper.get(
      'auth'
    ).then(
      auth => {
        dispatch(actionSetAuthData(auth));

        dispatch(actionAuthSuccess());
      },
      error => {
        if (authRequired || error.globalError.code === WL_TEMPLATE_NOT_SUPPORTED) {
          dispatch(actionAuthFail(error));
        } else {
          dispatch(actionAuthSuccess(null));
        }
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
