import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_ADD_POSTS,
  ACTION_ADD_MEDIA,
  ACTION_SET_BLOG,
  ACTION_LOAD_POSTS__SUCCESS,
  ACTION_UNSUBSCRIBE__REQUEST,
  ACTION_UNSUBSCRIBE__SUCCESS,
  ACTION_UNSUBSCRIBE__FAIL,
  ACTION_SET_ACTIVE_VIDEO_UUID,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_BLOG:
      return action.payload.blog;

    case ACTION_LOAD_POSTS__SUCCESS: {
      const items = _union(
        state.posts.items,
        action.payload.posts.items
      );

      return {
        ...state,
        posts: {
          items,
          count: action.payload.posts.count,
          limit: action.payload.posts.limit,
        },
      };
    }

    default:
      return state;
  }
};

const postsById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_POSTS:
      return { ...state, ...action.payload.posts };

    default:
      return state;
  }
};

const mediaById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_MEDIA:
      return { ...state, ...action.payload.media };

    default:
      return state;
  }
};

const isUserBeingUnsubscribed = (state = false, action) => {
  switch (action.type) {
    case ACTION_UNSUBSCRIBE__REQUEST:
      return true;

    case ACTION_UNSUBSCRIBE__SUCCESS:
    case ACTION_UNSUBSCRIBE__FAIL:
      return false;

    default:
      return state;
  }
};

const activeVideoUuid = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_ACTIVE_VIDEO_UUID:
      return action.payload.videoUuid;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  postsById,
  mediaById,
  isUserBeingUnsubscribed,
  activeVideoUuid,
  errors,
});
