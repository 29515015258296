import { combineReducers } from 'redux';
import _omit from 'lodash/omit';
import _without from 'lodash/without';

import errors from './errors';

import {
  ACTION_SET_PROFILE_PHOTOS,
  ACTION_DELETE_PROFILE_PHOTO__REQUEST,
  ACTION_DELETE_PROFILE_PHOTO__SUCCESS,
  ACTION_DELETE_PROFILE_PHOTO__FAIL,
} from '../../actionTypes/profilePhoto';


const profilePhotos = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_PROFILE_PHOTOS:
      return action.payload.profilePhotos;

    case ACTION_DELETE_PROFILE_PHOTO__SUCCESS:
      return _without(state, action.payload.profilePhotoId);

    default:
      return state;
  }
};

const isProfilePhotoBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_PROFILE_PHOTO__REQUEST:
      return { ...state, [action.payload.profilePhotoId]: true };

    case ACTION_DELETE_PROFILE_PHOTO__SUCCESS:
    case ACTION_DELETE_PROFILE_PHOTO__FAIL:
      return _omit(state, action.payload.profilePhotoId);

    default:
      return state;
  }
};

export default combineReducers({
  profilePhotos,
  isProfilePhotoBeingDeleted,
  errors,
});
