import {
  ACTION_LOAD_INIT_SETTINGS__REQUEST,
  ACTION_LOAD_INIT_SETTINGS__SUCCESS,
  ACTION_LOAD_INIT_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddTranslations } from 'react-redux-app/lib/modules/i18n/actions';
import { actionAddInitSettings } from './addInitSettings';


const actionLoadInitSettingsSuccess = () => ({
  type: ACTION_LOAD_INIT_SETTINGS__SUCCESS,
});

const actionLoadInitSettingsFail = error => ({
  type: ACTION_LOAD_INIT_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadInitSettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_INIT_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'init'
    ).then(
      ({
        responseLang,
        translations,
        ...settings
      }) => {
        dispatch(actionAddInitSettings({ ...settings, seo: { [responseLang]: settings.seo } }));
        dispatch(actionAddTranslations({ [responseLang]: translations }));
        dispatch(actionLoadInitSettingsSuccess());
      },
      error => {
        dispatch(actionLoadInitSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
