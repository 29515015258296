import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_OWN_UNDERWEAR,
  ACTION_SET_OWN_UNDERWEAR,

  ACTION_SET_OWN_UNDERWEAR_PRESERVE,
  ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE,

  ACTION_LOAD_OWN_UNDERWEAR__REQUEST,
  ACTION_LOAD_OWN_UNDERWEAR__SUCCESS,
  ACTION_LOAD_OWN_UNDERWEAR__FAIL,
} from '../actionTypes/underwear';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getUnderwearPaginationMode } from '../selectors/underwear';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionAddOwnUnderwear = underwear => ({
  type: ACTION_ADD_OWN_UNDERWEAR,
  payload: { underwear },
});

const actionSetOwnUnderwear = underwear => ({
  type: ACTION_SET_OWN_UNDERWEAR,
  payload: { underwear },
});

export const actionSetOwnUnderwearPreserve = () => ({
  type: ACTION_SET_OWN_UNDERWEAR_PRESERVE,
});

const actionSetOwnUnderwearActivePage = activePage => ({
  type: ACTION_SET_OWN_UNDERWEAR_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnUnderwearSuccess = () => ({
  type: ACTION_LOAD_OWN_UNDERWEAR__SUCCESS,
});

const actionLoadOwnUnderwearFail = error => ({
  type: ACTION_LOAD_OWN_UNDERWEAR__FAIL,
  payload: { error },
});

export const actionLoadOwnUnderwearRequest = ({ page = 1, limit = 36 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getUnderwearPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_UNDERWEAR__REQUEST,
    });

    return apiClientHelper.get(
      `account/underwear/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: underwear, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetOwnUnderwear(underwear));
          dispatch(actionSetOwnUnderwearActivePage(page));

          return dispatch(actionLoadOwnUnderwearSuccess());
        }

        // Preload underwear thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddOwnUnderwear(underwear));
          } else {
            dispatch(actionSetOwnUnderwear(underwear));
          }

          dispatch(actionSetOwnUnderwearActivePage(page));
          dispatch(actionLoadOwnUnderwearSuccess());
        });
      },
      error => {
        dispatch(actionLoadOwnUnderwearFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
