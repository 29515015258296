import { getChangePasswordUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated, getIsNeedChangePassword } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function notChangePasswordRequiredHandler(nextState, successCallback, errorCallback) {
    if (
      getIsAuthenticated(getState())
      && getIsNeedChangePassword(getState())
    ) {
      errorCallback(getChangePasswordUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
