import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useQueryParam } from 'react-redux-app/lib/modules/routing/hooks';

import {
  ANSWER_QUERY_PARAM,
  DEPOSIT_ID_QUERY_PARAM,
  AMOUNT_QUERY_PARAM,
  WARNING_MESSAGE_QUERY_PARAM,
  ERROR_MESSAGE_QUERY_PARAM,
  NOTIFICATION_MESSAGE_QUERY_PARAM,
} from '../../constants';

import {
  actionShowPaymentResultNotificationDialog,
} from '../../actions/dialogs/paymentResultNotificationDialog';

import DepositTracker from '../DepositTracker';


export default () => {
  const dispatch = useDispatch();

  const answer = useQueryParam(ANSWER_QUERY_PARAM);
  const depositId = parseInt(useQueryParam(DEPOSIT_ID_QUERY_PARAM), 10);
  const amount = parseInt(useQueryParam(AMOUNT_QUERY_PARAM), 10);

  const warningMessage = useQueryParam(WARNING_MESSAGE_QUERY_PARAM);
  const errorMessage = useQueryParam(ERROR_MESSAGE_QUERY_PARAM);
  const notificationMessage = useQueryParam(NOTIFICATION_MESSAGE_QUERY_PARAM);

  useEffect(() => {
    if (answer || warningMessage || errorMessage || notificationMessage) {
      dispatch(actionShowPaymentResultNotificationDialog());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <DepositTracker answer={answer} id={depositId} amount={amount} />;
};
