export const SOCIAL_TYPE__GOOGLE = 'google';

export const socialTypes = [
  SOCIAL_TYPE__GOOGLE,
];

export const socialTypeMnemonics = {
  [SOCIAL_TYPE__GOOGLE]: 'Google',
};

export const ERROR_CODE_QUERY_PARAM = 'errorCode';
export const AUTH_TOKEN_QUERY_PARAM = 'authToken';
export const SOCIAL_TOKEN_QUERY_PARAM = 'socialToken';
export const REDIRECT_URL_QUERY_PARAM = 'redirectUrl';
export const EMAIL_QUERY_PARAM = 'email';
