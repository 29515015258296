import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_LOAD_INTERVIEW__REQUEST,
  ACTION_LOAD_INTERVIEW__SUCCESS,
  ACTION_LOAD_INTERVIEW__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadInterviewSuccess = interview => ({
  type: ACTION_LOAD_INTERVIEW__SUCCESS,
  payload: { interview },
});

const actionLoadInterviewFail = error => ({
  type: ACTION_LOAD_INTERVIEW__FAIL,
  payload: { error },
});

export const actionLoadInterviewRequest = id => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_INTERVIEW__REQUEST,
    });

    return apiClientHelper.get(
      `interview/${id}`
    ).then(
      interview => {
        if (!dom.isBrowser()) {
          return dispatch(actionLoadInterviewSuccess(interview));
        }

        // Preload model photo thumb
        return preloadImage(interview.respondent.profilePhotoThumbUrl).then(() => {
          dispatch(actionLoadInterviewSuccess(interview));
        });
      },
      error => {
        dispatch(actionLoadInterviewFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
