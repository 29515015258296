import { ACTION_SET_DISCOUNT_CODE } from '../actionTypes';

import {
  DISCOUNT_CODE_COOKIE,
  COOKIE_DISCOUNT_CODE_EXPIRES,
} from '../constants';


export const actionSetDiscountCode = discountCode => ({
  type: ACTION_SET_DISCOUNT_CODE,
  payload: { discountCode },
});

export const actionSaveDiscountCode = discountCode => (
  (dispatch, getState, { setCookie }) => {
    setCookie(
      DISCOUNT_CODE_COOKIE,
      discountCode,
      {
        expires: COOKIE_DISCOUNT_CODE_EXPIRES,
        path: '/',
      }
    );

    dispatch(actionSetDiscountCode(discountCode));
  }
);

export const actionDropDiscountCode = () => (
  (dispatch, getState, { deleteCookie }) => {
    deleteCookie(DISCOUNT_CODE_COOKIE, { path: '/' });

    dispatch(actionSetDiscountCode(null));
  }
);
