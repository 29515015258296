import {
  ACTION_UPDATE_UNDERWEAR__REQUEST,
  ACTION_UPDATE_UNDERWEAR__SUCCESS,
  ACTION_UPDATE_UNDERWEAR__FAIL,
} from '../../../actionTypes/underwear';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_UPDATE_UNDERWEAR__REQUEST:
    case ACTION_UPDATE_UNDERWEAR__SUCCESS:
      return null;

    case ACTION_UPDATE_UNDERWEAR__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
