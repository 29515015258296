import { setCreditsBonuses } from '../../chatGallery/utils';
import { setOldUiCookie } from '../utils';

import {
  ACTION_LOGIN_BY_AUTH_TOKEN__REQUEST,
  ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS,
  ACTION_LOGIN_BY_AUTH_TOKEN__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionSetAuthData } from './auth';
import { actionSetLoginInfo } from './loadLoginInfo';


const actionLoginByAuthTokenSuccess = () => ({
  type: ACTION_LOGIN_BY_AUTH_TOKEN__SUCCESS,
});

const actionLoginByAuthTokenFail = error => ({
  type: ACTION_LOGIN_BY_AUTH_TOKEN__FAIL,
  payload: { error },
});

export const actionLoginByAuthTokenRequest = (authToken, zendesk) => (
  (dispatch, getState, { apiClientHelper, setCookie }) => {
    dispatch({
      type: ACTION_LOGIN_BY_AUTH_TOKEN__REQUEST,
    });

    return apiClientHelper.post(
      'login/auth_token',
      { data: { authToken, zendesk } }
    ).then(
      ({ auth, loginInfo }) => {
        setOldUiCookie(setCookie, loginInfo);
        setCreditsBonuses(dispatch, loginInfo);
        dispatch(actionSetAuthData(auth));
        dispatch(actionSetLoginInfo(loginInfo));

        dispatch(actionLoginByAuthTokenSuccess());
      },
      error => {
        dispatch(actionLoginByAuthTokenFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
