import _get from 'lodash/get';


export const getMediaViewerItem = (state, id) => _get(state, `mediaViewer.byId.${id}`);
export const getMediaViewerItemPhotoUrl = (state, id) => (
  _get(getMediaViewerItem(state, id), 'photoUrl')
);

export const getMediaIds = state => _get(state, 'mediaViewer.items');

const replyReceiverSelector = state => _get(state, 'mediaViewer.receiver');
export const getReplyReceiverId = state => _get(replyReceiverSelector(state), 'id');
export const getReplyReceiverNick = state => _get(replyReceiverSelector(state), 'nick');
