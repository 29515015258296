import {
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST:
    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS:
      return null;

    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
