import _get from 'lodash/get';


export const getAnsweredInterviews = state => (
  _get(state, 'interview.answeredInterviewsById')
);

export const getInterviewRequests = state => (
  _get(state, 'interview.interviewRequestsById')
);
export const getStaffInterviewRequests = state => (
  _get(state, 'interview.staffInterviewRequestsById')
);

const interviewerSelector = state => (
  _get(state, 'interview.interviewer')
);
export const getInterviewerFormId = state => (
  _get(interviewerSelector(state), 'interviewForm.id')
);
export const getInterviewerTitle = state => (
  _get(interviewerSelector(state), 'interviewForm.title')
);
export const getInterviewerDescription = state => (
  _get(interviewerSelector(state), 'interviewForm.description')
);
export const getInterviewerQuestions = state => (
  _get(interviewerSelector(state), 'questions')
);

export const getIsInterviewBeingRejected = (state, id) => (
  !!_get(state, `interview.isInterviewBeingRejected.${id}`)
);

const errorsSelector = state => _get(state, 'interview.errors');
export const getLoadInterviewerError = state => (
  _get(errorsSelector(state), 'loadInterviewerError')
);
export const getAnswerInterviewError = state => (
  _get(errorsSelector(state), 'answerInterviewError')
);
export const getRejectInterviewError = state => (
  _get(errorsSelector(state), 'rejectInterviewError')
);
