import {
  ACTION_SEND_REWARD_REQUEST__REQUEST,
  ACTION_SEND_REWARD_REQUEST__SUCCESS,
  ACTION_SEND_REWARD_REQUEST__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_SEND_REWARD_REQUEST__REQUEST:
    case ACTION_SEND_REWARD_REQUEST__SUCCESS:
      return null;

    case ACTION_SEND_REWARD_REQUEST__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
