import { combineReducers } from 'redux';

import complainCopyrightError from './complainCopyrightError';
import provideInformationError from './provideInformationError';
import removeContentError from './removeContentError';
import reportContentError from './reportContentError';
import reportIllegalContentError from './reportIllegalContentError';
import reportIllegalContentTfError from './reportIllegalContentTfError';


export default combineReducers({
  complainCopyrightError,
  provideInformationError,
  removeContentError,
  reportContentError,
  reportIllegalContentError,
  reportIllegalContentTfError,
});
