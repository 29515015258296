import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'auth.errors');
export const getAcceptPrizeError = state => (
  _get(errorsSelector(state), 'acceptPrizeError')
);
export const getAuthError = state => (
  _get(errorsSelector(state), 'authError')
);
export const getDeleteNotificationError = state => (
  _get(errorsSelector(state), 'deleteNotificationError')
);
export const getFillNameError = state => (
  _get(errorsSelector(state), 'fillNameError')
);
export const getLoadSpinPriceError = state => (
  _get(errorsSelector(state), 'loadSpinPriceError')
);
export const getLoadLoginInfoError = state => (
  _get(errorsSelector(state), 'loadLoginInfoError')
);
export const getLoadZendeskLoginLinkError = state => (
  _get(errorsSelector(state), 'loadZendeskLoginLinkError')
);
export const getLoginAsModelError = state => (
  _get(errorsSelector(state), 'loginAsModelError')
);
export const getLoginByAuthTokenError = state => (
  _get(errorsSelector(state), 'loginByAuthTokenError')
);
export const getLoginByCredentialsError = state => (
  _get(errorsSelector(state), 'loginByCredentialsError')
);
export const getLoginBySocialError = state => (
  _get(errorsSelector(state), 'loginBySocialError')
);
export const getLogoutError = state => (
  _get(errorsSelector(state), 'logoutError')
);
export const getRegisterError = state => (
  _get(errorsSelector(state), 'registerError')
);
export const getResetDepositRemindError = state => (
  _get(errorsSelector(state), 'resetDepositRemindError')
);
export const getReturnToAgencyError = state => (
  _get(errorsSelector(state), 'returnToAgencyError')
);
export const getSpinRouletteError = state => (
  _get(errorsSelector(state), 'spinRouletteError')
);
export const getUnbanError = state => (
  _get(errorsSelector(state), 'unbanError')
);
export const getVisitPageError = state => (
  _get(errorsSelector(state), 'visitPageError')
);
