import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


export const getOwnPost = (state, id) => _get(state, `ownPost.byId.${id}`);
export const getOwnPostVisibility = (state, id) => _get(getOwnPost(state, id), 'visibility');
export const getOwnPostText = (state, id) => _get(getOwnPost(state, id), 'text');
export const getOwnPostPrice = (state, id) => _get(getOwnPost(state, id), 'price');

const postIdsSelector = state => _get(state, 'ownPost.postIds');
export const getPostIds = state => _get(postIdsSelector(state), 'items');
const getPostsTotalCount = state => _get(postIdsSelector(state), 'count');
const getPostsLimit = state => _get(postIdsSelector(state), 'limit');
export const getIsLastPostLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getPostIds, getPostsTotalCount, getPostsLimit
);
export const getPostTotalPagesCount = state => getTotalPagesCount(
  state, getPostsTotalCount, getPostsLimit
);

export const getPostPaginationMode = state => _get(state, 'ownPost.paginationMode');
export const getPostActivePage = state => _get(state, 'ownPost.activePage');
export const getShouldLoadPostFirstPage = state => _get(state, 'ownPost.shouldLoadFirstPage');
export const getShouldReloadPostActivePage = state => (
  _get(state, 'ownPost.shouldReloadActivePage')
);

export const getIsPostBeingDeleted = (state, postId) => (
  !!_get(state, `ownPost.isPostBeingDeleted.${postId}`)
);

export const getAccessType = state => _get(state, 'ownPost.accessType');
export const getSentMedia = state => _get(state, 'ownPost.sentMedia');
export const getSentMediaByAccessType = (accessType, state) => (
  _get(getSentMedia(state), accessType)
);
export const getIsSentMediaSelected = (state, id) => (
  getSentMediaByAccessType(getAccessType(state), state)?.some(media => media.id === id)
);
