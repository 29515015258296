export const ACTION_CLEAR_DIALOG_MESSAGE_STATE = 'ACTION_CLEAR_DIALOG_MESSAGE_STATE';

export const ACTION_LOAD_DIALOG_MESSAGES__REQUEST = 'ACTION_LOAD_DIALOG_MESSAGES__REQUEST';
export const ACTION_LOAD_DIALOG_MESSAGES__SUCCESS = 'ACTION_LOAD_DIALOG_MESSAGES__SUCCESS';
export const ACTION_LOAD_DIALOG_MESSAGES__FAIL = 'ACTION_LOAD_DIALOG_MESSAGES__FAIL';

export const ACTION_ADD_DIALOG_MESSAGES = 'ACTION_ADD_DIALOG_MESSAGES';

export const ACTION_ADD_OLD_DIALOG_MESSAGES_IDS = 'ACTION_ADD_OLD_DIALOG_MESSAGES_IDS';

export const ACTION_SEND_MESSAGE__REQUEST = 'ACTION_SEND_MESSAGE__REQUEST';
export const ACTION_SEND_MESSAGE__SUCCESS = 'ACTION_SEND_MESSAGE__SUCCESS';
export const ACTION_SEND_MESSAGE__FAIL = 'ACTION_SEND_MESSAGE__FAIL';

export const ACTION_ADD_NEW_DIALOG_MESSAGES_IDS = 'ACTION_ADD_NEW_DIALOG_MESSAGES_IDS';

export const ACTION_CLEAR_NEW_MESSAGE_COUNT = 'ACTION_CLEAR_NEW_MESSAGE_COUNT';

export const ACTION_DELETE_MESSAGES__REQUEST = 'ACTION_DELETE_MESSAGES__REQUEST';
export const ACTION_DELETE_MESSAGES__SUCCESS = 'ACTION_DELETE_MESSAGES__SUCCESS';
export const ACTION_DELETE_MESSAGES__FAIL = 'ACTION_DELETE_MESSAGES__FAIL';

export const ACTION_CHOOSE_MESSAGE = 'ACTION_CHOOSE_MESSAGE';
export const ACTION_UNCHOOSE_MESSAGE = 'ACTION_UNCHOOSE_MESSAGE';

export const ACTION_LOAD_PEER__REQUEST = 'ACTION_LOAD_PEER__REQUEST';
export const ACTION_LOAD_PEER__SUCCESS = 'ACTION_LOAD_PEER__SUCCESS';
export const ACTION_LOAD_PEER__FAIL = 'ACTION_LOAD_PEER__FAIL';

export const ACTION_ADD_ATTACHMENT = 'ACTION_ADD_ATTACHMENT';

export const ACTION_DELETE_ATTACHMENT__REQUEST = 'ACTION_DELETE_ATTACHMENT__REQUEST';
export const ACTION_DELETE_ATTACHMENT__SUCCESS = 'ACTION_DELETE_ATTACHMENT__SUCCESS';
export const ACTION_DELETE_ATTACHMENT__FAIL = 'ACTION_DELETE_ATTACHMENT__FAIL';
