import { combineReducers } from 'redux';

import approveProfilePhotoError from './approveProfilePhotoError';
import cancelProfilePhotoError from './cancelProfilePhotoError';
import inspectModelError from './inspectModelError';
import loadAgencyModelError from './loadAgencyModelError';
import loadAgencyModelsError from './loadAgencyModelsError';
import loadModelRegistrationStepsError from './loadModelRegistrationStepsError';
import rotatePhotoError from './rotatePhotoError';
import updateModelStatusError from './updateModelStatusError';


export default combineReducers({
  approveProfilePhotoError,
  cancelProfilePhotoError,
  inspectModelError,
  loadAgencyModelError,
  loadAgencyModelsError,
  loadModelRegistrationStepsError,
  rotatePhotoError,
  updateModelStatusError,
});
