import { getGridFilter } from '../utils/whiteList';

import { ACTION_ADD_WHITELIST } from '../actionTypes';

import { WHITELIST_GRID_NAME } from '../constants/whiteList';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddWhitelist = whiteList => ({
  type: ACTION_ADD_WHITELIST,
  payload: { whiteList },
});

export const actionLoadWhiteListRequest = getActionLoadGridDataRequest(
  WHITELIST_GRID_NAME, getGridFilter, 'geo_filter/whitelist', actionAddWhitelist
);
