import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_ADD_FAVORITES,
  ACTION_ADD_FAVORITE_IDS,
  ACTION_SET_FAVORITE_IDS,
  ACTION_SET_FAVORITES_PRESERVE,
  ACTION_LOAD_FAVORITES__REQUEST,
  ACTION_LOAD_FAVORITES__SUCCESS,
  ACTION_LOAD_FAVORITES__FAIL,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getFavoritePaginationMode } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionAddFavorites = favorites => ({
  type: ACTION_ADD_FAVORITES,
  payload: { favorites },
});

const actionAddFavoriteIds = favorites => ({
  type: ACTION_ADD_FAVORITE_IDS,
  payload: { favorites },
});

const actionSetFavoriteIds = favorites => ({
  type: ACTION_SET_FAVORITE_IDS,
  payload: { favorites },
});

export const actionSetFavoritesPreserve = () => ({
  type: ACTION_SET_FAVORITES_PRESERVE,
});

const actionLoadFavoritesSuccess = () => ({
  type: ACTION_LOAD_FAVORITES__SUCCESS,
});

const actionLoadFavoritesFail = error => ({
  type: ACTION_LOAD_FAVORITES__FAIL,
  payload: { error },
});

export const actionLoadFavoritesRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getFavoritePaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_FAVORITES__REQUEST,
    });

    const { page = 1, orderBy, limit = 12 } = getFilter(location);

    const params = { limit };
    if (orderBy) {
      params.orderBy = orderBy;
    }

    return apiClientHelper.get(
      `favorite/list/${page}`,
      { params },
      ITEMS_SCHEMA
    ).then(
      ({ result: favoriteIds, entities }) => {
        const favorites = entities.items || {};

        dispatch(actionAddFavorites(favorites));

        if (!dom.isBrowser()) {
          dispatch(actionSetFavoriteIds(favoriteIds));

          return dispatch(actionLoadFavoritesSuccess());
        }

        const promises = Object.keys(favorites).map(id => preloadImage(favorites[id].photoUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddFavoriteIds(favoriteIds));
          } else {
            dispatch(actionSetFavoriteIds(favoriteIds));
          }

          dispatch(actionLoadFavoritesSuccess());
        });
      },
      error => {
        dispatch(actionLoadFavoritesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
