import {
  ACTION_CREATE_PARTNER__REQUEST,
  ACTION_CREATE_PARTNER__SUCCESS,
  ACTION_CREATE_PARTNER__FAIL,
  ACTION_DELETE_PARTNER__REQUEST,
  ACTION_DELETE_PARTNER__SUCCESS,
  ACTION_DELETE_PARTNER__FAIL,
  ACTION_UPDATE_PARTNER__REQUEST,
  ACTION_UPDATE_PARTNER__SUCCESS,
  ACTION_UPDATE_PARTNER__FAIL,
} from '../../actionTypes';

import { PARTNERS_GRID_NAME } from '../../constants';

import { actionLoadPartnersRequest } from '../../actions/partnersGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  PARTNERS_GRID_NAME,
  actionLoadPartnersRequest,
  [ACTION_CREATE_PARTNER__REQUEST, ACTION_DELETE_PARTNER__REQUEST, ACTION_UPDATE_PARTNER__REQUEST],
  [ACTION_CREATE_PARTNER__FAIL, ACTION_DELETE_PARTNER__FAIL, ACTION_UPDATE_PARTNER__FAIL],
  [ACTION_CREATE_PARTNER__SUCCESS, ACTION_DELETE_PARTNER__SUCCESS, ACTION_UPDATE_PARTNER__SUCCESS]
);
