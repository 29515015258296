import { NICK_QUERY_PARAM } from '../../modelContentSearchControl/constants';
import { CATEGORY_QUERY_PARAM } from '../../../saleItem/constants';
import { ORDER_BY_QUERY_PARAM } from '../../switch/constants';


export const PAGE_QUERY_PARAM = 'page';

export const PAGINATION_MODE__SCROLL = 'PAGINATION_MODE__SCROLL';
export const PAGINATION_MODE__SWITCH = 'PAGINATION_MODE__SWITCH';

export const paginationModes = [
  PAGINATION_MODE__SCROLL,
  PAGINATION_MODE__SWITCH,
];

export const loadableItemsQueryParams = [
  NICK_QUERY_PARAM,
  CATEGORY_QUERY_PARAM,
  ORDER_BY_QUERY_PARAM,
  PAGE_QUERY_PARAM,
];
