import {
  ACTION_CANCEL_INTERVIEW_FORM__REQUEST,
  ACTION_CANCEL_INTERVIEW_FORM__SUCCESS,
  ACTION_CANCEL_INTERVIEW_FORM__FAIL,
  ACTION_CREATE_INTERVIEW_FORM__REQUEST,
  ACTION_CREATE_INTERVIEW_FORM__SUCCESS,
  ACTION_CREATE_INTERVIEW_FORM__FAIL,
} from '../../actionTypes';

import { INTERVIEW_FORMS_GRID_NAME } from '../../constants/interviewForm';

import { actionLoadInterviewFormsRequest } from '../../actions/interviewFormsGrid';

import { getGridSaga } from 'react-redux-app/lib/modules/grid/sagas';


export default getGridSaga(
  INTERVIEW_FORMS_GRID_NAME,
  actionLoadInterviewFormsRequest,
  [ACTION_CANCEL_INTERVIEW_FORM__REQUEST, ACTION_CREATE_INTERVIEW_FORM__REQUEST],
  [ACTION_CANCEL_INTERVIEW_FORM__FAIL, ACTION_CREATE_INTERVIEW_FORM__FAIL],
  [ACTION_CANCEL_INTERVIEW_FORM__SUCCESS, ACTION_CREATE_INTERVIEW_FORM__SUCCESS]
);
