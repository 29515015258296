export const ACTION_ADD_VISITORS = 'ACTION_ADD_VISITORS';

export const ACTION_ADD_VISITOR_IDS = 'ACTION_ADD_VISITOR_IDS';
export const ACTION_SET_VISITOR_IDS = 'ACTION_SET_VISITOR_IDS';

export const ACTION_LOAD_VISITORS__REQUEST = 'ACTION_LOAD_VISITORS__REQUEST';
export const ACTION_LOAD_VISITORS__SUCCESS = 'ACTION_LOAD_VISITORS__SUCCESS';
export const ACTION_LOAD_VISITORS__FAIL = 'ACTION_LOAD_VISITORS__FAIL';

export const ACTION_SET_VISITORS_PRESERVE = 'ACTION_SET_VISITORS_PRESERVE';
