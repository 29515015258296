export const MEDIA_VIEWER__QUERY_FILTER_NAME = 'viewMedia';

export const MEDIA_VIEWER__TYPE__PUBLIC_PHOTO = 'publicPhoto';
export const MEDIA_VIEWER__TYPE__PRIVATE_PHOTO = 'privatePhoto';
export const MEDIA_VIEWER__TYPE__PROFILE_PHOTO = 'profilePhoto';
export const MEDIA_VIEWER__TYPE__POST_MEDIA = 'postMedia';

export const MEDIA_VIEWER__ALBUM__OWN = 'my';
export const MEDIA_VIEWER__ALBUM__LATEST = 'latest';

export const mediaViewerTypes = [
  MEDIA_VIEWER__TYPE__PUBLIC_PHOTO,
  MEDIA_VIEWER__TYPE__PRIVATE_PHOTO,
  MEDIA_VIEWER__TYPE__PROFILE_PHOTO,
  MEDIA_VIEWER__TYPE__POST_MEDIA,
];

export const mediaViewerBlogTypes = [
  MEDIA_VIEWER__TYPE__POST_MEDIA,
];

export const MEDIA_VIEWER_ITEM_LIMIT = 1000;
