import { uiLangs } from '../constants';

import formatLangForUrl from './formatLangForUrl';


export const isLangKeyValid = key => {
  if (!key) {
    return false;
  }

  return /^[0-9a-z.-]+$/.test(key);
};

export const getLangFromRequestUri = requestUri => {
  const re = new RegExp(`^/(${uiLangs.join('|')})(/.*)?$`);
  const matches = requestUri.match(re);
  return matches?.[1] || null;
};

export const getLangUrlPart = (lang, defaultLang = null) => {
  const langForUrl = defaultLang ? formatLangForUrl(lang, defaultLang) : lang;
  return langForUrl ? `/${langForUrl}` : '';
};

export const changeLangForRequestUri = (requestUri, lang, defaultLang) => {
  const currentLang = getLangFromRequestUri(requestUri);
  const langUrlPart = getLangUrlPart(lang, defaultLang);
  if (currentLang) {
    const re = new RegExp(`^/(${uiLangs.join('|')})`);
    return requestUri.replace(re, langUrlPart);
  }

  return `${langUrlPart}${requestUri}`;
};

export const stripLangFromUri = uri => {
  let uriBeforeStrip;
  let strippedUri = uri;

  do {
    uriBeforeStrip = strippedUri;
    strippedUri = uriBeforeStrip.replace(new RegExp(`^/(${uiLangs.join('|')})/`), '/');
  } while (uriBeforeStrip !== strippedUri);

  if (strippedUri.length === 3) {
    strippedUri = strippedUri.replace(new RegExp(`^/(${uiLangs.join('|')})`), '/');
  }

  return strippedUri;
};
