import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_UNDERWEAR_REQUESTS,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST,
  ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL,
  ACTION_CANCEL_UNDERWEAR_REQUEST,
  ACTION_CANCEL_UNDERWEAR__SUCCESS,
  ACTION_CANCEL_UNDERWEAR__FAIL,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_UNDERWEAR_REQUESTS:
      return { ...state, ...action.payload.underwearRequests };

    default:
      return state;
  }
};

const isUnderwearRequestBeingMarkedSent = (state = {}, action) => {
  switch (action.type) {
    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__REQUEST:
      return { ...state, [action.payload.saleId]: true };

    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__SUCCESS:
    case ACTION_MARK_UNDERWEAR_REQUEST_SENT__FAIL:
      return _omit(state, action.payload.saleId);

    default:
      return state;
  }
};

const isUnderwearRequestBeingCanceled = (state = {}, action) => {
  switch (action.type) {
    case ACTION_CANCEL_UNDERWEAR_REQUEST:
      return { ...state, [action.payload.saleId]: true };

    case ACTION_CANCEL_UNDERWEAR__SUCCESS:
    case ACTION_CANCEL_UNDERWEAR__FAIL:
      return _omit(state, action.payload.saleId);

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isUnderwearRequestBeingMarkedSent,
  isUnderwearRequestBeingCanceled,
  errors,
});
