import { PERMISSION_STATE__PROMPT, PERMISSION_STATE__DENIED } from '../constants';


export const getIsWebPushAvailable = () => 'serviceWorker' in navigator && 'PushManager' in window;

export const createServiceWorkerRegistration = async () => {
  await navigator.serviceWorker.register('/service-worker.js');

  return navigator.serviceWorker.ready;
};

export const getServiceWorkerRegistration = () => (
  navigator.serviceWorker.getRegistration()
);

const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const getSubscriptionInfo = subscription => {
  const subscriptionObject = JSON.parse(JSON.stringify(subscription));
  return {
    endpoint: subscriptionObject.endpoint,
    publicKey: subscriptionObject.keys.p256dh,
    authToken: subscriptionObject.keys.auth,
  };
};

export const createPushManagerSubscription = async (registration, applicationServerKey) => {
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
  });

  return getSubscriptionInfo(subscription);
};

export const getPushManagerSubscription = async registration => {
  const subscription = await registration.pushManager.getSubscription();
  if (!subscription) {
    return Promise.resolve(undefined);
  }

  return getSubscriptionInfo(subscription);
};

/**
 * @returns Promise<PERMISSION_STATE__PROMPT|PERMISSION_STATE__GRANTED|PERMISSION_STATE__DENIED>
 */
export const getNotificationPermissionState = async () => {
  if (!getIsWebPushAvailable()) {
    return PERMISSION_STATE__DENIED;
  }

  if (navigator.permissions) {
    const permissionStatus = await navigator.permissions.query({
      name: 'notifications',
    });
    return permissionStatus.state;
  }

  const permissionStatus = Notification.permission;

  return Promise.resolve(
    permissionStatus === 'default'
      ? PERMISSION_STATE__PROMPT
      : permissionStatus
  );
};
