import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadDialogsError } from '../../selectors/errors';

import { actionLoadDialogsRequest } from '../../actions/loadDialogs';


export default ({ getState, dispatch }) => (
  function loadDialogsHandler(nextState, successCallback, errorCallback) {
    dispatch(
      actionLoadDialogsRequest()
    ).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        const error = getLoadDialogsError(getState());
        if (error) {
          if (!maybeHandleCriticalError(nextState.location, error.globalError, errorCallback)) {
            console.consoleError('loadDialogsHandler error', error);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadDialogsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
