import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_ADD_BLACKLIST,
  ACTION_DELETE_BLOCKED_USER__SUCCESS,
  ACTION_DELETE_BLOCKED_USER__REQUEST,
  ACTION_DELETE_BLOCKED_USER__FAIL,
  ACTION_ADD_BLOCKED_USER__REQUEST,
  ACTION_ADD_BLOCKED_USER__SUCCESS,
  ACTION_ADD_BLOCKED_USER__FAIL,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_BLACKLIST:
      return { ...state, ...action.payload.blackList };

    case ACTION_DELETE_BLOCKED_USER__SUCCESS:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

const isBlockedUserBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_BLOCKED_USER__REQUEST:
      return { ...state, [action.payload.userId]: true };

    case ACTION_DELETE_BLOCKED_USER__SUCCESS:
    case ACTION_DELETE_BLOCKED_USER__FAIL:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

const isUserBeingBlocked = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_BLOCKED_USER__REQUEST:
      return action.payload.userId ? { ...state, [action.payload.userId]: true } : state;

    case ACTION_ADD_BLOCKED_USER__SUCCESS:
    case ACTION_ADD_BLOCKED_USER__FAIL:
      return action.payload.userId ? _omit(state, action.payload.userId) : state;

    default:
      return state;
  }
};

const isUserBlocked = (state = false, action) => {
  switch (action.type) {
    case ACTION_ADD_BLOCKED_USER__REQUEST:
    case ACTION_ADD_BLOCKED_USER__FAIL:
      return false;

    case ACTION_ADD_BLOCKED_USER__SUCCESS:
      return true;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  isBlockedUserBeingDeleted,
  isUserBeingBlocked,
  isUserBlocked,
  errors,
});
