import _get from 'lodash/get';

import { getIsLastLoaded, getTotalPagesCount } from '../../core/utils/selectors';


const videoSelector = state => _get(state, 'ownSaleItem.video');
const videosSelector = state => _get(videoSelector(state), 'videos');
export const getVideoIds = state => _get(videosSelector(state), 'items');
const getVideosTotalCount = state => _get(videosSelector(state), 'count');
const getVideosLimit = state => _get(videosSelector(state), 'limit');
export const getIsLastVideoLoaded = (state, activePageNumber) => getIsLastLoaded(
  state, activePageNumber, getVideoIds, getVideosTotalCount, getVideosLimit
);
export const getVideoTotalPagesCount = state => getTotalPagesCount(
  state, getVideosTotalCount, getVideosLimit
);

export const getVideoPaginationMode = state => _get(videoSelector(state), 'paginationMode');
export const getVideoActivePage = state => _get(videoSelector(state), 'activePage');
export const getShouldLoadVideoFirstPage = state => (
  _get(videoSelector(state), 'shouldLoadFirstPage')
);
export const getShouldReloadVideoActivePage = state => (
  _get(videoSelector(state), 'shouldReloadActivePage')
);

export const getIsVideoBeingDeleted = (state, videoId) => (
  !!_get(videoSelector(state), `isVideoBeingDeleted.${videoId}`)
);

const errorsSelector = state => _get(videoSelector(state), 'errors');
export const getLoadVideosError = state => (
  _get(errorsSelector(state), 'loadVideosError')
);
export const getCreateVideoError = state => (
  _get(errorsSelector(state), 'createVideoError')
);
export const getDeleteVideoError = state => (
  _get(errorsSelector(state), 'deleteVideoError')
);
export const getUpdateVideoError = state => (
  _get(errorsSelector(state), 'updateVideoError')
);
