import { createSelector } from 'reselect';
import _get from 'lodash/get';

import { USER_TYPE__AGENCY, USER_TYPE__COMMON, USER_TYPE__MODEL } from '../constants';


export const getAuthData = state => _get(state, 'auth.data');
export const getIsAuthenticated = state => !!getAuthData(state);

export const getUser = state => _get(getAuthData(state), 'user');
export const getUserId = state => _get(getUser(state), 'id');
export const getUserLogin = state => _get(getUser(state), 'login');
export const getUserNick = state => _get(getUser(state), 'nick');
export const getUserEmail = state => _get(getUser(state), 'email');
export const getUserProfilePhoto = state => _get(getUser(state), 'profilePhoto');
export const getUserCredits = state => _get(getUser(state), 'credits');
export const getUserRevenue = state => _get(getUser(state), 'revenue');
export const getUserSex = state => _get(getUser(state), 'sex');
export const getUserIsBlueChecked = state => _get(getUser(state), 'isBlueChecked');
export const getUnreadMessagesNumber = state => _get(getUser(state), 'unreadMessagesNumber');
export const getUnreadNewsFeedNumber = state => _get(getUser(state), 'unreadNewsFeedNumber');
export const getOnlineFavoritesNumber = state => _get(getUser(state), 'onlineFavoritesNumber');
export const getPrizes = state => _get(getUser(state), 'siteEventPrizeStats');
export const getPrizesAfterDeposit = state => (
  _get(getUser(state), 'adventPrizesAvailableAfterDeposit')
);
export const getSwitchedFromUser = state => _get(getUser(state), 'switchedFromUser');
export const getIncompleteRegistration = state => _get(getUser(state), 'incompleteRegistration');

export const getUserType = state => _get(getUser(state), 'type');
export const getIsAgency = state => getUserType(state) === USER_TYPE__AGENCY;
export const getIsCommon = state => (
  getUserType(state) === USER_TYPE__COMMON
  && !getIncompleteRegistration(state)
);
export const getIsModel = state => getUserType(state) === USER_TYPE__MODEL;

const requestsSelector = state => _get(getUser(state), 'requests');
export const getModelInterviewNumber = state => _get(requestsSelector(state), 'interviews');
export const getModelAppointmentNumber = state => _get(requestsSelector(state), 'appointments');
export const getModelUnderwearNumber = state => _get(requestsSelector(state), 'underwear');
export const getModelRequestNumber = createSelector(
  getModelInterviewNumber,
  getModelAppointmentNumber,
  getModelUnderwearNumber,
  (interviewNumber, appointmentNumber, underwearNumber) => (
    (interviewNumber + appointmentNumber + underwearNumber) || 0
  )
);

const isNeedChangeEmailSelector = state => _get(getAuthData(state), 'isNeedChangeEmail');
export const getUserBouncedEmail = state => _get(isNeedChangeEmailSelector(state), 'bouncedEmail');
export const getUserNewEmail = state => _get(isNeedChangeEmailSelector(state), 'newEmail');

const banSelector = state => _get(getAuthData(state), 'activeBan');
export const getIsBanned = state => !!banSelector(state);
export const getIsBanPermanent = state => _get(banSelector(state), 'isPermanent');
export const getBanLocation = state => _get(banSelector(state), 'location');
export const getIsUnbanRequestSent = state => _get(banSelector(state), 'isUnbanRequestSent');
export const getBanComment = state => _get(banSelector(state), 'comment');
export const getBanReason = state => _get(banSelector(state), 'reason');
export const getBanCreated = state => _get(banSelector(state), 'created');
export const getBanExpired = state => _get(banSelector(state), 'expired');
export const getBanServerTimestamp = state => _get(banSelector(state), 'serverTimestamp');

const underwearSelector = state => _get(getAuthData(state), 'underwear');
export const getIsUnderwearForbidden = state => !!_get(underwearSelector(state), 'isForbidden');

const underwearAddressSelector = state => _get(underwearSelector(state), 'address');
export const getUnderwearFullname = state => _get(underwearAddressSelector(state), 'fullname');
export const getUnderwearAddress = state => _get(underwearAddressSelector(state), 'address');
export const getUnderwearCity = state => _get(underwearAddressSelector(state), 'city');
export const getUnderwearProvince = state => _get(underwearAddressSelector(state), 'province');
export const getUnderwearZip = state => _get(underwearAddressSelector(state), 'zip');

export const getIsNeedChangePassword = state => _get(getAuthData(state), 'isNeedChangePassword');
export const getIsNeedSetWebcamType = state => _get(getAuthData(state), 'isNeedSetWebcamType');
export const getNotifications = state => _get(getAuthData(state), 'notifications');
export const getUserRoulettePrize = state => _get(getAuthData(state), 'siteEventRoulettePrize');
export const getUserAdventPrizes = state => _get(getAuthData(state), 'siteEventAdventPrizes');

export const getLoginInfo = state => _get(state, 'auth.loginInfo');
const payoutRequestNoticeSelector = state => _get(getLoginInfo(state), 'payoutRequestNotice');
export const getPayoutRequestNoticeAmount = state => _get(payoutRequestNoticeSelector(state), 'amount');
export const getPayoutRequestNoticeComment = state => _get(payoutRequestNoticeSelector(state), 'comment');
export const getPayoutRequestNoticeDate = state => _get(payoutRequestNoticeSelector(state), 'date');
export const getPayoutRequestNoticeMethod = state => _get(payoutRequestNoticeSelector(state), 'method');
export const getPayoutRequestNoticeBankAccount = state => _get(payoutRequestNoticeSelector(state), 'bankAccount');

const paidRouletteSelector = state => _get(state, 'auth.paidRoulette');
export const getPaidRouletteSector = state => _get(paidRouletteSelector(state), 'sector');
export const getPaidRouletteNextSpinPrice = state => _get(paidRouletteSelector(state), 'nextSpinPrice');
export const getPaidRoulettePrizeSubTypes = state => _get(paidRouletteSelector(state), 'prize.subTypes');
export const getIsRouletteSpinConfirmed = state => _get(state, 'auth.isRouletteSpinConfirmed');

export const getModelId = state => _get(state, 'auth.modelId');
