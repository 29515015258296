import { schema } from 'normalizr';


const INTERVIEW_REQUEST_SCHEMA = new schema.Entity(
  'items',
  {},
  { idAttribute: 'inviteId' }
);

export const INTERVIEW_REQUESTS_SCHEMA = {
  items: [INTERVIEW_REQUEST_SCHEMA],
};
