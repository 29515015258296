import React from 'react';

import { ThreeBounceSpinner, threeBounceSpinner } from 'react-redux-app/lib/modules/threeBounceSpinner/components';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { PAGE_SPINNER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const { themeApi } = threeBounceSpinner;

const PageSpinner = props => <ThreeBounceSpinner {...props} />;

export default theming.theme(
  PAGE_SPINNER,
  baseCssJson,
  themeApi
)(PageSpinner);
