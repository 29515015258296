import {
  ACTION_SET_CURRENT_PREVIEW_ITEM,
  ACTION_SET_PREVIEW_INITIALIZED,
  ACTION_SET_PREVIEW_READY,
} from '../actionTypes';


const defaultState = {
  previewModelId: null,
  previewInitialized: false,
  previewReady: false,
  settings: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_SET_CURRENT_PREVIEW_ITEM:
      return {
        ...state,
        previewModelId: action.payload.previewModelId,
      };

    case ACTION_SET_PREVIEW_INITIALIZED:
      return {
        ...state,
        previewInitialized: action.payload.previewInitialized,
      };

    case ACTION_SET_PREVIEW_READY:
      return {
        ...state,
        previewReady: action.payload.previewReady,
      };

    default:
      return state;
  }
};
