export default str => {
  const arr = str.split('_');

  switch (arr[0]) {
    case 'to':
      return `< ${arr[1]} cm`;

    case 'from':
      return `> ${arr[1]} cm`;

    default:
      return `${arr[0]} - ${arr[1]} cm`;
  }
};
