import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { HTML_TEXT } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'html',
];

const propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const HtmlText = ({
  tag: Tag = 'div',
  children,
  className = null,
  theme,
}) => (
  <Tag className={classnames(theme.html, className)}>
    {children}
  </Tag>
);

HtmlText.propTypes = propTypes;

export default theming.theme(
  HTML_TEXT,
  baseCssJson,
  themeApi
)(HtmlText);
