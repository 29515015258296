export const API_UNKNOWN_ERROR = 'API_UNKNOWN_ERROR';
export const API_BAD_REQUEST_ERROR = 'API_BAD_REQUEST_ERROR'; // 400
export const API_UNAUTHORIZED_ERROR = 'API_UNAUTHORIZED_ERROR'; // 401
export const API_FORBIDDEN_ERROR = 'API_FORBIDDEN_ERROR'; // 403
export const API_NOT_FOUND_ERROR = 'API_NOT_FOUND_ERROR'; // 404
export const API_OPERATION_FAILED_ERROR = 'OPERATION_FAILED';
export const API_BAD_REQUEST_BODY_ERROR = 'BAD_REQUEST_BODY';

// 400
export const FORBIDDEN = 'FORBIDDEN';
export const MONEY_OPERATIONS_WRONG_SITE = 'MONEY_OPERATIONS_WRONG_SITE';
export const WL_TEMPLATE_NOT_SUPPORTED = 'WL_TEMPLATE_NOT_SUPPORTED';

export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const NOT_FOUND = 'NOT_FOUND';
export const SPECIFIED_IN_MESSAGE = 'SPECIFIED_IN_MESSAGE';
