import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';

import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';


export const BANNED_USERS_GRID_NAME = 'BannedUsersGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'created',
  sortDirection: SORT_DIRECTION__DESC,
};
