import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_OWN_PRIVATE_PHOTOS,
  ACTION_SET_OWN_PRIVATE_PHOTOS,

  ACTION_SET_OWN_PRIVATE_PHOTOS_PRESERVE,
  ACTION_SET_OWN_PRIVATE_PHOTO_ACTIVE_PAGE,

  ACTION_LOAD_OWN_PRIVATE_PHOTOS__REQUEST,
  ACTION_LOAD_OWN_PRIVATE_PHOTOS__SUCCESS,
  ACTION_LOAD_OWN_PRIVATE_PHOTOS__FAIL,
} from '../actionTypes/privatePhoto';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getPrivatePhotoPaginationMode } from '../selectors/privatePhoto';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionAddOwnPrivatePhotos = privatePhotos => ({
  type: ACTION_ADD_OWN_PRIVATE_PHOTOS,
  payload: { privatePhotos },
});

const actionSetOwnPrivatePhotos = privatePhotos => ({
  type: ACTION_SET_OWN_PRIVATE_PHOTOS,
  payload: { privatePhotos },
});

export const actionSetOwnPrivatePhotosPreserve = () => ({
  type: ACTION_SET_OWN_PRIVATE_PHOTOS_PRESERVE,
});

const actionSetOwnPrivatePhotoActivePage = activePage => ({
  type: ACTION_SET_OWN_PRIVATE_PHOTO_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnPrivatePhotosSuccess = () => ({
  type: ACTION_LOAD_OWN_PRIVATE_PHOTOS__SUCCESS,
});

const actionLoadOwnPrivatePhotosFail = error => ({
  type: ACTION_LOAD_OWN_PRIVATE_PHOTOS__FAIL,
  payload: { error },
});

export const actionLoadOwnPrivatePhotosRequest = ({
  isOnlyApproved = false, page = 1, limit = 27,
} = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getPrivatePhotoPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_PRIVATE_PHOTOS__REQUEST,
    });

    return apiClientHelper.get(
      `account/private_photo/list/${page}`,
      { params: { isOnlyApproved, limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: privatePhotos, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetOwnPrivatePhotos(privatePhotos));
          dispatch(actionSetOwnPrivatePhotoActivePage(page));

          return dispatch(actionLoadOwnPrivatePhotosSuccess());
        }

        // Preload privatePhoto thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddOwnPrivatePhotos(privatePhotos));
          } else {
            dispatch(actionSetOwnPrivatePhotos(privatePhotos));
          }

          dispatch(actionSetOwnPrivatePhotoActivePage(page));
          dispatch(actionLoadOwnPrivatePhotosSuccess());
        });
      },
      error => {
        dispatch(actionLoadOwnPrivatePhotosFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);

export const actionLoadApprovedOwnPrivatePhotosRequest = (filter = {}) => (
  actionLoadOwnPrivatePhotosRequest({ ...filter, isOnlyApproved: true, limit: 28 })
);
