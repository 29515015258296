import { combineReducers } from 'redux';

import buySaleItemError from './buySaleItemError';
import buyUnderwearError from './buyUnderwearError';


export default combineReducers({
  buySaleItemError,
  buyUnderwearError,
});
