import { MODAL_DIALOG__FILL_NAME } from '../../constants/dialogs';

import { actionShowModal, actionHideModal } from 'react-redux-app/lib/modules/modal/actions';


export const actionShowFillNameDialog = () => dispatch => (
  dispatch(actionShowModal(MODAL_DIALOG__FILL_NAME))
);

export const actionHideFillNameDialog = () => dispatch => (
  dispatch(actionHideModal(MODAL_DIALOG__FILL_NAME))
);
