import { MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM } from '../../constants/dialogs';

import { actionHideBuySimpleSaleItemDialog } from '../../actions/dialogs/buySimpleSaleItemDialog';

import { getLoginDialogSaga } from '../../../auth/sagas/modals';


export default getLoginDialogSaga(
  MODAL_DIALOG__BUY_SIMPLE_SALE_ITEM, actionHideBuySimpleSaleItemDialog
);
