import React from 'react';
import PropTypes from 'prop-types';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { langKeyParamsPropType } from 'react-redux-app/lib/modules/i18n/propTypes';

import HtmlText from '../HtmlText';


const propTypes = {
  langKey: PropTypes.string,
  langKeyParams: langKeyParamsPropType,
  className: PropTypes.string,
};

const HtmlFromLangKey = ({ langKey = null, langKeyParams = {}, className = null }) => (
  <HtmlText className={className}>
    {l(langKey, langKeyParams, false)}
  </HtmlText>
);

HtmlFromLangKey.propTypes = propTypes;

export default HtmlFromLangKey;
