import {
  ACTION_LOAD_MODEL_PROFILE__REQUEST,
  ACTION_LOAD_MODEL_PROFILE__SUCCESS,
  ACTION_LOAD_MODEL_PROFILE__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadModelProfileSuccess = modelProfile => ({
  type: ACTION_LOAD_MODEL_PROFILE__SUCCESS,
  payload: { modelProfile },
});

const actionLoadModelProfileFail = error => ({
  type: ACTION_LOAD_MODEL_PROFILE__FAIL,
  payload: { error },
});

export const actionLoadModelProfileRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_MODEL_PROFILE__REQUEST,
    });

    return apiClientHelper.get(
      'account/model_information'
    ).then(
      modelProfile => {
        dispatch(actionLoadModelProfileSuccess(modelProfile));
      },
      error => {
        dispatch(actionLoadModelProfileFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
