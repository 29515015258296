export const ACTION_SET_PRESENTATIONS = 'ACTION_SET_PRESENTATIONS';

export const ACTION_LOAD_PRESENTATIONS__REQUEST = 'ACTION_LOAD_PRESENTATIONS__REQUEST';
export const ACTION_LOAD_PRESENTATIONS__SUCCESS = 'ACTION_LOAD_PRESENTATIONS__SUCCESS';
export const ACTION_LOAD_PRESENTATIONS__FAIL = 'ACTION_LOAD_PRESENTATIONS__FAIL';

export const ACTION_ACTIVATE_PRESENTATION__REQUEST = 'ACTION_ACTIVATE_PRESENTATION__REQUEST';
export const ACTION_ACTIVATE_PRESENTATION__SUCCESS = 'ACTION_ACTIVATE_PRESENTATION__SUCCESS';
export const ACTION_ACTIVATE_PRESENTATION__FAIL = 'ACTION_ACTIVATE_PRESENTATION__FAIL';

export const ACTION_CREATE_PRESENTATION__REQUEST = 'ACTION_CREATE_PRESENTATION__REQUEST';
export const ACTION_CREATE_PRESENTATION__SUCCESS = 'ACTION_CREATE_PRESENTATION__SUCCESS';
export const ACTION_CREATE_PRESENTATION__FAIL = 'ACTION_CREATE_PRESENTATION__FAIL';

export const ACTION_DELETE_PRESENTATION__REQUEST = 'ACTION_DELETE_PRESENTATION__REQUEST';
export const ACTION_DELETE_PRESENTATION__SUCCESS = 'ACTION_DELETE_PRESENTATION__SUCCESS';
export const ACTION_DELETE_PRESENTATION__FAIL = 'ACTION_DELETE_PRESENTATION__FAIL';

export const ACTION_UPDATE_PRESENTATION__REQUEST = 'ACTION_UPDATE_PRESENTATION__REQUEST';
export const ACTION_UPDATE_PRESENTATION__SUCCESS = 'ACTION_UPDATE_PRESENTATION__SUCCESS';
export const ACTION_UPDATE_PRESENTATION__FAIL = 'ACTION_UPDATE_PRESENTATION__FAIL';
