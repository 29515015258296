import {
  ACTION_LOAD_SOCIAL_SETTINGS__REQUEST,
  ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS,
  ACTION_LOAD_SOCIAL_SETTINGS__FAIL,
} from '../actionTypes';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';


const actionLoadSocialSettingsSuccess = socialSettings => ({
  type: ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS,
  payload: { socialSettings },
});

const actionLoadSocialSettingsFail = error => ({
  type: ACTION_LOAD_SOCIAL_SETTINGS__FAIL,
  payload: { error },
});

export const actionLoadSocialSettingsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_SOCIAL_SETTINGS__REQUEST,
    });

    return apiClientHelper.get(
      'social_network'
    ).then(
      socialSettings => {
        dispatch(actionLoadSocialSettingsSuccess(socialSettings));
      },
      error => {
        dispatch(actionLoadSocialSettingsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
