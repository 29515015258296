import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadWalletAddressError, getLoadDepositSettingsError } from '../../selectors/errors';

import { actionLoadDepositSettingsRequest } from '../../actions/loadDepositSettings';
import { actionLoadWalletAddressRequest } from '../../actions/loadWalletAddress';


export default ({ getState, dispatch }) => (
  function loadWalletAddressHandler(nextState, successCallback, errorCallback) {
    const { currency } = nextState.params;

    Promise.all([
      dispatch(actionLoadWalletAddressRequest(currency)),
      dispatch(actionLoadDepositSettingsRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadWalletAddressError(getState()),
          getLoadDepositSettingsError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            console.consoleError('loadWalletAddressHandler error', globalError);
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadWalletAddressHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
