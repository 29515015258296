import React from 'react';

import PageSpinner from '../../../core/components/PageSpinner';

import styles from './styles/index.css.json';


export default () => (
  <div className={styles.loading}>
    <PageSpinner />
  </div>
);
