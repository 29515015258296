import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';

import {
  ACTION_ADD_OWN_POSTS,

  ACTION_ADD_OWN_POST_IDS,
  ACTION_SET_OWN_POST_IDS,

  ACTION_SET_OWN_POSTS_PRESERVE,
  ACTION_SET_OWN_POST_ACTIVE_PAGE,

  ACTION_LOAD_OWN_POSTS__REQUEST,
  ACTION_LOAD_OWN_POSTS__SUCCESS,
  ACTION_LOAD_OWN_POSTS__FAIL,
} from '../actionTypes';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { POSTS_SCHEMA } from '../schemas';

import { getPostPaginationMode } from '../selectors';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnMedia } from './index';


export const actionAddOwnPosts = posts => ({
  type: ACTION_ADD_OWN_POSTS,
  payload: { posts },
});

const actionAddOwnPostIds = postIds => ({
  type: ACTION_ADD_OWN_POST_IDS,
  payload: { postIds },
});

const actionSetOwnPostIds = postIds => ({
  type: ACTION_SET_OWN_POST_IDS,
  payload: { postIds },
});

export const actionSetOwnPostsPreserve = () => ({
  type: ACTION_SET_OWN_POSTS_PRESERVE,
});

const actionSetOwnPostActivePage = activePage => ({
  type: ACTION_SET_OWN_POST_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnPostsSuccess = () => ({
  type: ACTION_LOAD_OWN_POSTS__SUCCESS,
});

const actionLoadOwnPostsFail = error => ({
  type: ACTION_LOAD_OWN_POSTS__FAIL,
  payload: { error },
});

export const actionLoadOwnPostsRequest = ({ page = 1, limit = 10 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getPostPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_POSTS__REQUEST,
    });

    return apiClientHelper.get(
      `account/blog/post/list/${page}`,
      { params: { limit } },
      POSTS_SCHEMA
    ).then(
      ({ result: postIds, entities }) => {
        const media = entities.media || {};

        dispatch(actionAddOwnPosts(entities.posts || {}));
        dispatch(actionAddOwnMedia(media));

        if (!dom.isBrowser()) {
          dispatch(actionSetOwnPostIds(postIds));
          dispatch(actionSetOwnPostActivePage(page));

          return dispatch(actionLoadOwnPostsSuccess());
        }

        // Preload media thumbs
        const promises = Object.keys(media).map(id => preloadImage(media[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddOwnPostIds(postIds));
          } else {
            dispatch(actionSetOwnPostIds(postIds));
          }

          dispatch(actionSetOwnPostActivePage(page));
          dispatch(actionLoadOwnPostsSuccess());
        });
      },
      error => {
        dispatch(actionLoadOwnPostsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
