import {
  ACTION_ADD_OWN_EROSTORIES,
  ACTION_SET_OWN_EROSTORIES,

  ACTION_SET_OWN_EROSTORIES_PRESERVE,
  ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE,

  ACTION_LOAD_OWN_EROSTORIES__REQUEST,
  ACTION_LOAD_OWN_EROSTORIES__SUCCESS,
  ACTION_LOAD_OWN_EROSTORIES__FAIL,
} from '../actionTypes/erostory';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getErostoryPaginationMode } from '../selectors/erostory';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionAddOwnErostories = erostories => ({
  type: ACTION_ADD_OWN_EROSTORIES,
  payload: { erostories },
});

const actionSetOwnErostories = erostories => ({
  type: ACTION_SET_OWN_EROSTORIES,
  payload: { erostories },
});

export const actionSetOwnErostoriesPreserve = () => ({
  type: ACTION_SET_OWN_EROSTORIES_PRESERVE,
});

const actionSetOwnErostoryActivePage = activePage => ({
  type: ACTION_SET_OWN_EROSTORY_ACTIVE_PAGE,
  payload: { activePage },
});

const actionLoadOwnErostoriesSuccess = () => ({
  type: ACTION_LOAD_OWN_EROSTORIES__SUCCESS,
});

const actionLoadOwnErostoriesFail = error => ({
  type: ACTION_LOAD_OWN_EROSTORIES__FAIL,
  payload: { error },
});

export const actionLoadOwnErostoriesRequest = ({ page = 1, limit = 18 } = {}) => (
  (dispatch, getState, { apiClientHelper }) => {
    const preserve = getErostoryPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_OWN_EROSTORIES__REQUEST,
    });

    return apiClientHelper.get(
      `account/story/list/${page}`,
      { params: { limit } },
      ITEMS_SCHEMA
    ).then(
      ({ result: erostories, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddOwnSaleItems(items));

        if (preserve) {
          dispatch(actionAddOwnErostories(erostories));
        } else {
          dispatch(actionSetOwnErostories(erostories));
        }

        dispatch(actionSetOwnErostoryActivePage(page));
        dispatch(actionLoadOwnErostoriesSuccess());
      },
      error => {
        dispatch(actionLoadOwnErostoriesFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
