import React from 'react';
import PropTypes from 'prop-types';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsVideoSite } from '../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';

import { getMenuItems, getCollapsedMenuItems } from '../../selectors/menuItems';


const propTypes = {
  itemComponent: PropTypes.func.isRequired,
  itemComponentProps: PropTypes.object,
  dropdownComponent: PropTypes.func.isRequired,
  isHeader: PropTypes.bool,
  className: PropTypes.string,
};

const MenuItems = ({
  itemComponent: ItemComponent,
  itemComponentProps = {},
  dropdownComponent: DropdownComponent,
  isHeader = false,
  className = null,
}) => {
  const collapsedMenuItems = useShortSelector(getCollapsedMenuItems);
  const menuItems = useShortSelector(getMenuItems);
  const isVideoSite = useIsVideoSite();

  return ((isVideoSite && isHeader) ? menuItems : collapsedMenuItems).map(
    ({ langKey, path, products }) => (products ? (
      <DropdownComponent
        itemLangKey={langKey}
        key={langKey}
        items={products}
        className={className}
      />
    ) : (
      <ItemComponent
        {...itemComponentProps}
        to={path}
        key={path}
        className={className}
      >
        {l(langKey)}
      </ItemComponent>
    ))
  );
};

MenuItems.propTypes = propTypes;

export default MenuItems;
