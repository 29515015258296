import { useDispatch } from 'react-redux';

import { useMaybeHandleCriticalError } from 'react-redux-app/lib/modules/api/hooks';
import { useUri } from 'react-redux-app/lib/modules/routing/hooks';

import { actionAddGlobalError } from 'react-redux-app/lib/modules/globalError/actions';


export default () => {
  const dispatch = useDispatch();

  const currentUri = useUri();

  const maybeHandleCriticalError = useMaybeHandleCriticalError();

  return (
    error,
    errorDialogHeaderLangKey,
    successCallback = null,
    errorCallback = null,
    reloadPageOnGlobalError = true,
    callback = null,
    globalErrorCodes = null
  ) => {
    if (callback) {
      callback();
    }

    if (!error) {
      if (successCallback) {
        successCallback();
      }

      return;
    }

    const { globalError } = error;

    if (maybeHandleCriticalError(globalError)) {
      return;
    }

    if (globalError && (!globalErrorCodes || globalErrorCodes.includes(globalError.code))) {
      dispatch(actionAddGlobalError({
        text: globalError.message,
        header: { id: errorDialogHeaderLangKey },
        redirectUrl: reloadPageOnGlobalError ? currentUri : null,
      }));

      if (reloadPageOnGlobalError) {
        return;
      }
    }

    if (errorCallback) {
      errorCallback(error);
    }
  };
};
