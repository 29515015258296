import { combineReducers } from 'redux';
import _union from 'lodash/union';

import errors from './errors';

import {
  ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS,
  ACTION_UPDATE_PERSONAL_CONTACT__SUCCESS,
} from '../../actionTypes/personalContact';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS:
      return action.payload.personalContacts;

    case ACTION_UPDATE_PERSONAL_CONTACT__SUCCESS:
      return {
        ...state,
        ...{
          init: {
            ...state.init,
            contacts: _union(state.init.contacts, [action.payload.personalContactId]),
          },
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  data,
  errors,
});
