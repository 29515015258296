import {
  ACTION_LOAD_EMOTICONS__REQUEST,
  ACTION_LOAD_EMOTICONS__SUCCESS,
  ACTION_LOAD_EMOTICONS__FAIL,
} from '../actionTypes';

import { EMOTICONS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionSetEmoticons, actionSetEmoticonIds } from './index';


const actionLoadEmoticonsSuccess = emoticons => ({
  type: ACTION_LOAD_EMOTICONS__SUCCESS,
  payload: { emoticons },
});

const actionLoadEmoticonsFail = error => ({
  type: ACTION_LOAD_EMOTICONS__FAIL,
  payload: { error },
});

export const actionLoadEmoticonsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_EMOTICONS__REQUEST,
    });

    return apiClientHelper.get(
      'emoticon/list',
      {},
      EMOTICONS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionSetEmoticons(entities.items));
        dispatch(actionSetEmoticonIds(result));

        dispatch(actionLoadEmoticonsSuccess());
      },
      error => {
        dispatch(actionLoadEmoticonsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
