import { combineReducers } from 'redux';

import privatePhoto from './privatePhoto';
import publicPhoto from './publicPhoto';
import video from './video';
import erostory from './erostory';
import underwear from './underwear';
import errors from './errors';

import {
  ACTION_ADD_SALE_ITEMS,
  ACTION_ADD_VIEW_SALE_ITEM_URL,
  ACTION_SET_VIEW_SALE_ITEM_ID,
  ACTION_BUY_SALE_ITEM__SUCCESS,
} from '../actionTypes';


const byId = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_SALE_ITEMS:
      return { ...state, ...action.payload.items };

    case ACTION_BUY_SALE_ITEM__SUCCESS:
      return {
        ...state,
        [action.payload.saleItemId]: { ...state[action.payload.saleItemId], isBought: true },
      };

    default:
      return state;
  }
};

const viewUrl = (state = null, action) => {
  switch (action.type) {
    case ACTION_ADD_VIEW_SALE_ITEM_URL:
      return action.payload.viewUrl;

    default:
      return state;
  }
};

const viewId = (state = null, action) => {
  switch (action.type) {
    case ACTION_SET_VIEW_SALE_ITEM_ID:
      return action.payload.viewId;

    default:
      return state;
  }
};

export default combineReducers({
  byId,
  viewUrl,
  viewId,
  privatePhoto,
  publicPhoto,
  video,
  erostory,
  underwear,
  errors,
});
