import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';
import { defaultFilter as baseDefaultFilter } from '../../core/queryFilter/constants';
import { rewardRequestStatuses } from './index';


export const REWARD_REQUESTS_GRID_NAME = 'RewardRequestsGrid';

export const defaultFilter = {
  ...baseDefaultFilter,
  sortField: 'created',
  sortDirection: SORT_DIRECTION__DESC,
  statuses: rewardRequestStatuses,
};
