import _get from 'lodash/get';


const errorsSelector = state => _get(state, 'agency.errors');
export const getApproveProfilePhotoError = state => (
  _get(errorsSelector(state), 'approveProfilePhotoError')
);
export const getCancelProfilePhotoError = state => (
  _get(errorsSelector(state), 'cancelProfilePhotoError')
);
export const getInspectModelError = state => (
  _get(errorsSelector(state), 'inspectModelError')
);
export const getLoadAgencyModelError = state => (
  _get(errorsSelector(state), 'loadAgencyModelError')
);
export const getLoadAgencyModelsError = state => (
  _get(errorsSelector(state), 'loadAgencyModelsError')
);
export const getLoadModelRegistrationStepsError = state => (
  _get(errorsSelector(state), 'loadModelRegistrationStepsError')
);
export const getRotatePhotoError = state => (
  _get(errorsSelector(state), 'rotatePhotoError')
);
export const getUpdateModelStatusError = state => (
  _get(errorsSelector(state), 'updateModelStatusError')
);
