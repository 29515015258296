import { SORT_DIRECTION__DESC } from 'react-redux-app/lib/modules/table/constants';


export const RECORDED_PRIVATES_GRID_NAME = 'RecordedPrivatesGrid';
export const PERSONAL_CONTACTS_GRID_NAME = 'PersonalContactsGrid';

export const defaultFilter = {
  sortField: 'added',
  sortDirection: SORT_DIRECTION__DESC,
  page: 1,
  limit: 50,
};

export const defaultPersonalContact = {
  data: '',
  cost: '',
  isEnabled: false,
};
