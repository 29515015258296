import {
  ACTION_LOGIN_BY_CREDENTIALS__REQUEST,
  ACTION_LOGIN_BY_CREDENTIALS__SUCCESS,
  ACTION_LOGIN_BY_CREDENTIALS__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LOGIN_BY_CREDENTIALS__REQUEST:
    case ACTION_LOGIN_BY_CREDENTIALS__SUCCESS:
      return null;

    case ACTION_LOGIN_BY_CREDENTIALS__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
