import {
  ACTION_LOAD_PERSONAL_CONTACTS__REQUEST,
  ACTION_LOAD_PERSONAL_CONTACTS__FAIL,
  ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS,
} from '../actionTypes/personalContact';

import { CONTACTS_SCHEMA } from '../schemas';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddOwnSaleItems } from './index';


const actionLoadPersonalContactsSuccess = personalContacts => ({
  type: ACTION_LOAD_PERSONAL_CONTACTS__SUCCESS,
  payload: { personalContacts },
});

const actionLoadPersonalContactsFail = error => ({
  type: ACTION_LOAD_PERSONAL_CONTACTS__FAIL,
  payload: { error },
});

export const actionLoadPersonalContactsRequest = () => (
  (dispatch, getState, { apiClientHelper }) => {
    dispatch({
      type: ACTION_LOAD_PERSONAL_CONTACTS__REQUEST,
    });

    return apiClientHelper.get(
      'account/personal_contact/init',
      {},
      CONTACTS_SCHEMA
    ).then(
      ({ result, entities }) => {
        dispatch(actionAddOwnSaleItems(entities.items));
        dispatch(actionLoadPersonalContactsSuccess(result));
      },
      error => {
        dispatch(actionLoadPersonalContactsFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
