import { combineReducers } from 'redux';
import _omit from 'lodash/omit';

import errors from './errors';

import {
  ACTION_LOAD_ACCOUNT__SUCCESS,
  ACTION_UPDATE_ACCOUNT__SUCCESS,
  ACTION_LOAD_PREFERENCES__SUCCESS,
  ACTION_UPDATE_PREFERENCES__SUCCESS,
  ACTION_LOAD_WEBCAM_SETTINGS__SUCCESS,
  ACTION_UPDATE_WEBCAM_SETTINGS__SUCCESS,
  ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS,
  ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS,
  ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__SUCCESS,
  ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS,
  ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST,
  ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS,
  ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL,
  ACTION_LOAD_PRIVACY__SUCCESS,
  ACTION_UPDATE_PRIVACY__SUCCESS,
  ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS,
  ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__SUCCESS,
  ACTION_ADD_GEO_FILTERS,
  ACTION_DELETE_GEO_FILTER__REQUEST,
  ACTION_DELETE_GEO_FILTER__SUCCESS,
  ACTION_DELETE_GEO_FILTER__FAIL,
  ACTION_LOAD_COUNTRIES__SUCCESS,
  ACTION_LOAD_MODEL_PROFILE__SUCCESS,
  ACTION_UPDATE_MODEL_PROFILE__SUCCESS,
  ACTION_ADD_WHITELIST,
  ACTION_DELETE_WHITELISTED_USER__REQUEST,
  ACTION_DELETE_WHITELISTED_USER__SUCCESS,
  ACTION_DELETE_WHITELISTED_USER__FAIL,
  ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS,
  ACTION_LOAD_PUSH_SETTINGS__SUCCESS,
  ACTION_UPDATE_PUSH_SETTINGS__SUCCESS,
  ACTION_DISCONNECT_FROM_TELEGRAM__REQUEST,
  ACTION_DISCONNECT_FROM_TELEGRAM__SUCCESS,
  ACTION_DISCONNECT_FROM_TELEGRAM__FAIL,
  ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS,
  ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS,
  ACTION_DETACH_SOCIAL__SUCCESS,
  ACTION_LOAD_BLOG_SETTINGS__SUCCESS,
  ACTION_UPDATE_BLOG_SETTINGS__SUCCESS,
  ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS,
} from '../actionTypes';


const data = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_ACCOUNT__SUCCESS:
    case ACTION_UPDATE_ACCOUNT__SUCCESS:
      return action.payload.account;

    default:
      return state;
  }
};

const preferences = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PREFERENCES__SUCCESS:
    case ACTION_UPDATE_PREFERENCES__SUCCESS:
      return action.payload.preferences;

    default:
      return state;
  }
};

const webcamSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_WEBCAM_SETTINGS__SUCCESS:
    case ACTION_UPDATE_WEBCAM_SETTINGS__SUCCESS:
      return action.payload.webcamSettings;

    default:
      return state;
  }
};


const blueCheckSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_BLUE_CHECK_SETTINGS__SUCCESS:
    case ACTION_UPDATE_BLUE_CHECK_SETTINGS__SUCCESS:
    case ACTION_UPDATE_BLUE_CHECK_AUTO_CONVERT__SUCCESS:
      return action.payload.blueCheckSettings;

    default:
      return state;
  }
};

const videochatExternalApplicationSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS:
    case ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS:
      return action.payload.videochatExternalApplicationSettings;

    default:
      return state;
  }
};

const areVideochatExternalApplicationSettingsBeingGenerated = (
  state = false, action
) => {
  switch (action.type) {
    case ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__REQUEST:
      return true;

    case ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__SUCCESS:
    case ACTION_GENERATE_VIDEOCHAT_EXTERNAL_APPLICATION_SETTINGS__FAIL:
      return false;

    default:
      return state;
  }
};

const privacy = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PRIVACY__SUCCESS:
    case ACTION_UPDATE_PRIVACY__SUCCESS:
      return action.payload.privacy;

    default:
      return state;
  }
};

const privacyAddressInformation = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PRIVACY_ADDRESS_INFORMATION__SUCCESS:
    case ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__SUCCESS:
      return action.payload.privacyAddressInformation;

    case ACTION_UPDATE_MODEL_PROFILE__SUCCESS:
    case ACTION_UPDATE_BLOG_SETTINGS__SUCCESS: {
      const { modelInfo } = action.payload.modelProfile;
      return {
        ...state,
        isVisible: {
          city: modelInfo.isCityVisible,
          country: modelInfo.isCountryVisible,
          province: modelInfo.isProvinceVisible,
        },
      };
    }

    default:
      return state;
  }
};

const modelProfile = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_MODEL_PROFILE__SUCCESS:
    case ACTION_UPDATE_MODEL_PROFILE__SUCCESS:
    case ACTION_LOAD_BLOG_SETTINGS__SUCCESS:
    case ACTION_UPDATE_BLOG_SETTINGS__SUCCESS:
      return action.payload.modelProfile;

    case ACTION_UPDATE_PRIVACY_ADDRESS_INFORMATION__SUCCESS: {
      const { isVisible } = action.payload.privacyAddressInformation;
      return {
        ...state,
        modelInfo: {
          ...state.modelInfo,
          isCityVisible: isVisible.city,
          isProvinceVisible: isVisible.province,
          isCountryVisible: isVisible.country,
        },
      };
    }

    default:
      return state;
  }
};

const countries = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_COUNTRIES__SUCCESS:
      return action.payload.countries;

    default:
      return state;
  }
};

const geoFiltersById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_GEO_FILTERS:
      return { ...state, ...action.payload.geoFilters };

    case ACTION_DELETE_GEO_FILTER__SUCCESS:
      return _omit(state, action.payload.geoFilterId);

    default:
      return state;
  }
};

const isGeoFilterBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_GEO_FILTER__REQUEST:
      return { ...state, [action.payload.geoFilterId]: true };

    case ACTION_DELETE_GEO_FILTER__SUCCESS:
    case ACTION_DELETE_GEO_FILTER__FAIL:
      return _omit(state, action.payload.geoFilterId);

    default:
      return state;
  }
};

const whiteListById = (state = {}, action) => {
  switch (action.type) {
    case ACTION_ADD_WHITELIST:
      return { ...state, ...action.payload.whiteList };

    case ACTION_DELETE_WHITELISTED_USER__SUCCESS:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

const isWhiteListedUserBeingDeleted = (state = {}, action) => {
  switch (action.type) {
    case ACTION_DELETE_WHITELISTED_USER__REQUEST:
      return { ...state, [action.payload.userId]: true };

    case ACTION_DELETE_WHITELISTED_USER__SUCCESS:
    case ACTION_DELETE_WHITELISTED_USER__FAIL:
      return _omit(state, action.payload.userId);

    default:
      return state;
  }
};

const telegramSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_TELEGRAM_SETTINGS__SUCCESS:
      return action.payload.telegramSettings;

    case ACTION_DISCONNECT_FROM_TELEGRAM__SUCCESS:
      return null;

    default:
      return state;
  }
};

const pushSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_PUSH_SETTINGS__SUCCESS:
    case ACTION_UPDATE_PUSH_SETTINGS__SUCCESS:
      return action.payload.pushSettings;

    default:
      return state;
  }
};

const isUserBeingDisconnectedFromTelegram = (state = false, action) => {
  switch (action.type) {
    case ACTION_DISCONNECT_FROM_TELEGRAM__REQUEST:
      return true;

    case ACTION_DISCONNECT_FROM_TELEGRAM__SUCCESS:
    case ACTION_DISCONNECT_FROM_TELEGRAM__FAIL:
      return false;

    default:
      return state;
  }
};

const socialSettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_SOCIAL_SETTINGS__SUCCESS:
    case ACTION_COMPLETE_SOCIAL_ATTACHMENT__SUCCESS:
    case ACTION_DETACH_SOCIAL__SUCCESS:
      return action.payload.socialSettings;

    default:
      return state;
  }
};

const flowerDeliverySettings = (state = null, action) => {
  switch (action.type) {
    case ACTION_LOAD_FLOWER_DELIVERY_SETTINGS__SUCCESS:
      return action.payload.flowerDeliverySettings;

    default:
      return state;
  }
};

export default combineReducers({
  data,
  preferences,
  webcamSettings,
  blueCheckSettings,
  videochatExternalApplicationSettings,
  areVideochatExternalApplicationSettingsBeingGenerated,
  privacy,
  privacyAddressInformation,
  modelProfile,
  countries,
  geoFiltersById,
  isGeoFilterBeingDeleted,
  whiteListById,
  isWhiteListedUserBeingDeleted,
  telegramSettings,
  pushSettings,
  isUserBeingDisconnectedFromTelegram,
  socialSettings,
  flowerDeliverySettings,
  errors,
});
