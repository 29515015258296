import _omit from 'lodash/omit';

import { getGridFilter } from '../utils/rewardRequest';

import { ACTION_ADD_REWARD_REQUESTS } from '../actionTypes';

import { REWARD_REQUESTS_GRID_NAME } from '../constants/rewardRequest';

import { getActionLoadGridDataRequest } from '../../core/grid/actions';


const actionAddRewardRequests = rewardRequests => ({
  type: ACTION_ADD_REWARD_REQUESTS,
  payload: { rewardRequests },
});

export const actionLoadRewardRequestsRequest = getActionLoadGridDataRequest(
  REWARD_REQUESTS_GRID_NAME,
  getGridFilter,
  'reward/request/list',
  actionAddRewardRequests,
  filter => ({ ..._omit(filter, ['statuses']), 'statuses[]': filter.statuses })
);
