import {
  ACTION_LEAVE_COMMENT__REQUEST,
  ACTION_LEAVE_COMMENT__SUCCESS,
  ACTION_LEAVE_COMMENT__FAIL,
} from '../../actionTypes';


export default (state = null, action) => {
  switch (action.type) {
    case ACTION_LEAVE_COMMENT__REQUEST:
    case ACTION_LEAVE_COMMENT__SUCCESS:
      return null;

    case ACTION_LEAVE_COMMENT__FAIL:
      return action.payload.error;

    default:
      return state;
  }
};
