import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { HIGHLIGHTED_NUMBER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'highlightedNumber',
];

const propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string,

  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const HighlightedNumber = ({ number, className = null, theme }) => (
  <strong className={classnames(theme.highlightedNumber, className)}>
    {number}
  </strong>
);

HighlightedNumber.propTypes = propTypes;

export default theming.theme(
  HIGHLIGHTED_NUMBER,
  baseCssJson,
  themeApi
)(HighlightedNumber);
